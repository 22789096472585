import React, { FC, ChangeEvent } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  name: string,
  checked?: boolean,
  error?: string,
  label?: string,
  disabled?: boolean,
  forAdept?: boolean,
  className?: string,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
};

const InputRadio: FC<Props> = ({
  name,
  checked = false,
  error = '',
  label = '',
  disabled = false,
  forAdept = false,
  className,
  onChange = () => {},
}) => (
  <div className={cx(styles.wrap, className)}>
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label
      className={cx(
        styles.checkbox,
        { [styles.forAdept]: forAdept },
      )}
    >
      <input
        type="radio"
        name={name}
        disabled={disabled}
        checked={checked}
        className={styles.input}
        onChange={onChange}
      />
      <span className={styles.checkmark} />
      {label && <span className={styles.text}>{label}</span>}
    </label>
    {error && <span className={styles.error}>{error}</span>}
  </div>
);

export default InputRadio;
