import React, {
  FC,
  memo,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { State } from 'types';
import { Modal } from 'components';
import {
  testVGetSessionsAction,
  testQGetSessionsAction,
} from 'store/test/actions';
import DescriptionBlockControl, { ControlProps } from './DescriptionBlockControl';
import { ValidationSessionsTable } from './ValidationSessionsTable';

import styles from './styles.module.scss';
import modal from '../PersonalTestVideo/styles.module.scss';
import { PersonalTestVideo } from '../PersonalTestVideo';
import { QualificationSessionsTable } from './QualificationSessionsTable';

type Props = {
  title: string,
  description: string,
  control: ControlProps,
};

const PersonalTestDescriptionBlock: FC<Props> = memo(({
  title,
  description,
  control,
}) => {
  const dispatch = useDispatch();
  const testState = useSelector(({ test }: State) => test);

  const [isShowValidHistory, setShowValidHistory] = useState(false);
  const [isShowQualHistory, setShowQualHistory] = useState(false);

  const OpenHistoryByKind:{
    qualification: () => void,
    validation: () => void,
  } = {
    qualification: () => setShowQualHistory(true),
    validation: () => setShowValidHistory(true),
  };

  useEffect(() => {
    if (
      testState.personalData.member.role === 'E' &&
      control.kind === 'qualification'
    ) {
      dispatch(testQGetSessionsAction());
    }
  }, [testState.personalData.member.role]);

  useEffect(() => {
    if (
      testState.personalData.member.role === 'E' &&
      control.kind === 'validation' &&
      testState.qualificationMeta.isFinished &&
      testState.qualificationMeta.status === 'P'
    ) {
      dispatch(testVGetSessionsAction());
    }
  }, [
    testState.personalData.member.role,
    testState.qualificationMeta.isFinished,
    testState.qualificationMeta.status,
  ]);

  const showQualSessionsTable:boolean = true;

  const showValidationSessionsTable:boolean =
    control.kind === 'validation' &&
    testState.validationSessions.length !== 0;

  const showStringVideoLink =
    (control.specificProps?.type !== 'button' && control.kind === 'qualification') ||
    (showValidationSessionsTable && control.kind === 'validation');

  let specificProps:ControlProps['specificProps'] = control.specificProps ?
    control.specificProps : undefined;
  if (control.specificProps?.type === 'button' ||
    control.specificProps?.type === 'error' ||
    control.specificProps?.type === 'passed'
  ) {
    specificProps = {
      ...control.specificProps,
      showHistory: showValidationSessionsTable,
      onHistoryClick: () => OpenHistoryByKind[control.kind](),
    };
  }

  return (
    <div className={styles.data}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
      {showStringVideoLink && (
        <PersonalTestVideo
          buttonName="Смотреть видеоинструкцию"
          videoUrl={control.videoProps?.videoUrl}
          notButton
        />
      )}
      <DescriptionBlockControl
        kind={control.kind}
        videoProps={control.videoProps}
        specificProps={specificProps}
      />

      {showQualSessionsTable && (
        <Modal
          isOpen={isShowQualHistory}
          onClose={() => setShowQualHistory(false)}
          title='История прохождения этапа "Квалификации"'
          className={styles.modalContent}
          classNameTitle={styles.modalTitle}
          classNameOverlay={modal.modalOverlay}
          classNameClose={styles.modalBtnClose}
        >
          <QualificationSessionsTable />
        </Modal>
      )}

      {showValidationSessionsTable && (
        <Modal
          isOpen={isShowValidHistory}
          onClose={() => setShowValidHistory(false)}
          title='История прохождения этапа "Валидация"'
          className={styles.modalContent}
          classNameTitle={styles.modalTitle}
          classNameOverlay={modal.modalOverlay}
          classNameClose={styles.modalBtnClose}
        >
          <ValidationSessionsTable />
        </Modal>
      )}
    </div>
  );
});

export default PersonalTestDescriptionBlock;
