/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FC, MouseEvent } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  theme?: 'default' | 'transparent',
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void,
};

const ButtonClose: FC<Props> = ({ theme = 'default', className, onClick = () => {} }) => (
  <button
    type="button"
    className={cx(
      styles.btn,
      styles[theme],
      className,
    )}
    onClick={onClick}
  />
);

export default ButtonClose;
