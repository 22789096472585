import React, {
  FC,
  useState,
  useEffect,
  // useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { useRequest } from 'hooks';
import { useFormik } from 'formik';
import { URL } from 'appConstants';
import {
  // LeadStatus,
  State,
  RequestStatus,
  TestGoodsData,
} from 'types';
import {
  // dateToUiFormat,
  validateEmptyParams,
} from 'utils';
import { PersonalTabLayout } from 'containers';
import {
  Preloader,
  NoData,
  Paginate,
  Input,
} from 'components';
// import cx from 'classnames';
import PersonalGoodsReqsFilter from '../PersonalGoodsReqsFilter';
import PersonalBDLayout from '../../PersonalBDLayout';
import { testResults } from '../testData';
import styles from './styles.module.scss';

type Props = {};

interface Params {
  support_measures: Array<number>,
  financial_res: number,
  page: number,
  count: number,
  results: TestGoodsData[],
  search?: string,
}

const PAGE_SIZE = 12;

const initialValues:Params = {
  support_measures: [],
  financial_res: 0,
  page: 0,
  count: testResults.length,
  results: testResults,
};

const PersonalGoodsMainTab: FC<Props> = () => {
  const [search, setSearch] = useState<string>('');
  // const [statusParam, setStatusParam] = useState<number[]>([]);
  // const [categoryParam, setCategoryParam] = useState<number[]>([]);
  const [statusParam, setStatusParam] = useState<number>(0);
  const [categoryParam, setCategoryParam] = useState<number>(0);
  // const [detail, setDetail] = useState<number>(-1);

  const { data: GoodsData, status: GoodsReqStatus, request: GoodsRequest } =
    useRequest<TestGoodsData[]>();
  // const { data: StatData, request: StatRequest } = useRequest<LeadStatus[]>();
  // const { data: CatData, request: CatRequest } = useRequest<LeadStatus[]>();
  const token = useSelector(({ me }: State) => me.token);

  const {
    values,
    handleChange,
    handleSubmit,
  } = useFormik<Params>({
    initialValues,
    enableReinitialize: true,
    onSubmit: (params) => {
      const {
        page,
        ...paramsToSend
      } = params;
      GoodsRequest({
        method: 'get',
        params: {
          ...validateEmptyParams({
            params: paramsToSend,
            emptyParamsToDelete: [
              'search',
              'support_measures',
              'new_country',
              'show_archive',
              'category_of_event',
              'form_of_holding',
              'start_filter_date',
              'end_filter_date',
            ],
          }),
          limit: PAGE_SIZE,
          offset: page * PAGE_SIZE,
        },
        // paramsSerializer: (value) => qs.stringify(value, { indices: false }),
        url: URL.EVENTS.GET,
      });
    },
  });

  // const setStatusFilter = (val: number = 0) => {
  //   if (statusParam.includes(val)) {
  //     setStatusParam(statusParam.filter((i) => (i !== val)));
  //   } else {
  //     setStatusParam(statusParam.concat(val));
  //   }
  // };

  const setStatusFilter = (val: number = 0) => {
    setStatusParam(val);
  };

  const setCategoryFilter = (val: number = 0) => {
    setCategoryParam(val);
  };

  const clearFilters = () => {
    setSearch('');
    setStatusParam(0);
    setCategoryParam(0);
  };

  const handleSearch = () => {
    handleSubmit();
  };

  useEffect(() => {
    GoodsRequest({
      method: 'GET',
      url: `${URL.PERSONAL.GOODS.REQS}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        limit: 9,
        offset: 0,
      },
    });
  }, [statusParam, categoryParam, search]);

  // useEffect(() => {
  //   StatRequest({
  //     method: 'GET',
  //     url: URL.PERSONAL.LEADS.STATUS,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  // }, []);

  // useEffect(() => {
  //   CatRequest({
  //     method: 'GET',
  //     url: URL.PERSONAL.LEADS.CATEGORY,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  // }, []);

  const renderSearch = (
    <Input
      type="search"
      name="search"
      value={values.search}
      placeholder="Поиск"
      onChange={handleChange}
      onSearch={handleSearch}
      className={styles.search}
    />
  );

  return (
    <PersonalTabLayout
      single
      className={styles.layout}
    >
      {/* <div className={styles.mainContent}> */}
      <div className={styles.filterNSearch}>
        {renderSearch}
        <PersonalGoodsReqsFilter
          categoryParam={categoryParam}
          statusParam={statusParam}
          setCategoryFilter={setCategoryFilter}
          setStatusFilter={setStatusFilter}
          clearFilters={clearFilters}
        />
      </div>

      <Preloader isLoading={GoodsReqStatus === RequestStatus.REQUEST}>
        <NoData count={GoodsData?.length || initialValues?.count || 0}>
          <PersonalBDLayout
            data={initialValues}
            viewSwitcher
            showCount
          />
          <Paginate
            page={values.page}
            count={initialValues?.count || 0}
            pageSize={PAGE_SIZE}
            className={styles.paginate}
            onChange={(value) => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              handleChange({
                target: {
                  name: 'page',
                  value,
                },
              });
              handleSubmit();
            }}
          />
        </NoData>
      </Preloader>

      {/* {GoodsData?.[detail] && (
        <PersonalExchangeDetail
          data={GoodsData[detail]}
        />
      )} */}
    </PersonalTabLayout>
  );
};

export default PersonalGoodsMainTab;
