import React, {
  FC,
  useState,
  useEffect,
  useMemo,
} from 'react';
import {
  PersonalBaseLayout,
} from 'containers';
import {
  Link,
} from 'components';
import {
  FavoriteEvent,
} from 'types';
import { dateToUiFormat, _t_back } from 'utils';
import { routes } from 'appConstants';
// import cx from 'classnames';
import styles from './styles.module.scss';

interface Props {
  data?: FavoriteEvent,
  onClose: () => void,
}

const PersonalLeadsDetail: FC<Props> = ({
  data = null,
  onClose,
}) => {
  const [showDetail, setShowDetail] = useState<boolean>(false);

  const params = useMemo(() => [
    {
      label: 'Страна',
      value: data?.event.country[0]?.title || '-',
    },
    {
      label: 'Дата мероприятия',
      value: `${dateToUiFormat(data?.event.date_of_event_start)} - ${dateToUiFormat(data?.event.date_of_event_end)}`,
    },
    {
      label: 'Отрасль',
      value: data?.event.support_measures.title || '-',
    },
    {
      label: 'Формат',
      value: data?.event.form_of_holding.title || '-',
    },
    {
      label: 'Дата подачи заявки',
      value: dateToUiFormat(data?.date_added),
    },
    // {
    //   label: 'Заявки принимаются до',
    //   value: 'data?.event.date_of_registration_to_event',
    // },
  ], [data]);

  useEffect(() => {
    if (data) {
      setShowDetail(true);
    } else {
      setShowDetail(false);
    }
  }, [data]);

  const closeHandler = () => {
    setShowDetail(false);
    onClose();
  };

  return (showDetail && data) ? (
    <div
      className={styles.PersonalLeadsDetail}
    >
      <div
        className={styles.close}
        onClick={closeHandler}
      />
      {/* <div className={styles.status}>
        Статус
      </div> */}
      <PersonalBaseLayout
        pageTitle={_t_back(data.event, 'title') || data.event.slug || ''}
        className={styles.detailContent}
        nonLang
        nonTopM
      >
        <div className={styles.data}>
          {params.map(({ label, value }) => (
            <div
              className={styles.dataLine}
              key={`leadsMain_${Math.random()}`}
            >
              <div className={styles.label}>{label}:</div>
              <div className={styles.value}>{value}</div>
            </div>
          ))}
        </div>

        <div>
          <Link
            className={styles.btn}
            to={`${routes.events.root}/${data.event.slug}`}
            title="Открыть мероприятие"
          >
            Открыть мероприятие
          </Link>
        </div>
      </PersonalBaseLayout>
    </div>
  ) : (
    <div />
  );
};

export default PersonalLeadsDetail;
