import React from 'react';
import type { Translates } from 'types';
import { ClientLang } from 'appConstants';
import MSP_l_en from 'assets/img/logo/main/light_en.svg';
import MSP_l_ru from 'assets/img/logo/main/light_ru.svg';
import MSP_d_en from 'assets/img/logo/main/dark_en.svg';
import MSP_d_ru from 'assets/img/logo/main/dark_ru.svg';
import DIPP_l_en from 'assets/img/logo/dipp/light_en.svg';
import DIPP_l_ru from 'assets/img/logo/dipp/light_ru.svg';
import DIPP_d_en from 'assets/img/logo/dipp/dark_en.svg';
import DIPP_d_ru from 'assets/img/logo/dipp/dark_ru.svg';
import ECOMOS_l_en from 'assets/img/logo/ecomos/light_en.svg';
import ECOMOS_l_ru from 'assets/img/logo/ecomos/light_ru.svg';
import ECOMOS_d_en from 'assets/img/logo/ecomos/dark_en.svg';
import ECOMOS_d_ru from 'assets/img/logo/ecomos/dark_ru.svg';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  // preset?: 'main' | 'dipp' | 'econom' | '',
  preset?: string,
  className?: string,
  view?: 'dark' | 'light',
  src?: string,
};

const LogoPresets:{[index:string]:Translates} = {
  main: {
    light: {
      en: MSP_l_en,
      ru: MSP_l_ru,
    },
    dark: {
      en: MSP_d_en,
      ru: MSP_d_ru,
    },
  },
  dipp: {
    light: {
      en: DIPP_l_en,
      ru: DIPP_l_ru,
    },
    dark: {
      en: DIPP_d_en,
      ru: DIPP_d_ru,
    },
  },
  econom: {
    light: {
      en: ECOMOS_l_en,
      ru: ECOMOS_l_ru,
    },
    dark: {
      en: ECOMOS_d_en,
      ru: ECOMOS_d_ru,
    },
  },
};

const Logo = ({
  preset = 'main',
  className,
  view = 'dark',
  src,
}: Props) => {
  return (
    <img
      src={src || LogoPresets[preset][view][ClientLang]}
      className={cx(styles.logo, className)}
      alt={`Logo_${preset}`}
      width="10px"
      height="10px"
    />
  );
};

export default Logo;
