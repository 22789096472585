import React, {
  FC,
  memo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'classnames';
import { _t } from 'utils';
import {
  ButtonRounded,
  Preloader,
  Modal,
  HTMLContent,
} from 'components';
import { State, TVideoProps } from 'types';
import {
  testQGetCustomPdfReport,
  testVGetCustomPdfReportConfirm,
  testQCloseCustomPdfReport,
  testVCloseCustomPdfReport,
  // testQShowReport,
  testQHideReport,
  testVHideReport,
  testQHideFinalAction,
  testVHideFinalAction,
} from 'store/test/actions';
import { PersonalTestVideo as VideoInstruction } from '../PersonalTestVideo';
import PersonalTestResults from '../../PersonalTestResultsTab';
import styles from './styles.module.scss';
import modalStyles from '../styles.module.scss';
import qualificationStyles from '../Tests/Qualification/styles.module.scss';
import './reportStyles.scss';

export type ControlProps = {
  kind: 'qualification' | 'validation';
  specificProps?: ButtonProps | WarnProps | DraftProps | ErrorProps | PassedProps;
  videoProps?: TVideoProps,
};

type ButtonProps = {
  type: 'button'
  onClick: (() => void) | (() => Promise<void>);
  onHistoryClick: (() => void) | (() => Promise<void>);
  showHistory?: boolean;
};
type WarnProps = {
  type: 'warning'
  text: 'notPassed' | 'notFinalized';
};
type DraftProps = {
  type: 'draft'
  date: string;
  progress: { passed: number; total: number };
  onContinue: (() => void) | (() => Promise<void>);
  onRemove: (() => void) | (() => Promise<void>);
};
type ErrorProps = {
  type: 'error'
  date?: string;
  onHistoryClick: (() => void) | (() => Promise<void>);
  showHistory?: boolean;
};
type PassedProps = {
  type: 'passed',
  dateComplete: string;
  dateNext: string;
  onHistoryClick: (() => void) | (() => Promise<void>);
  showHistory?: boolean;
};

const DescriptionBlockControl: FC<ControlProps> = memo(({
  kind,
  specificProps,
  videoProps,
}) => {
  const dispatch = useDispatch();
  const token = useSelector(({ me }: State) => me.token);
  const testState = useSelector(({ test }: State) => test);

  const [isActiveBtn, setActiveBtn] = useState(videoProps?.isVideoSeen || false);
  const endedHandler = () => {
    setActiveBtn(true);
    videoProps?.setSeenVideo();
  };

  const onSavedHandler = () => {
    dispatch(testVCloseCustomPdfReport());
    dispatch(testQCloseCustomPdfReport());
  };

  const closeReportsHandler = () => {
    dispatch(testQHideReport());
    dispatch(testVHideReport());
  };

  const closeFinalActionsHandler = () => {
    dispatch(testQHideFinalAction());
    dispatch(testVHideFinalAction());
  };

  let content = null;

  switch (specificProps?.type) {
    case 'button': {
      const text = kind === 'qualification' ? _t('Начать квалификацию') : _t('Начать валидацию');
      content = (!specificProps.showHistory ?
        (
          <>
            <div className={styles.buttonMsg}>
              Посмотрите видеоинструкцию, прежде чем {text.toLocaleLowerCase()}
            </div>
            <div>
              <ButtonRounded
                color="secondary"
                onClick={specificProps.onHistoryClick}
                title="показать отчеты"
              />
              <div className={styles.buttonsWrap}>
                <VideoInstruction
                  buttonName="Смотреть видеоинструкцию"
                  videoUrl={videoProps?.videoUrl}
                  onEndWatch={endedHandler}
                />
                <ButtonRounded
                  color="secondary"
                  onClick={specificProps.onClick}
                  title={text}
                  disabled={!isActiveBtn}
                />
              </div>
            </div>
          </>
        ) : (
          <div className={clsx(styles.buttonsWrap, styles.buttonsSpace)}>
            <ButtonRounded
              onClick={specificProps.onClick}
              title={text}
            />
            <ButtonRounded
              color="secondary"
              onClick={specificProps.onHistoryClick}
              title="показать отчеты"
            />
          </div>
        )
      );
      break;
    }
    case 'warning': {
      content = (
        <HTMLContent
          className={styles.warning}
          content={_t('Прохождение этапа недоступно. Этап «Квалификация» не был пройден или пройден более 6 месяцев назад.')}
        />
      );
      break;
    }
    case 'draft': {
      const text = _t('Доступна активная сессия этапа «Квалификация».<br>Сессия будет активна еще ') +
        (specificProps.date || '');
      content = (
        <>
          <HTMLContent content={text} className={styles.warning} />
          <div className={styles.buttonsWrap}>
            <ButtonRounded
              onClick={specificProps.onContinue}
              title={_t('Продолжить')}
            />
            <ButtonRounded
              onClick={specificProps.onRemove}
              color="secondary"
              title={_t('Удалить черновик')}
            />
          </div>
        </>
      );
      break;
    }
    case 'error': {
      const text = _t('этап квалификации не пройден') + specificProps.date;
      content = (
        <>
          <div className={styles.passedWrap}>
            <div className={styles.error}>
              {text}
            </div>
          </div>
          <div className={styles.buttonsWrap}>
            {/* <ButtonRounded
              onClick={() => {
                dispatch(testQShowReport());
              }}
              title={_t('Открыть отчет')}
            />
            <ButtonRounded
              onClick={() => {
                dispatch(testQGetCustomPdfReport());
              }}
              color="secondary"
              title={_t('Скачать отчет')}
            /> */}
            <ButtonRounded
              onClick={specificProps.onHistoryClick}
              color="secondary"
              title={_t('История отчетов')}
            />
          </div>
        </>
      );
      break;
    }
    case 'passed': {
      content = kind === 'qualification' ?
        (
          <>
            <div className={styles.passedWrap}>
              <HTMLContent
                className={styles.passed}
                content={`Опросник этапа «Квалификация» был пройден ${specificProps.dateComplete}.<br>Повторный запуск будет доступен с ${specificProps.dateNext}`}
              />
            </div>
            <div className={styles.buttonsWrap}>
              {/* <ButtonRounded
                onClick={() => {
                  dispatch(testQShowReport());
                }}
                title={_t('Открыть отчет')}
              />
              <ButtonRounded
                onClick={() => {
                  dispatch(testQGetCustomPdfReport());
                }}
                color="secondary"
                title={_t('Скачать отчет')}
              /> */}
              <ButtonRounded
                onClick={specificProps.onHistoryClick}
                color="secondary"
                title={_t('История отчетов')}
              />
            </div>
          </>
        ) : null;
      break;
    }
    default:
      break;
  }

  return (
    <div className={styles.controlWrap}>
      <Preloader
        isLoading={!specificProps}
        className={styles.preloader}
        withoutAnim={!!specificProps}
      >
        {content}
      </Preloader>

      <Modal
        title="Ответы обработаны."
        isOpen={
          (testState.qualificationMeta.checkFinalAction && kind === 'qualification') ||
          (testState.validationMeta.checkFinalAction && kind === 'validation')
        }
        onClose={() => {
          closeReportsHandler();
          closeFinalActionsHandler();
        }}
        className={clsx(modalStyles.modalContent, qualificationStyles.modal)}
        classNameTitle={modalStyles.modalTitle}
        classNameOverlay={modalStyles.modalOverlay}
      >
        <span>Для просмотра результата нажмите ниже.</span>
        <div className={modalStyles.modalControls}>
          <ButtonRounded
            className={modalStyles.modalButton}
            blueMode
            onClick={closeFinalActionsHandler}
            title={_t('Открыть отчет')}
          />
          <ButtonRounded
            className={modalStyles.modalButton}
            color="secondary"
            blueMode
            onClick={() => {
              if (testState.qualificationMeta.showReport) {
                dispatch(testQGetCustomPdfReport(testState.qualificationSessionID as string));
              }
              if (testState.validationMeta.showReport) dispatch(testVGetCustomPdfReportConfirm());
              closeFinalActionsHandler();
            }}
            title={_t('Скачать отчет')}
          />
        </div>
      </Modal>

      <Modal
        title=""
        isOpen={
          // testState.qualificationMeta.isFinished &&
          // testState.qualificationMeta.status !== 'A' &&
          !(testState.qualificationMeta.checkFinalAction ||
            testState.validationMeta.checkFinalAction) &&
          ((testState.qualificationMeta.showReport && kind === 'qualification') ||
            (testState.validationMeta.showReport && kind === 'validation'))
        }
        onClose={closeReportsHandler}
        className={styles.modalContent}
        classNameTitle={styles.modalTitle}
        classNameOverlay={styles.modalOverlay}
        id="modalSrollable"
      >
        <PersonalTestResults
          token={token}
          CompData={{
            name: testState.personalData.company.name || '',
            inn: testState.personalData.company.inn || '',
          }}
          validationId={kind === 'validation'
            ? testState.validationSessionShowID as string
            : testState.qualificationSessionShowID as string}
          kind={kind}
        />
      </Modal>

      {(
        (testState.qualificationMeta.getCustomPdf && kind === 'qualification') ||
        (testState.validationMeta.getCustomPdf && kind === 'validation')
      ) && (
        <div className={styles.toPDFWrapper}>
          <PersonalTestResults
            className={styles.toPDF}
            token={token}
            CompData={{
              name: testState.personalData.company.name || '',
              inn: testState.personalData.company.inn || '',
            }}
            validationId={kind === 'validation'
              ? testState.validationSessionShowID as string
              : testState.qualificationSessionShowID as string}
            kind={kind}
            saveConfig={{
              onEndSave: onSavedHandler,
              hideButtons: true,
            }}
          />
        </div>
      )}

    </div>
  );
});

export default DescriptionBlockControl;
