import React, { useState } from 'react';
import { MediaLayout, MediaBaseList } from 'containers';
import { Modal, RuTube } from 'components';
import { URL } from 'appConstants';
import { _t_back } from 'utils';
import type { News } from 'types';
import styles from './styles.module.scss';

const MediaVideosDetails = () => {
  const [openModalId, setOpenModalId] = useState<News | null>(null);

  const closeModal = () => {
    setOpenModalId(null);
  };

  return (
    <MediaLayout>
      <MediaBaseList
        requestUrl={URL.NEWS.TECHTOURS.GET}
        onClickHandler={(obj) => setOpenModalId(obj)}
        action="opener"
      />

      <Modal
        onClose={closeModal}
        isOpen={openModalId !== null}
        title={_t_back(openModalId, 'title')}
        className={styles.modal}
        classNameTitle={styles.title}
        classNameOverlay={styles.modalOverlay}
      >
        <RuTube
          url={_t_back(openModalId, 'video')}
        />

      </Modal>
    </MediaLayout>
  );
};

export default MediaVideosDetails;
