/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'classnames';
import { State, Step } from 'types';
import {
  testQDecrementStep,
  testQFinishAndShowReport,
  testQSendAnswer,
  testQVisibleToggle,
} from 'store/test/actions';
import { Modal, ButtonRounded } from 'components';
import { _t } from 'utils';
import { CardHeader } from '../CardHeader';
import {
  CardContent,
  CheckboxProps,
  RadioProps,
  RangeProps,
} from '../CardContent';
import type { AnswersProps } from '../CardContent';
import { CardControls } from '../CardControls';
import type { NextBtnTitle } from '../CardControls';

import styles from './styles.module.scss';
import modalStyles from '../../styles.module.scss';
import { Loader } from '../../PersonalTestLoader';

type QualificationCardProps = AnswersProps;

const QualificationCard: FC<QualificationCardProps & { handleNext?: () => void }> = memo(({
  kind,
  title,
  company,
  inn,
  name,
  position,
  answers,
  radioSelected,
  onChangeRadio,
  checkboxSelected,
  onChangeCheckbox,
  rangeSelected,
  onChangeRange,
  handleNext = () => {},
}) => {
  const dispatch = useDispatch();
  const testState = useSelector(({ test }: State) => test);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isInProgress, setInProgress] = useState(false);

  let cardContentProps: AnswersProps;
  let nextBtnTitle: NextBtnTitle;
  switch (kind) {
    case 'radio':
      cardContentProps = {
        kind,
        title,
        answers: answers as Step['answers'],
        radioSelected: radioSelected as RadioProps['radioSelected'],
        onChangeRadio: onChangeRadio as RadioProps['onChangeRadio'],
      };
      nextBtnTitle = 'Далее';
      break;
    case 'checkbox':
      cardContentProps = {
        kind,
        title,
        answers: answers as Step['answers'],
        checkboxSelected: checkboxSelected as CheckboxProps['checkboxSelected'],
        onChangeCheckbox: onChangeCheckbox as CheckboxProps['onChangeCheckbox'],
      };
      nextBtnTitle = 'Далее';
      break;
    case 'range':
      cardContentProps = {
        kind,
        title,
        answers: answers as Step['answers'],
        rangeSelected: rangeSelected as RangeProps['rangeSelected'],
        onChangeRange: onChangeRange as RangeProps['onChangeRange'],
      };
      nextBtnTitle = 'Далее';
      break;
    case 'text':
      cardContentProps = {
        kind,
        title,
        company: company as string,
        inn: inn as string,
        name: name as string,
        position: position as string,
      };
      nextBtnTitle = 'Подтвердить';
      break;
    default:
      cardContentProps = {
        kind: 'text',
        title: '',
        company: '',
        inn: '',
        name: '',
        position: '',
      };
      nextBtnTitle = 'Далее';
  }

  const isLastQuestion = testState.qualificationMeta.current === testState.qualificationMeta.total;
  if (nextBtnTitle === 'Далее' && isLastQuestion) {
    nextBtnTitle = 'Завершить';
  }

  return (
    <div className={styles.card}>
      <CardHeader
        // kind="qualification"
        currentQuestion={testState.qualificationMeta.current}
        passedQuestions={testState.qualificationMeta.passed}
        totalQuestions={testState.qualificationMeta.total}
        hideStep={kind === 'text'}
      />
      <div className={styles.contentControl}>
        <CardContent {...cardContentProps} />
        <CardControls
          sessionCreatedDate={testState.qualificationMeta.created}
          onBack={() => {
            if (
              !testState.qualificationMeta.passed ||
              !testState.qualificationMeta.current ||
              testState.qualificationMeta.current === 1
            ) {
              dispatch(testQVisibleToggle(false));
            } else {
              dispatch(testQDecrementStep());
            }
          }}
          onNext={() => {
            if (isLastQuestion) {
              setModalOpen(true);
              dispatch(testQSendAnswer({ shouldFinish: false, shouldExit: false }));
            } else {
              handleNext();
            }
          }}
          titleNext={nextBtnTitle}
          onExit={() => {
            if (testState.qualificationMeta.answer === null) {
              dispatch(testQVisibleToggle(false));
            } else {
              dispatch(testQSendAnswer({ shouldExit: true }));
            }
          }}
          isDisabledNext={!(
            (kind === 'radio' && radioSelected !== null) ||
            (kind === 'checkbox' && Array.isArray(checkboxSelected) && checkboxSelected.length) ||
            (kind === 'range' && Array.isArray(rangeSelected) && rangeSelected.every((a) => a !== null)) ||
            kind === 'text'
          )}
          showExit={kind !== 'text'}
          showFixData={kind === 'text'}
        />
      </div>

      <Modal
        onClose={() => setModalOpen(false)}
        title={isInProgress ?
          _t('Ответы обрабатываются') :
          _t('Вы уверены, что хотите завершить тестирование?')}
        isOpen={isModalOpen}
        className={clsx(modalStyles.modalContent, styles.modal)}
        classNameTitle={modalStyles.modalTitle}
        classNameOverlay={modalStyles.modalOverlay}
        classNameClose={modalStyles.modalBtnClose}
      >
        {isInProgress ? (
          <div>
            <span>
              {_t('Пожалуйста, ожидайте. Обработка ответов может занять некоторое время.')}
            </span>
            <div className={styles.loaderWrap}>
              <Loader />
            </div>
          </div>
        ) : (
          <div>
            <span className={modalStyles.modalText}>
              {_t('После завершения тестирования изменить ответы на вопросы будет невозможно.')}
            </span>
            <div className={modalStyles.modalControls}>
              <ButtonRounded
                className={modalStyles.modalButton}
                color="secondary"
                blueMode
                onClick={() => setModalOpen(false)}
                title={_t('Отмена')}
              />
              <ButtonRounded
                className={modalStyles.modalButton}
                color="primary"
                blueMode
                onClick={() => {
                  setInProgress(true);
                  dispatch(testQFinishAndShowReport());
                }}
                title={_t('Завершить')}
              />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
});

export { QualificationCard };
