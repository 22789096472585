import type {
  Action,
  GetHtmlReportRes,
  SessionStepsRes,
  VGetProductsRes,
  VSessionIDRes,
  VSessionsRes,
} from 'types';

const TEST_V_GET_PRODUCTS = 'TEST_V_GET_PRODUCTS';
const TEST_V_GET_PRODUCTS_SUCCESS = 'TEST_V_GET_PRODUCTS_SUCCESS';

const TEST_V_SESSION_ID = 'TEST_V_SESSION_ID';
const TEST_V_SESSION_ID_SUCCESS = 'TEST_V_SESSION_ID_SUCCESS';

const TEST_V_SHOW = 'TEST_V_SHOW';
const TEST_V_HIDE = 'TEST_V_HIDE';

const TEST_V_SELECT_COUNTRY = 'TEST_V_SELECT_COUNTRY';
const TEST_V_SELECT_PRODUCT = 'TEST_V_SELECT_PRODUCT';
const TEST_V_SELECT_PRODUCT_MANUAL = 'TEST_V_SELECT_PRODUCT_MANUAL';
const TEST_V_SET_PRODUCT_DESC = 'TEST_V_SET_PRODUCT_DESC';

const TEST_V_SESSIONS = 'TEST_V_SESSIONS';
const TEST_V_SESSIONS_SUCCESS = 'TEST_V_SESSIONS_SUCCESS';

const TEST_V_POPULATE_SESSION = 'TEST_V_POPULATE_SESSION';

const TEST_V_GET_STEPS = 'TEST_V_GET_STEPS';
const TEST_V_GET_STEPS_SUCCESS = 'TEST_V_GET_STEPS_SUCCESS';

const TEST_V_SEND_ANSWER = 'TEST_V_SEND_ANSWER';
const TEST_V_SEND_ANSWER_SUCCESS = 'TEST_V_SEND_ANSWER_SUCCESS';

const TEST_V_INCREMENT_STEP = 'TEST_V_INCREMENT_STEP';
const TEST_V_DECREMENT_STEP = 'TEST_V_DECREMENT_STEP';

const TEST_V_FINISH = 'TEST_V_FINISH';
const TEST_V_FINISH_SUCCESS = 'TEST_V_FINISH_SUCCESS';

const TEST_V_SELECT_RADIO = 'TEST_V_SELECT_RADIO';
const TEST_V_SELECT_CHECKBOX = 'TEST_V_SELECT_CHECKBOX';
const TEST_V_SELECT_RANGE = 'TEST_V_SELECT_RANGE';

const TEST_V_REMOVE_SESSION = 'TEST_V_REMOVE_SESSION';
const TEST_V_REMOVE_SESSION_SUCCESS = 'TEST_V_REMOVE_SESSION_SUCCESS';

const TEST_V_RUN_FROM_EXISTED = 'TEST_V_RUN_FROM_EXISTED';

const TEST_V_FINISH_AND_SHOW_REPORT = 'TEST_V_FINISH_AND_SHOW_REPORT';

const TEST_V_SHOW_REPORT = 'TEST_V_SHOW_REPORT';
const TEST_V_HIDE_REPORT = 'TEST_V_HIDE_REPORT';

const TEST_V_SHOW_FINAL_ACTION = 'TEST_V_SHOW_FINAL_ACTION';
const TEST_V_HIDE_FINAL_ACTION = 'TEST_V_HIDE_FINAL_ACTION';
const TEST_V_GET_CUSTOM_PDF_REPORT_CONFIRM = 'TEST_V_GET_CUSTOM_PDF_REPORT_CONFIRM';

const TEST_V_GET_HTML_REPORT = 'TEST_V_GET_HTML_REPORT';
const TEST_V_GET_HTML_REPORT_SUCCESS = 'TEST_V_GET_HTML_REPORT_SUCCESS';

const TEST_V_GET_PDF_REPORT = 'TEST_V_GET_PDF_REPORT';
const TEST_V_GET_CUSTOM_PDF_REPORT = 'TEST_V_GET_CUSTOM_PDF_REPORT';
const TEST_V_CLOSE_CUSTOM_PDF_REPORT = 'TEST_V_CLOSE_CUSTOM_PDF_REPORT';

export type VGetProductsAction = Action<typeof TEST_V_GET_PRODUCTS>;
export type VShowAction = Action<typeof TEST_V_SHOW>;
export type VHideAction = Action<typeof TEST_V_HIDE>;
export type VSelectCountryAction = Action<typeof TEST_V_SELECT_COUNTRY, { countryId: number }>;
export type VSelectProductAction = Action<typeof TEST_V_SELECT_PRODUCT, {
  productId: number | null;
}>;
export type VSelectProductManualAction = Action<typeof TEST_V_SELECT_PRODUCT_MANUAL, {
  value: string | null;
  field: 'productName' | 'productTNVED';
}>;
export type VSetProductDescAction = Action<typeof TEST_V_SET_PRODUCT_DESC, {
  value: string | null;
}>;
export type VGetSessionIDAction = Action<typeof TEST_V_SESSION_ID>;
export type VGetSessionsAction = Action<typeof TEST_V_SESSIONS>;
export type VPopulateSessionAction = Action<typeof TEST_V_POPULATE_SESSION>;
export type VGetStepsAction = Action<typeof TEST_V_GET_STEPS>;
export type VSendAnswerAction = Action<typeof TEST_V_SEND_ANSWER, { shouldExit: boolean }>;
export type VIncStepAction = Action<typeof TEST_V_INCREMENT_STEP>;
export type VDecStepAction = Action<typeof TEST_V_DECREMENT_STEP>;
export type VFinishAction = Action<typeof TEST_V_FINISH>;
export type VSelectRadioAction = Action<typeof TEST_V_SELECT_RADIO, { answer: number }>;
export type VSelectCheckboxAction = Action<typeof TEST_V_SELECT_CHECKBOX, {
  answer: number;
  isChecked: boolean;
}>;
export type VSelectRangeAction = Action<typeof TEST_V_SELECT_RANGE, {
  position: number;
  value: number;
}>;
export type VRemoveSessionAction = Action<typeof TEST_V_REMOVE_SESSION>;
export type VRunFromExisted = Action<typeof TEST_V_RUN_FROM_EXISTED>;
export type VFinishAndShowReport = Action<typeof TEST_V_FINISH_AND_SHOW_REPORT>;
export type VShowReport = Action<typeof TEST_V_SHOW_REPORT, { sessionId: string }>;
export type VHideReport = Action<typeof TEST_V_HIDE_REPORT>;
export type VShowFinalAction = Action<typeof TEST_V_SHOW_REPORT>;
export type VHideFinalAction = Action<typeof TEST_V_HIDE_REPORT>;
export type VGetHtmlReport = Action<typeof TEST_V_GET_HTML_REPORT, { sessionId: string }>;
export type VGetPdfReport = Action<typeof TEST_V_GET_PDF_REPORT, { sessionId: string }>;

export type VGetCustomPdfReport = Action<typeof TEST_V_GET_CUSTOM_PDF_REPORT, {
  sessionId: string;
}>;
export type VCloseCustomPdfReport = Action<typeof TEST_V_CLOSE_CUSTOM_PDF_REPORT>;

export type TestActionV =
  VGetProductsAction | Action<typeof TEST_V_GET_PRODUCTS_SUCCESS, VGetProductsRes> |
  VShowAction | VHideAction |
  VSelectCountryAction |
  VSelectProductAction |
  VSelectProductManualAction | VSetProductDescAction |
  VGetSessionIDAction | Action<typeof TEST_V_SESSION_ID, VSessionIDRes> |
  VGetSessionsAction | Action<typeof TEST_V_SESSIONS_SUCCESS, VSessionsRes> |
  VPopulateSessionAction |
  VGetStepsAction | Action<typeof TEST_V_GET_STEPS_SUCCESS, SessionStepsRes> |
  VSendAnswerAction | Action<typeof TEST_V_SEND_ANSWER_SUCCESS> |
  VIncStepAction | VDecStepAction |
  VFinishAction | Action<typeof TEST_V_FINISH_SUCCESS> |
  VRemoveSessionAction | Action<typeof TEST_V_REMOVE_SESSION_SUCCESS> |
  VSelectRadioAction | VSelectCheckboxAction | VSelectRangeAction |
  VRunFromExisted | VFinishAndShowReport |
  VShowReport | VHideReport |
  Action<typeof TEST_V_GET_CUSTOM_PDF_REPORT_CONFIRM> |
  VGetHtmlReport | Action<typeof TEST_V_GET_HTML_REPORT_SUCCESS, GetHtmlReportRes> |
  VGetPdfReport|
  VGetCustomPdfReport | VCloseCustomPdfReport;

export const actionTypesV = {
  TEST_V_GET_PRODUCTS,
  TEST_V_GET_PRODUCTS_SUCCESS,
  TEST_V_SHOW,
  TEST_V_HIDE,
  TEST_V_SELECT_COUNTRY,
  TEST_V_SELECT_PRODUCT,
  TEST_V_SELECT_PRODUCT_MANUAL,
  TEST_V_SET_PRODUCT_DESC,
  TEST_V_SESSION_ID,
  TEST_V_SESSION_ID_SUCCESS,
  TEST_V_SESSIONS,
  TEST_V_SESSIONS_SUCCESS,
  TEST_V_POPULATE_SESSION,
  TEST_V_GET_STEPS,
  TEST_V_GET_STEPS_SUCCESS,
  TEST_V_SEND_ANSWER,
  TEST_V_SEND_ANSWER_SUCCESS,
  TEST_V_INCREMENT_STEP,
  TEST_V_DECREMENT_STEP,
  TEST_V_FINISH,
  TEST_V_FINISH_SUCCESS,
  TEST_V_SELECT_RADIO,
  TEST_V_SELECT_CHECKBOX,
  TEST_V_SELECT_RANGE,
  TEST_V_REMOVE_SESSION,
  TEST_V_REMOVE_SESSION_SUCCESS,
  TEST_V_RUN_FROM_EXISTED,
  TEST_V_GET_HTML_REPORT,
  TEST_V_GET_HTML_REPORT_SUCCESS,
  TEST_V_SHOW_REPORT,
  TEST_V_HIDE_REPORT,
  TEST_V_SHOW_FINAL_ACTION,
  TEST_V_HIDE_FINAL_ACTION,
  TEST_V_GET_CUSTOM_PDF_REPORT_CONFIRM,
  TEST_V_FINISH_AND_SHOW_REPORT,
  TEST_V_GET_PDF_REPORT,
  TEST_V_GET_CUSTOM_PDF_REPORT,
  TEST_V_CLOSE_CUSTOM_PDF_REPORT,
};
