import { IAdeptItem, TTexts } from 'types';
import { ParamsText } from './data';

export const ValidParams:IAdeptItem[] = [
  {
    id: 0,
    toRes: 'd1',
    low: 0.49,
    mid: 0.73,
    ...ParamsText.d1,
  }, {
    id: 1,
    toRes: 'd2',
    low: 0.46,
    mid: 0.72,
    ...ParamsText.d2,
  }, {
    id: 2,
    toRes: 'd3',
    low: 0.42,
    mid: 0.62,
    ...ParamsText.d3,
  }, {
    id: 3,
    toRes: 'd4',
    low: 0.53,
    mid: 0.71,
    ...ParamsText.d4,
  }, {
    id: 4,
    toRes: 'd5',
    low: 0.2,
    mid: 0.6,
    ...ParamsText.d5,
  }, {
    id: 5,
    toRes: 'd6',
    low: 0.38,
    mid: 0.66,
    ...ParamsText.d6,
  }, {
    id: 6,
    toRes: 'd7',
    low: 0.23,
    mid: 0.52,
    ...ParamsText.d7,
  },
];

export const vTexts:TTexts = {
  d1: [
    {
      to: 1000,
      from: 4.0,
      class: 'high',
      text: [{
        p: 'Оценка {value} по измерению «Люди и компетенции» означает <strong>высокий уровень</strong> подготовки сотрудников в направлении экспортной деятельности, в частности для выбранного товара и целевого рынка. Данное измерение относится к «Сильным сторонам». Руководство и сотрудники обладают опытом во внешнеэкономической (экспортной) деятельности, понимают принципы позиционирования товаров на зарубежных рынках и располагают доступом к необходимой информации о юридических и таможенных аспектах экспортной деятельности. На данный момент развитие измерения «Люди и компетенции» не является приоритетным. Рекомендуется в дальнейшем поддерживать достигнутый уровень по данному измерению.',
      }],
    },
    {
      from: 0.0,
      to: 4.0,
      class: 'mid',
      text: [{
        p: 'Оценка {value} по измерению «Люди и компетенции» означает <strong>средний уровень</strong> (без ярко выраженных преимуществ) подготовки сотрудников в направлении экспортной деятельности, в частности для выбранного товара и целевого рынка. Данное измерение относится к «Нейтральной зоне». При подготовке конкретного экспортного направления с конкретно заданными товаром, страной и запланированными объемами сбыта рекомендуется:',
        tips: [
          'Назначить сотрудников (или отдел), которые смогут вникнуть в детали новых бизнес-процессов. Эти сотрудники должны изучить все аспекты предполагаемого экспортного проекта, высказывать свое экспертное мнение или даже принимать некоторые решения самостоятельно, оставляя при этом за руководством управление в глобальном смысле;',
          'Обеспечить ответственным сотрудникам доступ к необходимой информации и ресурсам для подготовки и осуществления экспортной деятельности.',
        ],
      }],
    },
    {
      from: -4.0,
      to: 0.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Люди и компетенции» означает <strong>недостаточный уровень</strong> подготовки сотрудников в разрезе экспортной деятельности, в частности для выбранного товара и целевого рынка. Данное измерение относится к «Зоне развития». Для улучшения уровня подготовки рекомендуется:',
        tips: [
          'Назначить сотрудников ответственных за изучение доступной информации по процедурам экспорта. Таможенные правила, законодательная база касательно экспорта выбранного товара в принимающей стране, требования по сертификации товара, его перевозке и упаковке – все это необходимо учесть до начала разворачивания экспортного проекта;',
          'Руководству ознакомиться с результатами проведенного исследования. Полученная информация может повлиять не только на незначительные детали в планировании экспортного проекта, но и частично изменить курс развития для обхода возможных ограничений или трудностей.',
        ],
      }],
    },
    {
      to: -4.0,
      from: -100,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Люди и компетенции» означает <strong>недостаточный уровень</strong> подготовки сотрудников в направлении экспортной деятельности, в частности для выбранного товара и целевого рынка. Данное измерение относится к «Зоне развития». Перед выходом на экспорт с выбранным продуктом рекомендуется:',
        tips: [
          'Определить ответственных сотрудников, которые займутся курированием нового направления. Желательно, чтобы выбранные сотрудники имели опыт в осуществлении экспортной деятельности, можно нанять сторонних специалистов или выбрать кого-то из числа действующих сотрудников;',
          'Повысить квалификацию имеющихся сотрудников в вопросах экспорта, если среди сотрудников нет никого с опытом ведения экспортной деятельности, а нанять дополнительный персонал нет возможности. Это можно сделать при помощи специализированных курсов или поставив сотрудникам задачу провести самостоятельные исследования с целью поиска информации об экспортных процедурах, правилах и требованиях к продукции. В таком случае у компании появятся ответы на многие вопросы и решения для многих потенциальных проблем при развертывании экспортного проекта.',
        ],
      }],
    },
  ],
  d2: [
    {
      to: 100,
      from: 3.0,
      class: 'high',
      text: [{
        p: 'Оценка {value} по измерению «Средства» демонстрирует <strong>высокий уровень</strong> материального и финансового обеспечения экспортного проекта для выбранного товара. Данное измерение относится к «Сильным сторонам».  На данный момент развитие измерения «Средства» не является приоритетным. Рекомендуется в дальнейшем поддерживать достигнутый уровень по данному измерению.',
      }],
    },
    {
      from: 0.0,
      to: 3.0,
      class: 'mid',
      text: [{
        p: 'Оценка {value} по измерению «Средства» демонстрирует <strong>средний уровень</strong> (без ярко выраженных преимуществ) материального и финансового обеспечения экспортного проекта для выбранного товара. Данное измерение относится к «Нейтральной зоне».  Текущего уровня подготовки может быть достаточно для старта экспортного проекта. Для успешного его развития в будущем рекомендуется:',
        tips: [
          'Заняться финансовым планированием, прояснить бюджет компании на ближайшее будущее и определить, какая часть бюджета может быть выделена на экспортную деятельность;',
          'Полезно рассмотреть различные источники финансирования, а также заняться перераспределением имеющихся материальных ресурсов компании, чтобы высвободить дополнительные ресурсы.',
        ],
      }],
    },
    {
      from: -3.0,
      to: 0.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Средства» демонстрирует <strong>недостаточный уровень</strong> материального и финансового обеспечения экспортного проекта для выбранного товара. Данное измерение относится к «Зоне развития». В качестве мер улучшения рекомендуется:',
        tips: [
          'Продумать и утвердить финансовый план экспортного проекта, а также краткосрочный бюджет. Даже если финансирование еще не поступило, важно иметь план расходов и примерные ожидания о доходах зафиксированными на бумаге. Такие документы являются точкой отсчета при разговорах о финансировании, а также позволяют в будущем контролировать отклонения при воплощении проекта и устранять их;',
          'Обозначить достижимые цели касательно объема продаж выбранного товара при старте экспортного проекта. Продумать и реализовать необходимые действия для обеспечения возможности производства и транспортировки обозначенного количества продукции.',
        ],
      }],
    },
    {
      from: -100,
      to: -3.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Средства» демонстрирует <strong>недостаточный уровень</strong> материального и финансового обеспечения экспортного проекта для выбранного товара. Данное измерение относится к «Зоне развития». Материальное обеспечение и финансирование – важнейшие составляющие, которыми не следует пренебрегать при планировании нового проекта. Для поднятия уровня подготовки к началу экспорта на выбранный зарубежный рынок рекомендуется:',
        tips: [
          'Зафиксировать и задокументировать все имеющиеся договоренности с партнерами и инвесторами;',
          'Формально определить бюджет экспортного проекта;',
          'Составить финансовый план доходов и расходов;',
          'Обеспечить возможность производства выбранного товара в объемах, необходимых для экспорта.',
        ],
      }],
    },
  ],
  d3: [
    {
      from: 10.0,
      to: 100,
      class: 'high',
      text: [{
        p: 'Оценка {value} по измерению «Инфраструктура и адаптивность» демонстрирует <strong>высокий уровень</strong> подготовки компании по данному измерению. Данное измерение относится к «Сильным сторонам». На данный момент развитие измерения «Инфраструктура и адаптивность» не является приоритетным. Рекомендуется в дальнейшем поддерживать достигнутый уровень по данному измерению.',
      }],
    },
    {
      from: 5.0,
      to: 10.0,
      class: 'high',
      text: [{
        p: 'Оценка {value} по измерению «Инфраструктура и адаптивность» демонстрирует <strong>высокий уровень</strong> подготовки компании по данному измерению. Данное измерение относится к «Сильным сторонам». На данный момент развитие измерения «Инфраструктура и адаптивность» не является приоритетным. Рекомендуется в дальнейшем поддерживать достигнутый уровень по данному измерению.',
      }],
    },
    {
      from: 0.0,
      to: 5.0,
      class: 'mid',
      text: [{
        p: 'Оценка {value} по измерению «Инфраструктура и адаптивность» демонстрирует <strong>средний уровень</strong> (без ярко выраженных преимуществ) подготовки компании по данному измерению. Данное измерение относится к «Нейтральной зоне». Компания уже сделала некоторую подготовку к началу распространения выбранного продукта на целевом рынке, однако стоит удостовериться в том, что регламентированы все необходимые процессы и учтены все нюансы. Рекомендуется:',
        tips: [
          'Прописать все необходимые аспекты и компоненты экспортного проекта, утвердить порядок действий для всех новых бизнес-процессов;',
          'Определить источники получения всех нужных материальных благ и человеческих ресурсов.',
        ],
      }, {
        p: 'Фиксация данной информации в виде текстов, списков, схем и таблиц поможет выявить недостающие блоки текущей инфраструктуры.',
      }],
    },
    {
      from: -5.0,
      to: 0.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Инфраструктура и адаптивность» демонстрирует <strong>недостаточный уровень</strong> подготовки компании по данному измерению. Данное измерение относится к «Зоне развития». Старт полномасштабной экспортной деятельности может означать для компании появление новых видов работ, увеличение требований по расходным материалам, изменения в текущих бизнес-процессах. Для обеспечения уверенности в способности компании подстроиться под подобные изменения рекомендуется:',
        tips: [
          'Заранее определить путь, по которому выбранный продукт будет переходить от компании потребителю, описать все необходимые требования и действия на этом пути. Например, продумать, как компания может найти покупателя, каким образом и на каких условиях фиксируется факт продажи, способы доставки товара до покупателя и т.п.;',
          'Определить, чего конкретно компании не хватает для реализации пунктов, определенных ранее, и направить усилия на получение соответствующих благ. Это могут быть квалифицированные человеческие ресурсы, информация об особенностях транспортировки и хранения выбранного товара в выбранной стране, юридическая помощь в составлении договоров или другой необходимой документации.',
        ],
      }],
    },
    {
      from: -100,
      to: -5.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Инфраструктура и адаптивность» демонстрирует <strong>недостаточный уровень</strong> подготовки компании по данному измерению. Данное измерение относится к «Зоне развития». Начало экспортной деятельности компании влечет за собой изменения в инфраструктуре и процессах компании, которым следует подготовиться. Необходимо заранее предусмотреть основные нововведения и обеспечить плавный переход к новой инфраструктуре. Для этого рекомендуется:',
        tips: [
          'Позаботиться о юридических аспектах продажи, хранения, перевозки, пост продажной поддержки выбранного товара в выбранной стране. Законодательства разных стран могут включать различные ограничения, поощрения и требования, которые следует изучить сотрудникам компании или обратиться к профессиональным консультантам.',
          'Продумать способы рекламы и распространения товара в выбранной стране. Определить людей или отделы, ответственные за решение вопросов с реализацией товара.',
          'Подробно изучить особенности таможенных процедур выбранной страны для ввоза выбранного товара. Прояснить какие дополнительные затраты или специалисты могут понадобиться для правильного таможенного оформления.',
        ],
      }],
    },
  ],
  d4: [
    {
      from: 6.0,
      to: 100,
      class: 'high',
      text: [{
        p: 'Оценка {value} по измерению «Мотивация и цели» означает <strong>высокий уровень</strong> мотивации сотрудников и проработки целей относительно экспорта выбранного товара на целевой рынок. Данное измерение относится к «Сильным сторонам». На данный момент развитие измерения «Мотивация и цели» не является приоритетным. Рекомендуется в дальнейшем поддерживать достигнутый уровень по данному измерению.',
      }],
    },
    {
      from: 3.0,
      to: 6.0,
      class: 'high',
      text: [{
        p: 'Оценка {value} по измерению «Мотивация и цели» означает <strong>высокий уровень</strong> мотивации сотрудников и проработки целей относительно экспорта выбранного товара на целевой рынок. Данное измерение относится к «Сильным сторонам».  На данный момент развитие измерения «Мотивация и цели» не является приоритетным. Рекомендуется в дальнейшем поддерживать достигнутый уровень по данному измерению.',
      }],
    },
    {
      from: 0.0,
      to: 3.0,
      class: 'mid',
      text: [{
        p: 'Оценка {value} по измерению «Мотивация и цели» означает <strong>средний уровень</strong> (без ярко выраженных преимуществ) мотивации сотрудников и проработки целей относительно экспортной деятельности. Данное измерение относится к «Нейтральной зоне». Компания уже определилась с целевым рынком и предлагаемым товаром для экспорта и делает шаги в направлении осуществления планов во внешнеэкономической (экспортной) деятельности. Для увеличения шансов успешного выхода на экспорт с выбранным товаром рекомендуется:',
        tips: [
          'Вернуться к рассмотрению целей экспортной деятельности. Обдумать и спланировать действия, которые помогут достичь ваших целей;',
          'Проявить интерес к обстановке на целевом рынке, оценить свои возможности по сравнению с конкурентами;',
          'Руководству увеличить степень вовлечения на начальных этапах проекта. Участие руководства в процессах планирования и контроля на начальных этапах проекта обеспечит более точное понимание целей и дальнейшую мотивацию сотрудников.',
        ],
      }],
    },
    {
      from: -4.0,
      to: 0.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Мотивация и цели» означает <strong>недостаточный уровень</strong> мотивации сотрудников и проработки целей относительно экспортной деятельности. Данное измерение относится к «Зоне развития». Проработка целей, составление планов, продумывание различных сценариев развития – основа любого успешного проекта. Рекомендуется:',
        tips: [
          'Разработать планы и четко прописать цели относительно экспорта выбранного товара на целевой рынок, что способствует пониманию направления движения компании как у высшего руководства, так и у рядовых сотрудников. Более того, подобная формализация позволяет придерживаться запланированного курса на более длительных промежутках времени, а также сохраняет и усиливает «стартовый» импульс мотивации при условии доступности для сотрудников документации относительно планов и целей компании.',
          'Вовлекать сотрудников, ответственных за экспортное направление, в процессы принятия решений относительно их зон ответственности. Понимание сотрудником причин и предпосылок принятых решений укрепит взаимопонимание и повысит уровень приверженности общему делу.',
        ],
      }],
    },
    {
      from: -10,
      to: -4.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Мотивация и цели» означает <strong>недостаточный уровень</strong> мотивации сотрудников и проработки целей относительно экспортной деятельности. Данное измерение относится к «Зоне развития». Постановка целей и мотивация имеют тесную двухстороннюю взаимосвязь. Чем четче и понятнее сформулированы цели, тем легче и быстрее оказывается их понимание и принятие сотрудниками. Осознание целей усиливает мотивацию и увеличивает продуктивность.  И наоборот, сильная мотивация и заинтересованность сотрудников и руководства в успехе экспортной деятельности способствует приближению этапа сбора и изучения информации, выделения приоритетных направлений деятельности и четкой постановки целей для пошагового развития экспортного проекта. Эксплуатируя вышеописанную взаимосвязь, можно значительно увеличить уровень мотивации и проработки целей. Для этого рекомендуется:',
        tips: [
          'Найти и исследовать всю доступную информацию об аспектах экспорта выбранного товара на целевой рынок. Полезно это сделать до или в процессе постановки целей, в таком случае цели будут более реалистичными и достижимыми;',
          'Отнестись к целям, как к конкретным установкам, которых необходимо достичь в рамках выхода на экспорт с выбранным товаром в выбранной стране. Продумать все условия и формулировки целей так, чтобы при их достижении руководство было действительно удовлетворено результатом;',
          'Позаботиться не только о составлении целей и плана развития проекта, но и о донесении в доступной форме этой информации до сотрудников, задействованных в развитии выбранного экспортного направления.',
        ],
      }],
    },
  ],
  d5: [
    {
      from: 2.0,
      to: 100,
      class: 'high',
      text: [{
        p: 'Оценка {value} по измерению «Среда» характеризует <strong>высокий уровень</strong> осведомленности и готовности к окружению, в которое компания попадет после начала экспорта выбранного продукта на целевой рынок. Данное измерение относится к «Сильным сторонам». На данный момент развитие измерения «Среда» не является приоритетным. Рекомендуется в дальнейшем поддерживать достигнутый уровень по данному измерению.',
      }],
    },
    {
      from: 0.0,
      to: 2.0,
      class: 'mid',
      text: [{
        p: 'Оценка {value} по измерению «Среда» характеризует <strong>средний уровень</strong> (без ярко выраженных преимуществ) осведомленности и готовности к окружению, в которое компания попадет после начала экспорта выбранного продукта на целевой рынок. Данное измерение относится к «Нейтральной зоне». Рекомендуется:',
        tips: [
          'Собрать данные о целевом рынке, продумать планы и возможности укрепления своих позиций сразу после начала экспорта;',
          'Исследовать рынок на предмет возможных конкурентов и партнеров. Определить свои преимущества перед конкурентами, предложить потенциальным партнерам начать сотрудничество.',
        ],
      }],
    },
    {
      from: -100,
      to: 0.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Среда» характеризует <strong>недостаточный уровень</strong> осведомленности и готовности к окружению, в которое компания попадет после начала экспорта выбранного продукта на целевой рынок. Данное измерение относится к «Зоне развития». Рекомендуется уделить больше внимания сбору и изучению информации о целевом рынке. Отыщите информацию о спросе и предложении выбранного товара, определите уровень конкуренции в вашей целевой нише, определите список потенциальных партнеров в выбранной стране, оцените размер целевой аудитории и ее покупательскую способность. Подобная информация позволит скорректировать планы развития экспортного проекта для достижения успешных результатов на конкретном рынке.',
      }],
    },
  ],
  d6: [
    {
      from: 1.5,
      to: 100,
      class: 'high',
      text: [{
        p: 'Оценка {value} по измерению «Продукт» выявляет <strong>высокий уровень</strong> подготовки продукта, представленного для экспорта. Данное измерение относится к «Сильным сторонам». На данный момент развитие измерения «Продукт» не является приоритетным. Рекомендуется в дальнейшем поддерживать достигнутый уровень по данному измерению.',
      }],
    },
    {
      from: 0.0,
      to: 1.5,
      class: 'mid',
      text: [{
        p: 'Оценка {value} по измерению «Продукт» выявляет <strong>средний уровень</strong> (без ярко выраженных преимуществ) подготовки продукта, представленного для экспорта. Данное измерение относится к «Нейтральной зоне». Рекомендуется:',
        tips: [
          'Завершить первичную подготовку выбранного продукта еще до начала полномасштабной экспортной деятельности;',
          'Провести дополнительные исследования возможностей модификации продукта для соответствия требованиям и потребностям целевого рынка;',
          'Продумать планы воплощения необходимых изменений;',
          'Полезно также провести маркетинговые исследования на предмет актуальности размещения выбранного продукта на зарубежном рынке. Данное исследование может выявить потенциальные модификации продукта и требования к рекламной стратегии, которые увеличат вероятность успеха экспортного проекта.',
        ],
      }],
    },
    {
      from: -100,
      to: 0.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Продукт» выявляет <strong>недостаточный уровень</strong> подготовки продукта, представленного для экспорта. Данное измерение относится к «Зоне развития». После выбора конкретного продукта для экспорта необходимо начать его подготовку к продаже на зарубежном рынке. Для этого рекомендуется:',
        tips: [
          'Провести исследования на предмет необходимости модификации выбранного продукта для соответствия требования целевого рынка. Законодательство и особенности торговли в выбранной стране могут сильно отличаться от домашних. Все требуемые изменения продукции необходимо подготовить и выполнить до начала полномасштабной экспортной деятельности;',
          'Исследовать целевой рынок на предмет востребованности выбранного товара. Провести маркетинговые изыскания, выявляющие наилучшие способы презентации товара целевой аудитории на рынке выбранной страны;',
          'Продумать план внедрения всех необходимых изменений продукта, его упаковки и сопутствующей поддержки, предполагающий минимально необходимые модификации. Полное изменение или разработка нового продукта с нуля может оказаться более затратным и рискованным проектом, чем изменение текущих продуктов компании.',
        ],
      }],
    },
  ],
  d7: [
    {
      from: 6.0,
      to: 100,
      class: 'high',
      text: [{
        p: 'Оценка {value} по измерению «Внедрение» отражает <strong>высокий уровень</strong> внедрения компании на целевой рынок. Данное измерение относится к «Сильным сторонам». На данный момент развитие измерения «Внедрение» не является приоритетным. Рекомендуется в дальнейшем поддерживать достигнутый уровень по данному измерению.',
      }],
    },
    {
      from: 3.0,
      to: 6.0,
      class: 'high',
      text: [{
        p: 'Оценка {value} по измерению «Внедрение» отражает <strong>высокий уровень</strong> внедрения компании на целевой рынок. Данное измерение относится к «Сильным сторонам» Руководством компании уже заложен курс продвижения товара, который приведет к полномасштабному развертыванию экспортного проекта в выбранной стране.  На данный момент развитие измерения «Внедрение» не является приоритетным. Рекомендуется в дальнейшем поддерживать достигнутый уровень по данному измерению.',
      }],
    },
    {
      from: 0.0,
      to: 3.0,
      class: 'mid',
      text: [{
        p: 'Оценка {value} по измерению «Внедрение» отражает <strong>средний уровень</strong> (без ярко выраженных преимуществ) внедрения компании на целевой рынок с выбранным товаром. Данное измерение относится к «Нейтральной зоне». Для дальнейшего продвижения и укрепления позиций на целевом рынке рекомендуется:',
        tips: [
          'Осуществить поиск информации о рынке, в частности в сфере, связанной с выбранным продуктом. Знания о рынке и особенностях его развития – первый шаг ко внедрению на этот рынок;',
          'Сделать свою компанию доступной для иностранных клиентов, инвесторов и партнеров: провести маркетинговую компанию в принимающей стране, обеспечить работу сайта на языке принимающей страны.',
        ],
      }],
    },
    {
      from: -100,
      to: 0.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Внедрение» отражает <strong>недостаточный уровень</strong> внедрения компании на целевой рынок с выбранным товаром. Данное измерение относится к «Зоне развития». Для улучшения ситуации рекомендуется:',
        tips: [
          'Начать подготовку к введению выбранного товара на целевой рынок;',
          'Спланировать финансирование и маркетинговое сопровождение проекта;',
          'Наладить как можно больше контактов на целевом рынке, изучить информацию о конкурентах и о целевом рынке в целом.',
        ],
      }, {
        p: 'Таким образом уже до начала фактического экспорта выбранного товара компания будет в определенной степени погружена в среду целевого рынка, что облегчит начало непосредственной экспортной деятельности и откроет возможность для сотрудничества и взаимодействия с иностранными контрагентами.',
      }],
    },
  ],
};
