import React, { FC, useEffect, useState } from 'react';
import {
  MediaLayout,
  MediaCard,
} from 'containers';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { News, RequestStatus } from 'types';
import { useRequest } from 'hooks';
import {
  Modal,
  Paginate,
  Preloader,
  RuTube,
  VKVideo,
} from 'components';
import { routes, URL } from 'appConstants';
import { _has_t_back, _t_back } from 'utils';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  // filterId?: number,
};

interface TVideos extends News {
  video: string,
  created_at: string,
}

interface VideoItem extends News {
  created_at: string,
  videos: TVideos[],
}

type Params = {
  page: number,
};
const initialValues: Params = { page: 0 };
const PAGE_SIZE = 9;

const MediaVideos:FC<Props> = () => {
  const { id: DetailId } = useParams<{id:string}>();
  const url = `${URL.NEWS.VIDEO_ALBUMS.GET}`;

  const [openModalId, setOpenModalId] = useState<News | null>(null);
  const closeModal = () => {
    setOpenModalId(null);
  };

  const {
    data,
    request,
    status,
  } = useRequest<VideoItem[]>();

  const {
    values,
    handleChange,
    handleSubmit,
  } = useFormik<Params>({
    initialValues,
    onSubmit: (params) => {
      const { page } = params;
      request({
        url,
        params: {
          limit: PAGE_SIZE,
          offset: page * PAGE_SIZE,
        },
      });
    },
  });

  useEffect(() => {
    request({
      url,
    });
  }, []);

  // const CatItem:VideoItem[] = (DetailId
  //   ? data?.filter((item) => item.id === +DetailId) : undefined) || [];

  const listOfData:(VideoItem | TVideos)[] = (DetailId
    ? data?.filter((item) => item.id === +DetailId)?.[0]?.videos
    : data) || [];

  return (
    <MediaLayout>
      <Preloader isLoading={status === RequestStatus.REQUEST}>
        {/* {DetailId && <h3 className={styles.titleH3}>{_t_back(CatItem[0], 'title')}</h3>} */}
        <section className={cx()}>
          <ul className={styles.list}>
            {listOfData.map((el) => (
              _has_t_back(el) && el.is_active && (
                <li key={el.id} className={styles.item}>
                  <MediaCard
                    action={DetailId ? 'opener' : 'detail'}
                    to={`${routes.media.videoAlbums.root}/${el.id}`}
                    value={{
                      ...el,
                      category: {
                        id: 500,
                      },
                      pubdate: el.created_at,
                    }}
                    onClickHandler={(obj) => setOpenModalId(obj)}
                  />
                </li>
              )))}
          </ul>
          <Paginate
            pageSize={PAGE_SIZE}
            page={values.page}
            count={data?.length || 0}
            onChange={(selected) => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              handleChange({
                target: {
                  name: 'page',
                  value: selected,
                },
              });
              handleSubmit();
            }}
          />
        </section>
      </Preloader>

      <Modal
        onClose={closeModal}
        isOpen={openModalId !== null}
        title={_t_back(openModalId, 'title')}
        className={styles.modal}
        classNameTitle={styles.title}
        classNameOverlay={styles.modalOverlay}
      >
        {openModalId?.video && (openModalId?.video?.indexOf('vk.com') >= 0 ? (
          <VKVideo
            url={openModalId?.video || ''}
          />
        ) : (
          <RuTube
            url={openModalId?.video}
          />
        ))}
      </Modal>
    </MediaLayout>
  );
};

export default MediaVideos;
