import React, {
} from 'react';
import {
  CustomApplicationForm,
  AvatarBlock,
  ValueLine,
} from 'components';
import {
  PersonalTabLayout,
  PersonalTabLayoutLeft,
  PersonalTabLayoutRight,
} from 'containers';
import { _t } from 'utils';
import styles from './styles.module.scss';

const PersonalGoodsCompDetail = () => {
  return (
    <CustomApplicationForm
      onSubmit={() => {}}
      className={styles.compDetail}
      customModal={styles.modal}
      openerName="подробнее о компании"
      titleName=""
      submitName=""
      disabledSubmit={false}
      isLoading={false}
    >
      <PersonalTabLayout>
        <PersonalTabLayoutLeft>
          <AvatarBlock />
          <div>
            <div className={styles.userName}>
              ООО «Альтаир Диджитал»
              {/* {userName} */}
            </div>
            <div className={styles.userRole}>
              Пищевкусовая промышленность/ Масложировая
              // промышленность (без производства мыла и моющих средств)
              {/* {userRole} */}
            </div>
          </div>
        </PersonalTabLayoutLeft>
        <PersonalTabLayoutRight>
          <div className={styles.dataTitle}>{_t('Данные компании')}</div>
          <div className={styles.copmInfo}>
            <ValueLine
              label="Название компании"
              value="ООО «Альтаир Диджитал»"
              name=""
              isEdit={false}
              error=""
              updValue={() => {}}
            />
            <ValueLine
              label="ИНН"
              value="223465781254"
              name=""
              isEdit={false}
              error=""
              updValue={() => {}}
            />
            <ValueLine
              label="Сфера деятельности"
              value="Цветная металлургия"
              name=""
              isEdit={false}
              error=""
              updValue={() => {}}
            />
            <ValueLine
              label="География поставки"
              value="Африка, Азия"
              name=""
              isEdit={false}
              error=""
              updValue={() => {}}
            />
            <ValueLine
              label="Сайт компании"
              value="https://dribbble.com/search/profile"
              name=""
              isEdit={false}
              error=""
              updValue={() => {}}
            />
          </div>

          <div className={styles.dataTitle}>{_t('Описание компании')}</div>
          <div className={styles.copmDescr}>
            С другой стороны постоянный количественный рост и сфера нашей активности
            способствует подготовки и реализации системы обучения кадров, соответствует насущным
            потребностям.Задача организации, в особенности же консультация с широким активом
            позволяет оценить значение модели развития. Равным образом постоянный количественный
            рост и сфера нашей активности позволяет выполнять важные задания по разработке
            соответствующий условий активизации.
          </div>
          {/* <Preloader isLoading={status === RequestStatus.REQUEST}>
            {(!noData && !!resolutions?.length) && (
            )}
          </Preloader> */}
        </PersonalTabLayoutRight>
      </PersonalTabLayout>
    </CustomApplicationForm>
  );
};

export default PersonalGoodsCompDetail;
