import React, { FC, memo } from 'react';

import { Checkbox as CheckboxUIKit } from 'components';
import { Step } from 'types';
import styles from './styles.module.scss';

type Props = {
  answers: Step['answers'];
  selectedItems: number[] | null;
  onChange: (id: number, isChecked: boolean) => void;
};

const Checkbox: FC<Props> = memo(({ answers, selectedItems, onChange }) => {
  return (
    <div>
      {answers.map((item) => (
        <CheckboxUIKit
          key={item.value}
          checked={selectedItems?.includes(item.value)}
          name={item.value.toString()}
          label={item.text}
          forAdept
          className={styles.checkbox}
          onChange={(e, isChecked) => onChange(parseInt(e.target.id, 10), isChecked)}
        />
      ))}
    </div>
  );
});

export { Checkbox };
