/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useEffect } from 'react';
import {
  Route,
  RouteProps,
} from 'react-router-dom';
import { setPageMeta } from 'appConstants';

interface Props extends RouteProps {
  item?: {
    root: string,
    title: string,
    descr: string,
  },
}

const PublicAddRoute: FC<Props> = ({ location, item, ...rest }) => {
  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (location?.pathname === item?.root) {
      setPageMeta(item?.title, item?.descr);
    } else {
      setPageMeta('', '');
    }
  });

  return (
    <Route
      {...rest}
    />
  );
};

export default PublicAddRoute;
