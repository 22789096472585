import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const Label: FC<PropsWithChildren<Props>> = ({
  children,
  className,
}) => (
  <span className={cx(styles.label, className)}>
    {children}
  </span>
);

export default Label;
