import React, {
  FC,
  PropsWithChildren,
  useCallback, useRef,
  useState,
} from 'react';
import cx from 'classnames';
import { DURATION_ANIMATION } from 'appConstants';
import { _t } from 'utils';
import { Height } from 'types';
import AnimateHeight from 'react-animate-height';
import styles from './styles.module.scss';

type Props = {
  label: string,
  theme?: 'primary' | 'secondary',
  selectedCount?: number,
  className?: string,
  buttonStyle?: string,
  buttonStyleOpen?: string,
  textStyle?: string,
  classNameContent?: string,
  manualHeight?: Height,
  onToggle?: (isOpen: boolean) => void,
  onScroll?: () => void,
};

const ExpandingBlock: FC<PropsWithChildren<Props>> = ({
  children,
  label,
  theme = 'secondary',
  selectedCount = 0,
  className,
  buttonStyle,
  buttonStyleOpen = '',
  textStyle,
  classNameContent,
  manualHeight,
  onToggle = () => {},
  onScroll = () => {},
}) => {
  const [heightSubmenu, setHeightSubmenu] = useState<Height>(Height.empty);

  const handlerToggleSpoiler = useCallback(() => {
    setHeightSubmenu((state) => {
      const isOpen = (manualHeight !== undefined ? manualHeight : state) === Height.empty;
      onToggle(isOpen);
      return state === Height.empty ? Height.full : Height.empty;
    });
  }, [setHeightSubmenu, onToggle]);

  const ref = useRef(null);

  const handleScroll = useCallback(() => {
    // @ts-ignore
    const { scrollHeight: Wheight, scrollTop: Wtop, clientHeight: height } = ref.current;
    // @ts-ignore
    if (Wheight - Wtop === height) {
      onScroll();
    }
  }, [ref]);

  return (
    <div
      className={cx(className, styles.wrap, styles[theme])}
    >
      <button
        type="button"
        className={cx(
          buttonStyle,
          styles.toggleBtn,
          {
            [buttonStyleOpen]: buttonStyleOpen && heightSubmenu === Height.full,
            [styles.open]: heightSubmenu === Height.full,
          },
        )}
        onClick={handlerToggleSpoiler}
      >
        {`${_t(label)} ${selectedCount > 0 ? `(${selectedCount})` : ''}`}
        <span className={styles.icon}>&#43;</span>
      </button>
      <AnimateHeight
        className={cx(styles.content, classNameContent)}
        duration={DURATION_ANIMATION}
        height={manualHeight !== undefined ? manualHeight : heightSubmenu}
      >
        <div
          className={cx(
            textStyle,
            styles.children,
          )}
          ref={ref}
          onScroll={handleScroll}
        >
          {children}
        </div>
      </AnimateHeight>
    </div>
  );
};

export default ExpandingBlock;
