import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  PersonalResolutionsMainTab,
  PersonalResolutionsDetail,
  PersonalBaseLayout,
  PersonalBarrierRequest,
} from 'containers';
import { PersonalPageMenu } from 'components';
import { routes } from 'appConstants';

const Links = [
  {
    title: 'Добавленные меры',
    to: routes.personal.resolutions.root,
  },
];

const PersonalResolutions = () => {
  return (
    <PersonalBaseLayout pageTitle={routes.personal.resolutions.title}>
      <PersonalPageMenu
        links={Links}
      >
        <PersonalBarrierRequest />
      </PersonalPageMenu>
      <Switch>
        <Route
          path={routes.personal.resolutions.root}
          exact
          render={() => (<PersonalResolutionsMainTab />)}
        />
        <Route
          path={`${routes.personal.resolutions.root}/:id`}
          exact
          render={() => (<PersonalResolutionsDetail />)}
        />
        <Redirect to={{ pathname: routes.personal.resolutions.root }} />
      </Switch>
    </PersonalBaseLayout>
  );
};

export default PersonalResolutions;
