/* eslint-disable @typescript-eslint/no-throw-literal */
import { put, takeLatest, call } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { toast } from 'react-toastify';
import { URL } from 'appConstants';
import { TESTS_ERRORS } from 'utils';
import type {
  GetHtmlReportRes,
  Response,
  VGetProductsRes,
  VSessionIDRes,
  VSessionStepsRes,
} from 'types';
import { store } from 'store/configureStore';
import api from './api';
import actionTypes, { TestAction as Action } from '../actionTypes';
import { APIError, errorHandler } from './errorHandler';
import {
  testVShowReport,
  testVShowFinalAction,
  testVVisibleToggle,
} from '../actions';

function* getProducts({ type }: Action) {
  try {
    const {
      personalData: {
        member: { role },
      },
    } = store.getState().test;

    if (role !== 'E') throw new Error('Not authorized for the current user');

    yield put(apiActions.request(type));
    const { data }: Response<VGetProductsRes> = yield call(api, {
      method: 'GET',
      url: URL.TEST.VALIDATION.GET_PRODUCTS,
    });
    yield put(apiActions.success(type, data));
    yield put({ type: actionTypes.TEST_V_SHOW });
  } catch (err) {
    yield errorHandler({ type, err });
  }
}

function* getSteps({ type }: Action) {
  try {
    const sessionId = store.getState().test.validationSessionID;
    if (!sessionId) throw new Error('Not found active validation');

    yield put(apiActions.request(type));
    const { data }: Response<VSessionStepsRes> = yield call(api, {
      method: 'GET',
      url: `${URL.TEST.VALIDATION.V}/${sessionId}/steps`,
    });
    yield put(apiActions.success(type, data));
  } catch (err) {
    yield errorHandler({ type, err });
  }
}

function* getSessionId({ type }: Action) {
  try {
    const { test } = store.getState();
    const {
      validationSessionID,
      personalData: {
        member: { role },
      },
      validationTopic: {
        countryId,
        productId,
        productName,
        productTNVED,
        productDescription,
      },
    } = test;
    if (role !== 'E') throw new Error('Not authorized for the current user');
    if (!(
      countryId &&
      (productId || productName || productTNVED)
    )) throw new Error('You have unfilled fields');

    if (!validationSessionID) {
      yield put(apiActions.request(type));
      const { data }: Response<VSessionIDRes> = yield call(api, {
        method: 'POST',
        url: URL.TEST.VALIDATION.GET_ID,
        data: {
          countryId,
          productId: productId ?? undefined,
          productName: productName ?? undefined,
          productTNVED: productTNVED ?? undefined,
          productDescription: productDescription ?? undefined,
        },
      });
      yield put(apiActions.success(type, data));
    }
  } catch (err) {
    const error = TESTS_ERRORS[(err as APIError).response.data.reason];
    if ((err as APIError).response.data.code === 2) {
      toast.info(error || (err as APIError).response.data.message);
    } else {
      toast.error(error || (err as APIError).response.data.message);
    }
    yield put(apiActions.error(type, err));
  }
}

function* getSessions({ type }: Action) {
  try {
    const {
      personalData: {
        member: { role },
      },
    } = store.getState().test;
    if (role !== 'E') throw new Error('Not authorized for the current user');

    yield put(apiActions.request(type));
    const { data }: Response<VSessionIDRes> = yield call(api, {
      method: 'GET',
      url: URL.TEST.VALIDATION.GET_SESSIONS,
    });
    yield put(apiActions.success(type, data));
  } catch (err) {
    yield errorHandler({ type, err });
  }
}

function* populateSession({ type }: Action) {
  try {
    const {
      validationTopic: {
        countryId,
        productId,
        productName,
        productTNVED,
      },
    } = store.getState().test;

    if (!(countryId && (productId || (productName && productTNVED)))) {
      throw new Error('Country or/and product should be selected');
    }

    yield call(getSessions, { type: actionTypes.TEST_V_SESSIONS } as Action);

    const session = store.getState().test.validationSessions.find((s) => (
      s.country.id === countryId && s.product.id === productId
    ));

    if (session) {
      yield put(apiActions.success(actionTypes.TEST_V_SESSION_ID, { id: session.id }));
    } else {
      yield call(getSessionId, { type: actionTypes.TEST_V_SESSION_ID } as Action);
    }
    yield call(getSteps, { type: actionTypes.TEST_V_GET_STEPS } as Action);
  } catch (err) {
    yield errorHandler({ type, err });
  }
}

function* finish({ type }: Action) {
  try {
    const {
      validationSessionID: sessionId,
      validationSteps,
      personalData: {
        member: { role },
      },
    } = store.getState().test;
    const isAllAnswers = validationSteps.every((step) => !!step.answer);

    if (!sessionId) throw new Error('Not found active validation');
    if (!isAllAnswers) throw new Error('You have unfilled answers');
    if (role !== 'E') throw new Error('Not authorized for the current user');

    yield put(apiActions.request(type));
    yield call(api, {
      method: 'POST',
      url: `${URL.TEST.VALIDATION.V}/${sessionId}/finish`,
    });
    yield put(apiActions.success(type));
  } catch (err) {
    yield errorHandler({ type, err });
  }
}

function* sendAnswer({ type, payload }: Action) {
  try {
    const {
      validationSessionID: sessionId,
      validationMeta: {
        current,
        answer,
        total,
      },
      personalData: {
        member: { role },
      },
      validationSteps,
    } = store.getState().test;
    const {
      shouldExit,
      shouldFinish,
      shouldIncrement,
    } = payload as {
      shouldExit: boolean;
      shouldFinish: boolean;
      shouldIncrement: boolean;
    };

    if (!sessionId) throw new Error('Not found active validation');
    if (answer === null) throw new Error('You have to select an answer');
    if (role !== 'E') throw new Error('Not authorized for the current user');

    const currentStep = validationSteps.find((step) => step.step === current);
    if (JSON.stringify(answer) !== JSON.stringify(currentStep?.answer)) {
      yield put(apiActions.request(type));
      yield call(api, {
        method: 'POST',
        url: `${URL.TEST.VALIDATION.V}/${sessionId}/step/${current}`,
        data: { answer },
      });
      yield put(apiActions.success(type));
    }

    if (shouldIncrement) {
      yield put({ type: actionTypes.TEST_V_INCREMENT_STEP });
    }

    if (shouldExit) {
      if (current === total && shouldFinish) {
        yield call(finish, { type: actionTypes.TEST_V_FINISH } as Action);
      }
      yield put({ type: actionTypes.TEST_V_HIDE });
    }
  } catch (err) {
    yield errorHandler({ type, err });
  }
}

function* removeSession({ type, payload }: Action) {
  try {
    const {
      personalData: {
        member: { role },
      },
    } = store.getState().test;
    const { id } = payload as { id: string };
    if (role !== 'E') throw new Error('Not authorized for the current user');

    yield put(apiActions.request(type));
    yield call(api, {
      method: 'DELETE',
      url: `${URL.TEST.VALIDATION.V}/${id}`,
    });
    yield put(apiActions.success(type, id));
  } catch (err) {
    yield errorHandler({ type, err });
  }
}

function* runFromExisted({ type, payload }: Action) {
  try {
    const {
      personalData: {
        member: { role },
      },
    } = store.getState().test;

    if (role !== 'E') throw new Error('Not authorized for the current user');

    const { productId, countryId } = payload as { productId: number; countryId: number };
    const session = store.getState().test.validationSessions.find((s) => (
      s.country.id === countryId && s.product.id === productId
    ));

    yield put(apiActions.success(actionTypes.TEST_V_SESSION_ID, { id: session?.id }));
    yield call(getSteps, { type: actionTypes.TEST_V_GET_STEPS } as Action);
    store.dispatch(testVVisibleToggle(true));
  } catch (err) {
    yield errorHandler({ type, err });
  }
}

function* getHtmlReport({ type, payload }: Action) {
  try {
    const {
      personalData: {
        member: { role },
      },
    } = store.getState().test;
    const { sessionId } = payload as { sessionId: string };
    if (!sessionId) throw new Error('Not found active validation');
    if (role !== 'E') throw new Error('Not authorized for the current user');

    yield put(apiActions.request(type));
    const { data }: Response<GetHtmlReportRes> = yield call(api, {
      method: 'GET',
      url: `${URL.TEST.VALIDATION.V}/${sessionId}/html`,
    });
    yield put(apiActions.success(type, { html: data.html }));
  } catch (err) {
    yield errorHandler({ type, err });
  }
}

function* getPdfReport({ type, payload }: Action) {
  try {
    const {
      personalData: {
        member: { role },
      },
    } = store.getState().test;
    const { sessionId } = payload as { sessionId: string };
    if (!sessionId) throw new Error('Not found active qualification');
    if (role !== 'E') throw new Error('Not authorized for the current user');

    yield put(apiActions.request(type));
    const response: Response<BlobPart> = yield call(api, {
      method: 'GET',
      url: `${URL.TEST.VALIDATION.V}/${sessionId}/report.pdf`,
      responseType: 'blob',
    });
    const file = new Blob(
      [response.data],
      { type: 'application/pdf' },
    );
    const fileURL = window.URL.createObjectURL(file);
    window.open(fileURL);

    yield put(apiActions.success(type));
  } catch (err) {
    yield errorHandler({ type, err });
  }
}

function* finishAndShowReport({ type }: Action) {
  try {
    const {
      validationSessionID: sessionId,
    } = store.getState().test;
    yield call(finish, { type: actionTypes.TEST_V_FINISH } as Action);
    yield call(getHtmlReport, {
      type: actionTypes.TEST_V_GET_HTML_REPORT,
      payload: { sessionId },
    } as Action);
    store.dispatch(testVVisibleToggle(false));
    store.dispatch(testVShowFinalAction());
    yield put(testVShowReport(sessionId as string));
  } catch (err) {
    yield errorHandler({ type, err });
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.TEST_V_GET_PRODUCTS, getProducts);
  yield takeLatest(actionTypes.TEST_V_SESSION_ID, getSessionId);
  yield takeLatest(actionTypes.TEST_V_SESSIONS, getSessions);
  yield takeLatest(actionTypes.TEST_V_POPULATE_SESSION, populateSession);
  yield takeLatest(actionTypes.TEST_V_GET_STEPS, getSteps);
  yield takeLatest(actionTypes.TEST_V_SEND_ANSWER, sendAnswer);
  yield takeLatest(actionTypes.TEST_V_FINISH, finish);
  yield takeLatest(actionTypes.TEST_V_REMOVE_SESSION, removeSession);
  yield takeLatest(actionTypes.TEST_V_RUN_FROM_EXISTED, runFromExisted);
  yield takeLatest(actionTypes.TEST_V_FINISH_AND_SHOW_REPORT, finishAndShowReport);
  yield takeLatest(actionTypes.TEST_V_GET_HTML_REPORT, getHtmlReport);
  yield takeLatest(actionTypes.TEST_V_GET_PDF_REPORT, getPdfReport);
}
