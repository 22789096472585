import React, { FC, useState } from 'react';
import {
  // Checkbox,
  InputRadio,
  Select,
} from 'components';
import { LeadStatus } from 'types';
import {
  _t,
  _t_back,
} from 'utils';
// import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  finIndParam: number,
  finIndData?: LeadStatus[] | null,
  setFinIndFilter: (id?:number) => void,
  indFCompParam: number,
  indFCompData?: LeadStatus[] | null,
  setIndFCompFilter: (id?:number) => void,
  applyFilters: () => void,
  clearFilters: () => void,
};

// const getTextLabel = (name: string, label: string, className?: string) => (
//   <label htmlFor={name} className={cx(styles.label, className)}>{_t(label)}</label>
// );

const PersonalExchangeFilter: FC<Props> = ({
  finIndParam = 0,
  finIndData,
  setFinIndFilter,
  // indFCompParam = 0,
  indFCompData,
  // setIndFCompFilter,
  applyFilters,
  clearFilters,
}) => {
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  return (
    <div className={styles.filterWrap}>
      <div
        className={styles.filter}
        onClick={() => setOpenFilter(!openFilter)}
      >
        {_t('Фильтр')}
      </div>
      {openFilter && (
        <div
          className={styles.filterBlock}
          // onMouseLeave={() => setOpenFilter(false)}
        >
          <div
            className={styles.close}
            onClick={() => setOpenFilter(false)}
          >
            +
          </div>
          <h3>{_t('Фильтр')}</h3>
          <div className={styles.filterparam}>
            <h4>{_t('Отрасль компании')}</h4>
            <Select
              placeholder={_t('Отрасль')}
              isSearchable
              isClearable
              className={styles.select}
              options={indFCompData?.map((industry) => ({
                label: _t_back(industry, 'title'),
                value: industry.id.toString(),
              }))}
              isMulti
              // customLabel={getTextLabel('region', 'Выбрать регион')}
              onChange={() => {
              //   onChange={(option) => {
              //     if (option) {
              //       handleSelectRegion(+(option as OptionType).value);
              //     } else {
              //       handleSelectRegion(0);
              //     }
              }}
            />
          </div>
          <div className={styles.filterparam}>
            <h4>{_t('Финансовые показатели')}</h4>
            {/* {list.map((listItem) => ( */}
            {finIndData?.filter((fin) => fin.is_active).map((finance) => (
              <InputRadio
                key={finance.id.toString()}
                checked={finance.id === finIndParam}
                name="name"
                label={_t_back(finance, 'title')}
                className={styles.checkbox}
                onChange={() => setFinIndFilter(finance.id)}
              />
            ))}
          </div>
          <div className={styles.filterControl}>
            <div
              className={styles.applyFilter}
              onClick={applyFilters}
            >
              Применить фильтр
            </div>
            <div
              className={styles.clearFilterBtn}
              onClick={clearFilters}
            >
              {_t('Очистить фильтр')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalExchangeFilter;
