import React, {
  useEffect,
} from 'react';
import { setPageMeta, routes } from 'appConstants';
import { Contacts as Container } from 'containers';

const Contacts = () => {
  useEffect(() => {
    setPageMeta(routes.contacts.title, routes.contacts.descr);
  });

  return <Container />;
};

export default Contacts;
