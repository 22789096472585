import React, { PropsWithChildren, FC } from 'react';
import ReactInfinityScroll, { Props } from 'react-infinite-scroll-component';

const InfinityScroll: FC<PropsWithChildren<Props>> = ({
  children,
  ...rest
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <ReactInfinityScroll {...rest}>
    {children}
  </ReactInfinityScroll>
);

export default InfinityScroll;
