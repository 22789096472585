// /* eslint-disable */
import React, {
  useState,
  useEffect,
  // useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { useRequest } from 'hooks';
import {
  Input,
  Section,
  Legend,
  Calendar,
  Select,
} from 'components';
import { URL } from 'appConstants';
import { useFormik } from 'formik';
import {
  State,
  ColorsLegend,
  FavoriteEvent,
  ReactCalendarCEvent,
} from 'types';
import { PersonalTabLayout } from 'containers';
import { _t, _t_back } from 'utils';
import cx from 'classnames';
import PersonalCalendarDetail from '../PersonalCalendarDetail';
import styles from './styles.module.scss';
import './styles.scss';

type Values = {
  search: string,
  country: string,
  format: string,
  industry: string,
};

interface Legend {
  color?: ColorsLegend;
  text: string;
}

const initialValues: Values = {
  search: '',
  country: '',
  format: '',
  industry: '',
};

const FORMAT:{[index:string]: string} = {
  1: 'Online',
  2: 'Offline',
};

const LEGEND: Legend[] = [
  {
    color: 'gray',
    text: 'Без заявки',
  },
  {
    color: 'green',
    text: 'Заявка подана',
  },
  {
    color: 'yellow',
    text: 'На рассмотрении',
  },
  {
    color: 'red',
    text: 'Отклонено',
  },
  {
    color: 'blue',
    text: 'Требуется отчет',
  },
];

const splitTitle = (str: string) => {
  const maxLength = 40;
  return str.length > maxLength ? `${str.substr(0, maxLength - 3)}...` : str;
};

const getTextLabel = (name: string, label: string, className?: string) => (
  <label htmlFor={name} className={cx(styles.label, className)}>{label}</label>
);

const PersonalCalendarGrid = () => {
  const token = useSelector(({ me }: State) => me.token);
  const [detailId, setDetailId] = useState<number>(-1);
  const [eventsData, setEventsData] = useState<ReactCalendarCEvent[]>([]);
  const { data: CalendarData, request: CalendarRequest } = useRequest<FavoriteEvent[]>();

  const { values, handleChange, handleSubmit } = useFormik<Values>({
    initialValues,
    onSubmit: () => {},
  });

  const handleSearch = () => {
    handleSubmit();
  };

  useEffect(() => {
    CalendarRequest({
      method: 'GET',
      url: URL.PERSONAL.CALENDAR.GET,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }, []);

  useEffect(() => {
    if (CalendarData) {
      let convertArray = eventsData;
      CalendarData.forEach(({ event }, index) => {
        convertArray = convertArray.concat({
          id: index,
          title: _t_back(event, 'title'),
          allDay: false,
          start: new Date(event.date_of_event_start),
          end: new Date(event.date_of_event_end),
          format: FORMAT[event.form_of_holding.id],
          status: 0,
          request: true,
        });
      });
      setEventsData(convertArray);
    }
  }, [CalendarData]);

  const EventItem = ({ event }:{ event:ReactCalendarCEvent }) => {
    return (
      <div
        className={cx(
          styles.customItem,
          styles[`${LEGEND[event.status].color}`],
        )}
        onClick={() => {
          setDetailId(event.id);
        }}
      >
        <div className={styles.customItemTitle}>{splitTitle(event.title || '')}</div>
        <div className={styles.customItemFormat}>{event.format}</div>
      </div>
    );
  };

  return (
    <PersonalTabLayout
      single
      className={styles.layout}
    >
      <div className={styles.filters}>
        <Input
          type="search"
          name="search"
          value={values.search}
          placeholder="Поиск"
          onChange={handleChange}
          onSearch={handleSearch}
          className={styles.search}
        />
        <div className={cx(styles.select, 'calculatorSelect')}>
          <Select
            placeholder={_t('Все мероприятия')}
            isClearable
            options={LEGEND.map(({ text }, index) => ({
              label: _t(text),
              value: index.toString(),
            }))}
            isMulti={false}
            customLabel={getTextLabel('region', `${_t('Статус')}: `)}
            // onChange={(option) => {
            //   if (option) {
            //     handleSelectRegion(+(option as OptionType).value);
            //   } else {
            //     handleSelectRegion(0);
            //   }
            // }}
          />
        </div>
      </div>

      <Section wide>
        <Calendar
          className={styles.calendar}
          events={eventsData}
          components={{
            event: EventItem,
          }}
        />
      </Section>

      {detailId >= 0 && (
        <PersonalCalendarDetail
          onClose={() => {
            setDetailId(-1);
          }}
          data={CalendarData?.[detailId]}
        />
      )}
    </PersonalTabLayout>
  );
};

export default PersonalCalendarGrid;
