import React from 'react';
// import cx from 'classnames';
import { routes } from 'appConstants';
import { Link } from 'components';
import styles from './styles.module.scss';

const Error404 = () => (
  <>
    <div className={styles.text404}>
      <div className={styles.big}>404</div>
      Страница, которую вы ищете, не существует.
    </div>
    <Link
      key={routes.main.root}
      to={routes.main.root || '/'}
      className={styles.link}
    >
      перейти на главную
    </Link>
  </>
);

export default Error404;
