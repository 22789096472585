import { fork } from 'redux-saga/effects';
import login from './login';
import restore from './restore';
import logout from './logout';
import newPass from './newPass';
import activateUser from './activation';
import signUp from './signUp';
import exportTestRequire from './exportTestRequire';
import sendApplications from './sendApplications';
import updateUser from './updateUser';
import updateCompany from './updateCompany';

export default function* meSaga() {
  yield fork(login);
  yield fork(restore);
  yield fork(newPass);
  yield fork(logout);
  yield fork(signUp);
  yield fork(exportTestRequire);
  yield fork(sendApplications);
  yield fork(updateUser);
  yield fork(updateCompany);
  yield fork(activateUser);
}
