/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useRef,
  useEffect,
  useLayoutEffect,
  FC,
  useState,
} from 'react';
import cx from 'classnames';
import { ClientLang } from 'appConstants';
import { Translates } from 'types';
import {
  useTheme,
  create,
  color,
} from '@amcharts/amcharts4/core';
import {
  MapChart,
  projections,
  MapPolygonSeries,
} from '@amcharts/amcharts4/maps';
import { usePrevious } from 'hooks';
import am4geodata_worldRussiaHigh from '@amcharts/amcharts4-geodata/worldRussiaHigh';
import am4geodata_lang_RU from '@amcharts/amcharts4-geodata/lang/RU';
import am4geodata_lang_EN from '@amcharts/amcharts4-geodata/lang/EN';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import styles from './styles.module.scss';

useTheme(am4themes_animated);

const ID = 'chart';

type Props = {
  selectedCountry: string,
  className?: string,
};

const COLORS = {
  passive: '#F6F6F6',
  active: '#CDCDCD',
};

const GEODATANAMES:Translates = {
  ru: am4geodata_lang_RU,
  en: am4geodata_lang_EN,
};

const Globe: FC<Props> = ({ selectedCountry, className }) => {
  const chart = useRef<null | any>(null);
  const polygonSeries = useRef<null | any>(null);
  const previousSelectedCountry = usePrevious(selectedCountry);

  const [isReady, setReady] = useState<boolean>(false);

  // eslint-disable-next-line consistent-return
  useLayoutEffect(() => {
    if (!chart.current) {
      const diagram = create(ID, MapChart);
      chart.current = diagram;
      chart.current.projection = new projections.Miller();
      chart.current.geodataNames = GEODATANAMES[ClientLang];

      // create a polygon series
      polygonSeries.current = chart.current.series.push(new MapPolygonSeries());

      polygonSeries.current.useGeodata = true;
      // Exclude Antartica
      polygonSeries.current.exclude = ['AQ'];
      // Set map definition
      chart.current.geodata = am4geodata_worldRussiaHigh;

      // Create active state
      const polygonTemplate = polygonSeries.current.mapPolygons.template;
      polygonTemplate.tooltipText = '{name}';
      polygonTemplate.fill = color(COLORS.passive);

      const as = polygonTemplate.states.create('active');
      as.properties.fill = color(COLORS.active);

      chart.current.events.on('ready', () => setReady(true));

      return () => {
        diagram.dispose();
      };
    }
  }, []);

  useEffect(() => {
    const el = polygonSeries.current.getPolygonById(selectedCountry);
    if (el && setReady) {
      chart.current.zoomToMapObject(el, 3);
      el.isActive = true;
    } else {
      chart.current.goHome();
    }

    // reset the previous country active state
    if (previousSelectedCountry && previousSelectedCountry !== selectedCountry) {
      const previousEl = polygonSeries.current.getPolygonById(previousSelectedCountry);
      if (previousEl) {
        previousEl.isActive = false;
      }
    }
  }, [selectedCountry, isReady]);

  return <div className={cx(styles.chart, className)} id={ID} />;
};

export default Globe;
