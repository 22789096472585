import React, { FC, memo } from 'react';

import { Country, Product, Step } from 'types';
import { CardContentTitle } from './CardContentTitle';
import {
  Radio,
  Checkbox,
  Text,
  Range,
} from './CardContentAnswers';
import styles from './styles.module.scss';
import { ProductSelect } from './CardContentAnswers/ProductSelect';

type AnswersProps = RadioProps | CheckboxProps | TextProps | RangeProps | ProductSelectProps;

const CardContent: FC<AnswersProps> = memo(({
  title,
  kind,
  answers,
  onChangeRadio,
  radioSelected,
  checkboxSelected,
  onChangeCheckbox,
  company,
  name,
  inn,
  position,
  onChangeRange,
  rangeSelected,
  countries,
  products,
  onChangeCountry,
  onChangeProduct,
  onChangeProductManual,
  setProductDesc,
}) => {
  let Component = null;
  switch (kind) {
    case 'radio':
      Component = (
        <Radio
          answers={answers as Step['answers']}
          selectedItem={radioSelected as number | null}
          onChange={onChangeRadio as (id: number) => void}
        />
      );
      break;
    case 'checkbox':
      Component = (
        <Checkbox
          answers={answers as Step['answers']}
          selectedItems={checkboxSelected as number[]}
          onChange={onChangeCheckbox as (id: number, isChecked: boolean) => void}
        />
      );
      break;
    case 'text':
      Component = (
        <Text
          company={company as string}
          name={name as string}
          position={position as string}
          inn={inn as string}
        />
      );
      break;
    case 'range':
      Component = (
        <Range
          answers={answers as Step['answers']}
          selectedItems={rangeSelected as number[]}
          onChange={onChangeRange as RangeProps['onChangeRange']}
        />
      );
      break;
    case 'productSelect':
      Component = (
        <ProductSelect
          countries={countries as ProductSelectProps['countries']}
          product={products as ProductSelectProps['products']}
          onChangeCountry={onChangeCountry as ProductSelectProps['onChangeCountry']}
          onChangeProduct={onChangeProduct as ProductSelectProps['onChangeProduct']}
          onChangeProductManual={onChangeProductManual as ProductSelectProps['onChangeProductManual']}
          setProductDesc={setProductDesc as ProductSelectProps['setProductDesc']}
        />
      );
      break;
    default:
      return null;
  }

  return (
    <div className={styles.wrapperCardContent}>
      <CardContentTitle
        title={title}
        kind={kind}
      />
      <div className={styles.answersWrapper}>
        {Component}
      </div>
    </div>
  );
});

type Kind = 'radio' | 'checkbox' | 'range' | 'text' | 'productSelect';
type RadioProps = {
  kind: 'radio';
  title: string;
  answers: Step['answers'];
  radioSelected: number | null;
  onChangeRadio: (id: number) => void;
} & Omit<FullProps, 'answers' | 'radioSelected' | 'onChangeRadio'>;
type CheckboxProps = {
  kind: 'checkbox';
  title: string;
  answers: Step['answers'];
  checkboxSelected: number[] | null;
  onChangeCheckbox: (id: number, isChecked: boolean) => void;
} & Omit<FullProps, 'answers' | 'checkboxSelected' | 'onChangeCheckbox'>;
type TextProps = {
  kind: 'text',
  title: string;
  company: string;
  name: string;
  inn: string;
  position: string;
} & Omit<FullProps, 'company' | 'name' | 'position' | 'inn'>;
type ProductSelectProps = {
  kind: 'productSelect',
  title: string;
  countries: Country[];
  products: Product[];
  onChangeCountry: (value: number) => void;
  onChangeProduct: (value: number | null) => void;
  onChangeProductManual: (value: string | null, field: 'productName' | 'productTNVED') => void;
  setProductDesc: (value: string | null) => void;
} & Omit<FullProps, 'countries' | 'products' | 'onChangeCountry' | 'onChangeProduct' | 'onChangeProductManual' | 'setProductDesc'>;
type RangeProps = {
  kind: 'range',
  title: string;
  rangeSelected: number[] | null;
  answers: Step['answers'];
  onChangeRange: (position: number, value: number | null) => void;
} & Omit<FullProps, 'answers' | 'onChangeRange' | 'rangeSelected'>;

type FullProps = {
  company?: undefined;
  name?: undefined;
  inn?: undefined;
  position?: undefined;
  answers?: undefined;
  radioSelected?: undefined;
  onChangeRadio?: undefined;
  checkboxSelected?: undefined;
  onChangeCheckbox?: undefined;
  onChangeRange?: undefined;
  rangeSelected?: undefined;
  countries?: undefined;
  products?: undefined;
  onChangeCountry?: undefined;
  onChangeProduct?: undefined;
  onChangeProductManual?: undefined;
  setProductDesc?: undefined;
};

export { CardContent };
export type {
  AnswersProps,
  Kind,
  TextProps,
  RangeProps,
  RadioProps,
  CheckboxProps,
  ProductSelectProps,
};
