export const TITLE = 'Центр - поддержки и развития промышленного экспорта МОСПРОМ';
export const DESCR = 'Индивидуальная поддержка экспортеров, байерская программа и аналитические исследования/.';

export default {
  main: {
    root: '/',
    title: `${TITLE} - экспорт из РФ`,
    descr: `${TITLE}. Финансовая и нефинансовая поддержка экспорта из РФ. Экспорт товаров из России в Европу.`,
  },
  about: {
    root: '/about',
    title: 'О центре поддержки и развития промышленного экспорта МОСПРОМ',
    descr: `О центре поддержки и развития промышленного экспорта МОСПРОМ. ${DESCR}`,
  },
  resolutions: {
    root: '/resolutions',
    title: `Меры поддержки экспортеров - ${TITLE}`,
    descr: `Меры поддержки экспортеров. ${DESCR} ${TITLE}.`,
  },
  services: {
    root: '/services',
    title: `Услуги экспортерам - ${TITLE}`,
    descr: `Услуги экспортерам. ${DESCR} ${TITLE}.`,
  },
  events: {
    root: '/events',
    title: `Мероприятия по экспорту - выставки экспорт, ${TITLE}`,
    descr: `Мероприятия и выставки по экспорту. ${DESCR} ${TITLE}. `,
  },
  contacts: {
    root: '/contacts',
    title: 'Контакты центра поддержки и развития промышленного экспорта МОСПРОМ',
    descr: `Контакты центра поддержки и развития промышленного экспорта МОСПРОМ. ${DESCR}`,
  },
  media: {
    root: '/media',
    title: 'Медиа',
    descr: 'Медиа раздел центрa поддержки и развития экспорта "Моспром"',
    // interesting: {
    //   root: '/media/interesting',
    //   label: 'Интересное',
    //   title: `Статьи, интервью и новости экспорта
    // на сайте центра поддержки и развития промышленного экспорта МОСПРОМ`,
    //   descr: `Новости, статьи и интервью об экспорте`. ${DESCR} ${TITLE}. `,
    // },
    news: {
      root: '/media/news',
      label: 'Новости',
      title: `Новости экспорта России - ${TITLE}`,
      descr: `Новости экспорта России. ${DESCR} ${TITLE}.`,
    },
    // history: {
    //   root: '/media/history',
    //   label: 'Истории успеха',
    //   title: `Истории успеха - ${TITLE}`,
    //   descr: `Истории успеха. ${DESCR} ${TITLE}.`,
    // },
    // interview: {
    //   root: '/media/interview',
    //   label: 'Интервью',
    //   title: `Интервью об экспорте - ${TITLE}`,
    //   descr: `Интервью об экспорте. ${TITLE}.
    // ${DESCR}`,
    // },
    gallery: {
      root: '/media/gallery',
      label: 'Галерея',
      title: `Галерея - ${TITLE}`,
      descr: `Галерея экспортных мероприятий. ${DESCR} ${TITLE}.`,
    },
    articles: {
      root: '/media/articles',
      label: 'Статьи',
      title: `Статьи - ${TITLE}`,
      descr: `Статьи - ${TITLE}.`,
    },
    specProjects: {
      root: '/media/special-projects',
      label: 'Внешние мероприятия',
      title: `Внешние мероприятия - ${TITLE}`,
      descr: `Внешние мероприятия - ${TITLE}.`,
    },
    techTours: {
      root: '/media/technological-tours',
      label: 'Технологические туры',
      title: `Технологические туры - ${TITLE}`,
      descr: `Технологические туры - ${TITLE}.`,
    },
    videoAlbums: {
      root: '/media/video-albums',
      label: 'Видео',
      title: `Видео - ${TITLE}`,
      descr: `Видео - ${TITLE}.`,
    },
    // Video Pages
    videoFaces: {
      root: '/media/video-faces',
      // label: 'Лица московского экспорта',
      label: 'Истории успеха',
      title: `Видео. Истории успеха - ${TITLE}`,
      descr: `Видео. Истории успеха - ${TITLE}.`,
    },
    videoLifeHacks: {
      root: '/media/video-lifehacks',
      label: 'Лайфхаки',
      title: `Видео. Лайфхаки - ${TITLE}`,
      descr: `Видео. Лайфхаки - ${TITLE}.`,
    },
    // videoWhatBuy: {
    //   root: '/media/video-what-buy',
    //   label: 'Что покупают в Москве',
    //   title: `Видео. Что покупают в Москве - ${TITLE}`,
    //   descr: `Видео. Что покупают в Москве - ${TITLE}.`,
    // },
    videoWhatBuy: {
      root: '/media/video-what-buy',
      label: 'BUY IN MOSCOW. Что покупают в Москве?',
      title: `Видео. BUY IN MOSCOW. Что покупают в Москве? - ${TITLE}`,
      descr: `Видео. BUY IN MOSCOW. Что покупают в Москве? - ${TITLE}.`,
    },
    videoAboutExport: {
      root: '/media/video-about-export',
      label: 'Эксперты об экспорте',
      title: `Видео. Эксперты об экспорте - ${TITLE}`,
      descr: `Видео. Эксперты об экспорте - ${TITLE}.`,
    },
  },
  analytics: {
    root: '/analytics',
    title: `Аналитика экспорта - ${TITLE}`,
    descr: `Аналитика экспорта по странам и индустриям. ${DESCR} ${TITLE}.`,
    regionalExecute: {
      root: '/analytics/regional-execute',
      title: `Исполнение региональных проектов - ${TITLE}`,
      descr: `Исполнение региональных проектов. ${DESCR} ${TITLE}.`,
    },
    countries: {
      root: '/analytics/countries',
      title: `Аналитика экспорта по странам - ${TITLE}`,
      descr: `Аналитика экспорта по странам. ${DESCR} ${TITLE}.`,
    },
    industry: {
      root: '/analytics/industry',
      title: `Аналитика экспорта по индустриям - ${TITLE}`,
      descr: `Аналитика экспорта по индустриям. ${DESCR} ${TITLE}.`,
    },
  },
  page404: {
    root: '/sorry-404',
    title: 'Страница не найдена',
    descr: 'Страница не найдена',
  },
  personal: {
    root: '/personal',
    title: 'Личный кабинет',
    descr: 'Личный кабинет',
    analytics: {
      root: '/personal/analytics',
      title: 'Аналитика',
      descr: 'Аналитика',
      individual: {
        root: '/personal/analytics/individual',
        title: 'Индивидуальные исследования',
        descr: 'Индивидуальные исследования',
      },
    },
    goods: {
      root: '/personal/goods',
      title: 'Объявления',
      descr: 'Запросы и предложения',
      offers: {
        root: '/personal/goods/offers',
        title: 'Предложения',
        descr: 'Предложения',
      },
      announcements: {
        root: '/personal/goods/mine',
        title: 'Мои объявления',
        descr: 'Мои объявления',
      },
    },
    calendar: {
      root: '/personal/calendar',
      title: 'Календарь',
      descr: 'Календарь',
    },
    catalog: {
      root: '/personal/catalog',
      title: 'Каталог',
      descr: 'Каталог',
    },
    faq: {
      root: '/personal/faq',
      title: 'Faq',
      descr: 'Faq',
    },
    exchange: {
      root: '/personal/exchange',
      title: 'Биржа контактов',
      descr: 'Биржа контактов',
      buyer: {
        root: '/personal/exchange/buyer',
        title: 'Поиск байера',
        descr: 'Поиск байера',
      },
      suggestions: {
        root: '/personal/exchange/suggestions',
        title: 'Поиск экспортера',
        descr: 'Поиск экспортера',
      },
    },
    feed: {
      root: '/personal/feed',
      title: 'Лента новостей',
      descr: 'Лента новостей',
    },
    events: {
      root: '/personal/events',
      title: 'События',
      descr: 'События',
      list: {
        root: '/personal/events/list',
        title: 'Список событий',
        descr: 'Список событий',
      },
    },
    leads: {
      root: '/personal/leads',
      title: 'Заявки',
      descr: 'Заявки',
      archive: {
        root: '/personal/leads/archive',
        title: 'Архивные заявки',
        descr: 'Архивные заявки',
      },
    },
    notifications: {
      root: '/personal/notifications',
      title: 'Уведомления',
      descr: 'Уведомления',
    },
    profile: {
      root: '/personal/profile',
      title: 'Профиль',
      descr: 'Профиль',
      company: {
        root: '/personal/profile/company',
        title: 'Профиль компании',
        descr: 'Профиль компании',
      },
      documents: {
        root: '/personal/profile/documents',
        title: 'Документы',
        descr: 'Документы',
      },
      ADEPT7QualResult: {
        root: '/personal/profile/adept7-qualification',
        title: 'Квалификация тестирования ADEPT7',
        descr: 'Результаты тестирования ADEPT7',
      },
      ADEPT7ValidResult: {
        root: '/personal/profile/adept7-validation',
        title: 'Валидация тестирования ADEPT7',
        descr: 'Результаты тестирования ADEPT7',
      },
      tests: {
        root: '/personal/profile/tests',
        title: 'Тест ADEPT7',
        descr: 'Тест ADEPT7',
      },
    },
    resolutions: {
      root: '/personal/resolutions',
      title: 'Меры поддержки',
      descr: 'Меры поддержки',
    },
    welcome: {
      root: '/welcome',
      title: 'Добро пожаловать',
      descr: 'Добро пожаловать на портал Моспром',
    },
  },
  auth: {
    root: '/account',
    title: 'Авторизация',
    descr: 'Авторизация',
    login: {
      root: '/account/login',
      title: 'Авторизация',
      descr: 'Авторизация',
    },
    restore: {
      root: '/account/restore',
      title: 'Восстановление пароля',
      descr: 'Восстановление пароля ',
    },
    newPass: {
      root: '/account/newPass',
      title: 'Восстановление пароля',
      descr: 'Восстановление пароля ',
    },
    signUp: {
      root: '/account/signUp',
      title: 'Регистрация',
      descr: 'Регистрация',
    },
    exportTest: {
      root: '/account/exportTest',
      title: 'Тест экспортной готовности',
      descr: 'Тест экспортной готовности',
    },
    activation: {
      root: '/account/activation',
      title: 'Активация аккаунта',
      descr: 'Активация аккаунта',
    },
    activated: {
      root: '/account/activated',
      title: 'Аккаунт активирован',
      descr: 'Аккаунт активирован',
    },
  },
};
