import React, { FC, useEffect } from 'react';
import cx from 'classnames';
import RModal, { Props as RModalProps } from 'react-modal';
import { _t } from 'utils';
import { ButtonClose, H3 } from 'components';
import styles from './styles.module.scss';

const ROOT = document.getElementById('root');

interface ModalProps extends RModalProps {
  onClose?: () => void;
  title?: string;
  className?: string,
  classNameTitle?: string;
  classNameClose?: string;
  classNameOverlay?: string,
  classNameContent?: string,
}

const Modal: FC<ModalProps> = ({
  onClose,
  title,
  isOpen,
  style,
  portalClassName,
  bodyOpenClassName,
  htmlOpenClassName,
  className,
  appElement,
  onAfterOpen = () => {},
  onAfterClose = () => {},
  closeTimeoutMS,
  aria,
  data,
  role,
  contentLabel,
  contentRef,
  overlayRef,
  testId,
  id,
  classNameTitle,
  classNameClose,
  classNameOverlay,
  classNameContent,
  children,
}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  });

  return (
    <RModal
      isOpen={isOpen}
      style={style}
      portalClassName={portalClassName}
      bodyOpenClassName={cx(styles.body, bodyOpenClassName)}
      htmlOpenClassName={htmlOpenClassName}
      // className={cx(styles.modal)}
      className={cx(styles.modal, className)}
      overlayClassName={cx(classNameOverlay, styles.overlay)}
      appElement={appElement}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
      onRequestClose={onClose}
      closeTimeoutMS={closeTimeoutMS}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      shouldReturnFocusAfterClose
      parentSelector={() => ROOT!}
      aria={aria}
      data={data}
      role={role}
      contentLabel={contentLabel}
      contentRef={contentRef}
      overlayRef={overlayRef}
      testId={testId}
      id={id}
    >
      <div className={classNameContent}>
        {title && <H3 className={cx(styles.title, classNameTitle)}>{_t(title)}</H3>}
        {onClose && (
          <ButtonClose
            onClick={onClose}
            theme="transparent"
            className={cx(styles.btnClose, classNameClose)}
          />
        )}
        {children}
      </div>
    </RModal>
  );
};

export default Modal;
