import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import { NavLink } from 'components';
import { Link } from 'types';
import { _t } from 'utils';
import styles from './styles.module.scss';

interface Props {
  links: Link[] | null,
  onClick?: () => void,
}

const PersonalPageMenu: FC<PropsWithChildren<Props>> = ({
  links = [],
  children,
  onClick = () => {},
}) => {
  const fixed = links?.length === 1;

  return (
    <div className={styles.profileHeader}>
      {links && links?.map(({ title, to }) => ((
        <NavLink
          key={title}
          className={cx(
            styles.link,
          )}
          activeClassName={cx(
            { [styles.active]: (window.location.pathname === to || fixed) },
          )}
          to={to}
          onClick={() => onClick()}
        >
          {_t(title)}
        </NavLink>
      )))}
      {children && (
        <div className={styles.right}>
          {children}
        </div>
      )}
    </div>
  );
};

export default PersonalPageMenu;
