import { put, takeLatest, call } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { toast } from 'react-toastify';
import api from 'store/api';
import { URL } from 'appConstants';
import { RESTORE_ERRORS, _t } from 'utils';
import type { Response, RestoreReq } from 'types';
import actionTypes, { RestoreAction as Action } from '../actionTypes';

function* restore({ type, payload }: Action) {
  try {
    if (payload) {
      yield put(apiActions.request(type));
      const { data }: Response<RestoreReq> = yield call(api, {
        method: 'post',
        url: URL.AUTH.RESTORE_START,
        data: payload,
      });
      yield put(apiActions.success(type, data));

      toast.success(_t('Запрос на смену пароля отправлен, проверьте почту'));
    }
  } catch (err) {
    const error = RESTORE_ERRORS[err.response.data.detail];
    toast.error(_t(error || err.response.data.detail));
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.ME_RESTORE, restore);
}
