import { TAdeptScores } from 'types';

export const testResults:{
  'Q': TAdeptScores,
  'V': TAdeptScores,
} = {
  Q: {
    d1: 0.95,
    d2: 0.6,
    d3: 0.3,
    d4: 0.7,
    d5: 0.25,
    d6: 0.56,
    d7: 0.45,
  },
  V: {
    d1: 0.56,
    d2: 0.50,
    d3: 0.95,
    d4: 0.6,
    d5: 0.3,
    d6: 0.7,
    d7: 0.25,
  },
};

export const ParamsText = {
  d1: {
    title: 'Люди и компетенции',
    descr: 'Данное измерение дает характеристику человеческих ресурсов компании: уточняет знания и навыки руководства и рядовых сотрудников, опыт в экспортной деятельности или смежных направлениях, возможность персонала и руководства справляться с рутиной и трудностями, с которыми они могут столкнутся после начала экспортной деятельности',
  },
  d2: {
    title: 'Средства',
    descr: 'Измерение Средства описывает материальные ресурсы компании. В том числе помогает уточнить достаточность производственных возможностей предприятия, планируемое финансирование, наличие необходимых внутренних ресурсов компании и доступность привлечения/приобретения новых.',
  },
  d3: {
    title: 'Инфраструктура и адаптивность',
    descr: 'Измерение Инфраструктура и адаптивность позволяет охарактеризовать гибкость внутренних структур компании, то есть возможность и необходимость подстроить инфраструктуру и процессы компании под экспортную деятельность. Оно отражает способность компании решать вопросы транспортировки, сертификации, прохождения таможенных процедур и другие технические вопросы.',
  },
  d4: {
    title: 'Мотивация и цели',
    descr: 'Данное измерение дает численную оценку мотивированности высшего руководства компании и согласованности стратегических целей компании с желанием выхода на экспорт (расширения экспортной деятельности).',
  },
  d5: {
    title: 'Среда',
    descr: 'Измерение Среда дает характеристику окружению, в котором компания находится на внутреннем рынке (включая поддержку государства), а также степени подверженности компании факторам внешнего окружения: чувствительность к политической и экономической ситуации на внутреннем рынке, предпосылки к началу экспортной деятельности.',
  },
  d6: {
    title: 'Продукт',
    descr: 'Данное измерение позволяет определить готовность продукта для экспорта. Уточняются недостатки и конкурентные преимущества продукта, а также, способность компании поддерживать и удовлетворять спрос на него.',
  },
  d7: {
    title: 'Внедрение',
    descr: 'Измерение Внедрение характеризует степень вовлечения копании в международную торговлю. Определяет положение компании на шкале от «Никогда не экспортировали» до «Активно и успешно занимаемся экспортном». Измерение Внедрение дает понять, какие шаги компания уже предприняла в направлении экспортной деятельности.',
  },
};

export const overallScore:number = 0.9;

export const Conclusions = {
  Q: {
    passed: (company: string, score: number) => `По итогам прохождения опросника этапа Квалификация теста Экспортной готовности ${company} получила оценку ${score},
      и прошла этап Квалификация, получив доступ к этапу Валидация.`,
    failed: `Компания уже движется в верном направлении, для начала успешной экспортной деятельности, остается совсем немного подготовиться.
      Придерживайтесь рекомендаций в разделе «Результаты и рекомендации в разрезе измерений» и через полгода сможете пройти тестирование повторно.
      <br><br>
      Желаем успехов на вашем экспортном пути!`,
  },
  V: {
    passed: (company: string, score: number) => `По итогам прохождения опросника этапа Валидация теста Экспортной готовности ${company} получила оценку ${score},
      и прошла этап Валидация.`,
    failed: `Компания уже движется в верном направлении, для начала успешной экспортной деятельности с выбранным товаром в выбранной стране, остается совсем немного подготовиться.
      Придерживайтесь рекомендаций в разделе «Результаты и рекомендации в разрезе измерений» и через полгода сможете пройти тестирование повторно для выбранных товара и страны.
      <br><br>
      Желаем успехов на вашем экспортном пути!`,
  },
};
