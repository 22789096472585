import React, { FC } from 'react';
import { Section, Text } from 'components';
import { _t } from 'utils';
import styles from './styles.module.scss';

type Props = {
  className?: string;
  email?: string;
};

const ActivationPage: FC<Props> = ({ email }) => {
  return (
    <Section>
      <Text
        tag="span"
        align="center"
        className={styles['activation__title']}
        bold
        uppercase
      >{_t('Благодарим за регистрацию!')}
      </Text>
      <Text tag="p" align="center" className={styles['activation__link-info']}>
        {_t('Ссылка для активации аккаунта отправлена на указанный адрес электронной почты - ')}
        <b>{email}</b>
      </Text>
      <Text tag="p" align="center" className={styles['activation__activation-info']}>
        {_t('Пожалуйста, активируйте свой аккаунт, чтобы получить возможность зайти в личный кабинет.')}
      </Text>
    </Section>
  );
};
export default ActivationPage;
