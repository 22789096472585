/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import cx from 'classnames';
import { Link as ReactRouterDomLink, LinkProps } from 'react-router-dom';

const Link: FC<LinkProps> = ({
  to,
  children,
  className,
  onClick,
  ...rest
}) => (
  <ReactRouterDomLink
    {...rest}
    to={to}
    className={cx(className)}
    onClick={onClick}
  >
    {children}
  </ReactRouterDomLink>
);

export default Link;
