import { FC, PropsWithChildren, createElement } from 'react';
import cx from 'classnames';
import {
  TextAlign as Align,
  TextColor as Color,
  TextSize as Size,
} from 'types';

import styles from './styles.module.scss';

type Tag = 'p' | 'span';
type Props = {
  tag?: Tag,
  className?: string,
  text?: string,
  title?: string,
  underline?: boolean,
  style?: { [key: string]: string | number },
  use?: '' | 'title',
  size?: Size,
  color?: Color,
  align?: Align;
  bold?: boolean,
  uppercase?: boolean,
};

const Text: FC<PropsWithChildren<Props>> = ({
  tag = 'p',
  children,
  className,
  style = { },
  text = 'text',
  use = '',
  title,
  size = 'normal',
  color = 'dark',
  align = 'left',
  underline = false,
  bold = false,
  uppercase = false,
}) => (
  createElement(
    tag,
    {
      style,
      className: cx(
        styles[text],
        styles[size],
        styles[color],
        styles[align],
        styles[use],
        {
          [styles.bold]: bold,
          [styles.underline]: underline,
          [styles.uppercase]: uppercase,
        },
        className,
      ),
      title: title || '',
    },
    children,
  )
);

export default Text;
