import React, { FC, PropsWithChildren } from 'react';
import Modal from 'react-modal';
import { ButtonClose } from 'components';
import styles from './styles.module.scss';

type Props = {
  isOpen: boolean,
  onClose?: () => void,
};

const Popup: FC<PropsWithChildren<Props>> = ({
  isOpen,
  children,
  onClose = () => {},
}) => (
  <Modal
    isOpen={isOpen}
    ariaHideApp={false}
    className={styles.popup}
    closeTimeoutMS={300}
    overlayClassName={styles.overlay}
    shouldCloseOnOverlayClick
    parentSelector={() => document.querySelector('#root') as HTMLElement}
    onRequestClose={onClose}
  >
    <div className={styles.content}>
      <ButtonClose className={styles.close} onClick={onClose} />
      {children}
    </div>
  </Modal>
);

export default Popup;
