import { IAdeptItem, TTexts } from 'types';
import { ParamsText } from './data';

export const QualParams:IAdeptItem[] = [
  {
    id: 0,
    toRes: 'd1',
    low: 0.6,
    mid: 0.85,
    ...ParamsText.d1,
  }, {
    id: 1,
    toRes: 'd2',
    low: 0.47,
    mid: 0.72,
    ...ParamsText.d2,
  }, {
    id: 2,
    toRes: 'd3',
    low: 0.32,
    mid: 0.66,
    ...ParamsText.d3,
  }, {
    id: 3,
    toRes: 'd4',
    low: 0.43,
    mid: 0.63,
    ...ParamsText.d4,
  }, {
    id: 4,
    toRes: 'd5',
    low: 0.11,
    mid: 0.44,
    ...ParamsText.d5,
  }, {
    id: 5,
    toRes: 'd6',
    low: 0.47,
    mid: 0.71,
    ...ParamsText.d6,
  }, {
    id: 6,
    toRes: 'd7',
    low: 0.07,
    mid: 0.53,
    ...ParamsText.d7,
  },
];

export const qTexts:TTexts = {
  d1: [
    {
      from: 2.0,
      to: 10,
      class: 'high',
      text: [{
        p: 'Оценка {value} по измерению «Люди и компетенции» означает <strong>высокий уровень</strong> подготовки кадров для осуществления экспортной деятельности, что позволяет отнести данное измерение к «Сильным сторонам». Руководство и сотрудники ответственно подходят к развитию экспортного направления и приобретают для него новые необходимые навыки и знания. На данный момент развитие измерения «Люди и компетенции» не является приоритетным. Рекомендуется в дальнейшем поддерживать достигнутый уровень по данному измерению.',
      }],
    },
    {
      from: 0.0,
      to: 2.0,
      class: 'mid',
      text: [{
        p: 'Оценка {value} по измерению «Люди и компетенции» означает <strong>средний уровень</strong> (без ярко выраженных преимуществ) подготовки кадров для начала экспортной деятельности. Данное измерение находится в «Нейтральной зоне». Руководство и сотрудники стремятся к приобретению необходимых навыков и знаний, что выводит компанию на уровень подготовки, достаточный для вступления в экспортную гонку. Для достижения максимальных оценок по измерению «Люди и компетенции» и добавления уверенности в будущей эффективности экспортного направления компании рекомендуется:',
        tips: [
          'Продолжить развитие в направлении обогащения компетенций сотрудников, занятых в экспортной деятельности;',
          'Обеспечить сотрудникам и руководству доступ к информации и ресурсам, необходимым для подготовки и осуществления планируемых экспортных процедур.',
        ],
      }],
    },
    {
      from: -2.0,
      to: 0.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Люди и компетенции» означает <strong>недостаточный уровень</strong> знаний и опыта у сотрудников и руководства компании для осуществления экспортной деятельности. Данное измерение соответственно относится к «Зоне развития».  Сотрудники и руководство не подготовлены к преодолению препятствий, которые могут возникнуть при осуществлении экспортной деятельности: налаживание и поддержание сотрудничества с заграничными партнерами, устранение проблем с прохождением таможни, ведение переговоров с иностранными контрагентами.',
      }, {
        p: 'Рекомендуется:',
        tips: [
          'Расширить базу знаний компании об экспортных процедурах;',
          'Провести исследование иностранных рынков;',
          'Присмотреться к опыту компаний-конкурентов на иностранных рынках.',
        ],
      }, {
        p: 'Увеличить уровень знаний сотрудников и руководства об экспорте. Этого можно достичь несколькими способами, например:',
        tips: [
          'Повышение квалификации в экспортных вопросах: курсы или семинары для сотрудников и руководства;',
          'Наем сотрудников, имеющих соответствующие знания и навыки по внешнеэкономической (экспортной) деятельности;',
          'Выделение отдельного сотрудника или группы сотрудников (создание отдела), деятельность которых направлена на изучение и внедрение общепринятых практик и методик, используемых для оптимизации процедур экспорта.',
        ],
      }],
    },
    {
      from: -100,
      to: -2.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Люди и компетенции» означает <strong>недостаточный уровень</strong> знаний и опыта у сотрудников и руководства компании для осуществления экспортной деятельности. Данное измерение соответственно относится к «Зоне развития». Если экспорт – новое направление деятельности компании, то, чтобы в нем преуспеть, необходимо подготовиться. Рекомендуется:',
        tips: [
          'Изучить материалы по экспортной деятельности компаний в вашей рыночной нише, провести исследование на предмет успешности выхода на экспорт конкурентов с домашнего рынка;',
          'Подготовить (нанять или обучить имеющихся) сотрудников к новым видам деятельности, связанным с экспортом;',
          'Руководству уделить больше внимания подготовке к выходу на экспорт и направить развитие компании в сторону повышения компетенции сотрудников и руководства в вопросах экспортной деятельности.',
        ],
      }],
    },
  ],
  d2: [
    {
      from: 2.0,
      to: 100,
      class: 'high',
      text: [{
        p: 'Оценка {value} по измерению «Средства» характеризует <strong>высокий уровень</strong> финансового и материального обеспечения экспортного проекта компании. Данное измерение является одной из «Сильных сторон». На данный момент развитие измерения «Средства» не является приоритетным. Рекомендуется в дальнейшем поддерживать достигнутый уровень по данному измерению.',
      }],
    },
    {
      from: 0.0,
      to: 2.0,
      class: 'mid',
      text: [{
        p: 'Оценка {value} по измерению «Средства» характеризует <strong>средний уровень</strong> (без ярко выраженных преимуществ) финансового и материального обеспечения экспортного направления компании. Данное измерение находится в «Нейтральной зоне». Имеющихся ресурсов достаточно для начальной стадии экспорта, однако для обеспечения успешных результатов рекомендуется:',
        tips: [
          'Возобновить или начать поиск источников и возможностей дополнительного привлечения ресурсов. Выгодные партнерства и новые инвестиции помогут удержать и повысить уровень готовности к экспорту в рамках финансового и материального обеспечения;',
          'Провести учет и распределение материальных ресурсов, которые могут быть задействованы в рамках экспортного проекта.',
        ],
      }],
    },
    {
      from: -2.0,
      to: 0.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Средства» характеризует <strong>недостаточный уровень</strong> материальных и финансовых ресурсов для начала экспортной деятельности. Это измерение находится в «Зоне развития». Рекомендуется проявить активность в поиске дополнительных источников финансирования экспорта:',
        tips: [
          'Найти партнеров-единомышленников для взаимовыгодного сотрудничества с целью выхода на экспорт;',
          'Провести перераспределение имеющихся материальных ресурсов с целью высвобождения ресурсов под экспортную деятельность;',
          'Привлечь сторонние источники финансирования (инвесторов, банки/кредиторов).',
        ],
      }],
    },
    {
      from: -100,
      to: -2.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Средства» характеризует <strong>недостаточный уровень</strong> материальных и финансовых ресурсов для начала экспортной деятельности. Это измерение находится в «Зоне развития». Руководству компании необходимо продумать различные варианты источников финансирования экспорта, а также варианты распределения и получения дополнительных ресурсов. Рекомендуется:',
        tips: [
          'Рассмотреть варианты партнерства с компаниями с домашнего или целевого рынка с целью взаимного обмена недостающими ресурсами;',
          'Рассмотреть варианты займа средств для старта экспортного проекта в банках;',
          'Привлечь инвесторов, заинтересованных в экспорте вашего продукта на целевой рынок;',
          'Провести оценку и перераспределения имеющихся ресурсов компании, найти новые источники материальных ресурсов, если текущих окажется недостаточно.',
        ],
      }],
    },
  ],
  d3: [
    {
      from: 3.0,
      to: 100,
      class: 'high',
      text: [{
        p: 'Оценка {value} по измерению «Инфраструктура и адаптивность» отмечает <strong>высокий уровень</strong> подготовленности инфраструктуры компании к возможным препятствиям. Такая оценка позволяет отнести данное измерение к «Сильным сторонам» На данный момент развитие измерения «Инфраструктура и адаптивность» не является приоритетным. Рекомендуется в дальнейшем поддерживать достигнутый уровень по данному измерению.',
      }],
    },
    {
      from: 0.0,
      to: 3.0,
      class: 'mid',
      text: [{
        p: 'Оценка {value} по измерению «Инфраструктура и адаптивность» отмечает <strong>средний уровень</strong> (без ярко выраженных преимуществ) подготовленности инфраструктуры компании к возможным препятствиям и вызовам. Это измерение находится в «Нейтральной зоне». Руководство понимает, что с началом экспортной деятельности в компании могут появиться новые виды работ, новые обязанности и процессы и стремится предоставить сотрудникам необходимые инструменты и знания для решения возникших задач. Для укрепления уверенности в успешном выходе на экспорт и повышения уровня готовности рекомендуется:',
        tips: [
          'Обеспечить гибкость внутренней инфраструктуры компании;',
          'Удостовериться, что назначены ответственные за выполнение экспортных задач и им доступны необходимые ресурсы для выполнения этих задач;',
          'Продумать варианты поведения и адаптации рабочего процесса в различных сценариях развития экспортного проекта.',
        ],
      }],
    },
    {
      from: -1.5,
      to: 0.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Инфраструктура и адаптивность» отмечает <strong>недостаточный уровень</strong> подготовленности инфраструктуры компании к возможным препятствиям. Данное измерение находится в «Зоне развития». С началом экспортной деятельности в компании возникнут новые задачи и направления работ, появятся новые обязанности и процессы, руководство компании должно обеспечить сотрудников необходимыми инструментами и доступом к источникам знаний для решения возникших задач, а также обеспечить гибкость инфраструктуры на случай непредвиденных изменений. Для этого рекомендуется:',
        tips: [
          'Пересмотреть инфраструктуру, организованную для экспортного проекта. Ответить на вопросы: Кто принимает решения (и несет ответственность) по экспортному проекту? Кто является исполнителем рутинных задач? Есть ли у исполнителей все необходимое для выполнения поставленных задач?',
          'Продумать возможности адаптации бизнеса под новые реалии целевого рынка. Определить какие действия могут быть предприняты и кто будет их осуществлять в разных сценариях развития экспортного проекта.',
          'Укрепить свои позиции на домашнем рынке, стабилизировать объемы производства, обеспечивая крепкий фундамент (старт) для нового экспортного проекта.',
        ],
      }],
    },
    {
      from: -100,
      to: -1.5,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Инфраструктура и адаптивность» отмечает <strong>недостаточный уровень</strong> подготовленности инфраструктуры компании к препятствиям и вызовам. Данное измерение находится в «Зоне развития».  Гибкость инфраструктуры и адаптивность процессов компании обеспечат выживание экспортного проекта в случае непредвиденных сценариев развития деятельность на целевом рынке. Для подготовки структуры компании к началу экспортного проекта и улучшения показателей адаптивности внутри процессов компании рекомендуется:',
        tips: [
          'Четко распределить обязанности, связанные с будущим проектом, назначить ответственных и исполнителей.',
          'Определить заранее какие ресурсы будут вовлечены в новый проект и обеспечить в случае необходимости замещение или компенсацию этих ресурсов для поддержания бесперебойной работы основного бизнеса.',
          'Продумать возможности адаптации процессов компании под иностранный рынок, обсудить преимущества и недостатки подобной адаптации, ее потенциальные выгоды.',
        ],
      }],
    },
  ],
  d4: [
    {
      from: 6.0,
      to: 100,
      class: 'high',
      text: [{
        p: 'Оценка {value} по измерению «Мотивация и цели» отражает <strong>высокий уровень</strong> мотивации сотрудников и руководства относительно экспортного проекта, что позволяет отнести данное измерение к «Сильным сторонам». На данный момент развитие измерения «Мотивация и цели» не является приоритетным. Рекомендуется в дальнейшем поддерживать достигнутый уровень по данному измерению.',
      }],
    },
    {
      from: 3.0,
      to: 6.0,
      class: 'high',
      text: [{
        p: 'Оценка {value} по измерению «Мотивация и цели» отражает <strong>высокий уровень</strong> мотивации сотрудников и руководства, что позволяет отнести данное измерение к «Сильным сторонам». Компания работает в правильном направлении относительно формирования целей и поддержания мотивации. На данный момент развитие измерения «Мотивация и цели» не является приоритетным. Рекомендуется в дальнейшем поддерживать достигнутый уровень по данному измерению.',
      }],
    },
    {
      from: 0.0,
      to: 3.0,
      class: 'mid',
      text: [{
        p: 'Оценка {value} по измерению «Мотивация и цели» отражает <strong>средний уровень</strong> (без ярко выраженных преимуществ) мотивации сотрудников и руководства. Данное измерение находится в «Нейтральной зоне». Рекомендуется:',
        tips: [
          'Уделить больше времени проработке целей экспортной деятельности;',
          'Заинтересовать сотрудников в содействии достижения поставленных целей;',
          'Провести необходимые встречи и презентовать планы компании относительно экспортного проекта для достижения взаимопонимания между сотрудниками и руководством и обеспечения движения компании по установленному курсу.',
        ],
      }],
    },
    {
      from: -3.0,
      to: 0.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Мотивация и цели» отражает <strong>недостаточный уровень</strong> мотивации сотрудников и руководства. Данное измерение находится в «Зоне развития». Рекомендуется:',
        tips: [
          'Руководству четко сформировать краткосрочные и долгосрочные цели экспортного проекта. Донести эти цели до сотрудников, задействованных в проекте;',
          'Стимулировать интерес сотрудников к проекту, прояснить плюсы и преимущества успешного развития экспортного направления;',
          'Провести исследования целевого рынка, изучить опыт экспорта компаний-конкурентов с домашнего рынка с целью прояснения возможных препятствий и преимуществ.',
        ],
      }],
    },
    {
      from: -100,
      to: -3.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Мотивация и цели» отражает <strong>недостаточный уровень</strong> мотивации сотрудников и руководства. Данное измерение находится в «Зоне развития».',
      }, {
        p: 'Постановка целей – важнейший процесс на этапе планирования экспортной деятельности. Цели и мотивация руководства определяют направление всего проекта. Перед началом экспортной деятельности компании рекомендуется:',
        tips: [
          'Задуматься о целях выхода на экспорт, сформировать одну или несколько четких целей, которые не противоречат друг другу и вписываются в общий курс развития компании на данный момент;',
          'Продумать план или примерный порядок действий, необходимых для достижения каждой из утверждённых целей;',
          'В ясной и четкой форме донести цели и планы по их достижению до задействованных сотрудников. Показать какие преимущества их ждут в процессе и по окончанию выполнения плана;',
          'Провести исследования на предмет возможных трудностей экспортной деятельности в вашей сфере. Изучить опыт других компаний в этом вопросе.',
        ],
      }],
    },
  ],
  d5: [
    {
      from: 6.0,
      to: 100,
      class: 'high',
      text: [{
        p: 'Оценка {value} по измерению «Среда» говорит о <strong>высоком уровне</strong> проработки данного измерения и относит его к «Сильным сторонам». На данный момент развитие измерения «Среда» не является приоритетным. Рекомендуется в дальнейшем поддерживать достигнутый уровень по данному измерению.',
      }],
    },
    {
      from: 3.0,
      to: 6.0,
      class: 'high',
      text: [{
        p: 'Оценка {value} по измерению «Среда» говорит о <strong>высоком уровне</strong> проработки данного измерения и относит его к «Сильным сторонам».  На данный момент развитие измерения «Среда» не является приоритетным. Рекомендуется в дальнейшем поддерживать достигнутый уровень по данному измерению.',
      }],
    },
    {
      from: 0.0,
      to: 3.0,
      class: 'mid',
      text: [{
        p: 'Оценка {value} по измерению «Среда» говорит о <strong>среднем уровне</strong> (без ярко выраженных преимуществ) проработки данного измерения. Данное измерение находится в «Нейтральной зоне». Рекомендуется:',
        tips: [
          'Провести исследования зарубежных рынков на предмет востребованности вашей сферы деятельности, ознакомиться с нюансами международных отношений в этой сфере;',
          'Изучить возможности поддержки экспортеров государством, дополнительные льготы или поощрения могут помочь продвинуть экспортный проект на новый уровень;',
          'Изучить ситуацию относительно экспорта на вашем домашнем рынке: выяснить кто экспортирует, в каких объемах. Данное исследование поможет понять какие компании являются вашими конкурентами в экспортном деле, а с какими можно заключить выгодные союзы.',
        ],
      }],
    },
    {
      from: -100,
      to: 0.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Среда» говорит о <strong>недостаточном уровне</strong> проработки данного измерения. Данное измерение относится к «Зоне развития». Рекомендуется:',
        tips: [
          'Выделить время для изучения текущего экономического и политического окружения компании, а также окружения, в которое компания попадет после начала экспортной деятельности;',
          'Отыскать информацию о количестве и качестве конкурентов на целевом рынке, сравнить эти данные с имеющимися о домашнем рынке. Это позволит спрогнозировать и предупредить возможные трудности;',
          'Изучить законодательство домашнего и целевого рынков в вашей сфере. Это позволит воспользоваться преимуществами, которые может предоставить государство, и проработать возможные ограничения в законах, связанные с вашим родом деятельности.',
        ],
      }],
    },
  ],
  d6: [
    {
      from: 1.5,
      to: 100,
      class: 'high',
      text: [{
        p: 'Оценка {value} по измерению «Продукт» свидетельствует о <strong>высоком уровне</strong> подготовки продукта к началу экспортной деятельности. Данное измерение относится к «Сильным сторонам». Компания хорошо изучила особенности торговли в своей сфере и знает, как правильно представить свой продукт и на домашнем, и на целевом рынке. На данный момент развитие измерения «Продукт» не является приоритетным. Рекомендуется в дальнейшем поддерживать достигнутый уровень по данному измерению.',
      }],
    },
    {
      from: 0.0,
      to: 1.5,
      class: 'mid',
      text: [{
        p: 'Оценка {value} по измерению «Продукт» свидетельствует о <strong>среднем уровне</strong> (без ярко выраженных преимуществ) подготовки продукта к началу экспортной деятельности. Данное измерение относится к «Нейтральной зоне». Для улучшения уровня готовности рекомендуется:',
        tips: [
          'Провести качественное сравнение своего продукта с продуктами конкурентов;',
          'Выделить и осветить преимущества своего продукта.',
        ],
      }],
    },
    {
      from: -1.5,
      to: 0.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Продукт» свидетельствует о <strong>недостаточном уровне</strong> подготовки продукта к началу экспортной деятельности. Данное измерение находится в «Зоне развития». Рекомендуется:',
        tips: [
          'Перед началом экспорта необходимо убедиться в качестве вашей продукции и сопутствующих услуг;',
          'Провести качественное сравнение продукции с аналогами на внутреннем и зарубежных рынках. Такое исследование поможет открыть потенциально возможные улучшения для продукта, а также выявить сильные стороны относительно продукции конкурентов, которые стоить учитывать при продвижении;',
          'Подготовить маркетинговые материалы, продумать и осветить преимущества и важные характеристики продукции и компании в целом.',
        ],
      }],
    },
    {
      from: -100,
      to: -1.5,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Продукт» свидетельствует о <strong>недостаточном уровне</strong> подготовки продукта к началу экспортной деятельности. Данное измерение находится в «Зоне развития». Компании следует сосредоточиться на качестве производимого товара и сопутствующих услуг. Перед началом экспортной деятельности рекомендуется:',
        tips: [
          'Убедиться в устойчивости своих позиций на домашнем рынке. Крепкая основа придаст уверенности в начале экспортного проекта и скомпенсирует возможные потрясения и проблемы, которые могут возникнуть при открытии нового направления;',
          'Подробно изучить свой продукт и его характеристики, провести сравнение с продукцией компаний-конкурентов. Такое исследование поможет пролить свет на потенциальные преимущества и недостатки продукта.',
        ],
      }],
    },
  ],
  d7: [
    {
      from: 2.0,
      to: 100,
      class: 'high',
      text: [{
        p: 'Оценка {value} по измерению «Внедрение» показывает <strong>высокий уровень</strong> вовлечения копании во внешнеэкономическую (экспортную) деятельность и относит данное измерение к «Сильным сторонам». Высокий уровень вовлечения и информированности о зарубежных рынках поможет компании открыть новые экспортные направления и успешно управлять их развитием. На данный момент развитие измерения «Внедрение» не является приоритетным. Рекомендуется в дальнейшем поддерживать достигнутый уровень по данному измерению.',
      }],
    },
    {
      from: 0.0,
      to: 2.0,
      class: 'mid',
      text: [{
        p: 'Оценка {value} по измерению «Внедрение» показывает <strong>средний уровень</strong> (без ярко выраженных преимуществ) вовлечения копании во внешнеэкономическую (экспортную) деятельность. Данное измерение находится в «Нейтральной зоне». Компания уже движется в правильном направлении касательно экспорта и должна не останавливаться на достигнутом. Улучшить подготовку к развертыванию полномасштабной экспортной деятельности помогут следующие рекомендации:',
        tips: [
          'Провести исследование, направленное на изучение зарубежных рынков и процедур экспорта. Имея эти данные, компания сможет быстрее определиться с целевыми направлениями экспортной деятельности;',
          'Оговорить и зафиксировать бюджет экспортного проекта. Внесение в бюджет расходов на экспортный проект в официальной документации компании свидетельствует о серьезности намерений относительно экспорта;',
          'Повысить узнаваемость компании и продукции на зарубежных рынках: принять участие в международных выставках товаров и услуг, связаться с зарубежными компаниями для обмена опытом, попытаться найти партнеров и вступить в международные торговые ассоциации в вашей сфере деятельности.',
        ],
      }],
    },
    {
      from: -100,
      to: 0.0,
      class: 'low',
      text: [{
        p: 'Оценка {value} по измерению «Внедрение» показывает <strong>недостаточный уровень</strong> вовлечения копании во внешнеэкономическую (экспортную) деятельность. Данное измерение относится к «Зоне развития». Рекомендуется:',
        tips: [
          'Определить курс продвижения на иностранном рынке. Без четкого понимания направлений движения можно ненамеренно распылить свои усилия на достижения второстепенных целей и зайти в тупик. В целях предотвращения такого нежелательного эффекта рекомендуется начать исследование зарубежных рынков, добыть информацию о таможенных процедурах, возможных транспортных и складских издержках, требованиях законодательства касательно различных групп товаров;',
          'Утвердить бюджет для экспортного проекта – без понимания объема финансирования крайне проблематично сформировать достижимые требования и планы по проекту;',
          'Направить усилия на поиск и внедрение в международные сообщества, установление контактов с зарубежными компаниями и потенциальными клиентами.',
        ],
      }],
    },
  ],
};
