import { put, takeLatest, call } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { toast } from 'react-toastify';
import api from 'store/api';
import { URL } from 'appConstants';
import { _t, camelCaseToSnakeCase } from 'utils';
import actionTypes, { SendApplicationAction as Action } from '../actionTypes';

function* sendApplications({ type, payload }: Action) {
  try {
    yield put(apiActions.request(type));

    yield call(api, {
      method: 'post',
      url: URL.APPLICATIONS,
      data: camelCaseToSnakeCase(payload!),
    });

    yield put(apiActions.success(type));
    toast.success(_t('Заявка успешно отправлена'));
  } catch (err) {
    toast.error(_t(err.response.data.detail));
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.ME_SEND_APPLICATIONS, sendApplications);
}
