import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import cx from 'classnames';
import { NavLink, Icon, Text } from 'components';
import AnimateHeight from 'react-animate-height';
import { Link, Height } from 'types';
import { NavLinkProps, Link as RLink } from 'react-router-dom';
import { _t } from 'utils';
import { DURATION_ANIMATION } from 'appConstants';
import styles from './styles.module.scss';

interface Props extends NavLinkProps {
  isOpen: boolean,
  title: string,
  icon: string,
  sublinks?: Link[],
  className?: string,
}

const SidebarLink: FC<Props> = ({
  isOpen,
  to,
  title,
  icon,
  sublinks,
  className = '',
}) => {
  const [heightSubmenu, setHeightSubmenu] = useState<Height>(Height.empty);

  useEffect(() => {
    if (!isOpen) setHeightSubmenu(Height.empty);
  }, [isOpen]);

  const handlerToggleSpoiler = useCallback(() => {
    if (isOpen) {
      setHeightSubmenu((state) => {
        return state === Height.empty ? Height.full : Height.empty;
      });
    }
  }, [isOpen]);

  const iconComponent = useMemo(() => (
    <Icon
      icon={icon}
      className={cx(
        styles.icon,
        {
          [styles.isOpen]: isOpen,
        },
      )}
    />
  ), [icon, isOpen]);

  const titleComponent = useMemo(() => (
    <Text
      tag="span"
      bold
      size="small"
      uppercase
      className={cx(
        styles.title,
        {
          [styles.isOpen]: isOpen,
        },
      )}
    >
      {_t(title)}
    </Text>
  ), [title, isOpen]);

  return sublinks
    ? (
      <div className={cx(className)}>
        <div className={styles.spoiler} onClick={handlerToggleSpoiler}>
          {iconComponent}
          {titleComponent}
          {sublinks && (
            <Icon
              icon="dropdown"
              className={cx(
                styles.dropdown,
                {
                  [styles.isOpen]: isOpen,
                  [styles.isActiveItem]: heightSubmenu === Height.full,
                },
              )}
            />
          )}
        </div>
        {sublinks && (
          <AnimateHeight
            duration={DURATION_ANIMATION}
            height={heightSubmenu}
          >
            <div className={styles.submenu}>
              {
                sublinks.map(({ title: titleSublink, to: toSublink }) => (
                  <RLink
                    to={toSublink}
                    key={toSublink}
                    className={styles.sublink}
                  >
                    <Text
                      tag="span"
                      size="small"
                    >
                      {titleSublink}
                    </Text>
                  </RLink>
                ))
              }
            </div>
          </AnimateHeight>
        )}
      </div>
    )
    : (
      <NavLink
        className={cx(styles.link, className)}
        activeClassName={styles.active}
        title={title}
        to={to}
      >
        {iconComponent}
        {titleComponent}
      </NavLink>
    );
};

export default SidebarLink;
