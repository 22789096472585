import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from 'appConstants';
import { EventsList, EventsDetails, MainLayout } from 'containers';
import styles from './styles.module.scss';

const crumbs = [
  { label: 'Календарь мероприятий', to: routes.events.root },
];

const Events = () => (
  <Switch>
    <Route
      path={routes.events.root}
      exact
      render={() => (
        <MainLayout title="Мероприятия">
          <EventsList />
        </MainLayout>
      )}
    />
    <Route
      path={`${routes.events.root}/:id`}
      exact
      render={() => (
        <EventsDetails
          className={styles.details}
          crumbs={crumbs}
          tag="div"
        />
      )}
    />
  </Switch>
);

export default Events;
