import React, {
  FC,
  PropsWithChildren,
} from 'react';
import tabImg from 'assets/img/getResearch.svg';
import { _t } from 'utils';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  tabTitle?: string,
  className?: string,
  defaultImage?: boolean,
  image?: string,
  buttonText?: string,
};

const PersonalTabLayoutLeft: FC<PropsWithChildren<Props>> = ({
  tabTitle = '',
  className,
  defaultImage = false,
  image,
  buttonText = '',
  children,
}) => {
  return (
    <div className={cx(className, styles.tabLayoutLeft)}>
      {tabTitle && (
        <h3 className={styles.title}>{_t(tabTitle)}</h3>
      )}
      {(defaultImage || image) && (
        <img
          src={defaultImage ? tabImg : image}
          className={styles.tabImg}
          alt=""
        />
      )}
      {buttonText && (
        <div className={styles.fakeButton}>{_t(buttonText)}</div>
      )}

      {children}
    </div>
  );
};

export default PersonalTabLayoutLeft;
