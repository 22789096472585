import { put, takeLatest, call } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
// import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import api from 'store/api';
import { URL } from 'appConstants';
import type { Response, Activation } from 'types';
import actionTypes, { ActivateAction as Action } from '../actionTypes';

function* activateUser({ type, payload }: Action) {
  try {
    yield put(apiActions.request(type));
    const { data }: Response<Activation> = yield call(api, {
      method: 'post',
      url: URL.AUTH.ACTIVATION,
      data: payload,
    });
    yield put(apiActions.success(type, data));
    toast.success('Аккаунт активирован!');
  } catch (err) {
    yield put(apiActions.error(actionTypes.ME_ACTIVATE, err));
    toast.error('Ошибка сервиса активации');
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.ME_ACTIVATE, activateUser);
}
