import React, { FC } from 'react';
import { RadialChart } from 'components';
import { TAdeptResults, TScoreParams } from 'types';
import ButtonToScroll from '../ButtonToScroll';
import styles from './styles.module.scss';

type Props = {
  title: string,
  hideButton?: boolean,
  data?: TAdeptResults,
  scoreParams: TScoreParams,
};

const QualScore:FC<Props> = ({
  title,
  hideButton = false,
  data,
  scoreParams,
}) => {
  return (
    <div>
      <h2 className={styles.title}>{title}</h2>

      {data?.quiz.type === 'V' && (
        <div className={styles.productInfo}>
          <div><b>Страна:</b> {data?.country.name}</div>
          <div><b>Описание ТН ВЭД:</b> {data?.product.name}</div>
          <div><b>Код ТН ВЭД:</b> {data?.product.code}</div>
          <div><b>Описание товара:</b> {data?.product_description}</div>
        </div>
      )}

      <div className={styles.block}>
        <div className={styles.score}>
          <h5>Вероятность успешного выхода компании на экспорт</h5>
          <RadialChart
            cur={data?.results.model_res.toFixed(2)}
            level={scoreParams.isPassed ? 'high' : 'low'}
          />
        </div>

        <div className={styles.textRes}>
          <div>
            Оценка компании по результатам этапа {scoreParams.isPassed ? 'выше' : 'ниже'} порогового значения ({scoreParams.limitScoreForPass}).
          </div>
          <div
            className={styles[scoreParams.isPassed ? 'good' : 'bad']}
          >
            Для данной компании проверка на этапе {title} {scoreParams.isPassed ? '' : 'не'} пройдена.
          </div>

          {!hideButton && (
            <ButtonToScroll
              title="Результаты и рекомендации представлены ниже"
              targetId="adept2"
              className={styles.button}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default QualScore;
