/* eslint-disable react/button-has-type */
import React, {
  FC,
  useState,
} from 'react';
import { ClientLang } from 'appConstants';
import type { Translates } from 'types';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  theme?: 'light' | 'dark',
};

const Langs:Translates = {
  ru: {
    to: 'en',
    label: 'EN',
  },
  en: {
    to: 'ru',
    label: 'RU',
  },
};

const LangSwitcher: FC<Props> = ({
  theme = 'dark',
}) => {
  const lang:string = localStorage.getItem('siteLocale') || ClientLang;
  const [locale, setLocale] = useState<string>(lang);

  const clickHandle = () => {
    setLocale(Langs[locale].to);
    localStorage.setItem('siteLocale', Langs[locale].to);
    window.location.reload();
  };

  return (
    <button
      className={cx(
        styles.langSwitcher,
        styles[theme],
      )}
      onClick={clickHandle}
    >
      {Langs[locale].label}
    </button>
  );
};

export default LangSwitcher;
