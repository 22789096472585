import React, {
  FC,
  PropsWithChildren,
} from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  single?: boolean,
};

const PersonalTabLayout: FC<PropsWithChildren<Props>> = ({
  className,
  single = false,
  children,
}) => {
  return (
    <div
      className={cx(
        className,
        [single ? styles.single : styles.rows],
      )}
    >
      {children}
    </div>
  );
};

export default PersonalTabLayout;

export { default as PersonalTabLayoutLeft } from './PersonalTabLayoutLeft';
export { default as PersonalTabLayoutRight } from './PersonalTabLayoutRight';
