import React, {
  FC,
  // useState,
  // useEffect,
  // useMemo,
} from 'react';
// import { useSelector } from 'react-redux';
// import { useRequest } from 'hooks';
import {
  // URL,
  routes,
} from 'appConstants';
// import {
//   PersonalCompanyAnalytic,
//   State,
// } from 'types';
// import {
//   PersonalTabLayout,
//   PersonalTabLayoutLeft,
//   PersonalTabLayoutRight,
// } from 'containers';
// import { HTMLContent } from 'components';
import { _t } from 'utils';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {};

type Tstatus = '1' | '2';
type TtestData = {
  id: number,
  status: Tstatus,
  author: string,
  checked: boolean,
  title: string,
  text: string,
  date: string,
};
type Ttest = {
  [index: string]: string,
};

const testData:TtestData = {
  id: 1,
  status: '1',
  author: 'detail МОСПРОМ',
  checked: true,
  title: 'Lalalalaltae front test',
  text: `Lorem ipsum dolor sit amet consectetur, adipisicing elit.
  Quasi laborum doloremque eligendi! Repellendus aperiam quod fugiat asperiores,
  excepturi facere temporibus ratione non aut dolore tempora quasi dolor,
  molestiae cupiditate commodi?`,
  date: 'пт, 7 июля 2021 г.',
};

const PersonalNotificationsDetail: FC<Props> = () => {
  // const [search, setSearch] = useState<string>('');
  // const [openFilter, setOpenFilter] = useState<boolean>(false);

  // const { data, request } = useRequest<PersonalCompanyAnalytic[]>();
  // const token = useSelector(({ me }: State) => me.token);

  // useEffect(() => {
  //   request({
  //     method: 'GET',
  //     url: URL.PERSONAL.LEADS.GET,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  // }, []);

  const renderBackButton = () => (
    <a
      href={routes.personal.notifications.root}
      className={styles.backButton}
    >
      {_t('Назад')}
    </a>
  );

  const renderHeadBlock = () => (
    <div
      className={styles.headBlock}
    >
      <div className={styles.author}>
        <div>
          {_t('от кого')}: {testData.author}
        </div>
        <div
          className={cx(styles.checkBlock, styles[testData.checked ? 'checked' : 'none'])}
        >
          <div className={styles.chevron} />
          <span className={styles.descr}>{_t(testData.checked ? 'Снять отметку' : 'Пометить флажком')}</span>
        </div>
      </div>
      <div className={styles.date}>
        {testData.date}
      </div>
    </div>
  );

  const renderText = () => (
    <div>
      <div className={styles.title}>
        {testData.title}
      </div>
      <div className={styles.text}>
        {testData.text}
      </div>
    </div>
  );

  return (
    <div className={styles.mainContent}>
      {renderBackButton()}
      {renderHeadBlock()}
      {renderText()}
    </div>
  );
};

export default PersonalNotificationsDetail;
