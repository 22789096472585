import { put } from '@redux-saga/core/effects';
import { toast } from 'react-toastify';
import apiActions from 'store/api/actions';
import { TESTS_ERRORS } from 'utils';
import actionTypes from '../actionTypes';

type ErrorHandler = {
  type: keyof typeof actionTypes;
  err: unknown;
  showToast?: boolean;
};

export function* errorHandler({
  type,
  err,
  showToast = true,
}: ErrorHandler) {
  if (showToast) {
    let errorMsg = '';
    if ((err as APIError).response && TESTS_ERRORS[(err as APIError).response.data.reason]) {
      errorMsg = TESTS_ERRORS[(err as APIError).response.data.reason];
    } else if (
      (err as { message: string }).message &&
      TESTS_ERRORS[(err as { message: string }).message]
    ) {
      errorMsg = TESTS_ERRORS[(err as { message: string }).message];
    } else if ((err as APIError).response?.data?.message) {
      errorMsg = (err as APIError).response.data.message;
    } else if ((err as { message: string }).message) {
      errorMsg = (err as { message: string }).message;
    }

    if (errorMsg) toast.error(errorMsg);
  }
  yield put(apiActions.error(type, err));
}

export type APIError = {
  response: {
    data: {
      detail: string;
      message: string;
      reason: string;
      code: number;
    };
  };
};
