import React, {
  FC,
  useState,
} from 'react';
import {
  Section,
  // Link,
  NavLink,
  Burger,
  LangSwitcher,
  Text,
} from 'components';
import { _t } from 'utils';
import { routes } from 'appConstants';
import cx from 'classnames';
import HeaderLoginBtn from './HeaderLoginBtn';
import HeaderLogos from './HeaderLogos';
import styles from './styles.module.scss';

type Props = {
  theme?: 'light' | 'dark'
};

const LINKS = [
  {
    to: routes.about.root,
    title: 'О нас',
  },
  {
    to: routes.services.root,
    title: 'Услуги',
  },
  {
    to: routes.resolutions.root,
    title: 'Экспортерам',
  },
  {
    to: routes.events.root,
    title: 'Мероприятия',
  },
  {
    to: routes.analytics.root,
    title: 'Аналитика',
  },
  {
    to: routes.media.root,
    title: 'Медиа',
  },
  {
    to: routes.contacts.root,
    title: 'Контакты',
  },
  {
    to: routes.auth.exportTest.root,
    title: 'Тест экспортной готовности',
  },
];

const Header: FC<Props> = ({ theme = 'light' }) => {
  const inverseTheme = theme === 'dark' ? 'light' : 'dark';
  const [topHeight, setTopHeight] = useState<number>(0);
  const [isMobileMenuVisible, setMobileMenuVisible] = useState<boolean>(false);
  const [pageScrolled, setPageScrolled] = useState<boolean>(false);

  document.addEventListener('scroll', () => {
    const logosHeight = 108;
    if (window.scrollY >= logosHeight) {
      setPageScrolled(true);
    } else {
      setPageScrolled(false);
    }
  }, false);

  return (
    <header
      className={cx(
        styles.header,
        styles[theme],
        { [styles.scrolled]: pageScrolled },
      )}
    >
      <Section
        className={cx(
          styles.section,
        )}
        tag="div"
        customRef={(element) => {
          if (element && element.offsetHeight > 0) {
            setTopHeight(element.offsetHeight);
          }
        }}
      >
        <HeaderLogos
          inverseTheme={inverseTheme}
          onMainClick={() => {
            if (isMobileMenuVisible) setMobileMenuVisible(false);
          }}
        />

        <div className={styles.rightAlign}>
          <LangSwitcher theme={theme} />
          <Burger
            lines={2}
            theme={inverseTheme}
            className={styles.burger}
            isActive={isMobileMenuVisible}
            onClick={() => setMobileMenuVisible(!isMobileMenuVisible)}
          />

          <HeaderLoginBtn
            theme={theme}
            className={styles.desktopLogin}
          />
        </div>

      </Section>
      <Section
        className={styles.section2}
        tag="div"
        customRef={(element) => {
          if (element && element.offsetHeight > 0) {
            setTopHeight(element.offsetHeight);
          }
        }}
      >
        <nav className={styles.nav}>
          {LINKS.map(({
            to,
            title,
          }) => ((
            <NavLink
              key={title}
              to={to}
              className={styles.link}
            >
              <Text
                tag="span"
                size="tiny"
                bold
                uppercase
                color={theme === 'dark' ? 'secondary' : 'primary'}
              >
                {_t(title)}
              </Text>
            </NavLink>
          )))}
        </nav>
      </Section>

      {isMobileMenuVisible && (
        <div
          className={styles.mobileMenu}
          style={{
            height: `calc(100vh - ${topHeight - topHeight}px)`,
          }}
        >
          <Section tag="div">
            <HeaderLoginBtn
              theme={theme}
              className={styles.mobileLogin}
            />
            <nav>
              {LINKS.map(({
                title,
                to,
              }) => (
                <NavLink
                  key={title}
                  to={to}
                  className={styles.mobileLink}
                  onClick={() => {
                    if (isMobileMenuVisible) setMobileMenuVisible(false);
                  }}
                >
                  {_t(title)}
                </NavLink>
              ))}
            </nav>
          </Section>
        </div>
      )}
    </header>
  );
};

export default Header;
