import { put, takeLatest, call } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
// import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import api from 'store/api';
import { URL, routes } from 'appConstants';
import {
  _t,
  SIGNUP_ERRORS_CHECK,
  history,
  camelCaseToSnakeCase,
  snakeCaseToCamelCase,
} from 'utils';
import type { Response, SignUpResSnake } from 'types';
import actionTypes, { SignUpAction as Action } from '../actionTypes';

function* signUp({ type, payload }: Action) {
  try {
    yield put(apiActions.request(type));

    const { data }: Response<SignUpResSnake> = yield call(api, {
      method: 'post',
      url: URL.AUTH.SIGN_UP,
      data: camelCaseToSnakeCase(payload!),
    });

    yield put(apiActions.success(type, snakeCaseToCamelCase(data)));

    yield history.push(routes.auth.activation.root);
  } catch (err) {
    if (err) {
      const errorArray = err.response.data.email || err.response.data.password || err;
      if (errorArray[0]) toast.error(_t(SIGNUP_ERRORS_CHECK(errorArray[0])) || errorArray[0]);
    } else {
      toast.error('Ошибка сервиса регистрации');
    }
    yield put(apiActions.error(type, err));
  }
}

// src/store/me/saga/signUp.ts

export default function* listener() {
  yield takeLatest(actionTypes.ME_SIGN_UP, signUp);
}
