/* eslint-disable no-console, @typescript-eslint/quotes, comma-dangle, space-in-parens */
import React, { FC, useState } from 'react';
import RCTable from 'rc-table';
import clsx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
  format,
} from 'date-fns';

import { State } from 'types';
import {
  ButtonRounded,
  Input,
  Modal,
} from 'components';
import { _t } from 'utils';
import {
  testVRemoveSession,
  testVRunFromExisted,
  testVSelectCountry,
  testVSelectProduct,
  testVShowReport,
  // testVGetHtmlReport,
  testVGetCustomPdfReport,
} from 'store/test/actions';

// import { PersonalTestProgressBar } from '../PersonalTestProgressBar';
import styles from './styles.module.scss';
import modalStyles from '../styles.module.scss';

type Row = {
  date: string;
  status: string;
  code: string;
  product: string;
  productId: number;
  country: string;
  countryId: number;
  key: string;
  id: string;
};
type TableProps = {
  data: Row[];
  onRowClick?: () => void,
};

const Table: FC<TableProps> = ({ data, onRowClick }) => {
  const [isVModalOpen, setVModalOpen] = useState(false);
  const [idToRemove, setIdToRemove] = useState<string | null>(null);
  const dispatch = useDispatch();

  const columns = [{
    title: 'Дата валидации',
    key: 'date',
    dataIndex: 'date',
    width: '11%',
    className: styles.column,
  }, {
    title: 'Статус',
    key: 'status',
    dataIndex: 'status',
    width: '21%',
    className: styles.column,
    render: (text: string) => {
      let status = '';
      if (text === 'A') {
        status = 'Черновик';
      } else if (text === 'C') {
        status = 'Валидация не пройдена';
      } else if (text === 'P') {
        status = 'Валидация пройдена';
      }
      return (
        <div className={styles.statusWrap}>
          <div className={clsx(
            styles.statusCircle,
            {
              [styles.statusCircleA]: text === 'A',
              [styles.statusCircleC]: text === 'C',
              [styles.statusCircleP]: text === 'P',
            },
          )}
          />
          {status}
        </div>
      );
    },
  }, {
    title: 'Страна',
    key: 'country',
    dataIndex: 'country',
    width: '19%',
    className: styles.column,
  }, {
    title: 'ТН ВЭД',
    key: 'code',
    dataIndex: 'code',
    width: '10%',
    className: styles.column,
  }, {
    title: 'Продукт',
    key: 'product',
    dataIndex: 'product',
    width: '36%',
    className: styles.column,
  }, {
    key: 'action',
    dataIndex: 'action',
    width: '2%',
    className: styles.column,
    render: (_: string, record: Row) => (
      <div
        className={clsx(styles.commonIcon, {
          [styles.deleteIcon]: record.status === 'A',
          [styles.downloadIcon]: record.status !== 'A',
        })}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (record.status === 'A') {
            setIdToRemove(record.id);
            setVModalOpen(true);
          } else {
            dispatch(testVGetCustomPdfReport(record.id));
          }
        }}
      />
    ),
  }];

  return (
    <>
      <RCTable
        className={styles.table}
        rowClassName={styles.row}
        columns={columns}
        scroll={{ y: 300 }}
        data={data}
        onRow={(record) => ({
          onClick: (e: React.MouseEvent<HTMLElement>) => {
            // @ts-ignore
            if (e.target.closest('a')) {
              return;
            }
            if (record.status === 'A') {
              dispatch(testVSelectCountry(record.countryId));
              dispatch(testVSelectProduct(record.productId));
              dispatch(testVRunFromExisted({
                countryId: record.countryId,
                productId: record.productId,
              }));
            } else {
              if (onRowClick) onRowClick();
              dispatch(testVShowReport(record.id));
              // dispatch(testVGetHtmlReport(record.id));
            }
          },
        })}
      />

      <Modal
        onClose={() => setVModalOpen(false)}
        title={_t('Вы уверены, что хотите удалить черновик валидации?')}
        isOpen={isVModalOpen}
        className={modalStyles.modalContent}
        classNameTitle={modalStyles.modalTitle}
        classNameOverlay={modalStyles.modalOverlay}
        classNameClose={modalStyles.modalBtnClose}
      >
        <div className={modalStyles.modalControls}>
          <ButtonRounded
            className={modalStyles.modalButton}
            color="secondary"
            blueMode
            onClick={() => setVModalOpen(false)}
            title={_t('Отмена')}
          />
          <ButtonRounded
            className={modalStyles.modalButton}
            color="primary"
            blueMode
            onClick={() => {
              setVModalOpen(false);
              if (idToRemove) {
                dispatch(testVRemoveSession(idToRemove));
              }
              setIdToRemove(null);
            }}
            title={_t('Удалить')}
          />
        </div>
      </Modal>
    </>
  );
};

const ValidationSessionsTable: FC = () => {
  const [inputValue, setInputValue] = useState('');
  const testState = useSelector(({ test }: State) => test);
  const tableData = testState.validationSessions
    .reduce((acc, item) => {
      const data = {
        date: format(new Date(item.completed || item.updated), 'dd.MM.yyyy'),
        status: item.status,
        code: item.product.code,
        product: item.product.name,
        productId: item.product.id,
        country: item.country.name,
        countryId: item.country.id,
        key: item.id,
        id: item.id,
      };
      return [...acc, data];
    }, new Array<Row>())
    .filter((item) => {
      if (!inputValue) return true;
      const inputValueLowerCase = inputValue.toLocaleLowerCase();
      return (
        item.date.includes(inputValue) ||
        item.code.includes(inputValue) ||
        item.product.toLocaleLowerCase().includes(inputValueLowerCase) ||
        item.country.toLocaleLowerCase().includes(inputValueLowerCase)
      );
    });

  return (
    <div className={styles.tableBlockWrap}>
      <div className={styles.controlsWrap}>
        <Input
          name="validationSearch"
          className={styles.inputSearch}
          placeholder="Поиск"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </div>
      <div className={styles.tableWrap}>
        <Table
          data={tableData}
        />
      </div>
    </div>
  );
};

export { ValidationSessionsTable };
