import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import { H4 } from 'components';
import { _t } from 'utils';
import styles from './styles.module.scss';

type Props = {
  count: number,
  warningText?: string,
  className?: string,
};

const NoData: FC<PropsWithChildren<Props>> = ({
  children,
  count = 0,
  warningText,
  className,
}) => (
  <>
    {!count ? (
      <div
        className={cx(styles.wrap, className)}
      >
        {warningText ? (
          <div>{_t(warningText)}</div>
        ) : (
          <H4 align="center">{_t('К сожалению, ничего не найдено')}</H4>
        )}
      </div>
    ) : children}
  </>
);

export default NoData;
