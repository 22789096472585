/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import cx from 'classnames';
import {
  Card,
  Link,
  Text,
} from 'components';
import type {
  PersonalExchangeResult,
} from 'types';
import {
  _t,
  // _t_back
} from 'utils';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  value: PersonalExchangeResult,
  targetDetailsPath?: string,
};

const PersonalExchangeListCard: FC<Props> = ({ className, value, targetDetailsPath }) => (
  <Card
    key={value.id}
    tag="div"
    className={cx(styles.card, className)}
    title={value.full_name}
  >
    <Link
      to={targetDetailsPath ? `${targetDetailsPath}/${value.id}` : `${targetDetailsPath}/`}
      // to={`${targetDetailsPath}/${value.id}`}
      className={styles.linkWrap}
    >
      <Text
        className={styles.title}
        size="big"
        bold
        title={value.full_name}
      >
        {value.full_name.length > 58
          ? value.full_name.substring(0, 55).concat('...')
          : value.full_name}
        {/* {_t_back(value, 'title')} */}
      </Text>

      <div className={styles.info}>
        {value.type_of_activity}
      </div>

      <div className={cx(styles.btnWrap, 'btnWrap')}>
        <button
          type="button"
          className={styles.btn}
        >
          {_t('Подробнее')}
        </button>
      </div>
    </Link>
  </Card>
);

export default PersonalExchangeListCard;
