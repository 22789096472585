import React, { FC, ChangeEvent } from 'react';
import cx from 'classnames';
// @ts-ignore
import PolicyPoliticsRu from 'assets/docs/personal-data.docx';
// import PolicyPoliticsRu from 'assets/docs/Mosprom_GDPR_PP_ru_152.docx';
// @ts-ignore
import PolicyPoliticsEn from 'assets/docs/Mosprom_GDPR_PP_en.docx';
import { ClientLang } from 'appConstants';
import { _t } from 'utils';
import { Text } from '../Typography';
import { Checkbox } from '../index';
import styles from './styles.module.scss';

type Props = {
  name: string,
  checkbox: boolean,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  className?: string
};

const Files:{[index: string]:string} = {
  ru: PolicyPoliticsRu,
  en: PolicyPoliticsEn,
};

const PersonalData: FC<Props> = ({
  name,
  checkbox,
  onChange,
  className,
}) => (
  <Checkbox
    name={name}
    checked={checkbox}
    onChange={onChange}
    label={(
      <Text
        tag="span"
        size="tiny"
        color="passive"
      >
        {_t('Даю своё согласие на обработку')}
        {' '}
        <a
          href={Files[ClientLang]}
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {_t('персональных данных')}
        </a>
      </Text>
    )}
    className={cx(styles.checkbox, className)}
  />
);

export default PersonalData;
