import React, {
  FC,
  PropsWithChildren,
  ReactElement,
} from 'react';
import cx from 'classnames';
import {
  Section,
  BreadCrumbs,
  H2,
  HTMLContent,
} from 'components';
import styles from './styles.module.scss';

type Route = {
  label: string,
  to: string,
};

type Props = {
  title: string,
  content: string,
  photo: string,
  routes: Array<Route>,
  className?: string,
  tag?: 'div' | 'section',
  additionalFields?: ReactElement,
};

const DetailsLayout: FC<PropsWithChildren<Props>> = ({
  children,
  title,
  content,
  photo,
  routes,
  className,
  tag = 'section',
  additionalFields,
}) => (
  <Section tag={tag} className={cx(styles.section, className)}>
    <aside className={styles.sidebar}>
      {children}
    </aside>
    <div className={styles.content}>
      <BreadCrumbs crumbs={routes} className={styles.crumbs} />
      <H2 className={styles.title}>{title}</H2>

      {photo && (
        <div
          className={styles.photo}
        >
          <img
            src={photo}
            alt={title}
          />
        </div>
      )}

      <HTMLContent content={content} />
      {additionalFields}
    </div>
  </Section>
);

export default DetailsLayout;
