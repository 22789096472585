import React, {
  FC,
  // useState,
  useEffect,
  // useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useRequest } from 'hooks';
import {
  URL,
  // routes,
} from 'appConstants';
import {
  PersonalExchangeResult,
  State,
} from 'types';
import {
  PersonalTabLayout,
  PersonalTabLayoutLeft,
  PersonalTabLayoutRight,
} from 'containers';
import { AvatarBlock, ValueLine } from 'components';
import {
  _t,
  history,
} from 'utils';
// import cx from 'classnames';
import PersonalExchangeRequest from '../PersonalExchangeRequest';
// import { testResults } from '../testData';
import styles from './styles.module.scss';

type Props = {};

type Tstatus = 1 | 2;
type TtestData = {
  id: number,
  status: Tstatus,
  author: string,
  checked: boolean,
  title: string,
  text: string,
  date: string,
};
type Ttest = {
  [index: string]: string,
};

// const testData:TtestData = testResult;

const PersonalExchangeDetail: FC<Props> = () => {
  const token = useSelector(({ me }: State) => me.token);
  const { id } = useParams<{ id: string }>();
  // const { data: LeadData, status: LeadReqStatus, request: ExchangeRequest } =
  const { data: LeadData, request: ExchangeRequest } =
    useRequest<PersonalExchangeResult>();

  useEffect(() => {
    ExchangeRequest({
      method: 'GET',
      url: `${URL.PERSONAL.EXCHANGE.GET}/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }, []);

  const renderBackButton = () => (
    <div
      className={styles.backButton}
      onClick={() => history.goBack()}
    >
      {_t('Назад')}
    </div>
  );

  return (
    <div className={styles.mainContent}>
      <PersonalTabLayout>
        {renderBackButton()}
      </PersonalTabLayout>
      <PersonalTabLayout>
        <PersonalTabLayoutLeft>
          <AvatarBlock
            src={LeadData?.company_logo}
          />
          <div>
            <div className={styles.userName}>
              {LeadData?.full_name}
            </div>
            <div className={styles.userRole}>
              {LeadData?.type_of_activity}
            </div>
          </div>
          <PersonalExchangeRequest />
        </PersonalTabLayoutLeft>
        <PersonalTabLayoutRight>
          <div className={styles.dataTitle}>{_t('Данные компании')}</div>
          <div className={styles.copmInfo}>
            <ValueLine
              label="Название компании"
              value={LeadData?.name || ''}
              name=""
              isEdit={false}
              error=""
              updValue={() => {}}
            />
            <ValueLine
              label="ИНН"
              value={LeadData?.inn || ''}
              name=""
              isEdit={false}
              error=""
              updValue={() => {}}
            />
            <ValueLine
              label="Сфера деятельности"
              value={LeadData?.type_of_activity || ''}
              name=""
              isEdit={false}
              error=""
              updValue={() => {}}
            />
            <ValueLine
              label="География поставки"
              value={LeadData?.region || ''}
              name=""
              isEdit={false}
              error=""
              updValue={() => {}}
            />
            <ValueLine
              label="Сайт компании"
              value={LeadData?.web_site || ''}
              name=""
              siteLink
              isEdit={false}
              error=""
              updValue={() => {}}
            />
          </div>

          <div className={styles.dataTitle}>{_t('Описание компании')}</div>
          <div className={styles.copmDescr}>
            {LeadData?.description_types_of_activity}
          </div>
          {/* <Preloader isLoading={status === RequestStatus.REQUEST}>
            {(!noData && !!resolutions?.length) && (
            )}
          </Preloader> */}
        </PersonalTabLayoutRight>
      </PersonalTabLayout>
    </div>
  );
};

export default PersonalExchangeDetail;
