import React, { FC } from 'react';
import { FormikHandlers, FormikValues } from 'formik';
import { FilterGroup, InputRadio } from 'components';
import { _t_back } from 'utils';
import { BaseFilter as Filter } from 'types';
import styles from './styles.module.scss';

type Props = {
  values: FormikValues,
  name: string,
  label: string,
  list: Array<Filter>,
  handleChange: FormikHandlers['handleChange']
};

const FiltersRadioButtons: FC<Props> = ({
  values,
  name,
  label,
  list,
  handleChange,
}) => (
  <FilterGroup
    label={label}
    selectedCount={values[name] > 0 ? 1 : 0}
    onReset={() => handleChange({
      target: {
        name,
        value: 0,
      },
    })}
  >
    {list?.map((value) => ((
      <InputRadio
        key={`${value.id}-${name}`}
        checked={values[name] === value.id}
        name={name}
        label={_t_back(value, 'title')}
        className={styles.filter}
        onChange={() => handleChange({
          target: {
            name,
            value: value.id,
          },
        })}
      />
    )))}
  </FilterGroup>
);

export default FiltersRadioButtons;
