import React, {
  FC,
  useState,
  PropsWithChildren,
  useCallback,
} from 'react';
import {
  Modal,
  Button,
} from 'components';
import { meLogoutAction } from 'store/me/actions';
import { useDispatch } from 'react-redux';
import { _t } from 'utils';
import styles from './styles.module.scss';

interface Props {
  className?: string,
}

const PersonalExit: FC<PropsWithChildren<Props>> = ({
  children,
  className,
}) => {
  const dispatch = useDispatch();

  const [exitConfirm, setExitConfirm] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setExitConfirm(true);
  }, []);

  const handleCancel = useCallback(() => {
    setExitConfirm(false);
  }, []);

  const handleConfirm = useCallback(() => {
    dispatch(meLogoutAction());
  }, []);

  return (
    <div
      className={className || styles.personalExit}
      onClick={handleOpen}
    >
      {children || _t('Выход')}
      <Modal
        onClose={handleCancel}
        title="Вы действительно хотите выйти из личного кабинета?"
        isOpen={exitConfirm}
        className={styles.exitModal}
        classNameTitle={styles.title}
        classNameOverlay={styles.modalOverlay}
      >
        <div className={styles.exitBtns}>
          <Button
            className={styles.exitDiscard}
            onClick={handleCancel}
            title="Отмена"
          />
          <Button
            className={styles.exitConfirm}
            onClick={handleConfirm}
            title="Выйти"
          />
        </div>
      </Modal>
    </div>
  );
};

export default PersonalExit;
