import React, {
  FC,
  PropsWithChildren,
  ChangeEvent,
} from 'react';
import cx from 'classnames';
import { _t } from 'utils';
import { Button, Input, FilterGroup } from 'components';
import { useWindowResize } from 'hooks';
import styles from './styles.module.scss';

type Props = {
  search?: string,
  className?: string,
  isCanReset?: boolean,
  onSubmit?: () => void,
  onReset?: () => void,
  onSubmitSearch?: () => void,
  onChangeSearch?: (e: ChangeEvent<HTMLInputElement>) => void,
};

const FilterSidebar: FC<PropsWithChildren<Props>> = ({
  search = '',
  children,
  isCanReset = false,
  className,
  onSubmit = () => {},
  onReset = () => {},
  onSubmitSearch = () => {},
  onChangeSearch = () => {},
}) => {
  const { layout } = useWindowResize({});
  return (
    <aside className={cx(styles.aside, className)}>
      <Input
        name="search"
        type="search"
        value={search}
        className={styles.search}
        onChange={onChangeSearch}
        onSearch={onSubmitSearch}
      />
      {layout === 'FULL' ? children : (
        <FilterGroup
          label="Фильтр"
          theme="primary"
        >
          {children}
        </FilterGroup>
      )}
      <div className={styles.buttons}>
        <Button className={styles.confirm} onClick={onSubmit}>{_t('Применить')}</Button>
        <button
          type="button"
          className={isCanReset ? styles.reset : styles.noreset}
          onClick={onReset}
        >
          {_t('Сбросить всё')}
        </button>
      </div>
    </aside>
  );
};

export default FilterSidebar;
