import React, {
  FC,
  useRef,
  // CSSProperties,
  ChangeEvent,
  useState,
} from 'react';
import { Modal } from 'components';
import { toast } from 'react-toastify';
import { _t } from 'utils';
import styles from './styles.module.scss';

type Props = {
  title?: string,
  className?: string,
  setFileName?: boolean,
  token?: string,
  method?: string,
  companyID?: number,
  uploadUrl: string,
  onFilesUpdate?: () => void,
  format?: string,
  size?: string,
};

let Files:File;

const FileUploader: FC<Props> = ({
  title = '',
  className,
  setFileName = false,
  token,
  method = 'PATCH',
  companyID,
  uploadUrl,
  onFilesUpdate,
  format = '',
  size = '',

}) => {
  const inputEl = useRef(null);
  const [fName, setFName] = useState<string>('');
  const [isOpen, setOpen] = useState<boolean>(false);

  const upload = (
    // eslint-disable-next-line
    fileIssue: any,
    fileName: string,
  ) => {
    const data = new FormData();
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
    data.append('file', fileIssue);
    if (setFileName && fName) data.append('title', fileName);
    if (companyID && companyID >= 0) data.append('company', `${companyID}`);

    setFName('');
    fetch(uploadUrl, {
      method,
      mode: 'cors',
      body: data,
      headers,
    })
      .then((response) => {
        if (response.ok) {
          if (isOpen) setOpen(false);
          if (onFilesUpdate) onFilesUpdate();
          toast.success('Файл успешно отправлен');
        }
        if (!response.ok) {
          if (response.status === 400) {
            toast.error(`${response.status}. Запрещенный тип файла.  Ожидается pdf, размером до 30МБ.`);
          } else {
            toast.error(`${response.status}. Ошибка отправки файла`);
          }
        }

        // eslint-disable-next-line
        // debugger;
      });
  };

  const handleSubmit = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    // upload(inputEl.current.files[0], _onSuccess, _onFailure);
    // eslint-disable-next-line
    Files = (inputEl as any).current.files[0];
    if (Files) {
      if (setFileName) {
        setOpen(true);
        setFName(Files.name);
      }
      // eslint-disable-next-line
      if (!setFileName) {
        upload(Files, '');
      }
    }
  };

  const handleChangeWithName = () => {
    if (Files && fName) {
      setOpen(false);
      upload(Files, fName);
    }
  };

  return (
    <div className={styles.uploader}>
      <span
        className={className}
      >
        {_t(title)}
        <span className={styles.restrictions}>
          {_t(format)}
          <br />
          {_t(size)}
        </span>
      </span>

      <input
        className={styles.uploadInput}
        onChange={handleSubmit}
        ref={inputEl}
        type="file"
        accept="file/*"
      />

      <Modal
        onClose={() => setOpen(false)}
        isOpen={isOpen}
        title="Укажите название файла"
        className={styles.customModal}
        classNameTitle={styles.customTitle}
        classNameClose={styles.customClose}
        classNameOverlay={styles.customModalOverlay}
        classNameContent={styles.modalContent}
      >
        <input
          className={styles.fileName}
          type="text"
          placeholder={_t('Укажите название файла')}
          value={fName}
          onChange={(e) => setFName(e.target.value)}
        />
        <div
          className={styles.fileSend}
          onClick={() => handleChangeWithName()}
        >
          {_t('Отправить файл')}
        </div>
      </Modal>
    </div>
  );
};

export default FileUploader;
