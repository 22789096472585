import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  PersonalBaseLayout,
  PersonalFeedMainTab,
} from 'containers';
import { PersonalPageMenu } from 'components';
import { routes } from 'appConstants';

const PersonalFeed = () => {
  return (
    <PersonalBaseLayout pageTitle="Моя лента">
      <PersonalPageMenu
        links={[]}
      />
      <Switch>
        <Route
          path={routes.personal.feed.root}
          exact
          render={() => (<PersonalFeedMainTab />)}
        />
        <Redirect to={{ pathname: routes.personal.feed.root }} />
      </Switch>
    </PersonalBaseLayout>
  );
};

export default PersonalFeed;
