import { TPersona } from 'types';

export const personaRefactor:(...args: string[]) => TPersona = (...args: string[]) => {
  return {
    lastName: args[0] || '',
    firstName: args[1] || '',
    sirName: args[2] || '',
    position: args[3] || '',
  };
};
