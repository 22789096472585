import type {
  Action,
  PersonalDataRes,
  GetHtmlReportRes,
  QSessionIDErr,
  QSessionIDRes,
  QSessionsRes,
  SessionStepsRes,
} from 'types';

const TEST_INIT = 'TEST_INIT';
const TEST_INIT_SUCCESS = 'TEST_INIT_SUCCESS';

const TEST_PERSONAL_DATA = 'TEST_PERSONAL_DATA';
const TEST_PERSONAL_DATA_SUCCESS = 'TEST_PERSONAL_DATA_SUCCESS';

const TEST_Q_SESSION_ID = 'TEST_Q_SESSION_ID';
const TEST_Q_SESSION_ID_SUCCESS = 'TEST_Q_SESSION_ID_SUCCESS';
const TEST_Q_SESSION_ID_ERROR = 'TEST_Q_SESSION_ID_ERROR';

const TEST_Q_SESSION_ID_AND_RUN_TEST = 'TEST_Q_SESSION_ID_AND_RUN_TEST';
const TEST_Q_SESSION_ID_AND_RUN_TEST_SUCCESS = 'TEST_Q_SESSION_ID_AND_RUN_TEST_SUCCESS';

const TEST_Q_SESSIONS = 'TEST_Q_SESSIONS';
const TEST_Q_SESSIONS_SUCCESS = 'TEST_Q_SESSIONS_SUCCESS';

// const TEST_Q_SESSIONS_LIST = 'TEST_Q_SESSIONS_LIST';

const TEST_Q_STEPS = 'TEST_Q_STEPS';
const TEST_Q_STEPS_SUCCESS = 'TEST_Q_STEPS_SUCCESS';

const TEST_Q_SHOW = 'TEST_Q_SHOW';
const TEST_Q_HIDE = 'TEST_Q_HIDE';

const TEST_Q_INCREMENT_STEP = 'TEST_Q_INCREMENT_STEP';
const TEST_Q_DECREMENT_STEP = 'TEST_Q_DECREMENT_STEP';
const TEST_Q_CONFIRM_PERSONAL_DATA = 'TEST_Q_CONFIRM_PERSONAL_DATA';

const TEST_Q_SELECT_RADIO = 'TEST_Q_SELECT_RADIO';
const TEST_Q_SELECT_CHECKBOX = 'TEST_Q_SELECT_CHECKBOX';
const TEST_Q_SELECT_RANGE = 'TEST_Q_SELECT_RANGE';

const TEST_Q_SEND_ANSWER = 'TEST_Q_SEND_ANSWER';
const TEST_Q_SEND_ANSWER_SUCCESS = 'TEST_Q_SEND_ANSWER_SUCCESS';

const TEST_Q_FINISH = 'TEST_Q_FINISH';
const TEST_Q_FINISH_SUCCESS = 'TEST_Q_FINISH_SUCCESS';

const TEST_Q_REMOVE_SESSION = 'TEST_Q_REMOVE_SESSION';
const TEST_Q_REMOVE_SESSION_SUCCESS = 'TEST_Q_REMOVE_SESSION_SUCCESS';

const TEST_Q_RESET = 'TEST_Q_RESET';

const TEST_Q_FINISH_AND_SHOW_REPORT = 'TEST_Q_FINISH_AND_SHOW_REPORT';

const TEST_Q_SHOW_REPORT = 'TEST_Q_SHOW_REPORT';
const TEST_Q_HIDE_REPORT = 'TEST_Q_HIDE_REPORT';

const TEST_Q_SHOW_FINAL_ACTION = 'TEST_Q_SHOW_FINAL_ACTION';
const TEST_Q_HIDE_FINAL_ACTION = 'TEST_Q_HIDE_FINAL_ACTION';

const TEST_Q_GET_HTML_REPORT = 'TEST_Q_GET_HTML_REPORT';
const TEST_Q_GET_HTML_REPORT_SUCCESS = 'TEST_Q_GET_HTML_REPORT_SUCCESS';

const TEST_Q_GET_PDF_REPORT = 'TEST_Q_GET_PDF_REPORT';
const TEST_Q_GET_CUSTOM_PDF_REPORT = 'TEST_Q_GET_CUSTOM_PDF_REPORT';
const TEST_Q_CLOSE_CUSTOM_PDF_REPORT = 'TEST_Q_CLOSE_CUSTOM_PDF_REPORT';

export type InitAction = Action<typeof TEST_INIT>;
export type GetPersonalDataAction = Action<typeof TEST_PERSONAL_DATA>;
export type QGetSessionIDAction = Action<typeof TEST_Q_SESSION_ID>;
export type QGetSessionIDAndRunTestAction = Action<typeof TEST_Q_SESSION_ID_AND_RUN_TEST>;
export type QGetSessionsAction = Action<typeof TEST_Q_SESSIONS>;
// export type QGetSessionsListAction = Action<typeof TEST_Q_SESSIONS_LIST>;
export type QGetStepsAction = Action<typeof TEST_Q_STEPS>;
export type QShowAction = Action<typeof TEST_Q_SHOW>;
export type QHideAction = Action<typeof TEST_Q_HIDE>;
export type QIncStepAction = Action<typeof TEST_Q_INCREMENT_STEP>;
export type QDecStepAction = Action<typeof TEST_Q_DECREMENT_STEP>;
export type QConfirmPersonalDataAction = Action<typeof TEST_Q_CONFIRM_PERSONAL_DATA>;
export type QSelectRadioAction = Action<typeof TEST_Q_SELECT_RADIO, { answer: number }>;
export type QSelectCheckboxAction = Action<typeof TEST_Q_SELECT_CHECKBOX, {
  answer: number;
  isChecked: boolean;
}>;
export type QSelectRangeAction = Action<typeof TEST_Q_SELECT_RANGE, {
  position: number;
  value: number;
}>;
export type QSendAnswerAction = Action<typeof TEST_Q_SEND_ANSWER, { shouldExit: boolean }>;
export type QFinishAction = Action<typeof TEST_Q_FINISH>;
export type QRemoveSessionAction = Action<typeof TEST_Q_REMOVE_SESSION>;
export type QResetAction = Action<typeof TEST_Q_RESET>;
export type QFinishAndShowReport = Action<typeof TEST_Q_FINISH_AND_SHOW_REPORT>;
export type QShowReport = Action<typeof TEST_Q_SHOW_REPORT, { sessionId: string }>;
export type QHideReport = Action<typeof TEST_Q_HIDE_REPORT>;
export type QShowFinalAction = Action<typeof TEST_Q_SHOW_FINAL_ACTION>;
export type QHideFinalAction = Action<typeof TEST_Q_HIDE_FINAL_ACTION>;
export type QGetHTMLReport = Action<typeof TEST_Q_GET_HTML_REPORT, { sessionId: string }>;
export type QGetPDFReport = Action<typeof TEST_Q_GET_PDF_REPORT, { sessionId: string }>;

export type QGetCustomPDFReport = Action<typeof TEST_Q_GET_CUSTOM_PDF_REPORT, {
  sessionId: string
}>;
export type QCloseCustomPDFReport = Action<typeof TEST_Q_CLOSE_CUSTOM_PDF_REPORT>;

export type TestActionQ =
  QShowAction | QHideAction |
  InitAction | Action<typeof TEST_INIT_SUCCESS> |
  GetPersonalDataAction | Action<typeof TEST_PERSONAL_DATA_SUCCESS, PersonalDataRes> |
  QGetSessionIDAction | Action<typeof TEST_Q_SESSION_ID_SUCCESS, QSessionIDRes> |
  Action<typeof TEST_Q_SESSION_ID_ERROR, QSessionIDErr> |
  QGetSessionIDAndRunTestAction |
  Action<typeof TEST_Q_SESSION_ID_AND_RUN_TEST_SUCCESS, QSessionIDRes> |
  QGetSessionsAction | Action<typeof TEST_Q_SESSIONS_SUCCESS, QSessionsRes> |
  QGetStepsAction | Action<typeof TEST_Q_STEPS_SUCCESS, SessionStepsRes> |
  QSelectRadioAction | QSelectCheckboxAction | QSelectRangeAction |
  QSendAnswerAction | Action<typeof TEST_Q_SEND_ANSWER_SUCCESS> |
  QFinishAction | Action<typeof TEST_Q_FINISH_SUCCESS> |
  QRemoveSessionAction | Action<typeof TEST_Q_REMOVE_SESSION_SUCCESS> |
  QResetAction | QFinishAndShowReport |
  QShowReport | QHideReport |
  QShowFinalAction | QHideFinalAction |
  QGetHTMLReport | Action<typeof TEST_Q_GET_HTML_REPORT_SUCCESS, GetHtmlReportRes> |
  QGetPDFReport | QGetCustomPDFReport | QCloseCustomPDFReport;

export const actionTypesQ = {
  TEST_INIT,
  TEST_INIT_SUCCESS,
  TEST_PERSONAL_DATA,
  TEST_PERSONAL_DATA_SUCCESS,
  TEST_Q_SESSION_ID,
  TEST_Q_SESSION_ID_SUCCESS,
  TEST_Q_SESSION_ID_ERROR,
  TEST_Q_SESSION_ID_AND_RUN_TEST,
  TEST_Q_SESSION_ID_AND_RUN_TEST_SUCCESS,
  TEST_Q_SESSIONS,
  // TEST_Q_SESSIONS_LIST,
  TEST_Q_SESSIONS_SUCCESS,
  TEST_Q_STEPS,
  TEST_Q_STEPS_SUCCESS,
  TEST_Q_SHOW,
  TEST_Q_HIDE,
  TEST_Q_INCREMENT_STEP,
  TEST_Q_DECREMENT_STEP,
  TEST_Q_CONFIRM_PERSONAL_DATA,
  TEST_Q_SELECT_RADIO,
  TEST_Q_SELECT_CHECKBOX,
  TEST_Q_SELECT_RANGE,
  TEST_Q_SEND_ANSWER,
  TEST_Q_SEND_ANSWER_SUCCESS,
  TEST_Q_FINISH,
  TEST_Q_FINISH_SUCCESS,
  TEST_Q_REMOVE_SESSION,
  TEST_Q_REMOVE_SESSION_SUCCESS,
  TEST_Q_RESET,
  TEST_Q_FINISH_AND_SHOW_REPORT,
  TEST_Q_SHOW_REPORT,
  TEST_Q_HIDE_REPORT,
  TEST_Q_SHOW_FINAL_ACTION,
  TEST_Q_HIDE_FINAL_ACTION,
  TEST_Q_GET_HTML_REPORT,
  TEST_Q_GET_HTML_REPORT_SUCCESS,
  TEST_Q_GET_PDF_REPORT,
  TEST_Q_GET_CUSTOM_PDF_REPORT,
  TEST_Q_CLOSE_CUSTOM_PDF_REPORT,
};
