import React from 'react';
import { isLocalTest } from 'appConstants';
import styles from './styles.module.scss';

const DevPanel = () => {
  const prodEnv = localStorage.getItem('useProdEnv') === 'true' ? 'Prod' : 'Dev';
  // commit for update
  const switchHandle:() => void = () => {
    localStorage.setItem('useProdEnv', prodEnv === 'Dev' ? 'true' : 'false');
    window.location.reload();
  };

  // const removeHandle:() => void = () => {
  //   localStorage.removeItem('useProdEnv');
  //   window.location.reload();
  // };

  return isLocalTest ? (
    <div
      className={styles.devEnvSwitcher}
      onClick={switchHandle}
    >
      <div className={styles.hidden}>{prodEnv[0]}</div>
      <div className={styles.hover}>{prodEnv} BackEnd</div>
    </div>
  ) : (
    <div
      className={styles.devEnvSwitcherWarning}
      // onClick={removeHandle}
    >
      {prodEnv === 'Prod' && (
        <b>
          Включена БОЕВАЯ БАЗА ДАННЫХ. (Не отправляйте формы с сайта).
          {/* <br />Нажмите, чтобы переключиться на DEV базу. */}
        </b>
      )}
    </div>
  );
};

export default DevPanel;
