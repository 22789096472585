import { put, takeLatest, call } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { toast } from 'react-toastify';
import api from 'store/api';
import { URL, routes } from 'appConstants';
import { LOGIN_ERRORS, history, _t } from 'utils';
import type { Response, AuthRes } from 'types';
import actionTypes, { LoginAction as Action } from '../actionTypes';

function* login({ type, payload }: Action) {
  try {
    if (payload) {
      yield put(apiActions.request(type));
      const { data }: Response<AuthRes> = yield call(api, {
        method: 'post',
        url: URL.AUTH.LOGIN,
        data: payload,
      });
      yield put(apiActions.success(type, data));

      if (!window.location.pathname.includes('/welcome')) {
        history.push(routes.personal.root);
      }
    }
  } catch (err) {
    if (err.response) {
      const error = LOGIN_ERRORS[err?.response?.data.detail];
      toast.error(_t(error || err?.response.data.detail));
    } else {
      toast.error('Ошибка сервиса авторизации');
    }
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.ME_LOGIN, login);
}
