import React, {
  FC,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { useRequest } from 'hooks';
import { URL } from 'appConstants';
import {
  PersonalCompanyAnalytic,
  RequestStatus,
  State,
} from 'types';
import {
  PersonalTabLayout,
  PersonalTabLayoutLeft,
  PersonalTabLayoutRight,
} from 'containers';
import {
  Preloader, NoData,
} from 'components';
import tabImg from 'assets/img/personal/analytics.svg';
import PersonalAnalyticsRequest from '../PersonalAnalyticsRequest';
import styles from './styles.module.scss';

type Props = {};

const PersonalAnalyticsIndividual: FC<Props> = () => {
  const { data, status, request } = useRequest<PersonalCompanyAnalytic[]>();
  const token = useSelector(({ me }: State) => me.token);

  useEffect(() => {
    request({
      method: 'GET',
      url: URL.PERSONAL.ANALYTICS.GET,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }, []);

  return (
    <PersonalTabLayout>
      <PersonalTabLayoutLeft
        tabTitle="Получите индивидуальные исследования прямо сейчас"
        image={tabImg}
      >
        <PersonalAnalyticsRequest />
      </PersonalTabLayoutLeft>
      <PersonalTabLayoutRight
        tabTitle="Индивидуальные аналитические исследования"
      >
        <Preloader isLoading={status === RequestStatus.REQUEST}>
          <NoData
            className={styles.noData}
            warningText="На данные момент индивидуальные аналитические исследования отсутствуют."
            count={data?.length || 0}
          >
            {data?.map((item) => (
              <a
                className={styles.links}
                href={item.file || ''}
              >
                {item.title}
              </a>
            ))}
          </NoData>
        </Preloader>
      </PersonalTabLayoutRight>
    </PersonalTabLayout>
  );
};

export default PersonalAnalyticsIndividual;
