import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import type { ButtonProps as Props } from 'types';
import { _t } from 'utils';
import styles from './styles.module.scss';

const ButtonRounded: FC<PropsWithChildren<Props>> = ({
  children,
  type = 'button',
  title = '',
  linkTo = '',
  color = 'primary',
  upperCase = false,
  disabled = false,
  whiteMode = false,
  isVideo = false,
  id = '',
  className,
  onClick = () => {},
}) => {
  let tTitle = title ? _t(title) : '';
  if (upperCase) tTitle = tTitle.toUpperCase();
  return (
    <>
      {/* eslint-disable-next-line react/button-has-type */}
      <button
        type={type}
        id={id}
        className={cx(
          styles.btn,
          className,
          styles[color],
          {
            [styles.video]: isVideo,
            [styles.whiteMode]: whiteMode,
            [styles.disabled]: disabled,
          },
        )}
        disabled={disabled}
        onClick={onClick}
      >
        {tTitle || children}
      </button>
      {!!linkTo && (
        <a className={styles.hiddenLink} href={linkTo}>
          {tTitle}
        </a>
      )}
    </>
  );
};

export default ButtonRounded;
