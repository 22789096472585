import React, {
  FC,
  // useEffect,
  useState,
  // useCallback,
} from 'react';
// import { useSelector } from 'react-redux';
// import { useFormik } from 'formik';
// import { object, string } from 'yup';
import {
  Input,
  Select,
  Textarea,
} from 'components';
// import { _t, _t_back } from 'utils';
// import { _t_back } from 'utils';
import {
  // State,
  // LeadStatus,
  OptionType,
} from 'types';
// import { useRequest } from 'hooks';
// import { URL } from 'appConstants';
// import { toast } from 'react-toastify';
// import { RequestStatus, State } from 'types';
// import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  nextStep: (data:TInitialData) => void,
  dataObj?: object,
};

type TInitialData = {
  // [index:string]: string,
  title: string,
  description: string,
  currency: number | '',
  price: number | '',
  category: number | '',
  subcategory: number | '',
  geography: number | '',
  photos?: string,
};

const Step3: FC<Props> = ({
  nextStep = () => {},
}) => {
  // const [title, setTitle] = useState<string>('');
  // const [description, setDescription] = useState<string>('');
  // const [currency, setCurrency] = useState<number | null>('');
  // const [price, setPrice] = useState<string>('');
  // const [category, setCategory] = useState<string>('');
  // const [subcategory, setSubcategory] = useState<string>('');
  // const [geography, setGeography] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [currency, setCurrency] = useState<number | ''>('');
  const [price, setPrice] = useState<number | ''>('');
  const [category, setCategory] = useState<number | ''>('');
  const [subcategory, setSubcategory] = useState<number | ''>('');
  const [geography, setGeography] = useState<number | ''>('');

  // const token = useSelector(({ me }: State) => me.token);

  // useEffect(() => {
  //   IndFCompRequest({
  //     method: 'GET',
  //     url: URL.PERSONAL.EXCHANGE.FILT_IFC,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  // }, []);

  const handleChange = () => {
    nextStep({
      title,
      description,
      currency,
      price,
      category,
      subcategory,
      geography,
    });
  };

  return (
    <div
      className={styles.wrapper}
    >
      <Input
        className={styles.input}
        label="Название объявления"
        name="title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <Textarea
        className={styles.input}
        label="Описание"
        name="description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <div className={styles.price}>
        <Select
          className={styles.input}
          label="Выберите валюту"
          placeholder="Выберите валюту"
          isSearchable
          isClearable
          options={[
            { id: 0, title: '(Р) Российский рубль' },
            { id: 1, title: '($) Американский доллар' },
          ].map((curr) => ({
            label: curr.title,
            value: curr.id.toString(),
          }))}
          isMulti={false}
          onChange={(option) => {
            if (option) {
              setCurrency(+(option as OptionType).value);
            } else {
              setCurrency('');
            }
          }}
        />
        <Input
          className={styles.input}
          label="Укажите стоимость"
          name="price"
          value={price.toString()}
          onChange={(e) => setPrice(+(e.target.value.replace(/[^+\d]/g, '')))}
        />
      </div>
      <Select
        className={styles.input}
        label="Категория"
        placeholder="Выберите категорию"
        isSearchable
        isClearable
        options={[
          { id: 0, title: 'Россия' },
          { id: 1, title: 'Мир' },
        ].map((curr) => ({
          label: curr.title,
          value: curr.id.toString(),
        }))}
        isMulti={false}
        onChange={(option) => {
          if (option) {
            setCategory(+(option as OptionType).value);
          } else {
            setCategory('');
          }
        }}
      />
      <Select
        className={styles.input}
        label="Подкатегория"
        placeholder="Выберите подкатегорию"
        isSearchable
        isClearable
        options={[
          { id: 0, title: 'Многоотраслевая' },
          { id: 1, title: 'АПК' },
        ].map((curr) => ({
          label: curr.title,
          value: curr.id.toString(),
        }))}
        isMulti={false}
        onChange={(option) => {
          if (option) {
            setSubcategory(+(option as OptionType).value);
          } else {
            setSubcategory('');
          }
        }}
      />
      <Select
        className={styles.input}
        label="География поставок"
        placeholder="Выберите регион поставки"
        isSearchable
        isClearable
        options={[
          { id: 0, title: 'Страна 1' },
          { id: 1, title: 'Страна 2' },
        ].map((curr) => ({
          label: curr.title,
          value: curr.id.toString(),
        }))}
        isMulti={false}
        onChange={(option) => {
          if (option) {
            setGeography(+(option as OptionType).value);
          } else {
            setGeography('');
          }
        }}
      />
      <div className={styles.controls}>
        <div
          onClick={handleChange}
          className={styles.next}
        >
          Продолжить
        </div>
        <div
          onClick={() => {}}
          className={styles.draft}
        >
          Сохранить в черновик
        </div>
      </div>
    </div>
  );
};

export default Step3;
