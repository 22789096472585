export const testResults = [
  {
    id: 0,
    date: '10.10.2021',
    title: 'Название товара или услуги',
    descr: 'Пищевкусовая промышленность/ Масложировая промышленность (без производства мыла и моющих средств)',
    company: 'ООО Компания',
    cost: 120,
  },
  // {
  //   id: 1,
  //   date: '10.10.2021',
  //   title: 'Название товара или услуги',
  //   descr: 'Пищевкусовая промышленность/ Масложировая промышленность',
  //   company: 'ООО Компания',
  //   cost: 120,
  // },
  // {
  //   id: 2,
  //   date: '10.10.2021',
  //   title: 'Название товара или услуги',
  //   descr: 'Пищевкусовая промышленность/ Масложировая промышленность',
  //   company: 'ООО Компания',
  //   cost: 120,
  // },
  // {
  //   id: 3,
  //   date: '10.10.2021',
  //   title: 'Название товара или услуги',
  //   descr: 'Пищевкусовая промышленность/ Масложировая промышленность',
  //   company: 'ООО Компания',
  //   cost: 120,
  // },
  // {
  //   id: 4,
  //   date: '10.10.2021',
  //   title: 'Название товара или услуги',
  //   descr: 'Пищевкусовая промышленность/ Масложировая промышленность',
  //   company: 'ООО Компания',
  //   cost: 120,
  // },
  // {
  //   id: 5,
  //   date: '10.10.2021',
  //   title: 'Название товара или услуги',
  //   descr: 'Пищевкусовая промышленность/ Масложировая промышленность',
  //   company: 'ООО Компания',
  //   cost: 120,
  // },
  // {
  //   id: 6,
  //   date: '10.10.2021',
  //   title: 'Название товара или услуги',
  //   descr: 'Пищевкусовая промышленность/ Масложировая промышленность',
  //   company: 'ООО Компания',
  //   cost: 120,
  // },
  // {
  //   id: 7,
  //   date: '10.10.2021',
  //   title: 'Название товара или услуги',
  //   descr: 'Пищевкусовая промышленность/ Масложировая промышленность',
  //   company: 'ООО Компания',
  //   cost: 120,
  // },
  // {
  //   id: 8,
  //   date: '10.10.2021',
  //   title: 'Название товара или услуги',
  //   descr: 'Пищевкусовая промышленность/ Масложировая промышленность',
  //   company: 'ООО Компания',
  //   cost: 120,
  // },
  // {
  //   id: 9,
  //   date: '10.10.2021',
  //   title: 'Название товара или услуги',
  //   descr: 'Пищевкусовая промышленность/ Масложировая промышленность',
  //   company: 'ООО Компания',
  //   cost: 120,
  // },
  // {
  //   id: 10,
  //   date: '10.10.2021',
  //   title: 'Название товара или услуги',
  //   descr: 'Пищевкусовая промышленность/ Масложировая промышленность',
  //   company: 'ООО Компания',
  //   cost: 120,
  // },
  // {
  //   id: 11,
  //   date: '10.10.2021',
  //   title: 'Название товара или услуги',
  //   descr: 'Пищевкусовая промышленность/ Масложировая промышленность',
  //   company: 'ООО Компания',
  //   cost: 120,
  // },
];
