/* eslint-disable no-irregular-whitespace */
import React, { FC } from 'react';
import cx from 'classnames';
import {
  Section,
  Text,
} from 'components';
import { _t, handleNoImage } from 'utils';
import photo from 'assets/img/main-about-preview.png';
import styles from './styles.module.scss';

type Props = {
  className?: string
};

const MainAbout: FC<Props> = ({
  className,
}) => (
  <Section className={cx(styles.section, className)}>
    <Text use="title" className={styles.title}>{_t('О нас')}</Text>
    <div className={styles.content}>
      <Text bold className={styles.upText} size="small">
        {_t('Центр «Моспром» был создан Департаментом...')}
      </Text>
      <img
        className={styles.aboutImg}
        src={photo}
        alt="Mosprom_About"
        onError={handleNoImage}
        width="100px"
        height="100px"
      />
      <Text bold className={styles.bottomText} size="small">
        {_t('Рост московских компаний за рубежом является главным приоритетом...')}
      </Text>
    </div>
  </Section>
);

export default MainAbout;
