export interface AuthReq {
  email: string,
  password: string,
}

export interface RestoreReq {
  email: string,
}

export interface ConfirmPassReq {
  new_password: string,
  re_new_password: string,
  token: string,
  uid: string,
}

export interface AuthRes {
  access: string,
  refresh: string,
}

export enum RoleUser {
  agent = 'A',
  export = 'E',
  buyer = 'B',
}

export interface IUniqueINNReq {
  'User already exist': boolean,
}

interface SignUp {
  companyName: string,
  inn: string,
  fullName?: string,
  position: string,
  phone: string,
}

interface SignUp2 {
  nameOfCompany: string,
  inn: string,
  fullName?: string,
  position: string,
  phone: string,
}

export interface RoleUserSelect {
  label: string,
  value: RoleUser,
}

export interface SignUpForm extends SignUp {
  role: RoleUserSelect,
  firstName: string,
  lastName: string,
  sirName: string,
  email: string,
  password: string,
  rePassword: string,
  ogrn: string,
}

export interface Activation {
  uid: string;
  token: string;
}

export interface SignUpReq extends SignUp {
  role: RoleUser,
}

export interface IExportTest extends SignUp2 {
  email: string,
  isActive: boolean,
  status: string,
  consent: boolean,
}

export interface SignUpResSnake {
  id: number,
  company_name: string,
  inn: string,
  ogrn: string,
  first_name: string,
  last_name: string,
  sir_name: string,
  position: string,
  phone: string,
  email: string,
  password: string,
  re_password: string,
  role: RoleUser,
  created: string
}

export interface SignUpResCamel {
  id: number,
  companyName: string,
  inn: string,
  ogrn: string,
  firstName: string,
  lastName: string,
  sirName: string,
  position: string,
  phone: string,
  email: string,
  password: string,
  re_password: string,
  role: RoleUser,
  created: string
}

export interface IExportTestResSnake {
  name_of_company: string,
  inn: string,
  full_name: string,
  position: string,
  phone: string,
  email: string,
  status: string,
  is_active: boolean,
}

export interface IExportTestResCamel {
  nameOfCompany: string,
  inn: string,
  fullName: string,
  position: string,
  phone: string,
  email: string,
  status: string,
  isActive: boolean,
}
