import React, { FC, useEffect } from 'react';
import cx from 'classnames';
import { useRequest } from 'hooks';
import { URL } from 'appConstants';
import { _t, _t1 } from 'utils';
import { Section, Text } from 'components';
import type { MainData } from 'types';
import MainNumbersBlock from './MainNumbersBlock';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const EXPORT_MOSCOW_PARAMS = (data: MainData) => [
  {
    id: 1,
    label: _t('Импортирует продукцию столицы'),
    value: (
      <Text bold className={styles.value}>
        {data.country_export}
        <Text tag="span" className={styles.smallValue}>{_t('страны')}</Text>
      </Text>
    ),
  }, {
    id: 2,
    label: _t1`Экспорт (ННЭ) Москвы в ${data.period_export_nne} году`,
    value: (
      <Text bold className={styles.value}>
        <Text tag="span" className={styles.smallValue}>$</Text>
        {data.export_nne}
        <Text tag="span" className={styles.smallValue}>{_t('млрд')}</Text>
      </Text>
    ),
  }, {
    id: 3,
    label: _t1`Рост столичного экспорта по сравнению с ${data.period_growth_export_moscow} годом`,
    value: (
      <Text bold className={styles.value}>
        {data.growth_export_moscow}
        <Text tag="span" className={styles.smallValue}>%</Text>
      </Text>
    ),
  },
];

const MOSPROM_PARAMS = (data: MainData) => [
  {
    id: 1,
    label: _t('Успешно проведённых байерских мероприятий'),
    value: <Text bold className={styles.value}>{data.country_mosprom}</Text>,
  },
  {
    id: 2,
    label: _t('Консультаций в области ВЭД'),
    value: <Text bold className={styles.value}>{data.consultation}</Text>,
  },
  {
    id: 3,
    label: _t('Верификаций зарубежных контрагентов'),
    value: <Text bold className={styles.value}>{data.verification}</Text>,
  },
];

const MainNumbers: FC<Props> = ({
  className,
}) => {
  const {
    data,
    request,
  } = useRequest<MainData[]>();
  useEffect(() => {
    request({
      method: 'GET',
      url: URL.MAIN_PAGE_DATA,
    });
  }, []);

  return (
    <Section className={cx(className, styles.section)}>
      {data?.map((main, index) => ((
        <div key={index}>
          <MainNumbersBlock
            className={styles.moscowExport}
            title="Общие показатели экспорта Москвы:"
            subTitle={_t1`* за ${main.period} год`}
            items={main ? EXPORT_MOSCOW_PARAMS(main) : []}
          />
          <MainNumbersBlock
            title="Консультации в области ВЭД:"
            items={main ? MOSPROM_PARAMS(main) : []}
          />
        </div>
      )))}
    </Section>
  );
};

export default MainNumbers;
