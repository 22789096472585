import React from 'react';
import { MediaLayout, MediaBaseList } from 'containers';
import { URL } from 'appConstants';

const MediaArticles = () => (
  <MediaLayout>
    <MediaBaseList requestUrl={URL.NEWS.ARTICLES.GET} />
  </MediaLayout>
);

export default MediaArticles;
