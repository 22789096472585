/* eslint-disable no-else-return */
import React, {
  FC,
  memo,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _t, history } from 'utils';
import {
  isLocalTest,
  isProd,
  routes,
  URL,
} from 'appConstants';
import {
  addDays,
  add,
  format,
  differenceInMinutes,
  differenceInDays,
  differenceInHours,
} from 'date-fns';
import {
  testInitAction,
  testQRemoveSession,
  testQSessionIDAndRunTestAction,
  testQVisibleToggle,
  testVGetProducts,
  testVVisibleToggle,
} from 'store/test/actions';
import {
  PersonalTabLayout,
  PersonalTabLayoutLeft,
  PersonalTabLayoutRight,
} from 'containers';
import { Modal, ButtonRounded } from 'components';
import {
  IUserMe,
  State,
  TAdeptVideos,
  TVideoProps,
} from 'types';
import { useRequest } from 'hooks';
import cx from 'classnames';
import AdeptLogo from 'assets/img/adept-test/adept-test-logo.svg';
import TestLogo from 'assets/img/adept-test/qual-logo.svg';
import PersonalTestDescriptionBlock from './PersonalTestDescriptionBlock';
import { CommonDescription } from './PersonalTestCommonDescription';
import { ControlProps } from './PersonalTestDescriptionBlock/DescriptionBlockControl';
import { Tests } from './Tests';
import styles from './styles.module.scss';

type TVideo = 'first_video' | 'second_video' | 'third_video';
type TVideoAlias = 'start' | 'qualification' | 'validation';

type TVideosStatus = {
  [index in TVideo]: boolean;
};

type Props = {
  token: string,
  user: IUserMe | null,
};

function getDateString(createdDateStr: string | null) {
  if (!createdDateStr) return '...';
  const now = new Date();
  const createdDate = new Date(createdDateStr);
  const expiredDate = addDays(createdDate, 7);

  const sub = Date.parse(expiredDate.toString()) - Date.parse(now.toString());
  if (sub < 0) return '...';

  const diffDays = differenceInDays(expiredDate, now);
  const diffHours = differenceInHours(expiredDate, now) % 24;
  const diffMin = differenceInMinutes(expiredDate, now) % 60;

  return `${diffDays} д. ${diffHours} ч. ${diffMin} мин.`;
}

const PersonalTestsTab: FC<Props> = memo(({ token, user }) => {
  const [isQModalOpen, setQModalOpen] = useState(false);
  const [isStart, setIsStart] = useState<boolean>(!user?.first_video);
  const [videosStatus, setVideosStatus] = useState<TVideosStatus>({
    first_video: !!user?.first_video,
    second_video: !!user?.second_video,
    third_video: !!user?.third_video,
  });

  const dispatch = useDispatch();
  const testState = useSelector(({ test }: State) => test);
  let qSpecificProps: ControlProps['specificProps'];
  let vSpecificProps: ControlProps['specificProps'];

  const {
    data: VideoLinks,
    request: getVideoLinks,
  } = useRequest<TAdeptVideos[]>();
  const {
    request: setSeenVideo,
  } = useRequest();

  useEffect(() => {
    if (token) {
      getVideoLinks({
        method: 'GET',
        url: URL.PERSONAL.ADEPT7.VIDEOS,
        headers: { Authorization: `Bearer ${token}` },
      });
    }
  }, [token]);

  const onSeenHandler = (id: TVideo) => {
    if (user && !user[id]) {
      setSeenVideo({
        method: 'PATCH',
        url: URL.PERSONAL.ADEPT7.SET_WATCHED(user?.id),
        headers: { Authorization: `Bearer ${token}` },
        data: { [id]: true },
      });
      setVideosStatus({
        ...videosStatus,
        [id]: true,
      });
    }
  };

  const getVideoUrl = (name: string) => {
    const link = VideoLinks?.find((item) => item.title === name)?.video || '';
    return !isProd ? link.replace('.moscow/', '.moscow:32100/') : link;
  };

  const getVideoProps:(
    type: TVideoAlias,
  ) => TVideoProps = (type) => {
    switch (type) {
      case 'start': {
        const videoId = 'first_video';
        return {
          isVideoSeen: user?.[videoId] || videosStatus[videoId],
          setSeenVideo: () => onSeenHandler(videoId),
          videoUrl: getVideoUrl('Общее Adept7'),
        };
      }
      case 'qualification': {
        const videoId = 'second_video';
        return {
          isVideoSeen: user?.[videoId] || videosStatus[videoId],
          setSeenVideo: () => onSeenHandler(videoId),
          videoUrl: getVideoUrl('Квалификация'),
        };
      }
      case 'validation': {
        const videoId = 'third_video';
        return {
          isVideoSeen: user?.[videoId] || videosStatus[videoId],
          setSeenVideo: () => onSeenHandler(videoId),
          videoUrl: getVideoUrl('Валидация'),
        };
      }
      default: return {
        isVideoSeen: false,
        setSeenVideo: () => {},
        videoUrl: '',
      };
    }
  };

  const WhatISTest:() => undefined|{
    title: string,
    type: 'validation' | 'qualification',
    image: string,
  } = () => {
    const getImgUrl = (kind: 'Q' | 'V', stepId: number) => (
      `${isLocalTest ? 'http://prom.moscow:32100' : ''}` +
      `/media/adept/${kind}-imgs-per-step/${stepId}.png`
    );

    if (testState.qualificationMeta.isGoing) {
      const { current: stepId } = testState.qualificationMeta;
      return {
        title: 'Квалификация',
        type: 'qualification',
        image: (!testState.qualificationMeta.isConfermedPersonalData)
          ? AdeptLogo : getImgUrl('Q', stepId),
      };
    }
    if (testState.qualificationMeta.isFinished &&
      testState.validationMeta.isGoing && (
      (testState.validationTopic.countries.length &&
        testState.validationTopic.products.length) ||
      (testState.validationTopic.countryId &&
        testState.validationTopic.productId)
    )) {
      const { current: stepId } = testState.validationMeta;
      return {
        title: 'Валидация',
        type: 'validation',
        image: !stepId ? TestLogo : getImgUrl('V', stepId),
      };
    }
    return undefined;
  };

  useEffect(() => {
    const section = document.getElementsByTagName('section')[0];
    section.style.minHeight = '100%';
    return () => {
      section.style.minHeight = '';
      dispatch(testQVisibleToggle(false));
      dispatch(testVVisibleToggle(false));
    };
  }, []);

  useEffect(() => {
    dispatch(testInitAction());
  }, []);

  useEffect(() => {
    if (testState.personalData.member.role !== 'E') {
      history.push(routes.personal.profile.root);
    }
  }, []);

  if (
    testState.personalData.member.role !== 'E'
  ) {
    qSpecificProps = undefined;
    vSpecificProps = undefined;
  } else if (
    !testState.qualificationMeta.isGoing &&
    testState.qualificationMeta.total &&
    !testState.qualificationMeta.passed &&
    !testState.qualificationMeta.isFinished
  ) {
    qSpecificProps = {
      type: 'button',
      onClick: () => {
        dispatch(testQSessionIDAndRunTestAction());
      },
      onHistoryClick: () => {},
    };
    vSpecificProps = {
      type: 'warning',
      text: 'notPassed',
    };
  } else if (
    testState.qualificationMeta.passed &&
    !testState.qualificationMeta.isFinished
  ) {
    qSpecificProps = {
      type: 'draft',
      onContinue: () => dispatch(testQVisibleToggle(true)),
      onRemove: () => setQModalOpen(true),
      date: getDateString(testState.qualificationMeta.created),
      progress: {
        passed: testState.qualificationMeta.passed,
        total: testState.qualificationMeta.total,
      },
    };
    vSpecificProps = {
      type: 'warning',
      text: 'notFinalized',
    };
  } else if (
    testState.qualificationMeta.isFinished &&
    testState.qualificationMeta.status === 'P'
  ) {
    const date = testState.qualificationMeta.completed ||
      testState.qualificationMeta.updated;
    qSpecificProps = {
      type: 'passed',
      dateComplete: format(new Date(date?.toString() || ''), 'dd.MM.yyyy'),
      dateNext: format(
        add(new Date(date?.toString() || ''), { months: 6 }),
        'dd.MM.yyyy',
      ),
      onHistoryClick: () => {},
      showHistory: true,
    };
    vSpecificProps = {
      type: 'button',
      onClick: () => {
        dispatch(testVGetProducts());
      },
      onHistoryClick: () => {},
    };
  } else if (
    testState.qualificationMeta.isFinished &&
    testState.qualificationMeta.status === 'C'
  ) {
    const date = testState.qualificationMeta.completed ||
      testState.qualificationMeta.updated;
    qSpecificProps = {
      type: 'error',
      date: format(
        add(new Date(date?.toString() || ''), { months: 6 }),
        'dd.MM.yyyy',
      ),
      onHistoryClick: () => {},
      showHistory: true,
    };
    vSpecificProps = {
      type: 'warning',
      text: 'notPassed',
    };
  }

  if (WhatISTest()) {
    return (
      <PersonalTabLayout className={styles.testWrapper}>
        <PersonalTabLayoutLeft
          className={styles.leftContent}
        >
          <h3 className={styles.testHeader}>{WhatISTest()?.title}</h3>
          <img
            src={WhatISTest()?.image}
            alt=""
          />
        </PersonalTabLayoutLeft>
        <PersonalTabLayoutRight
          withoutWrap
        >
          <Tests
            kindOfTest={WhatISTest()?.type || 'qualification'}
          />
        </PersonalTabLayoutRight>
      </PersonalTabLayout>
    );
  }

  return (
    <PersonalTabLayout>
      <PersonalTabLayoutLeft
        className={styles.leftContent}
      >
        <img src={AdeptLogo} alt="" />
        <h3 className={styles.mainHeader}>Цифровой тест оценки экспортного потенциала</h3>
        {!isStart && (
          <ButtonRounded
            className={styles.startContentButton}
            onClick={() => setIsStart(true)}
            title="узнать подробнее"
          />
        )}
      </PersonalTabLayoutLeft>
      <PersonalTabLayoutRight
        withoutWrap
      >
        {isStart && (
          <CommonDescription
            startTest={() => setIsStart(false)}
            videoProps={getVideoProps('start')}
          />
        )}

        {!isStart && (
          <>
            <PersonalTestDescriptionBlock
              title={_t('Квалификация')}
              description={_t('описание теста квалификации')}
              control={{
                kind: 'qualification',
                specificProps: qSpecificProps,
                videoProps: getVideoProps('qualification'),
              }}
            />

            <PersonalTestDescriptionBlock
              title={_t('Валидация')}
              description={_t('описание теста валидации')}
              control={{
                kind: 'validation',
                specificProps: vSpecificProps,
                videoProps: getVideoProps('validation'),
              }}
            />
          </>
        )}

        <Modal
          onClose={() => setQModalOpen(false)}
          title={_t('Вы уверены, что хотите удалить черновик квалификации?')}
          isOpen={isQModalOpen}
          className={cx(
            styles.modalContent,
            styles.modalContent_delete,
          )}
          classNameTitle={styles.modalTitle}
          classNameOverlay={styles.modalOverlay}
          classNameClose={styles.modalBtnClose}
        >
          <div>
            <div className={styles.modalControls}>
              <ButtonRounded
                className={styles.modalButton}
                color="secondary"
                blueMode
                onClick={() => setQModalOpen(false)}
                title={_t('Отмена')}
              />
              <ButtonRounded
                className={styles.modalButton}
                color="primary"
                onClick={() => {
                  setQModalOpen(false);
                  dispatch(testQRemoveSession());
                }}
                title={_t('Удалить')}
              />
            </div>
          </div>
        </Modal>
      </PersonalTabLayoutRight>
    </PersonalTabLayout>
  );
});

export { PersonalTestsTab };
