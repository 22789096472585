import React, { FC, useEffect, useMemo } from 'react';
import {
  ICompany,
  State,
  TAdeptResults,
} from 'types';
import { useSelector } from 'react-redux';
import { useRequest } from 'hooks';
import { toast } from 'react-toastify';
import { dateToUiFormat, dwnlPDF } from 'utils';
import { URL } from 'appConstants';
import { Preloader } from 'components';
import cx from 'classnames';
import AdeptHeader from './AdeptHeader';
import Recommendation from './Recommendation';
import QualScore from './QualScore';
import ScoreMethods from './ScoreMethods';
import Conclusions from './Conclusions';
import DetailScore from './DetailScore';
import ButtonToTop from './ButtonToTop';
import { ValidParams } from './vTexts';
import { QualParams } from './qTexts';
import styles from './styles.module.scss';
import ButtonToScroll from './ButtonToScroll';

type miniData = {
  name: string,
  inn: string,
};

type Props = {
  className?: string,
  token: string,
  CompData: ICompany | miniData | null,
  kind: 'qualification' | 'validation';
  validationId?: string,
  saveConfig?: {
    onEndSave: () => void,
    hideButtons?: boolean,
  }
};

const limitScoreForPass = 0.5;

const PersonalTestResults: FC<Props> = ({
  className,
  token,
  CompData,
  kind,
  validationId = '',
  saveConfig,
}) => {
  const testState = useSelector(({ test }: State) => test);
  const DATASETS = {
    qualification: {
      title: 'Квалификация',
      legend: QualParams,
      finder: (item:TAdeptResults) => item.quiz.type === 'Q' && item.id === validationId,
    },
    validation: {
      title: 'Валидация',
      legend: ValidParams,
      finder: (item:TAdeptResults) => item.quiz.type === 'V' && item.id === validationId,
    },
  };

  const {
    data: adeptData,
    request: getAdeptData,
  } = useRequest<TAdeptResults[]>();

  useEffect(() => {
    if (token) {
      getAdeptData({
        method: 'GET',
        url: URL.PERSONAL.ADEPT7.GET,
        headers: { Authorization: `Bearer ${token}` },
      });
    }
  }, [token]);

  const adeptRes = useMemo(() => {
    return adeptData?.find(DATASETS[kind].finder);
  }, [adeptData]);

  useEffect(() => {
    if (adeptRes as TAdeptResults && saveConfig) {
      toast.info('Файл подготавливается и скачается автоматически');

      dwnlPDF.byPages({
        kind,
        docData: adeptRes,
        testState,
        onEndSave: saveConfig?.onEndSave,
      });
    }
  }, [adeptRes]);

  return (
    <Preloader
      isLoading={!adeptRes}
      withoutAnim={!!saveConfig}
    >
      <div
        id="adept_report"
        className={cx(
          styles.adept_report,
          className,
        )}
      >
        <div id="adept0">
          <AdeptHeader
            client={{
              kval: DATASETS[kind].title,
              name: CompData?.name || '',
              tin: CompData?.inn || '',
            }}
          />
        </div>

        <div id="ForPDF_page1">
          <p className={cx(styles.pageTitle,
            { [styles.forPDF]: !!saveConfig })}
          >
            Отчет о прохождении ADEPT7
          </p>

          <div id="adept1">
            <QualScore
              title={DATASETS[kind].title}
              data={adeptRes}
              scoreParams={{
                limitScoreForPass,
                isPassed: adeptRes?.results.model_res as number >= limitScoreForPass,
              }}
              // hideButton={saveConfig?.hideButtons}
            />
          </div>
        </div>

        <div id="adept2">
          <DetailScore
            dimNormalized={adeptRes?.results.dim_values_normalized}
            legend={DATASETS[kind].legend}
          />
        </div>

        <div
          id="ForPDF_navs"
          className={styles.navigateButtons}
        >
          <ButtonToScroll
            title="Перейти к методике оценки"
            targetId="adept4"
            className={styles.button}
          />
          <ButtonToScroll
            title="Перейти к дополнительным материалам"
            targetId="adept5"
            className={styles.button}
          />
        </div>

        <div id="adept3">
          <Recommendation
            kind={kind}
            dimNormalized={adeptRes?.results.dim_values_normalized}
            dimValues={adeptRes?.results.dim_values}
            legend={DATASETS[kind].legend}
          />
        </div>

        <div id="ForPDF_conclusion">
          <Conclusions
            data={adeptRes}
            companyName={CompData?.name || ''}
            scoreParams={{
              limitScoreForPass,
              isPassed: adeptRes?.results.model_res as number >= limitScoreForPass,
            }}
          />
        </div>

        <ScoreMethods
          legend={DATASETS[kind].legend}
        />

        {saveConfig && (
          <div
            className={styles.PDFfooter}
            id="ForPDF_footer"
          >
            <span>Страница: </span>
            <span>Создан: {dateToUiFormat(adeptRes?.updated || adeptRes?.completed)}</span>
            <span>Сохранен: {dateToUiFormat(new Date())}</span>
          </div>
        )}
      </div>

      {!saveConfig?.hideButtons && <ButtonToTop />}
    </Preloader>
  );
};

export default PersonalTestResults;
