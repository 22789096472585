import React, {
  FC,
  useEffect,
} from 'react';
import { useRequest } from 'hooks';
import { URL } from 'appConstants';
import { Faq } from 'types';
import {
  ExpandingBlock,
} from 'components';
import {
  PersonalTabLayout,
  PersonalTabLayoutLeft,
  PersonalTabLayoutRight,
} from 'containers';
import tabImg from 'assets/img/personal/faq.svg';
import PersonalFaqRequest from '../PersonalFaqRequest';
import styles from './styles.module.scss';

type Props = {};

const PersonalFaqMain: FC<Props> = () => {
  const { data: FaqData, request: FaqReq } = useRequest<Faq[]>();

  useEffect(() => {
    FaqReq({
      method: 'GET',
      url: URL.PERSONAL.FAQ.GET,
    });
  }, []);

  return (
    <PersonalTabLayout>
      <PersonalTabLayoutLeft
        className={styles.forTitle}
        tabTitle="Задайте свой вопрос и мы ответим на него в ближайшее время"
        image={tabImg}
      >
        <PersonalFaqRequest />
      </PersonalTabLayoutLeft>
      <PersonalTabLayoutRight
        tabTitle="Основные вопросы"
      >
        {FaqData && (
          FaqData?.map((FaqItem, key) => (
            <ExpandingBlock
              key={key}
              className={styles.mapItem}
              buttonStyle={styles.faqQuestion}
              buttonStyleOpen={styles.faqQuestionOpen}
              textStyle={styles.faqText}
              label={FaqItem.question}
              classNameContent="{classNameContent}"
            >
              {FaqItem?.answer}
            </ExpandingBlock>
          ))
        )}
      </PersonalTabLayoutRight>
    </PersonalTabLayout>
  );
};

export default PersonalFaqMain;
