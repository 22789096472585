import React, { FC } from 'react';
import cx from 'classnames';
import {
  Section,
  Text,
  Card,
  H5,
  ButtonArrow,
  ButtonCircle,
  Link,
} from 'components';
import { routes } from 'appConstants';
// import service1 from 'assets/img/service-1.png';
// import service2 from 'assets/img/service-2.png';
// import service3 from 'assets/img/service-3.png';
import { history, _t } from 'utils';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const SERVICES = [
  {
    id: '01',
    serviceId: 2,
    title: 'Аналитические исследования',
  },
  {
    id: '02',
    serviceId: 0,
    title: 'Индивидуальная поддержка',
  },
  {
    id: '03',
    serviceId: 1,
    title: 'Байерская программа',
  },
];

const MainServices: FC<Props> = ({ className }) => (
  <Section className={cx(styles.section, className)}>
    <div className={styles.content}>
      <Text use="title" className={styles.title}>{_t('Услуги')}</Text>
      <ul className={styles.list}>
        {SERVICES.map(({ id, serviceId, title }) => (
          <Card key={id} tag="li" className={cx(styles.service, styles[`service_${serviceId + 1}`])}>
            <Link to={`${routes.services.root}?serviceId=${serviceId}`} className={styles.link}>
              <Text bold className={styles.number}>{id}</Text>
              <div className={styles.footer}>
                <H5 className={styles.name}>{_t(title)}</H5>
                <ButtonArrow className={styles.arrow} />
              </div>
            </Link>
          </Card>
        ))}
      </ul>
    </div>

    <ButtonCircle
      className={styles.more}
      onClick={() => history.push(routes.services.root)}
    >
      {_t('Узнать больше')}
    </ButtonCircle>
  </Section>
);

export default MainServices;
