/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import cx from 'classnames';
import {
  Card,
  Link,
  Text,
} from 'components';
import type { TestGoodsData } from 'types';
import { _t, _t_back, NoImage } from 'utils';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  value: TestGoodsData,
  targetDetailsPath?: string,
  minCard?: boolean,
};

const PersonalGoodsListCard: FC<Props> = ({
  className,
  value,
  targetDetailsPath,
  minCard = false,
}) => (
  <Card
    key={value.id}
    tag="div"
    className={cx(styles.card, className)}
  >
    <Link
      to={targetDetailsPath ? `${targetDetailsPath}/${value.id}` : `${targetDetailsPath}/`}
      // to={`${targetDetailsPath}/${value.id}`}
      className={styles.linkWrap}
    >
      <img className={styles.image} src={NoImage} alt="" />

      <div className={styles.detail}>
        <div className={styles.date}>
          {value.date}
        </div>

        <Text
          size="big"
          bold
          className={styles.title}
        >
          {_t_back(value, 'title')}
        </Text>

        {!minCard && (
          <div className={styles.info}>
            {value.descr}
          </div>
        )}

        <div className={styles.cost}>
          {_t('Стоимость')} {value.cost} р/шт
        </div>

        {!minCard && (
          <div className={styles.company}>
            {value.company}
          </div>
        )}
      </div>

      {/* <div className={cx(styles.btnWrap, 'btnWrap')}>
        <button
          type="button"
          className={styles.btn}
        >
          {_t('Подробнее')}
        </button>
      </div> */}
    </Link>
  </Card>
);

export default PersonalGoodsListCard;
