import React, { FC } from 'react';
// import cx from 'classnames';
// import { Text } from 'components';
import {
  AnalyticsRegional,
} from 'types';
import { _t1, _t_back } from 'utils';
import styles from './styles.module.scss';

type Props = {
  data: AnalyticsRegional,
};

const RefactorString = (str: string = '', country: boolean = true) => {
  return country && str
    ? (str[0] + str.substr(1).toLowerCase())
    : str.toLowerCase();
};

const RegionInfo: FC<Props> = ({
  data,
}) => {
  const prods = data.industry_top_product || data.regional_analytic_products;
  const country = data.industry_top_country || data.regional_analytic_country;

  return (
    <div>
      <div className={styles.industry_info}>
        <div className={styles.industry_prods}>
          <div className={styles.list_header}>{_t1`Топ ${prods?.length} товаров`}</div>
          {prods?.map((obj, index) => (
            <div
              key={`prods_${index}`}
              className={styles.list_item}
            >
              {`${obj.number_top} ${RefactorString(_t_back(obj, 'normal_name') || _t_back(obj, 'data_name'))}`}
            </div>
          ))}
        </div>
        <div className={styles.industry_countries}>
          <div className={styles.list_header}>{_t1`Топ ${country?.length} стран`}</div>
          {country?.map((obj, index) => (
            <div
              key={`country_${index}`}
              className={styles.list_item}
            >
              {`${obj.number_top} ${RefactorString(_t_back(obj, 'data_name'))}`}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RegionInfo;
