import React, {
  FC,
  // useState,
  useEffect,
  // useMemo,
} from 'react';
// import { useSelector } from 'react-redux';
import { useRequest } from 'hooks';
import { useFormik } from 'formik';
import { URL } from 'appConstants';
import {
  // LeadStatus,
  // State,
  RequestStatus,
  TestGoodsData,
} from 'types';
import {
  // dateToUiFormat,
  _t,
  validateEmptyParams,
} from 'utils';
import { PersonalTabLayout } from 'containers';
import {
  Preloader,
  NoData,
  Paginate,
  Input,
  Select,
} from 'components';
import cx from 'classnames';
import PersonalBDLayout from '../../PersonalBDLayout';
import styles from './styles.module.scss';

type Props = {};

interface Params {
  support_measures: Array<number>,
  financial_res: number,
  page: number,
  search: string,
  count: number,
  results: TestGoodsData[],
}

const PAGE_SIZE = 12;

interface Legend {
  code?: 'all' | 'request' | 'offer' | 'draft';
  text: string;
}

const LEGEND: Legend[] = [
  {
    code: 'all',
    text: 'Все объявления',
  },
  {
    code: 'request',
    text: 'Запросы',
  },
  {
    code: 'offer',
    text: 'Предложения',
  },
  {
    code: 'draft',
    text: 'Черновики',
  },
];

const initialValues:Params = {
  support_measures: [],
  financial_res: 0,
  page: 0,
  search: '',
  count: 12,
  results: [
    {
      id: 0,
      date: '10.10.2021',
      title: 'Название товара или услуги',
      descr: 'Пищевкусовая промышленность/ Масложировая промышленность (без производства мыла и моющих средств)',
      company: 'ООО Компания',
      cost: 120,
    },
    {
      id: 1,
      date: '10.10.2021',
      title: 'Название товара или услуги',
      descr: 'Пищевкусовая промышленность/ Масложировая промышленность (без производства мыла и моющих средств)',
      company: 'ООО Компания',
      cost: 120,
    },
    {
      id: 2,
      date: '10.10.2021',
      title: 'Название товара или услуги',
      descr: 'Пищевкусовая промышленность/ Масложировая промышленность (без производства мыла и моющих средств)',
      company: 'ООО Компания',
      cost: 120,
    },
    {
      id: 3,
      date: '10.10.2021',
      title: 'Название товара или услуги',
      descr: 'Пищевкусовая промышленность/ Масложировая промышленность (без производства мыла и моющих средств)',
      company: 'ООО Компания',
      cost: 120,
    },
    {
      id: 4,
      date: '10.10.2021',
      title: 'Название товара или услуги',
      descr: 'Пищевкусовая промышленность/ Масложировая промышленность (без производства мыла и моющих средств)',
      company: 'ООО Компания',
      cost: 120,
    },
  ],
};

const PersonalGoodsMine: FC<Props> = () => {
  // const [search, setSearch] = useState<string>('');
  // const [statusParam, setStatusParam] = useState<number[]>([]);
  // const [categoryParam, setCategoryParam] = useState<number[]>([]);
  // const [statusParam, setStatusParam] = useState<number>(0);
  // const [categoryParam, setCategoryParam] = useState<number>(0);
  // const [openFilter, setOpenFilter] = useState<boolean>(false);
  // const [detail, setDetail] = useState<number>(-1);

  const { data: GoodsData, status: LeadReqStatus, request: LeadRequest } =
    useRequest<TestGoodsData[]>();
  // const { data: StatData, request: StatRequest } = useRequest<LeadStatus[]>();
  // const { data: CatData, request: CatRequest } = useRequest<LeadStatus[]>();
  // const token = useSelector(({ me }: State) => me.token);

  const {
    values,
    handleChange,
    handleSubmit,
  } = useFormik<Params>({
    initialValues,
    enableReinitialize: true,
    onSubmit: (params) => {
      const {
        page,
        ...paramsToSend
      } = params;
      LeadRequest({
        method: 'get',
        params: {
          ...validateEmptyParams({
            params: paramsToSend,
            emptyParamsToDelete: [
              'search',
              'support_measures',
              'new_country',
              'show_archive',
              'category_of_event',
              'form_of_holding',
              'start_filter_date',
              'end_filter_date',
            ],
          }),
          limit: PAGE_SIZE,
          offset: page * PAGE_SIZE,
        },
        // paramsSerializer: (value) => qs.stringify(value, { indices: false }),
        url: URL.EVENTS.GET,
      });
    },
  });

  // const setStatusFilter = (val: number = 0) => {
  //   if (statusParam.includes(val)) {
  //     setStatusParam(statusParam.filter((i) => (i !== val)));
  //   } else {
  //     setStatusParam(statusParam.concat(val));
  //   }
  // };

  // const setStatusFilter = (val: number = 0) => {
  //   setStatusParam(val);
  // };

  // const setCategoryFilter = (val: number = 0) => {
  //   setCategoryParam(val);
  // };

  // const clearFilters = () => {
  //   setSearch('');
  //   setStatusParam(0);
  //   setCategoryParam(0);
  // };

  // useEffect(() => {
  //   const reqText = `${search && `&search=${search}`}${statusParam
  // ? `&status=${statusParam}` : ''}${categoryParam ? `&category=${categoryParam}` : ''}`;
  //   LeadRequest({
  //     method: 'GET',
  //     url: `${URL.PERSONAL.LEADS.GET}/?arhive=true${reqText}`,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  // }, [statusParam, categoryParam, search]);

  useEffect(() => {
    // StatRequest({
    //   method: 'GET',
    //   url: URL.PERSONAL.LEADS.STATUS,
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
  }, []);

  useEffect(() => {
    // CatRequest({
    //   method: 'GET',
    //   url: URL.PERSONAL.LEADS.CATEGORY,
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
  }, []);

  const handleSearch = () => {
    handleSubmit();
  };

  const getTextLabel = (name: string, label: string, className?: string) => (
    <label htmlFor={name} className={cx(styles.label, className)}>{label}</label>
  );

  return (
    <PersonalTabLayout
      single
      className={styles.layout}
    >
      <div className={styles.filters}>
        <Input
          type="search"
          name="search"
          value={values.search}
          placeholder="Поиск"
          onChange={handleChange}
          onSearch={handleSearch}
          className={styles.search}
        />
        <div className={cx(styles.select)}>
          <Select
            placeholder={_t('Все объявления')}
            isClearable
            options={LEGEND.map(({ text }, index) => ({
              label: _t(text),
              value: index.toString(),
            }))}
            isMulti={false}
            customLabel={getTextLabel('region', `${_t('Статус')}: `)}
            // onChange={(option) => {
            //   if (option) {
            //     handleSelectRegion(+(option as OptionType).value);
            //   } else {
            //     handleSelectRegion(0);
            //   }
            // }}
          />
        </div>
      </div>

      <Preloader isLoading={LeadReqStatus === RequestStatus.REQUEST}>
        <NoData count={initialValues?.count || GoodsData?.length || 0}>
          <PersonalBDLayout
            title="Мои объявления"
            data={initialValues}
            gridClassName={styles.list}
            viewSwitcher
            minCard
          />
          {/* <PersonalBDLayout
            data={initialValues}
            gridClassName={styles.list}
            viewSwitcher
          /> */}
          <Paginate
            page={values.page}
            count={initialValues?.count || 0}
            pageSize={PAGE_SIZE}
            className={styles.paginate}
            onChange={(value) => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              handleChange({
                target: {
                  name: 'page',
                  value,
                },
              });
              handleSubmit();
            }}
          />
        </NoData>
      </Preloader>
    </PersonalTabLayout>
  );
};

export default PersonalGoodsMine;
