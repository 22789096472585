import React, { useEffect, FC } from 'react';
import { useFormik } from 'formik';
import qs from 'qs';
import {
  FiltersCheckboxes,
  FilterSidebar,
  FiltersRadioButtons,
  ResolutionsListCard,
} from 'containers';
import { NoData, Paginate, Preloader } from 'components';
import { useRequest } from 'hooks';
import { URL, routes } from 'appConstants';
import {
  validateEmptyParams,
  _has_t_back,
} from 'utils';
import type { BaseFilter as Filter, GetResponse, Resolution } from 'types';
import { RequestStatus } from 'types';
import styles from './styles.module.scss';

type Props = {
  targetDetailsPath?: string,
};

type Params = {
  support_measures: Array<number>,
  scope_product_export: Array<number>,
  grants_and_loands: Array<number>,
  application_date: number,
  size_of_enterprise: number,
  search: string,
  page: number,
};

const PAGE_SIZE = 6;

const initialValues: Params = {
  support_measures: [],
  scope_product_export: [],
  grants_and_loands: [],
  application_date: 0,
  size_of_enterprise: 0,
  search: '',
  page: 0,
};

const Resolutions: FC<Props> = ({
  targetDetailsPath = routes.resolutions.root,
}) => {
  const {
    data: resolutions,
    request: getResolutions,
    status,
  } = useRequest<GetResponse<Resolution[]>>();

  const {
    values,
    handleChange,
    handleSubmit,
    dirty,
    resetForm,
  } = useFormik<Params>({
    initialValues,
    enableReinitialize: true,
    onSubmit: (params) => {
      const { page, ...paramsToSend } = params;
      getResolutions({
        method: 'get',
        params: {
          ...validateEmptyParams({
            params: paramsToSend,
            emptyParamsToDelete: ['search', 'application_date', 'size_of_enterprise'],
          }),
          limit: PAGE_SIZE,
          offset: page * PAGE_SIZE,
        },
        paramsSerializer: (value) => qs.stringify(value, { indices: false }),
        url: URL.RESOLUTIONS.GET,
      });
    },
  });

  const {
    data: supportMeasures,
    request: getSupportMeasures,
  } = useRequest<Filter[]>();

  const {
    data: scopeOfProducts,
    request: getScopeOfProducts,
  } = useRequest<Filter[]>();

  const {
    data: dateChoise,
    request: getDateChoise,
  } = useRequest<Filter[]>();

  const {
    data: sizeOfEnterprise,
    request: getSizeOfEnterprise,
  } = useRequest<Filter[]>();

  const {
    data: grantsAndLoans,
    request: getGrantsAndLoans,
  } = useRequest<Filter[]>();

  useEffect(() => {
    getSupportMeasures({ url: URL.RESOLUTIONS.FILTERS.SUPPORT_MEASURES });
    getScopeOfProducts({ url: URL.RESOLUTIONS.FILTERS.SCOPE_OF_PRODUCTS });
    getDateChoise({ url: URL.RESOLUTIONS.FILTERS.DATA_CHOICE });
    getSizeOfEnterprise({ url: URL.RESOLUTIONS.FILTERS.SIZE_OF_ENTERPRISE });
    getGrantsAndLoans({ url: URL.RESOLUTIONS.FILTERS.GRAND_AND_LOANS });

    getResolutions({
      method: 'get',
      url: URL.RESOLUTIONS.GET,
      params: {
        limit: PAGE_SIZE,
      },
    });
  }, []);

  const filtered = resolutions?.results.filter(_has_t_back) || [];

  return (
    <div className={styles.layout}>
      <FilterSidebar
        search={values.search}
        className={styles.sidebar}
        isCanReset={dirty}
        onSubmit={() => {
          // reset pagination
          handleChange({
            target: {
              name: 'page',
              value: 0,
            },
          });
          handleSubmit();
        }}
        onReset={resetForm}
        onChangeSearch={handleChange}
        onSubmitSearch={handleSubmit}
      >
        <FiltersCheckboxes
          values={values}
          name="scope_product_export"
          label="Область продукции для экспорта"
          list={scopeOfProducts || []}
          handleChange={handleChange}
        />

        <FiltersCheckboxes
          values={values}
          name="support_measures"
          label="Направления мер поддержки"
          list={supportMeasures || []}
          handleChange={handleChange}
        />

        <FiltersRadioButtons
          values={values}
          name="application_date"
          label="Сроки подачи"
          list={dateChoise || []}
          handleChange={handleChange}
        />

        <FiltersRadioButtons
          values={values}
          name="size_of_enterprise"
          label="Размер предприятия"
          list={sizeOfEnterprise || []}
          handleChange={handleChange}
        />

        <FiltersCheckboxes
          values={values}
          name="grants_and_loands"
          label="Формат предоставления меры поддержки"
          list={grantsAndLoans || []}
          handleChange={handleChange}
        />
      </FilterSidebar>

      <div className={styles.content}>
        <Preloader isLoading={status === RequestStatus.REQUEST}>
          <NoData count={filtered?.length || 0}>
            <div className={styles.cards}>
              {filtered?.map((resolution) => (
                <ResolutionsListCard
                  key={resolution.id}
                  value={resolution}
                  className={styles.card}
                  targetDetailsPath={targetDetailsPath}
                />
              ))}
            </div>
            <Paginate
              page={values.page}
              count={resolutions?.count || 0}
              pageSize={PAGE_SIZE}
              onChange={(value) => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                handleChange({
                  target: {
                    name: 'page',
                    value,
                  },
                });
                handleSubmit();
              }}
            />
          </NoData>
        </Preloader>
      </div>
    </div>
  );
};

export default Resolutions;
