import React, { FC } from 'react';
import cx from 'classnames';
import { HTMLContent } from 'components';
import { TAdeptResults, TScoreParams } from 'types';
import styles from './styles.module.scss';
import { Conclusions as Tdata } from '../data';
import BlockTitle from '../BlockTitle';

type Props = {
  data?: TAdeptResults,
  companyName: string,
  scoreParams: TScoreParams,
};

const Conclusions:FC<Props> = ({
  data,
  companyName,
  scoreParams,
}) => {
  if (data) {
    const {
      results: { model_res: score },
      quiz: { type: quizType },
    } = data;

    return (
      <div className={styles.conclusions}>
        <BlockTitle
          className={styles.title}
          title="Общий вывод и рекомендация"
        />

        <HTMLContent
          className={cx(
            styles.result,
            styles[scoreParams.isPassed ? 'good' : 'bad'],
          )}
          content={
            scoreParams.isPassed
              ? Tdata[quizType]['passed'](companyName, +score.toFixed(2))
              : Tdata[quizType]['failed']
          }
        />
      </div>
    );
  }

  return (<></>);
};

export default Conclusions;
