/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect } from 'react';
import {
  Calendar as ReactCalendar,
  dateFnsLocalizer,
} from 'react-big-calendar';
import {
  getDay,
  startOfWeek,
  parse,
  format,
} from 'date-fns';
import { ReactCalendarCEvent } from 'types';
import { ClientLang } from 'appConstants';
import { ru, enUS } from 'date-fns/locale';
import { _t } from 'utils';
import 'react-big-calendar/lib/css/react-big-calendar.css';

type Props = {
  height?: string,
  events: ReactCalendarCEvent[],
  components?: object,
  className?: string,
};

const locales = {
  ru,
  enUS,
};

const Langlocales:{[index:string]: string} = {
  ru: 'ru',
  en: 'enUS',
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const messages = {
  today: _t('Today'),
  previous: _t('Back'),
  next: _t('Next'),
  month: _t('Month'),
  week: _t('Week'),
  day: _t('Day'),
  agenda: _t('Agenda'),
  noEventsInRange: _t('There are no events in this range.'),
};

const Calendar: FC<Props> = ({
  height,
  events,
  components,
  className,
}) => {
  useEffect(() => {
  }, []);

  return (
    <ReactCalendar
      culture={Langlocales[ClientLang]}
      localizer={localizer}
      events={events}
      style={{ height }}
      formats={{
        monthHeaderFormat: 'MMM yyyy',
      }}
      className={className}
      components={components}
      messages={messages}
    />
  );
};

export default Calendar;
