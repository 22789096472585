/* eslint-disable */
import {
  isProd,
  DefaultLang,
  ClientLang,
  _debug_translates
} from 'appConstants';
import {
  Translate,
  Translates,
} from '../types';
import { langRu } from './langRu';
import { langEn } from './langEn';

type TField = string;

const Alphabet:Translate = {
  ru: 'абвгдеёжзийклмнопрстуфхцчшщъыьэюя',
  en: 'abcdefghijklmnopqrstuvwxyz',
};

const Dictionary:Translates = {
  ru: langRu,
  en: langEn,
};

const _DL = DefaultLang;

export const _t = (dataString: TField) => {
  if (dataString && typeof(dataString) === 'string') {
    const lowerCaseStr:TField = dataString.toLowerCase();
    const TransReady:boolean = Dictionary[ClientLang] && (ClientLang === _DL || !Alphabet[ClientLang].includes(lowerCaseStr[0]));

    const translate = TransReady ? Dictionary[ClientLang][lowerCaseStr] || '' : '';
    if (ClientLang !== _DL && TransReady && !translate && (!isProd || _debug_translates)) {
      console.warn(`Frontend hasn't '${ClientLang}' translate for key '${lowerCaseStr}'`);
    }
    return translate || dataString;
  } else {
    return dataString || '';
  }
};

// @ts-ignore
export const _t1 = (strings: TemplateStringsArray, ...values) => {
  // По форме строки получим шаблон для поиска в messages
  // На месте каждого из значений будет его номер: {0}, {1}, …
  let pattern = "";
  for(let i=0; i<values.length; i++) {
    pattern += strings[i] + '{' + i + '}';
  }
  pattern += strings[strings.length-1];
  // Теперь pattern = "Hello, {0}!"

  let translated = Dictionary[ClientLang][pattern.toLowerCase()] || pattern; // "Привет, {0}!"

  // Заменит в "Привет, {0}" цифры вида {num} на values[num]
  return translated.replace(/\{(\d)\}/g, (s, num) => values[num]);
};

export const _t_back = (obj: any, field: TField) => {
  const noData = '';
  if (obj) {
    let translate:string = '';
    let defaultLang:string = '';
    if (obj['translations']) {
      translate = (obj['translations'][ClientLang])
        ? (obj['translations'][ClientLang][`${field}_t`] || obj['translations'][ClientLang][field])
        : '';
      defaultLang = (obj['translations'][_DL])
        ? (obj['translations'][_DL][`${field}_t`] || obj['translations'][_DL][field])
        : '';
    }
    const initial:string = obj[`${field}`] ? _t(obj[`${field}`]) : noData;
    if (ClientLang !== _DL && (!isProd || _debug_translates)) {
      let warn_msg:string = '';
      if (!obj['translations']) warn_msg = `Backend hasn't 'translations' object`
      if (!translate) warn_msg = `Backend hasn't '${ClientLang}' translate for field '${field}_t' in '${obj.title ? obj.title : obj}'`;
      if (!initial && !defaultLang) warn_msg = `Backend hasn't '${ClientLang}' defaultLang for field '${field}_t' in '${obj.title ? obj.title : obj}'`;
      
      if (warn_msg) console.warn(warn_msg);
    }
    return translate || defaultLang || initial;
  } else {
    return noData;
  }
};

export const _has_t_back = (obj: any) => {
  if (ClientLang !== _DL) {
    return obj.hasOwnProperty('translations') && obj["translations"].hasOwnProperty(ClientLang);
  } else {
    return true;
  }
} 
