import React, { FC } from 'react';
import cx from 'classnames';
import {
  Card,
  Text,
  H5,
  Link,
} from 'components';
import { dateToUiFormat, _t, _t_back } from 'utils';
import type { Event } from 'types';
import { routes } from 'appConstants';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  value: Event,
  targetDetailsPath?: string,
  tag?: 'li' | 'div'
};

const EventsListCard: FC<Props> = ({
  value,
  className,
  targetDetailsPath = routes.events.root,
  tag = 'div',
}) => (
  <Card
    key={value.id}
    tag={tag}
    className={cx(styles.card, className)}
  >
    <Link
      to={`${targetDetailsPath}/${value.slug}`}
      className={styles.link}
    >
      <div className={styles.header}>
        <Text bold className={styles.holding}>{_t(value.form_of_holding.title)}</Text>
        <Text bold className={styles.date}>{dateToUiFormat(value.date_of_event_start)}</Text>
      </div>
      <Text
        color="extra"
        size="tiny"
        className={styles.supportMeasure}
      >
        {_t(value.support_measures.title)}
      </Text>
      <H5 className={styles.eventTitle}>
        {_t_back(value, 'title')}
      </H5>
    </Link>
  </Card>
);

export default EventsListCard;
