/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useEffect } from 'react';
import { Header, Footer } from 'containers';
import {
  Route,
  RouteProps,
} from 'react-router-dom';
import { routes, setPageMeta } from 'appConstants';
import styles from './styles.module.scss';

interface Props extends RouteProps {
  item: {
    root: string,
    title: string,
    descr: string,
  },
}

const PublicRoute: FC<Props> = ({ location, item, ...rest }) => {
  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (location?.pathname === item.root) {
      setPageMeta(item.title, item.descr);
    }
  });

  return (
    <>
      <Header theme={location?.pathname === routes.main.root ? 'dark' : 'light'} />
      <main className={styles.main}>
        <Route
          {...rest}
        />
      </main>
      <Footer />
    </>
  );
};

export default PublicRoute;
