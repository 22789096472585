import React, { FC, useMemo } from 'react';
import { IAdeptItem } from 'types';
import styles from './styles.module.scss';

type Props = {
  data: IAdeptItem[],
};

const LinesParams = {
  max: 1,
  digits: 10,
  fixed: 1,
};

const Linear:FC<Props> = ({
  data,
}) => useMemo(() => {
  const Lines = (() => {
    let iteration = LinesParams.max;
    const mass = [];
    while (iteration > 0) {
      mass.push(iteration.toFixed(LinesParams.fixed));
      iteration -= LinesParams.max / LinesParams.digits;
    }
    return mass;
  })();

  return (
    <div className={styles.chartWrap}>
      <div className={styles.graphic}>
        {Lines.map((lineValue, lineId) => (
          <div key={lineId.toString()} className={styles.line}>
            <span>{lineValue}</span>
          </div>
        ))}
      </div>

      <div className={styles.chart}>
        {data.map((dataItem, dataId) => (
          <div
            key={dataId.toString()}
            className={styles.category}
            style={{ width: `calc(54% / ${data.length})` }}
          >
            <div className={styles.catValues}>
              <div
                className={styles.mid}
                style={{ height: `calc(100% * ${dataItem.mid})` }}
                title={`${dataItem.mid}`}
              />
              <div
                className={styles.low}
                style={{ height: `calc(100% * ${dataItem.low})` }}
                title={`${dataItem.low}`}
              />
            </div>
            <div className={styles.catId}>{dataId + 1}</div>
          </div>
        ))}
      </div>
    </div>
  );
}, [data]);

export default Linear;
