import React, { FC } from 'react';
import {
  Input,
} from 'components';
import { _t } from 'utils';
import styles from './styles.module.scss';

type Options = {
  id: number,
  title: string,
};

type Props = {
  title: string,
  options?: Options[],
};

const FilterParamPrice: FC<Props> = ({
  title,
  // values,
}) => {
  return (
    <div className={styles.filterparam}>
      <div className={styles.title}>{_t(title)}</div>
      <div className={styles.priceWrap}>
        <Input
          className={styles.price}
          // value={' '}
          type="text"
          name=""
          placeholder="От"
        />
        <Input
          className={styles.price}
          type="text"
          name=""
          placeholder="До"
        />
      </div>
    </div>
  );
};

export default FilterParamPrice;
