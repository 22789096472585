import {
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useFormik, FormikHandlers } from 'formik';
import { useRequest, useDebounceCallback } from 'hooks';
import { URL } from 'appConstants';
import { validateEmptyParams } from 'utils';
import {
  AnalyticsLocationPrecise as Region,
  GetAnalyticsLocationCountryRes,
  GetAnalyticsLocationCountryReq,
} from 'types';

type Values = {
  search: string,
  page: number,
  country__location_precise: number,
  file_added: boolean,
};

type ReturnValues = {
  values: Values,
  regions: Region[],
  countries: GetAnalyticsLocationCountryRes,
  handleChange: FormikHandlers['handleChange'],
  handleSearch: () => void,
  handleNext: () => void,
  handleFile: (flag: boolean) => void,
  handleSubmit: FormikHandlers['handleSubmit'],
  handleSelectRegion: (id: number) => void,
};

const PAGE_SIZE = 20;

const initialValues: Values = {
  search: '',
  page: 0,
  country__location_precise: 0,
  file_added: false,
};

export default function useBusinessLogin(): ReturnValues {
  const [countries, setCountries] = useState<GetAnalyticsLocationCountryRes>({
    results: [],
    count: 0,
  });

  const {
    data: regions,
    request: getRegions,
  } = useRequest<Region[]>();

  const {
    data: countriesResponse,
    request: getCountries,
  } = useRequest<GetAnalyticsLocationCountryRes>();

  const {
    values,
    handleChange,
    handleSubmit,
  } = useFormik<Values>({
    initialValues,
    onSubmit: (obj) => {
      const {
        search,
        page,
        country__location_precise,
        file_added,
      } = obj;
      const params: GetAnalyticsLocationCountryReq = {
        search,
        limit: PAGE_SIZE,
        offset: PAGE_SIZE * (page || 0),
        country__location_precise,
        file_added,
      };
      getCountries({
        method: 'get',
        url: URL.ANALYTIC.COUNTRIES.LIST,
        params: validateEmptyParams({
          params,
          emptyParamsToDelete: ['search', 'country__location_precise'],
        }),
      });
    },
  });

  useEffect(() => {
    getRegions({
      method: 'get',
      url: URL.ANALYTIC.COUNTRIES.LOCATION_PRECISE,
    });
    handleSubmit();
  }, []);

  const handleSelectRegion = useCallback((id: number) => {
    handleChange({
      target: {
        name: 'country__location_precise',
        value: id,
      },
    });
    handleChange({
      target: {
        name: 'page',
        value: 0,
      },
    });
    handleSubmit();
  }, []);

  useEffect(() => {
    if (countriesResponse?.results) {
      const { results, count: countResponse } = countriesResponse;

      setCountries((prevState) => {
        const arr = values.page ? prevState.results.concat(results) : results;
        return {
          results: arr,
          count: countResponse,
        };
      });
    }
  }, [countriesResponse]);

  const handleSearch = useDebounceCallback(() => {
    handleChange({
      target: {
        name: 'page',
        value: 0,
      },
    });

    handleSubmit();
  }, 500);

  const handleFile = useCallback((file_added: boolean) => {
    handleChange({
      target: {
        name: 'page',
        value: 0,
      },
    });
    handleChange({
      target: {
        name: 'file_added',
        value: file_added,
      },
    });
    handleSubmit();
  }, []);

  const handleNext = useCallback(() => {
    handleChange({
      target: {
        name: 'page',
        value: values.page + 1,
      },
    });
    handleSubmit();
  }, [values]);

  return {
    values,
    regions: regions || [],
    countries,
    handleSearch,
    handleChange,
    handleNext,
    handleFile,
    handleSubmit,
    handleSelectRegion,
  };
}
