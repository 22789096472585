import React, {
  FC,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IProfileSnake,
  RequestStatus,
  State,
  RoleUser,
} from 'types';
import { useFormik } from 'formik';
import { _t } from 'utils';
import { object, string } from 'yup';
import { meProfileUserAction } from 'store/me/actions';
import { ERRORS, URL } from 'appConstants';
import {
  PersonalTabLayout,
  PersonalTabLayoutLeft,
  PersonalTabLayoutRight,
} from 'containers';
import { ProfileLeftSide, ValueLine } from 'components';

type Props = {
  data: IProfileSnake | null,
  isEdit: boolean,
  token: string,
  onUpdate: () => void,
  onClick: () => void,
  title: string,
};

const initialValues: IProfileSnake = {
  id: null,
  company: null,
  checked_user: false,
  email: '',
  inn: '',
  ogrn: '',
  phone: '',
  position: '',
  role: 'E',
  user: '',
};

const validationSchema = object().shape({
  // inn: string().min(10, ERRORS.min(10)).required(ERRORS.required),
  // ogrn: string().min(13, ERRORS.min(13)).required(ERRORS.required),
  position: string().required(ERRORS.required),
  user: string().required(ERRORS.required),
  role: string().required(ERRORS.required),
  phone: string().required(ERRORS.required),
  email: string().min(5, ERRORS.email).required(ERRORS.required),
});

const ROLE = {
  [RoleUser.agent]: {
    text: 'Агент',
    slug: 'AGENT',
  },
  [RoleUser.export]: {
    text: 'Экспортер',
    slug: 'EXPORTER',
  },
  [RoleUser.buyer]: {
    text: 'Байер',
    slug: 'BUYER',
  },
  def: {
    text: 'Не указан',
    slug: 'NONE',
  },
};

const PersonalProfilePage: FC<Props> = ({
  data,
  isEdit,
  token,
  onUpdate,
  onClick,
  title,
}) => {
  const dispatch = useDispatch();
  const ProfileUserStatus = useSelector(({ ui }: State) => ui.ME_PROFILE_USER);
  // const token = useSelector(({ me }: State) => me.token);

  const {
    values,
    errors,
    touched,
    setErrors,
    handleChange,
    handleSubmit,
  } = useFormik<IProfileSnake>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: (obj) => {
      const formatObj:{
        token: string,
        data: IProfileSnake,
      } = {
        data: {
          ...obj,
        },
        token,
      };
      dispatch(meProfileUserAction(formatObj));
    },
  });

  useEffect(() => {
    if (ProfileUserStatus === RequestStatus.ERROR) {
      setErrors({
        inn: '',
        ogrn: '',
        phone: '',
        email: '',
        position: '',
        role: '',
      });
    }
  }, [ProfileUserStatus]);

  // const handleChangeSelect = useCallback((value) => {
  //   const e = {
  //     target: {
  //       name: 'role',
  //       value,
  //     },
  //   };

  //   handleChange(e);
  // }, []);

  const handleChangeWithMask = useCallback(({ target: { name, value } }) => {
    const event = {
      target: {
        name,
        value: value.replace(/_+$/, ''),
      },
    };

    handleChange(event);
  }, []);

  useEffect(() => {
    if (data) {
      Object.keys(data).forEach((key) => {
        // eslint-disable-next-line
        const value = (data as any)[key];
        if (value && key !== 'client_avatar') {
          handleChange({
            target: {
              name: key,
              value,
            },
          });
        }
      });
    }
  }, [data]);

  // return data && (
  return (
    <PersonalTabLayout>
      <PersonalTabLayoutLeft>
        <ProfileLeftSide
          userName={values.user || 'Имя не указано'}
          userRole={ROLE[values.role || 'def'].text}
          image={data?.client_avatar}
          token={token}
          uploadUrl={`${URL.PERSONAL.PROFILE.MAIN}/${data?.id}`}
          handleSubmit={handleSubmit}
          onUpdate={onUpdate}
          onClick={onClick}
          isEdit={isEdit}
        />
      </PersonalTabLayoutLeft>
      <PersonalTabLayoutRight
        tabTitle={title}
      >
        <form>
          <ValueLine
            name="user"
            label="Ответственный"
            value={values.user || ''}
            isEdit={isEdit}
            locked
            error={errors?.hasOwnProperty('user') && touched.user ? errors.user || true : ''}
            updValue={handleChange}
          />

          {/* <ValueLine
            name="role"
            label="Статус"
            // type="select"
            // options={OPTIONS_TYPE}
            value={values.role}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('role') && touched.role ? errors.role || true : ''}
            updValue={handleChange}
          /> */}
          <ValueLine
            name="role"
            label="Статус"
            value={_t(ROLE[values.role].text)}
            isEdit={isEdit}
            locked
            error={errors?.hasOwnProperty('role') && touched.role ? errors.role || true : ''}
            updValue={handleChange}
          />

          <ValueLine
            name="position"
            label="Должность"
            value={values.position}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('position') && touched.position ? errors.position || true : ''}
            updValue={handleChange}
          />
          <ValueLine
            name="email"
            label="Email"
            value={values.email}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('email') && touched.email ? errors.email || true : ''}
            updValue={handleChange}
          />
          <ValueLine
            name="inn"
            label="ИНН"
            value={values.inn}
            isEdit={isEdit}
            locked
            error={errors?.hasOwnProperty('inn') && touched.inn ? errors.inn || true : ''}
            updValue={handleChangeWithMask}
            mask="999999999999"
          />
          <ValueLine
            name="ogrn"
            label="ОГРН"
            value={values.ogrn}
            isEdit={isEdit}
            locked
            error={errors?.hasOwnProperty('ogrn') && touched.ogrn ? errors.ogrn || true : ''}
            updValue={handleChangeWithMask}
            mask="9999999999999"
          />
          <ValueLine
            name="phone"
            label="Телефон"
            value={values.phone}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('phone') && touched.phone ? errors.phone || true : ''}
            updValue={handleChangeWithMask}
            mask="+7 (999) 999-99-99"
          />
        </form>
      </PersonalTabLayoutRight>
    </PersonalTabLayout>
  );
};

export default PersonalProfilePage;
