import React, { FC } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Button } from 'components';
import type { State } from 'types';
import { history } from 'utils';
import {
  routes,
  showLoginOnProd,
} from 'appConstants';
import styles from './styles.module.scss';

type Props = {
  theme: 'light' | 'dark',
  className?: string,
};

const HeaderLoginBtn: FC<Props> = ({ theme, className }) => {
  const isAuth = useSelector(({ me }: State) => me.isAuth);

  return (
    <Button
      color={theme === 'dark' ? 'secondary' : 'primary'}
      className={cx(
        styles.login,
        className,
        { [styles.disabled]: !showLoginOnProd },
      )}
      onClick={() => history.push(routes.auth.root)}
      linkTo={routes.auth.root}
      title={isAuth ? 'Личный кабинет' : 'Войти'}
    />
  );
};

export default HeaderLoginBtn;
