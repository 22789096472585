/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import cx from 'classnames';

type Props = {
  className?: string,
  href?: string,
  newTab?: boolean,
  // onClick?: () => void,
};

const ExternalLink: FC<Props> = ({
  className,
  href,
  newTab = false,
  children,
  // onClick = () => {},
}) => (
  <a
    href={href}
    className={cx(className)}
    target={newTab ? '_blank' : ''}
    rel="noreferrer"
  >
    {children}
  </a>
);

export default ExternalLink;
