// author Victor K.

import React, { FC } from 'react';
import { getRTId } from 'utils';
import styles from './styles.module.scss';

type Props = {
  url?: string,
};

const RuTube:FC<Props> = ({
  url,
}) => (
  <div className={styles.playerWrap}>
    <iframe
      className={styles.iframeItem}
      title={url}
      width="720"
      height="405"
      src={url ? getRTId(url) : ''}
      frameBorder="0"
      allow="clipboard-write"
      allowFullScreen
    />
  </div>
);

export default RuTube;
