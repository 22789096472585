import React, {
  useEffect,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import {
  H4,
  Input,
  NavLink,
  CustomApplicationForm,
} from 'components';
import { useRequest } from 'hooks';
import { ERRORS, URL, routes } from 'appConstants';
import { toast } from 'react-toastify';
import { RequestStatus, State } from 'types';
import { _t } from 'utils';
import cx from 'classnames';
import styles from './styles.module.scss';

type Values = {
  name_of_company: string,
  full_name: string,
  email: string,
  phone: string,
  // eslint-disable-next-line
  attachedFile: any,
};

type Req = {
  name_of_company: string,
  full_name: string,
  email: string,
  phone: string,
};

const initialValues: Values = {
  name_of_company: '',
  full_name: '',
  email: '',
  phone: '',
  attachedFile: new File([], ''),
};

const validationSchema = object().shape({
  full_name: string().required(ERRORS.required),
  name_of_company: string().required(ERRORS.required),
  email: string().min(5, ERRORS.email).required(ERRORS.required),
  phone: string().min(18, ERRORS.phone).required(ERRORS.required),
  // attachedFile: object().required(ERRORS.required),
});

const PersonalAnalyticsRequest = () => {
  const token = useSelector(({ me }: State) => me.token);

  const {
    values,
    isValid,
    errors,
    touched,
    handleChange,
    handleSubmit,
    // setFieldValue,
    resetForm,
  } = useFormik<Values>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: ({
      name_of_company,
      full_name,
      email,
      phone,
      attachedFile,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    }) => sendData({
      url: URL.PERSONAL.ANALYTICS.SEND,
      method: 'post',
      data: {
        name_of_company,
        full_name,
        email,
        phone,
        attachedFile,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  });

  const { request: sendData, status } = useRequest<Req>({
    callback: () => {
      toast.success(_t('Данные отправлены!'));
      resetForm();
    },
    errback: () => {
      toast.error(_t('Ошибка отправки данных'));
    },
  });
  const { data: docsValid, request: docsReq } = useRequest();

  useEffect(() => {
    docsReq({
      method: 'GET',
      url: URL.PERSONAL.PROFILE.DOCUMENTS.IS_VALID,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }, []);

  const handleChangeWithMask = useCallback(({ target: { name, value } }) => {
    const event = {
      target: {
        name,
        value: value.replace(/_+$/, ''),
      },
    };

    handleChange(event);
  }, []);

  return (
    <CustomApplicationForm
      onSubmit={handleSubmit}
      openerName="Отправить заявку"
      titleName="Заявка на индивидуальные аналитические исследования"
      disabledSubmit={isValid && !isValid}
      // attachFile
      // attachOnChange={f}
      isLoading={status === RequestStatus.REQUEST}
      autoClose={status === RequestStatus.SUCCESS}
    >
      <H4 className={styles.H4}>{_t('Контактные данные')}</H4>

      <Input
        name="full_name"
        placeholder="Фамилия Имя Отчество"
        className={styles.input}
        error={errors.full_name && touched.full_name ? errors.full_name : ''}
        value={values.full_name}
        onChange={handleChange}
      />
      <Input
        name="name_of_company"
        placeholder="Название компании"
        className={styles.input}
        error={errors.name_of_company && touched.name_of_company ? errors.name_of_company : ''}
        value={values.name_of_company}
        onChange={handleChange}
      />
      <Input
        name="email"
        placeholder="Контактная почта"
        className={styles.input}
        error={errors.email && touched.email ? errors.email : ''}
        value={values.email}
        onChange={handleChange}
      />
      <Input
        name="phone"
        placeholder="Контактный телефон"
        className={styles.input}
        error={errors.phone && touched.phone ? errors.phone : ''}
        value={values.phone}
        onChange={handleChangeWithMask}
        mask="+7 (999) 999-99-99"
      />

      <H4 className={styles.H4}>{_t('Проверка документов')}</H4>

      <div
        className={cx(
          styles.docsDefault,
          {
            [styles.docsOk]: docsValid && docsValid?.[0]['doc_is_valid'],
          },
        )}
      >
        {docsValid ? (
          <>
            {
              docsValid?.[0]['doc_is_valid'] ?
                _t('Все документы действительны.') : (
                  <div>
                    {_t('Пожалуйста, проверьте действительность документов. Некоторые документы необходимо обновить.')}

                    <NavLink
                      className={styles.toDocs}
                      to={`${routes.personal.profile.documents.root}`}
                    >
                      {_t('Перейти в профиль')}
                    </NavLink>
                  </div>
                )
            }
          </>
        ) : (
          _t('Внутрення ошибка проверки документов')
        )}
      </div>
    </CustomApplicationForm>
  );
};

export default PersonalAnalyticsRequest;
