import React, {
  FC,
  ChangeEvent,
  ReactElement,
} from 'react';
import cx from 'classnames';
import { Icon, Text } from 'components';
import InputMask from 'react-input-mask';
import { _t } from 'utils';
import styles from './styles.module.scss';

type Props = {
  type?: 'text' | 'number' | 'search' | 'password' | 'file',
  label?: string,
  customLabel?: ReactElement,
  name: string,
  value?: string,
  placeholder?: string,
  className?: string,
  accept?: string,
  error?: boolean | string,
  multiple?: boolean,
  ref?: React.MutableRefObject<null>,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
  onSearch?: () => void,
  mask?: string,
};

const Input: FC<Props> = ({
  type = 'text',
  label = '',
  customLabel = undefined,
  name,
  value,
  placeholder = 'Введите значение',
  className,
  accept,
  error = false,
  multiple = false,
  ref,
  onChange = () => {},
  onSearch = () => {},
  mask,
}) => {
  return (
    <div className={cx(styles.wrap, className)}>
      {customLabel}
      {(label && !customLabel) && (
        <label htmlFor={name} className={styles.label}>{_t(label)}</label>
      )}
      <div className={styles.inputWrap}>
        {mask ? (
          <InputMask
            name={name}
            mask={mask}
            value={value}
            onChange={onChange}
            placeholder={_t(placeholder)}
            className={cx(
              styles.input,
              {
                [styles.error]: error,
              },
            )}
          />
        ) : (
          <>
            <input
              id={name}
              type={type}
              name={name}
              value={value}
              accept={accept}
              ref={ref}
              placeholder={_t(placeholder)}
              multiple={multiple}
              className={cx(
                styles.input,
                {
                  [styles.error]: error,
                },
              )}
              onChange={onChange}
              onKeyDown={(e) => {
                if (type === 'search' && e.key === 'Enter') {
                  onSearch();
                }
              }}
            />

            {type === 'search' && (
              <button
                type="button"
                className={styles.searchBtn}
                onClick={onSearch}
              >
                <Icon icon="search" />
              </button>
            )}
          </>
        )}

        { error && (
          <Text
            size="small"
            color="error"
            align="left"
            className={styles.errorImgText}
          >
            {error}
          </Text>
        )}
      </div>
    </div>
  );
};

export default Input;
