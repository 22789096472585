import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { routes } from 'appConstants';
import {
  PublicAddRoute,
  AnalyticsList,
  AnalyticsRegionalExecute,
  AnalyticsCountries,
  AnalyticsIndustry,
  AnalyticsIndustriesDetails,
} from 'containers';
import styles from './styles.module.scss';

const crumbs = [
  {
    to: routes.analytics.root,
    label: routes.analytics.title,
  },
  {
    to: routes.analytics.industry.root,
    label: 'Аналитические исследования по индустриям',
  },
];

const Analytics = () => (
  <Switch>
    <PublicAddRoute
      path={routes.analytics.root}
      item={routes.analytics}
      exact
      component={AnalyticsList}
    />

    <PublicAddRoute
      path={routes.analytics.regionalExecute.root}
      item={routes.analytics.regionalExecute}
      exact
      component={AnalyticsRegionalExecute}
    />

    <PublicAddRoute
      path={routes.analytics.countries.root}
      item={routes.analytics.countries}
      exact
      component={AnalyticsCountries}
    />

    <PublicAddRoute
      path={routes.analytics.industry.root}
      item={routes.analytics.industry}
      exact
      component={AnalyticsIndustry}
    />

    <PublicAddRoute
      path={`${routes.analytics.industry.root}/:id`}
      exact
      render={() => (
        <AnalyticsIndustriesDetails crumbs={crumbs} className={styles.details} />
      )}
    />

    <Redirect to={routes.analytics.root} />
  </Switch>
);

export default Analytics;
