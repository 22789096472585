import React, { FC, ChangeEvent, ReactNode } from 'react';
import cx from 'classnames';
// import { _t } from 'utils';
import { Text } from 'components';
import styles from './styles.module.scss';

type Props = {
  name: string,
  checked?: boolean,
  error?: boolean | string,
  label?: string | ReactNode,
  disabled?: boolean,
  forAdept?: boolean,
  className?: string,
  onChange?: (e: ChangeEvent<HTMLInputElement>, value: boolean) => void,
};

const Checkbox: FC<Props> = ({
  name,
  checked = false,
  error = '',
  label = '',
  disabled = false,
  forAdept = false,
  className,
  onChange = () => {},
}) => (
  <div className={cx(styles.wrap, className)}>
    <label
      htmlFor={name}
      className={cx(
        styles.checkbox,
        { [styles.forAdept]: forAdept },
      )}
    >
      <input
        type="checkbox"
        disabled={disabled}
        id={name}
        checked={checked}
        className={cx(
          styles.input,
          {
            [styles.errorCheckbox]: error,
          },
        )}
        onChange={(e) => onChange(e, !checked)}
      />
      <span
        className={cx(
          styles.checkmark,
          {
            [styles.error]: error,
          },
        )}
        onClick={() => {
          // eslint-disable
          sessionStorage.setItem('eventsScrollTop', String(window.scrollY));
        }}
      />
      {label && (
        <span
          className={cx(
            styles.text,
            {
              [styles.error]: error,
            },
          )}
        >
          {label}
        </span>
      )}
    </label>
    {error && <Text className={styles.errorText} color="error" size="tiny" tag="span">* {error}</Text>}
  </div>
);

export default Checkbox;
