import React, { useCallback, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import SidebarButton from 'containers/Sidebar/SidebarButton';
import { Sidebar } from 'containers';
import { routes } from 'appConstants';
import PersonalFeed from './PersonalFeed';
import PersonalNotifications from './PersonalNotifications';
import PersonalCalendar from './PersonalCalendar';
import PersonalProfile from './PersonalProfile';
import PersonalExchange from './PersonalExchange';
import PersonalCatalog from './PersonalCatalog';
import PersonalLeads from './PersonalLeads';
import PersonalResolutions from './PersonalResolutions';
import PersonalAnalytics from './PersonalAnalytics';
import PersonalGoods from './PersonalGoods';
import PersonalFaq from './PersonalFaq';
// import cx from 'classnames';
import styles from './styles.module.scss';

const IS_OPEN_SIDEBAR: boolean = sessionStorage.getItem('isOpenSidebar') === 'true';

const Personal = () => {
  const [isOpen, setOpen] = useState<boolean>(IS_OPEN_SIDEBAR);

  const handleOpen = useCallback(() => {
    setOpen((state) => {
      sessionStorage.setItem('isOpenSidebar', String(!state));
      return !state;
    });
  }, [setOpen]);

  return (
    <div className={styles.layout}>
      <div className={styles.body}>
        <Sidebar
          isOpen={isOpen}
        >
          <SidebarButton
            isOpen={isOpen}
            onClick={handleOpen}
            className={styles.btnMenu}
          />
        </Sidebar>
        <main className={styles.main}>
          <Switch>
            <Route exact path={routes.personal.feed.root} component={PersonalFeed} />
            <Route path={routes.personal.notifications.root} component={PersonalNotifications} />
            <Route path={routes.personal.profile.root} component={PersonalProfile} />
            <Route path={routes.personal.calendar.root} component={PersonalCalendar} />
            <Route path={routes.personal.catalog.root} component={PersonalCatalog} />
            <Route path={routes.personal.leads.root} component={PersonalLeads} />
            <Route path={routes.personal.resolutions.root} component={PersonalResolutions} />
            <Route path={routes.personal.goods.root} component={PersonalGoods} />
            <Route path={routes.personal.analytics.root} component={PersonalAnalytics} />
            <Route path={routes.personal.faq.root} component={PersonalFaq} />
            <Route path={routes.personal.exchange.root} component={PersonalExchange} />
            <Redirect to={{ pathname: routes.personal.feed.root }} />
          </Switch>
        </main>
      </div>
    </div>
  );
};

export default Personal;
