import React, { FC, ReactNode } from 'react';
import { _t } from 'utils';
import cx from 'classnames';
import { Text } from 'components';
import styles from './styles.module.scss';

type Props = {
  title: string,
  subTitle?: string,
  className?: string,
  items: Array<{
    id: number,
    value: ReactNode,
    label: string,
  }>,
};

const MainNumbersBlock: FC<Props> = ({
  title,
  subTitle,
  className,
  items,
}) => (
  <div className={cx(styles.wrap, className)}>
    <div className={styles.name}>
      <Text uppercase>{_t(title)}</Text>
      {subTitle && <Text color="extra" size="tiny" className={styles.subTitle}>{_t(subTitle)}</Text>}
    </div>
    <ul className={styles.list}>
      {items.map(({ id, label, value }) => (
        <li key={id} className={styles.item}>
          {value}
          <Text className={styles.label} size="small">{label}</Text>
        </li>
      ))}
    </ul>
  </div>
);

export default MainNumbersBlock;
