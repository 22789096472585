import React, { FC, useMemo } from 'react';
import { ReactComponent as Diagram } from 'assets/img/adept-res/--img-diag.svg';
import { IAdeptItem, TAdeptScores } from 'types';
import styles from './styles.module.scss';

type Props = {
  dimNormalized?: TAdeptScores,
  poorResults: IAdeptItem[],
};

const DOTS = [
  (dot: number) => ({ x: '407', y: `${322 - (280 * dot)}` }),
  (dot: number) => ({ x: `${407 + (218 * dot)}`, y: `${322 - (177 * dot)}` }),
  (dot: number) => ({ x: `${407 + (272 * dot)}`, y: `${322 + (59 * dot)}` }),
  (dot: number) => ({ x: `${407 + (121 * dot)}`, y: `${322 + (250 * dot)}` }),
  (dot: number) => ({ x: `${407 - (121 * dot)}`, y: `${322 + (250 * dot)}` }),
  (dot: number) => ({ x: `${407 - (272 * dot)}`, y: `${322 + (59 * dot)}` }),
  (dot: number) => ({ x: `${407 - (218 * dot)}`, y: `${322 - (177 * dot)}` }),
];

const DiagramBlock:FC<Props> = ({
  dimNormalized,
  poorResults,
}) => useMemo(() => {
  const Dots = () => {
    if (dimNormalized) {
      return (
        <div className={styles.resultsOnDiag}>
          <svg
            width="916"
            height="601"
            viewBox="0 0 916 601"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke="#B71A3C"
              fill="#B71A3C"
              fillOpacity="0.1"
              strokeWidth="3"
              d={`M${DOTS[0](poorResults[0].low).x} ${DOTS[0](poorResults[0].low).y}
                L${DOTS[1](poorResults[1].low).x} ${DOTS[1](poorResults[1].low).y}
                L${DOTS[2](poorResults[2].low).x} ${DOTS[2](poorResults[2].low).y}
                L${DOTS[3](poorResults[3].low).x} ${DOTS[3](poorResults[3].low).y}
                L${DOTS[4](poorResults[4].low).x} ${DOTS[4](poorResults[4].low).y}
                L${DOTS[5](poorResults[5].low).x} ${DOTS[5](poorResults[5].low).y}
                L${DOTS[6](poorResults[6].low).x} ${DOTS[6](poorResults[6].low).y}Z`}
            />
            {DOTS.map((dotPos, id) => (
              <circle
                key={id.toString()}
                cx={dotPos(poorResults[id].low).x}
                cy={dotPos(poorResults[id].low).y}
                r="6"
                fill="white"
                stroke="#B71A3C"
                strokeWidth="3"
              />
            ))}

            <path
              stroke="#57627A"
              strokeWidth="3"
              d={`M${DOTS[0](dimNormalized.d1).x} ${DOTS[0](dimNormalized.d1).y}
                L${DOTS[1](dimNormalized.d2).x} ${DOTS[1](dimNormalized.d2).y}
                L${DOTS[2](dimNormalized.d3).x} ${DOTS[2](dimNormalized.d3).y}
                L${DOTS[3](dimNormalized.d4).x} ${DOTS[3](dimNormalized.d4).y}
                L${DOTS[4](dimNormalized.d5).x} ${DOTS[4](dimNormalized.d5).y}
                L${DOTS[5](dimNormalized.d6).x} ${DOTS[5](dimNormalized.d6).y}
                L${DOTS[6](dimNormalized.d7).x} ${DOTS[6](dimNormalized.d7).y}Z`}
            />
            {DOTS.map((dotPos, id) => (
              <circle
                key={id.toString()}
                cx={dotPos(dimNormalized[`d${id + 1}`]).x}
                cy={dotPos(dimNormalized[`d${id + 1}`]).y}
                r="6"
                fill="white"
                stroke="#57627A"
                strokeWidth="3"
              />
            ))}
          </svg>
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className={styles.diagram}>
      <Diagram />

      {dimNormalized && <Dots />}
    </div>
  );
}, [dimNormalized]);

export default DiagramBlock;
