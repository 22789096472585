import React, {
  FC,
// useState,
// useCallback,
} from 'react';
import {
  IDocument,
  IPresentation,
} from 'types';
import {
  ProfileMainDocuments,
  ProfilePresentations,
} from 'components';
import { _t } from 'utils';
import styles from './styles.module.scss';

interface Props {
  documents: Array<IDocument>,
  presentations: Array<IPresentation>,
  companyID?: number,
  token: string,
  onFilesUpdate?: () => void,
}

const PersonalDocumentsTab: FC<Props> = ({
  documents,
  presentations,
  companyID = 0,
  token,
  onFilesUpdate,
}) => {
  return (
    <div>
      {documents && (
        <div className={styles.data}>
          <div className={styles.title}>{_t('Основные документы')}</div>
          <ProfileMainDocuments
            token={token}
            data={documents}
            onFilesUpdate={onFilesUpdate}
          />
        </div>
      )}

      {presentations && (
        <div className={styles.data}>
          <div className={styles.title}>{_t('Презентационные материалы')}</div>
          <ProfilePresentations
            token={token}
            companyID={companyID}
            data={presentations}
            onFilesUpdate={onFilesUpdate}
          />
        </div>
      )}
    </div>
  );
};

export default PersonalDocumentsTab;
