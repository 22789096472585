import React, { FC } from 'react';
import cx from 'classnames';
import { IPresentation } from 'types';
import { useRequest } from 'hooks';
import { FileUploader } from 'components';
import { URL } from 'appConstants';
import { _t } from 'utils';
import styles from './styles.module.scss';

interface Props {
  data: IPresentation[],
  companyID: number,
  token: string,
  onFilesUpdate?: () => void,
}

const digitsFormat = (val:string | number = 0, digits:number = 2) => {
  let id = String(val);
  while (id.length < digits) (id = `0${id}`);
  return id;
};

const ProfilePresentations: FC<Props> = ({
  data,
  companyID,
  token,
  onFilesUpdate,
}) => {
  const { data: testD, request } = useRequest({
    callback: () => {
      if (onFilesUpdate) onFilesUpdate();
    },
  });

  const deleteItem = (id:string | number) => {
    request({
      method: 'DELETE',
      url: `${URL.PERSONAL.PROFILE.PRESENTATIONS}/${id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return testD;
  };

  return (
    <div className={styles.presData}>
      {data.map((item, key) => (
        <div
          key={`line_${item.id}`}
          className={styles.presItem}
        >
          <div className={styles.itemHeader}>
            <span className={styles.idItem}>
              {digitsFormat((key + 1))}
            </span>
            <div
              className={styles.deleteItem}
              onClick={() => deleteItem(item.id)}
            >
              {_t('Удалить')}
            </div>
          </div>
          {item.title}
        </div>
      ))}
      <FileUploader
        title="Добавить файл"
        format="Формат файла: pdf"
        size="Максимальный размер файла: 30mb"
        className={cx(
          styles.presItem,
          styles.addNewItem,
        )}
        token={token}
        companyID={companyID}
        method="POST"
        uploadUrl={`${URL.PERSONAL.PROFILE.PRESENTATIONS}/`}
        setFileName
        onFilesUpdate={onFilesUpdate}
      />
    </div>
  );
};

export default ProfilePresentations;
