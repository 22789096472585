import React from 'react';
import { Text, ApplicationForm } from 'components';
import { _t } from 'utils';
import styles from './styles.module.scss';

const ServicesAnalytics = () => (
  <>
    <div className={styles.blockTexts}>
      <Text className={styles.description}>
        {_t('Исследования международных рынков являются ключом к экспорту...')}
      </Text>

      {/* <Text className={styles.description}>
        {_t('Центр «Моспром» выпускает комплексные руководства по международным рынкам, в
        которые входят макропоказатели, динамика, особенности деловой культуры, правила
        ведения бизнеса и возможности для столичного бизнеса.')}
      </Text>

      <Text className={styles.description}>
        {_t('Московские компании также могут получить доступ к нишевым или индивидуальным
        маркетинговым исследованиям.')}
      </Text> */}
    </div>

    <ApplicationForm />
  </>
);

export default ServicesAnalytics;
