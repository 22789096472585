/* eslint-disable no-irregular-whitespace */
import React from 'react';
import { Text, ApplicationForm, HTMLContent } from 'components';
import { _t } from 'utils';
import styles from './styles.module.scss';

const ServicesSupport = () => (
  <>
    <Text className={styles.description}>
      <HTMLContent content={_t('Специалисты Центра оказывают помощь по базовым и практическим...')} />
    </Text>

    <ul className={styles.list}>
      <li className={styles.item}>
        {_t('Московским производителям, которые находятся на более ранней стадии своего развития за рубежом...')}
      </li>
      <li className={styles.item}>
        {_t('Действующим экспортерам Москвы, которые хотят увеличить свое присутствие на международной арене...')}
      </li>
    </ul>

    <ApplicationForm />
  </>
);

export default ServicesSupport;
