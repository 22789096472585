import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useRequest } from 'hooks';
import {
  URL,
  routes,
} from 'appConstants';
import {
  PersonalResolutions,
  RequestStatus,
  State,
} from 'types';
import {
  PersonalTabLayout,
  PersonalTabLayoutLeft,
  PersonalTabLayoutRight,
  ResolutionsListCard,
} from 'containers';
import {
  HTMLContent,
  Paginate,
  Preloader,
} from 'components';
import { _t } from 'utils';
import tabImg from 'assets/img/personal/resolutions.svg';
import PersonalResolutionsRequest from '../PersonalResolutionsRequest';
import styles from './styles.module.scss';
import './styles.scss';

type Props = {};

const PAGE_SIZE = 8;

interface Params {
  page: number,
}

const initialValues:Params = {
  page: 0,
};

const PersonalResolutionsMainTab: FC<Props> = () => {
  const [noData, setNoData] = useState<boolean>(false);
  const {
    data: resolutions,
    status,
    request: getResolutions,
  } = useRequest<Array<PersonalResolutions>>();
  const token = useSelector(({ me }: State) => me.token);

  const targetDetailsPath = routes.personal.resolutions.root;

  const GetData = (p:number) => getResolutions({
    method: 'GET',
    url: URL.PERSONAL.RESOLUTIONS.GET,
    headers: { Authorization: `Bearer ${token}` },
    params: {
      limit: PAGE_SIZE,
      offset: p * PAGE_SIZE,
    },
  });

  const {
    values,
    handleChange,
    handleSubmit,
  } = useFormik<Params>({
    initialValues,
    enableReinitialize: true,
    onSubmit: (params) => {
      const {
        page,
      } = params;
      GetData(page);
    },
  });

  useEffect(() => {
    GetData(values.page);
  }, []);

  useEffect(() => {
    setNoData((!resolutions || !resolutions.length));
  }, [resolutions]);

  return (
    <PersonalTabLayout>
      <PersonalTabLayoutLeft
        tabTitle="Получите индивидуальные меры поддержки"
        image={tabImg}
      >
        <PersonalResolutionsRequest />
      </PersonalTabLayoutLeft>
      <PersonalTabLayoutRight
        tabTitle="Меры поддержки"
      >
        <Preloader isLoading={status === RequestStatus.REQUEST}>
          {(!noData && !!resolutions?.length) && (
            <div>
              <div className={styles.cards}>
                {resolutions?.map((item) => (
                  <ResolutionsListCard
                    key={item.resolution.id}
                    value={item.resolution}
                    className={styles.card}
                    targetDetailsPath={targetDetailsPath}
                  />
                ))}
              </div>
              <Paginate
                page={values.page}
                count={resolutions?.length || 0}
                pageSize={PAGE_SIZE}
                onChange={(value) => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  handleChange({
                    target: {
                      name: 'page',
                      value,
                    },
                  });
                  handleSubmit();
                }}
              />
            </div>
          )}
          {noData && (
            <HTMLContent
              className={styles.noData}
              content={`${_t('На данный момент нет добавленных мер поддержки. Вы можете добавить их, перейдя на сайт, в раздел')} 
              <a href=${routes.resolutions.root} target="_blank">${_t('библиотека экспортера')}</a>.`}
            />
          )}
        </Preloader>
      </PersonalTabLayoutRight>
    </PersonalTabLayout>
  );
};

export default PersonalResolutionsMainTab;
