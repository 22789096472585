import React, { FC, Fragment } from 'react';
import cx from 'classnames';
import { _t } from 'utils';
import { Link, Text } from 'components';
import styles from './styles.module.scss';

type Crumb = {
  label: string,
  to: string,
};

type Props = {
  crumbs: Crumb[],
  className?: string,
};

const BreadCrumbs: FC<Props> = ({ crumbs, className }) => (
  <nav className={cx(className)}>
    {crumbs.map((crumb, index) => (
      <Fragment key={''.concat(index.toString())}>
        { (crumbs.length !== index + 1) ? (
          <Link
            key={''.concat(index.toString())}
            to={crumb.to || '/'}
            className={styles.link}
          >
            <Text size="tiny" tag="span" color="extra">{_t(crumb.label)}</Text>
            {(crumbs.length !== index + 1) && <span className={styles.divider}>/</span>}
          </Link>
        )
          :
          <Text size="tiny" tag="span">{_t(crumb.label)}</Text>}
      </Fragment>
    ))}
  </nav>
);

export default BreadCrumbs;
