import React, { FC, useState } from 'react';
import { Globe } from 'components';
import { MainLayout } from 'containers';
import { useWindowResize } from 'hooks';
import Sidebar from './AnalyticsCountriesSidebar';
import styles from './styles.module.scss';

type Props = {};

const AnalyticsCountries: FC<Props> = () => {
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const { layout } = useWindowResize({});

  return (
    <MainLayout
      title="Аналитика по странам"
      className={styles.layout}
    >
      <Sidebar
        className={styles.aside}
        onSelectedCountry={setSelectedCountry}
      />
      {['FULL', 'DESKTOP_BIG'].includes(layout) && (
        <Globe selectedCountry={selectedCountry} className={styles.globe} />
      )}
    </MainLayout>
  );
};

export default AnalyticsCountries;
