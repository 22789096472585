import React, { FC } from 'react';
import { IAdeptItem, TAdeptScores, TText } from 'types';
import { getAdeptLevel } from 'utils';
import cx from 'classnames';
import { HTMLContent, RadialChart } from 'components';
import { qTexts } from '../qTexts';
import { vTexts } from '../vTexts';
import BlockTitle from '../BlockTitle';
import styles from './styles.module.scss';

type Props = {
  kind: 'qualification' | 'validation';
  dimNormalized?: TAdeptScores,
  dimValues?: TAdeptScores,
  legend: IAdeptItem[],
};

const TestTexts = {
  qualification: qTexts,
  validation: vTexts,
};

const Recommendation:FC<Props> = ({
  kind,
  dimNormalized,
  dimValues,
  legend,
}) => {
  const Tip:FC<{
    text?: string,
  }> = ({
    text,
  }) => (
    <div className={styles.tip}>
      {text}
    </div>
  );

  const insertValue = (str: string, number: string | number = '') => {
    return str.replace('{value}', number.toString());
  };

  const isInRange = (text: TText, { toRes }: IAdeptItem) => dimValues &&
    (text.from <= dimValues[toRes] && dimValues[toRes] < text.to);

  return (
    <div>
      <div id="ForPDF_Page3_title">
        <BlockTitle
          title="Результаты и рекомендации в разрезе измерений"
        />
      </div>

      <div className={styles.recList}>
        {legend.map((item, legendId) => dimNormalized && (
          <div
            className={cx(
              styles.rec,
              styles[getAdeptLevel(item, dimNormalized[item.toRes])],
            )}
            key={`recs_${legendId.toString()}`}
            id={`recomend_${legendId.toString()}`}
          >
            <div className={styles.score}>
              <h4>{legendId + 1}. {item.title}</h4>
              <div className={styles.roundScore}>
                <RadialChart
                  cur={dimNormalized[item.toRes].toFixed(2)}
                  level={getAdeptLevel(item, dimNormalized[item.toRes])}
                  descr="оценка по измерению"
                  className={styles.scoreText}
                />
              </div>
            </div>

            <div className={styles.text}>
              {TestTexts[kind][item.toRes].map((qText) => (
                isInRange(qText, item) && qText.text.map((text, textId) => (
                  <div key={`qText_${textId.toString()}`}>
                    {text.p && (
                      <HTMLContent
                        content={insertValue(text.p, dimNormalized[item.toRes].toFixed(2))}
                      />
                    )}
                    {text.tips && text.tips.map((tip, tipId) => (
                      <Tip
                        key={`tip_${tipId.toString()}`}
                        text={tip}
                      />
                    ))}
                  </div>
                ))
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Recommendation;
