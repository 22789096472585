import React, { FC } from 'react';
import cx from 'classnames';
import {
  Section,
  Text,
  ButtonCircle,
} from 'components';
import { _t } from 'utils';
import { routes } from 'appConstants';
import { Link } from 'react-router-dom';
import map from 'assets/img/map.svg';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const MainAnalytic:FC<Props> = ({
  className,
}) => (
  <Section className={cx(className)}>
    <Text use="title">{_t('Аналитические исследования')}</Text>

    <div className={styles.row}>
      <div className={cx(styles.column, styles.imgWrap)}>
        <img
          className={styles.img}
          src={map}
          alt={map}
        />
      </div>
      <div className={cx(styles.column, styles.textWrap)}>
        <Text className={styles.text} bold size="small">
          {_t('Исследования международных рынков являются ключом к экспорту...')}
        </Text>
        <Link to={routes.analytics.root} title={routes.analytics.title}>
          <ButtonCircle>{_t('Подробнее')}</ButtonCircle>
        </Link>
      </div>
    </div>
  </Section>
);

export default MainAnalytic;
