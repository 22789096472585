import React, {
  FC,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ICompanySnake,
  RequestStatus,
  ICompany,
  State,
} from 'types';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { meProfileCompanyAction } from 'store/me/actions';
import { ERRORS, URL } from 'appConstants';
import {
  PersonalTabLayout,
  PersonalTabLayoutLeft,
  PersonalTabLayoutRight,
} from 'containers';
import {
  ProfileLeftSide,
  ValueLine,
} from 'components';
import { _t } from 'utils';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  data: ICompany | null,
  compId?: number,
  isEdit: boolean,
  token: string,
  onUpdate: () => void,
  onClick: () => void,
  title: string,
};

const initialValues: ICompanySnake = {
  id: 0,
  name: '',
  full_name: '',
  status: '',
  full_name_eng: '',
  region: '',
  sector: 'A',
  type_of_activity: '',
  description_types_of_activity: '',
  okved: '',
  tnved: '',
  legal_address: '',
  actual_address: '',
  inn: '',
  ogrn: '',
  kpp: '',
  web_site: '',
  work_phone: '',
  email: '',
  director: '',
  director_position: '',
};

const validationSchema = object().shape({
  name: string().required(ERRORS.required),
  // inn: string().min(10, ERRORS.min(10)).required(ERRORS.required),
  // ogrn: string().min(13, ERRORS.min(13)).required(ERRORS.required),
  // position: string().required(ERRORS.required),
  // role: string().required(ERRORS.required),
  // phone: string().required(ERRORS.required),
  // email: string().min(5, ERRORS.email).required(ERRORS.required),
});

const PersonalProfile: FC<Props> = ({
  data,
  isEdit,
  onUpdate,
  onClick,
  token,
  title,
}) => {
  const dispatch = useDispatch();
  const ProfileCompanyStatus = useSelector(({ ui }: State) => ui.ME_PROFILE_COMPANY);

  const {
    values,
    errors,
    touched,
    setErrors,
    handleChange,
    handleSubmit,
  } = useFormik<ICompanySnake>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: (obj) => {
      const formatObj:{
        token: string,
        data: ICompanySnake,
      } = {
        data: {
          ...obj,
        },
        token,
      };
      dispatch(meProfileCompanyAction(formatObj));
    },
  });

  useEffect(() => {
    if (ProfileCompanyStatus === RequestStatus.ERROR) {
      setErrors({
        actual_address: '',
        description_types_of_activity: '',
        director: '',
        director_position: '',
        email: '',
        full_name: '',
        full_name_eng: '',
        inn: '',
        kpp: '',
        legal_address: '',
        name: '',
        okved: '',
        ogrn: '',
        region: '',
        status: '',
        tnved: '',
        type_of_activity: '',
        web_site: '',
        sector: '',
        work_phone: '',
      });
    }
  }, [ProfileCompanyStatus]);

  const handleChangeWithMask = useCallback(({ target: { name, value } }) => {
    const event = {
      target: {
        name,
        value: value.replace(/_+$/, ''),
      },
    };

    handleChange(event);
  }, []);

  useEffect(() => {
    if (data) {
      Object.keys(values).forEach((key) => {
        // eslint-disable-next-line
        const value = (data as any)[key];
        if (value) {
          handleChange({
            target: {
              name: key,
              value,
            },
          });
        }
      });
    }
  }, [data]);

  return (
    <PersonalTabLayout>
      <PersonalTabLayoutLeft>
        <ProfileLeftSide
          userName={values.name || 'Название не указано'}
          userRole={values.type_of_activity || 'Отрасль не указана'}
          image={data?.company_logo}
          fileFieldName="company_logo"
          uploadUrl={`${URL.PERSONAL.PROFILE.COMPANY}/${data?.id}/`}
          token={token}
          handleSubmit={handleSubmit}
          onUpdate={onUpdate}
          onClick={onClick}
          isEdit={isEdit}
        />
      </PersonalTabLayoutLeft>
      <PersonalTabLayoutRight
        tabTitle={title}
      >
        <form className={styles.form}>
          <div className={styles.dataTitle}>{_t('Основные данные')}</div>
          <ValueLine
            name="director"
            label="Ответственный"
            value={values.director}
            locked
            isEdit={isEdit}
            error={errors?.hasOwnProperty('director') && touched.director ? errors.director || true : ''}
            updValue={handleChange}
          />
          <ValueLine
            name="name"
            label="Наименование компании"
            value={values.name}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('name') && touched.name ? errors.name || true : ''}
            updValue={handleChange}
          />
          <ValueLine
            name="full_name"
            label="Полное наименование компании"
            value={values.full_name}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('full_name') && touched.full_name ? errors.full_name || true : ''}
            updValue={handleChange}
          />
          <ValueLine
            name="full_name_eng"
            label="Полное наименование на английском"
            value={values.full_name_eng}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('full_name_eng') && touched.full_name_eng ? errors.full_name_eng || true : ''}
            updValue={handleChange}
          />
          <ValueLine
            name="status"
            label="Статус компании"
            value={values.status}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('status') && touched.status ? errors.status || true : ''}
            updValue={handleChange}
          />
          <ValueLine
            name="region"
            label="Регион организации"
            value={values.region}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('region') && touched.region ? errors.region || true : ''}
            updValue={handleChange}
          />
          <ValueLine
            name="date_of_registration"
            label="Дата регистрации"
            value={values?.date_of_registration || 'не указана'}
            isEdit={isEdit}
            locked
            error={errors?.hasOwnProperty('date_of_registration') && touched.date_of_registration ? errors.date_of_registration || true : ''}
            updValue={() => {}}
          />
          <ValueLine
            name="sector"
            label="Сектор АПК/ПК"
            value={values.sector}
            locked
            isEdit={isEdit}
            error={errors?.hasOwnProperty('sector') && touched.sector ? errors.sector || true : ''}
            updValue={handleChange}
          />
          <ValueLine
            name="type_of_activity"
            label="Виды деятельности/отрасль"
            value={values.type_of_activity}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('type_of_activity') && touched.type_of_activity ? errors.type_of_activity || true : ''}
            updValue={handleChange}
          />
          <ValueLine
            name="description_types_of_activity"
            label="Описание деятельности"
            value={values.description_types_of_activity}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('description_types_of_activity') && touched.description_types_of_activity ? errors.description_types_of_activity || true : ''}
            updValue={handleChange}
          />
          <ValueLine
            name="okved"
            label="Коды ОКВЭД"
            value={values.okved}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('okved') && touched.okved ? errors.okved || true : ''}
            updValue={handleChange}
          />
          <ValueLine
            name="tnved"
            label="Коды ТНВЭД"
            value={values.tnved}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('tnved') && touched.tnved ? errors.tnved || true : ''}
            updValue={handleChange}
          />

          <div
            className={cx(
              styles.dataTitle,
              styles.pb,
            )}
          >
            {_t('Реквизиты')}
          </div>
          <ValueLine
            name="legal_address"
            label="Юридический адрес орг-ции"
            value={values.legal_address}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('legal_address') && touched.legal_address ? errors.legal_address || true : ''}
            updValue={handleChange}
          />
          <ValueLine
            name="actual_address"
            label="Фактический адрес орг-ции"
            value={values.actual_address}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('actual_address') && touched.actual_address ? errors.actual_address || true : ''}
            updValue={handleChange}
          />
          <ValueLine
            name="inn"
            label="ИНН организации"
            value={values.inn}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('inn') && touched.inn ? errors.inn || true : ''}
            updValue={handleChangeWithMask}
            mask="999999999999"
          />
          <ValueLine
            name="ogrn"
            label="ОГРН организации"
            value={values.ogrn}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('ogrn') && touched.ogrn ? errors.ogrn || true : ''}
            updValue={handleChangeWithMask}
            mask="9999999999999"
          />
          <ValueLine
            name="kpp"
            label="КПП организации"
            value={values.kpp}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('kpp') && touched.kpp ? errors.kpp || true : ''}
            updValue={handleChange}
          />

          <div
            className={cx(
              styles.dataTitle,
              styles.pb,
            )}
          >
            {_t('Контакты')}
          </div>
          <ValueLine
            name="web_site"
            label="Веб-сайт"
            value={values.web_site}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('web_site') && touched.web_site ? errors.web_site || true : ''}
            updValue={handleChange}
          />
          <ValueLine
            name="work_phone"
            label="Рабочий телефон"
            value={values.work_phone}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('work_phone') && touched.work_phone ? errors.work_phone || true : ''}
            updValue={handleChange}
          />
          <ValueLine
            name="email"
            label="E-mail"
            value={values.email}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('email') && touched.email ? errors.email || true : ''}
            updValue={handleChange}
          />
          <ValueLine
            name="director"
            label="ФИО руководителя"
            value={values.director}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('director') && touched.director ? errors.director || true : ''}
            updValue={handleChange}
          />
          <ValueLine
            name="director_position"
            label="Должность руководителя"
            value={values.director_position}
            isEdit={isEdit}
            error={errors?.hasOwnProperty('director_position') && touched.director_position ? errors.director_position || true : ''}
            updValue={handleChange}
          />
        </form>
      </PersonalTabLayoutRight>
    </PersonalTabLayout>
  );
};

export default PersonalProfile;
