import React, {
  useEffect,
} from 'react';
import { setPageMeta, routes } from 'appConstants';
import { Services as Container } from 'containers';

const Services = () => {
  useEffect(() => {
    setPageMeta(routes.services.title, routes.services.descr);
  });

  return <Container />;
};

export default Services;
