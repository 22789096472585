import { AxiosRequestConfig } from 'axios';
import { CallEffect, PutEffect } from 'redux-saga/effects';
import api from 'store/api';
import { store } from 'store/configureStore';

export default function apiTestSaga(
  requestConfig: AxiosRequestConfig,
): Generator<CallEffect | PutEffect> {
  return api({ ...requestConfig, headers: { Authorization: `Bearer ${store.getState().me.token}` } });
}
