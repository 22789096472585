import React, { FC } from 'react';
import styles from './styles.module.scss';

const Loader: FC = () => (
  <div className={styles.loaderOuter}>
    <div className={styles.loaderInner} />
  </div>
);

export { Loader };
