import { put, takeLatest, call } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { toast } from 'react-toastify';
import api from 'store/api';
import { URL } from 'appConstants';
import {
  _t,
  history,
  camelCaseToSnakeCase,
  snakeCaseToCamelCase,
} from 'utils';
import type { Response, IExportTestResSnake } from 'types';
import actionTypes, { SignUpAction as Action } from '../actionTypes';

function* exportTestRequire({ type, payload }: Action) {
  try {
    yield put(apiActions.request(type));

    const { data }: Response<IExportTestResSnake> = yield call(api, {
      method: 'post',
      url: URL.FORMS.EXPORT_TEST,
      data: camelCaseToSnakeCase(payload!),
    });

    yield put(apiActions.success(type, snakeCaseToCamelCase(data)));
    history.goBack();
  } catch (err) {
    toast.error(_t(err.response.data.detail));
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.ME_EXPORT_TEST, exportTestRequire);
}
