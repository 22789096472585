export default {
  DADATA: {
    URL: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party',
    TOKEN: '96ca2347252f2ebe3ec129f40208626fcc67b7d5', // CFS
    // TOKEN: 'd8b1a69e44ab135b57174cc74f8a43314075dee9', // Victor K.
  },
  ANALYTIC: {
    COUNTRIES: {
      LIST: '/api/navigator/country-analytic',
      LOCATION_PRECISE: '/api/navigator/country-location-precise/',
    },
    COUNTRY_INFO: (id: number) => `/api/navigator/country-analytic/${id}`,
    GET_CARDS: '/api/navigator/industry-analytic/',
    REGIONAL: '/api/navigator/regional-analytic/',
  },
  APPLICATIONS: '/api/user/application-for-whole-site/',
  AUTH: {
    INNCHECK: '/api/user/user_inn',
    LOGIN: '/auth/jwt/create',
    REFRESH: '/auth/jwt/refresh',
    VERIFY: '/auth/jwt/verify',
    SIGN_UP: '/auth/users/',
    RESTORE_START: '/auth/users/reset_password/',
    RESTORE_CONF: '/auth/users/reset_password_confirm/',
    // RESTORE_SET: '/auth/users/set_password/',
    ACTIVATION: '/auth/users/activation/',
  },
  BUG_REPORT: 'https://cyb3rf0rm.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/sb53l8/b/24/e73395c53c3b10fde2303f4bf74ffbf6/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=ru-RU&collectorId=ef53da62%22',
  EVENTS: {
    GET: '/api/events/events',
    FILTERS: {
      CATEGORIES: '/api/events/events-category',
      COUNTRIES: '/api/events/country/',
      FORMS: '/api/events/form-of-holding/',
      NEW_COUNTRIES: '/api/navigator/country/',
      SUPPORT_MEASURES: '/api/events/support-measures/',
    },
  },
  FEEDBACK: '/api/feedback/',
  FORMS: {
    EXPORT_TEST: '/api/navigator/application-export-test/',
  },
  GALLERY: {
    GET: '/api/news/gallery',
    YEARS: '/api/news/category-gallery-year',
  },
  MAIN_PAGE_DATA: 'api/navigator/main-page-data/',
  NEWS: {
    GET: '/api/news/news',
    DETAILS: {
      GET: '/api/news/articles-search',
    },
    GET_GENERATOR: '/api/news/main-page-generator',
    HISTORY: {
      GET: '/api/news/history-of-success',
    },
    INTERVIEW: {
      GET: '/api/news/interview',
    },
    ARTICLES: {
      GET: '/api/news/section',
    },
    SPECPROJECTS: {
      GET: '/api/news/special-projects',
    },
    TECHTOURS: {
      GET: '/api/news/techno-tours',
    },
    VIDEO_ALBUMS: {
      GET: '/api/news/album/',
      GET_NEW: '/api/news/album-result/',
    },
  },
  PERSONAL: {
    ANALYTICS: {
      GET: '/api/user/analytic-for-company/',
      SEND: '/api/applications/analytic/',
    },
    EXCHANGE: { // https://cyb3rf0rm.atlassian.net/browse/MOSPROM-279
      GET: '/api/user/company/',
      FILT_FI: '/api/company/financial-indicators/',
      FILT_IFC: '/api/company/industry-for-company/',
      REPLY: '/api/applications/exchange-application/',
    },
    FAQ: {
      GET: '/api/user/faq/',
      SEND: '/api/applications/faq-help/',
    },
    FEED: {
      GET: '/api/user/news-feed/',
      OFFER_NEWS: '/api/applications/offer-news/',
    },
    GOODS: { // https://cyb3rf0rm.atlassian.net/browse/MOSPROM-355
      GET: '/api/company/tender_view/', // get, put, patch
      OFFS: '/api/company/tender_offers/',
      REQS: '/api/company/tender_requests/',
      CURR: '/api/company/currency/', // валюты
      IND: '/api/company/industry-for-company/', // категории
      SUB_IND: '/api/company/industry-for-company/', // подкатегории
      PUT: '/api/navigator/country-location-precise/',
    },
    PROFILE: {
      COMPANY: '/api/user/company',
      DOCUMENTS: {
        GET: '/api/user/documents',
        IS_VALID: '/api/user/valid-docs/',
      },
      HOLDING: '/api/user/holding/',
      MAIN: '/api/user/profile',
      PRESENTATIONS: '/api/user/presentations',
      USER: '/api/user/user_me/me/', // needs access token on reading
    },
    RESOLUTIONS: {
      GET: '/api/user/favorite-resolutions/',
      SEND: '/api/user/favorite-resolutions/',
      INDIVIDUAL: '/api/applications/individual-resolution/',
      BARRIER: '/api/applications/block/',
    },
    LEADS: {
      GET: '/api/applications/application/',
      CATEGORY: '/api/applications/category-of-application/',
      STATUS: '/api/applications/status-of-application/',
    },
    CALENDAR: {
      GET: '/api/user/favorite-events/',
      SEND: '/api/user/favorite-events/',
    },
    NOTIFICATION: {
      GET: '/no-link/',
    },
    ADEPT7: {
      GET: '/api/qualification/routes/session-api/',
      VIDEOS: '/api/navigator/video-adept/',
      SET_WATCHED: (id: number | string) => `/api/user/user_me/${id}/`, // коммент для коммита
    },
  },
  RESOLUTIONS: {
    FILTERS: {
      DATA_CHOICE: '/api/navigator/data-choice/',
      GRAND_AND_LOANS: '/api/navigator/grand-and-loans/',
      SCOPE_OF_PRODUCTS: '/api/navigator/scope-of-products/',
      SIZE_OF_ENTERPRISE: '/api/navigator/size-of-enterprise/',
      SUPPORT_MEASURES: '/api/navigator/support-measures/',
    },
    GET: '/api/navigator/resolutions/',
  },
  TEST: {
    QUALIFICATION: {
      Q: '/api/qualification',
      GET_ID: '/api/qualification/session',
      SESSION_INFO: '/api/qualification/info',
      GET_SESSIONS: '/api/qualification/company-sessions',
    },
    VALIDATION: {
      V: '/api/validation',
      GET_PRODUCTS: '/api/validation/product-info',
      GET_ID: '/api/validation/session',
      GET_SESSIONS: '/api/validation/company-sessions',
    },
  },
};
