/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, memo } from 'react';

import { Select as SelectUIKit } from 'components';
import { _t } from 'utils';
import { Step } from 'types';
import styles from './styles.module.scss';
import { RangeProps } from '..';

type Props = {
  answers: Step['answers'];
  selectedItems: number[];
  onChange: RangeProps['onChangeRange'];
};

const Range: FC<Props> = memo(({ answers, onChange, selectedItems }) => {
  return (
    <div className={styles.selectWrapper}>
      {answers.map((item, i) => {
        const val = selectedItems && selectedItems[i] &&
          answers.find((a) => a.value === selectedItems[i]);
        const valueProp = val ?
          { value: [{ label: val.text, value: val.value.toString() }] } :
          { value: [] };
        return (
          <div key={item.value} className={styles.selectItemWrap}>
            <span className={styles.number}>{item.value}.</span>
            <SelectUIKit
              {...valueProp}
              isClearable
              name={item.value.toString()}
              options={
                answers
                  .filter((answer) => !(selectedItems || []).includes(answer.value))
                  .map(({ text, value }) => ({ label: text, value: value.toString() }))
              }
              classNameWrap={styles.selectItem}
              placeholder={_t('Выберите цель')}
              disabled={false}
              onChange={(value, { name }) => onChange(
                parseInt(name as string, 10) - 1,
                value === null ? value : parseInt((value as { value: string }).value, 10),
              )}
            />
          </div>
        );
      })}
    </div>
  );
});

export { Range };
