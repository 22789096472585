import React, { FC } from 'react';
import cx from 'classnames';
import { SOCIAL_LINKS } from 'appConstants';
import { sendMetrics } from 'utils';
import { Icon } from 'components';
import styles from './styles.module.scss';

type Props = {
  theme?: 'grey' | 'black',
  className?: string,
  classNameLink?: string,
};

const SocialLinks: FC<Props> = ({
  theme = 'grey',
  className,
  classNameLink,
}) => (
  <div className={className}>
    {SOCIAL_LINKS.map(({ to, title, icon }) => ((
      <a
        href={to}
        key={icon}
        className={cx(styles.socialLink, styles[theme], classNameLink)}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => sendMetrics(null, ['social_network', 'click'])}
      >
        <div className={styles.hidden}>{title}</div>
        <Icon icon={icon} />
      </a>
    )))}
  </div>
);

export default SocialLinks;
