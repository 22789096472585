import React, {
  FC, useEffect,
} from 'react';
import cx from 'classnames';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { newPasswordAction } from 'store/me/actions';
import {
  Button, Input, Section,
} from 'components';
import { Link, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { ERRORS, routes } from 'appConstants';
import { history, _t } from 'utils';
import type {
  State,
} from 'types';
import { RequestStatus } from 'types';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

export interface IConfirmPass {
  newPass: string,
  confirmPass: string,
}

const initialValues: IConfirmPass = {
  newPass: '',
  confirmPass: '',
};

const validationSchema = object().shape({
  newPass: string().min(8, ERRORS.min(8)).required(ERRORS.required),
  confirmPass: string().min(8, ERRORS.min(8)).required(ERRORS.required),
});

const NewPassPage: FC<Props> = ({ className }) => {
  const queryUID = new URLSearchParams(useLocation().search).get('uid');
  const queryTOK = new URLSearchParams(useLocation().search).get('token');

  const dispatch = useDispatch();
  const confirmPassReqStatus = useSelector(({ ui }: State) => ui.ME_NEWPASS);

  const {
    values,
    errors,
    isValid,
    touched,
    setErrors,
    handleChange,
    handleSubmit,
  } = useFormik<IConfirmPass>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (data) => {
      if (
        data.newPass === data.confirmPass &&
        queryUID && queryTOK
      ) {
        dispatch(newPasswordAction({
          new_password: data.newPass,
          re_new_password: data.confirmPass,
          uid: queryUID,
          token: queryTOK,
        }));
      }
    },
  });

  useEffect(() => {
    if (!queryUID || !queryTOK) history.push(routes.main.root);
  }, []);

  useEffect(() => {
    if (confirmPassReqStatus === RequestStatus.ERROR) {
      setErrors({ newPass: '', confirmPass: '' });
    }
  }, [confirmPassReqStatus]);

  return (
    <Section>
      <form className={cx(styles.form, className)} onSubmit={handleSubmit}>
        <h2 className={styles.title}>{_t('Восстановление пароля')}</h2>
        <div className={styles.info}>{_t('Введите новый пароль')}</div>
        <Input
          name="newPass"
          type="password"
          placeholder="Новый пароль"
          className={styles.input}
          value={values.newPass}
          error={errors?.hasOwnProperty('newPass') && touched.newPass ? errors.newPass || true : ''}
          onChange={handleChange}
        />
        <Input
          name="confirmPass"
          type="password"
          placeholder="Подтверждение пароля"
          className={styles.input}
          value={values.confirmPass}
          error={(values.newPass !== '' && values.newPass !== values.confirmPass) ? _t('Подтвердите пароль') : ''}
          onChange={handleChange}
        />
        <Button
          type="submit"
          isLoading={confirmPassReqStatus === RequestStatus.REQUEST}
          className={styles.btn}
          disabled={(!isValid)}
          upperCase
          color="secondary"
          title="Восстановить"
        />

        <Link to={routes.auth.login.root} className={styles.linkBig}>{_t('Войти')}</Link>
      </form>
    </Section>
  );
};
export default NewPassPage;
