import React, { FC } from 'react';
import cx from 'classnames';
// @ts-ignore
import PolicyPoliticsRu from 'assets/docs/Mosprom_GDPR_PP_ru_152.docx';
// @ts-ignore
import PolicyPoliticsEn from 'assets/docs/Mosprom_GDPR_PP_en.docx';
import { ClientLang } from 'appConstants';
import { _t } from 'utils';
import styles from './styles.module.scss';

type Props = {
  policyText?: string,
  className?: string
};

const Files:{[index: string]:string} = {
  ru: PolicyPoliticsRu,
  en: PolicyPoliticsEn,
};

const PolicyPolitics: FC<Props> = ({
  policyText,
  className,
}) => (
  <div
    className={cx(className, styles.policy)}
  >
    {policyText || _t('Нажимая кнопку «Отправить»')}
    , {_t('вы соглашаетесь с')} {' '}
    <a
      href={Files[ClientLang]}
      className={styles.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {_t('политикой конфиденциальности')}
    </a>
  </div>
);

export default PolicyPolitics;
