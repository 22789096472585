import React, { FC, memo } from 'react';

import styles from './styles.module.scss';

type Props = {
  passed: number;
  total: number;
};

const PersonalTestProgressBar: FC<Props> = memo(({ passed, total }) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.passed}
        style={{ width: `${(100 * passed) / (total === 0 ? 1 : total)}%` }}
      />
    </div>
  );
});

export { PersonalTestProgressBar };
