/* eslint-disable react/button-has-type */
import React, { FC, MouseEvent } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  type?: 'button' | 'submit',
  className?: string,
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void,
};

const ButtonArrow: FC<Props> = ({
  type = 'button',
  className,
  onClick = () => {},
}) => (
  <button
    type={type}
    className={cx(styles.btn, className)}
    onClick={onClick}
  >
    &#8594;
  </button>
);

export default ButtonArrow;
