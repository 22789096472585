/* eslint-disable no-else-return */
import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  testQConfirmPersonalData,
  testQSelectCheckbox,
  testQSelectRadio,
  testQSelectRange,
  testQSendAnswer,
} from 'store/test/actions';
import { State, Step } from 'types';
import { _t } from 'utils';
import { Kind } from '../CardContent';
import { QualificationCard } from './Card';

const Qualification: FC<{}> = () => {
  const {
    qualificationMeta,
    personalData,
    qualificationSteps,
  } = useSelector(({ test }: State) => test);
  const dispatch = useDispatch();
  const {
    passed,
    total,
    current,
    isGoing,
    isFinished,
    isConfermedPersonalData,
  } = qualificationMeta;
  const getCurrentStep = (currentNum: number, steps: Step[]) => steps
    .find(({ step }) => step === currentNum);

  if (isGoing && !passed && total && !isConfermedPersonalData) {
    return (
      <QualificationCard
        kind="text"
        title={_t('Пожалуйста, проверьте и подтвердите свои данные')}
        company={personalData.company.name as string}
        name={personalData.member.client.user as string}
        inn={personalData.company.inn as string}
        position={personalData.member.client.position as string}
        handleNext={() => dispatch(testQConfirmPersonalData())}
      />
    );
  } else if (isGoing && current && total && !isFinished && isConfermedPersonalData) {
    const step = getCurrentStep(current, qualificationSteps);

    let kind: Kind = 'text';
    if (step?.type === 'S') kind = 'radio';
    if (step?.type === 'M') kind = 'checkbox';
    if (step?.type === 'R') kind = 'range';

    switch (kind) {
      case 'radio':
        return (
          <QualificationCard
            kind="radio"
            title={step?.text!}
            answers={step?.answers!}
            radioSelected={qualificationMeta.answer as (number | null)}
            onChangeRadio={(value: number) => dispatch(testQSelectRadio(value))}
            handleNext={() => dispatch(testQSendAnswer({ shouldIncrement: true }))}
          />
        );
      case 'checkbox':
        return (
          <QualificationCard
            kind="checkbox"
            title={step?.text!}
            answers={step?.answers!}
            checkboxSelected={qualificationMeta.answer as (number[] | null)}
            onChangeCheckbox={(id: number, isChecked: boolean) => {
              dispatch(testQSelectCheckbox(id, isChecked));
            }}
            handleNext={() => dispatch(testQSendAnswer({ shouldIncrement: true }))}
          />
        );
      case 'range':
        return (
          <QualificationCard
            kind="range"
            title={step?.text!}
            answers={step?.answers!}
            rangeSelected={qualificationMeta.answer as (number[] | null)}
            onChangeRange={(position: number, value: number | null) => {
              dispatch(testQSelectRange(position, value));
            }}
            handleNext={() => dispatch(testQSendAnswer({ shouldIncrement: true }))}
          />
        );
      default:
        break;
    }
  }
  return <></>;
};

export { Qualification };
