import React, {
  FC,
} from 'react';
import cx from 'classnames';
import { ProfileAvatar } from 'components';
import { _t } from 'utils';
import styles from './styles.module.scss';

interface Props {
  userName?: string,
  userRole?: string,
  image?: string,
  fileFieldName?: string,
  token: string,
  onClick: (key: boolean) => void,
  onUpdate: () => void,
  handleSubmit: () => void,
  isEdit: boolean,
  uploadUrl: string,
}

const ProfileLeftSide: FC<Props> = ({
  userName,
  userRole,
  image,
  onClick,
  onUpdate,
  fileFieldName,
  token,
  handleSubmit,
  isEdit,
  uploadUrl,
}) => {
  const clickHandle = (isEditing:boolean) => {
    onClick(!isEditing);
    if (isEditing) {
      handleSubmit();
    }
  };

  return (
    <div>
      {isEdit && (
        <div className={styles.editWarning}>
          {_t('Обязательно проверьте правильность данных перед сохранением.')}
        </div>
      )}

      <div className={styles.avatarWrapper}>
        <ProfileAvatar
          fileFieldName={fileFieldName}
          file={image}
          onUpdate={onUpdate}
          uploadUrl={uploadUrl}
          token={token}
        />
      </div>

      {!isEdit && (
        <div>
          <div className={styles.userName}>
            {userName}
          </div>
          <div className={styles.userRole}>
            {userRole}
          </div>
        </div>
      )}

      <div className={styles.editControls}>
        {isEdit && (
          <div
            className={styles.editCancel}
            onClick={() => onClick(!isEdit)}
          >
            {_t('Отменить')}
          </div>
        )}
        <div
          className={cx(
            styles.editUser,
            {
              [styles.editSave]: isEdit,
            },
          )}
          onClick={() => clickHandle(isEdit)}
        >
          {isEdit && _t('Сохранить')}
          {!isEdit && _t('Редактировать данные')}
        </div>
      </div>

    </div>
  );
};

export default ProfileLeftSide;
