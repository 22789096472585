import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useRequest } from 'hooks';
import { routes, URL } from 'appConstants';
import {
  IUserMe,
  IProfileSnake,
  ICompany,
  State,
} from 'types';
import {
  PersonalProfileTab,
  PersonalCompanyTab,
  PersonalDocumentsTab,
  PersonalHoldingRequest,
  PersonalBaseLayout,
  PersonalTestsTab,
} from 'containers';
import { PersonalPageMenu } from 'components';

const Links = [
  {
    title: 'Личные данные',
    to: routes.personal.profile.root,
  },
  {
    title: routes.personal.profile.company.title,
    to: routes.personal.profile.company.root,
  },
  {
    title: routes.personal.profile.documents.title,
    to: routes.personal.profile.documents.root,
  },
  // adept7
];

const PersonalProfile = () => {
  const [isEditData, setIsEditData] = useState<boolean>(false);
  const [updateProf, setUpdateProf] = useState<string>('');
  const [updateDocs, setUpdateDocs] = useState<string>('');
  const token = useSelector(({ me }: State) => me.token);

  const loadNewProfData = () => {
    setUpdateProf(new Date().toString());
  };

  const loadNewDocsData = () => {
    setUpdateDocs(new Date().toString());
  };

  const editToggle = (status: boolean = false) => {
    setIsEditData(status);
    if (!status) loadNewProfData();
  };

  const handleToggle = () => {
    editToggle(false);
  };

  const { data: UserData, request: UserReq } = useRequest<IUserMe>();
  const { data: ProfData, request: ProfReq } = useRequest<IProfileSnake>();
  const { data: CompData, request: CompReq } = useRequest<ICompany>();

  useEffect(() => {
    UserReq({
      method: 'GET',
      url: URL.PERSONAL.PROFILE.USER,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }, [updateProf]);

  useEffect(() => {
    const adept7 = {
      title: routes.personal.profile.tests.title,
      to: routes.personal.profile.tests.root,
    };
    if (UserData?.role === 'E' &&
      (Links[Links.length - 1].to !== adept7.to)
    ) {
      Links.push(adept7);
    }

    if (UserData?.client?.id) {
      ProfReq({
        method: 'GET',
        url: `${URL.PERSONAL.PROFILE.MAIN}/${UserData.client.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }, [UserData]);

  useEffect(() => {
    if (ProfData?.company) {
      CompReq({
        method: 'GET',
        url: `${URL.PERSONAL.PROFILE.COMPANY}/${ProfData?.company}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }, [ProfData, updateDocs]);

  return (
    <PersonalBaseLayout pageTitle={routes.personal.profile.title}>
      <PersonalPageMenu
        links={Links}
        onClick={handleToggle}
      >
        <PersonalHoldingRequest />
      </PersonalPageMenu>
      <Switch>
        <Route
          path={routes.personal.profile.root}
          exact
          render={() => (
            <PersonalProfileTab
              token={token}
              data={ProfData}
              isEdit={isEditData}
              onUpdate={loadNewProfData}
              onClick={editToggle}
              title={Links[0].title}
            />
          )}
        />
        <Route
          exact
          path={routes.personal.profile.company.root}
          render={() => (
            <PersonalCompanyTab
              token={token}
              data={CompData}
              isEdit={isEditData}
              onUpdate={loadNewProfData}
              onClick={editToggle}
              title="Данные компании"
            />
          )}
        />
        <Route
          exact
          path={routes.personal.profile.documents.root}
          render={() => (
            <PersonalDocumentsTab
              token={token}
              companyID={CompData?.id}
              documents={CompData ? CompData?.company_doc : []}
              presentations={CompData ? CompData?.company_presentation : []}
              onFilesUpdate={loadNewDocsData}
            />
          )}
        />
        <Route
          exact
          path={routes.personal.profile.tests.root}
          render={() => (
            <PersonalTestsTab
              token={token}
              user={UserData}
            />
          )}
        />
        <Redirect to={{ pathname: routes.personal.profile.root }} />
      </Switch>
    </PersonalBaseLayout>
  );
};

export default PersonalProfile;
