import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  PersonalAnalyticsMainTab,
  PersonalAnalyticsIndividual,
  PersonalBaseLayout,
} from 'containers';
import { PersonalPageMenu } from 'components';
import { routes } from 'appConstants';

const Links = [
  {
    title: 'Аналитические исследования',
    to: routes.personal.analytics.root,
  },
  {
    title: routes.personal.analytics.individual.title,
    to: routes.personal.analytics.individual.root,
  },
];

const PersonalAnalytics = () => (
  <PersonalBaseLayout pageTitle={routes.personal.analytics.title}>
    <PersonalPageMenu
      links={Links}
    />
    <Switch>
      <Route
        path={routes.personal.analytics.root}
        exact
        render={() => (<PersonalAnalyticsMainTab />)}
      />
      <Route
        path={routes.personal.analytics.individual.root}
        exact
        render={() => (<PersonalAnalyticsIndividual />)}
      />
      <Redirect to={{ pathname: routes.personal.analytics.root }} />
    </Switch>
  </PersonalBaseLayout>
);

export default PersonalAnalytics;
