import React, {
  useEffect,
} from 'react';
import {
  MainPreview,
  MainNumbers,
  MainServices,
  MainAbout,
  MainExportTest,
  MainNews,
  MainEvents,
  MainLibrary,
  MainAnalytic,
  // DadataTest,
} from 'containers';
// import { BannerBlock } from 'components';
import { setPageMeta, routes } from 'appConstants';
import styles from './styles.module.scss';

const Main = () => {
  useEffect(() => {
    setPageMeta(routes.main.title, routes.main.descr);

    const saveScrollPoint = () => {
      sessionStorage.setItem('mainScrollTop', String(window.scrollY));
    };

    if (sessionStorage.getItem('mainScrollTop') !== '0') {
      const topScroll = Number.parseInt(sessionStorage.getItem('mainScrollTop') || '', 10);
      setTimeout(() => {
        window.scrollTo({
          top: topScroll,
          behavior: 'smooth',
        });
      }, 50);
    }

    window.addEventListener('scroll', saveScrollPoint);
    return () => {
      window.removeEventListener('scroll', saveScrollPoint);
    };
  }, []);

  return (
    <>
      <MainPreview />
      <MainNumbers className={styles.section} />
      <MainAbout className={styles.section} />
      <MainServices className={styles.section} />
      <MainExportTest className={styles.section} />
      <MainNews className={styles.section} />
      <MainEvents className={styles.section} />
      <MainLibrary className={styles.section} />
      <MainAnalytic className={styles.section} />
      {/* <DadataTest /> */}
      {/* баннер перед футером */}
      {/* <BannerBlock wiev="horizontal" /> */}
    </>
  );
};

export default Main;
