import { actionTypesQ } from './qualification';
import { actionTypesV } from './validation';
import type { TestActionQ } from './qualification';
import type { TestActionV } from './validation';

export * from './qualification';
export * from './validation';

export type TestAction = TestActionQ | TestActionV;

export default {
  ...actionTypesQ,
  ...actionTypesV,
};
