import React, {
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { MediaLayout } from 'containers';
import {
  H2,
  Label,
  Preloader,
  NoData,
  Text,
  YouTube,
  ContentImage,
} from 'components';
import {
  URL,
} from 'appConstants';
import { useRequest } from 'hooks';
import type { Gallery } from 'types';
import { RequestStatus } from 'types';
import { dateToUiFormat, _t_back } from 'utils';
import cx from 'classnames';
import styles from './styles.module.scss';

type Opts = {
  type: string,
  index: number,
};

const getVideoLink = (link: string) => {
  const res = {
    youtube: true,
    url: link,
  };

  if (link.indexOf('youtu.be') > 0) {
    res.url = link.substr(link.indexOf('.be/') + 4);
  } else if (link.indexOf('youtube') > 0) {
    res.url = link.substr(link.indexOf('?v=') + 3);
  } else if (link.indexOf('/') < 0) {
    return res;
  } else {
    res.youtube = false;
  }
  return res;
};

const MediaGalleryDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { data, status, request } = useRequest<Gallery>();
  const [viewer, setViewer] = useState<Opts>({
    type: 'pic',
    index: 0,
  });

  useEffect(() => {
    request({
      method: 'get',
      url: `${URL.GALLERY.GET}/${id}`,
    });
  }, []);

  useEffect(() => {
    setViewer({
      type: data?.video ? 'vid' : 'pic',
      index: data?.video ? -1 : 0,
    });
  }, [data]);

  const clickHandler = (type: string, index: number) => {
    setViewer({
      type,
      index,
    });
  };
  const switchHandler = (step: number) => {
    const newViewerId = viewer.index + step;
    if (data && (newViewerId < data.pictures.length) && newViewerId >= -1) {
      setViewer({
        type: newViewerId < 0 ? 'vid' : 'pic',
        index: newViewerId,
      });
    }
  };

  const VIDEO = getVideoLink(data ? data.video : '');

  return (
    <MediaLayout>
      <Preloader isLoading={status === RequestStatus.REQUEST}>
        <div className={styles.wrap}>
          <div className={styles.content}>
            <H2 className={styles.title}>{_t_back(data, 'title')}</H2>
            <div className={styles.header}>
              <Label className={styles.label}>{_t_back(data, 'type_of_event')}</Label>
              <Text tag="span" bold size="tiny">{dateToUiFormat(data?.date)}</Text>
            </div>

            <NoData count={(data?.video.length || data?.pictures?.length) || 0}>
              <div className={styles.bigview}>
                {((data?.video && data?.pictures) || (data && data?.pictures.length > 1)) && (
                  <>
                    <div
                      className={styles.arrow_left}
                      onClick={() => switchHandler(-1)}
                    />
                    <div
                      className={styles.arrow_right}
                      onClick={() => switchHandler(1)}
                    />
                  </>
                )}
                {viewer.type === 'vid' && data?.video && (
                  <>
                    {VIDEO.youtube && (
                      <YouTube
                        videoId={VIDEO.url}
                        opts={{ playerVars: { autoplay: 0 } }}
                        className={styles.video}
                      />
                    )}
                    {!VIDEO.youtube && (
                      <video
                        className={styles.video}
                        src={VIDEO.url}
                      >
                        <track kind="captions" />
                      </video>
                    )}
                  </>
                )}
                {(viewer.type === 'pic' || (data && !data?.video && data.pictures[0])) && (
                  <ContentImage
                    className={styles.photo}
                    src={data?.pictures[viewer.index < 0 ? 0 : viewer.index].image}
                  />
                )}
              </div>

              {((data?.video && data?.pictures.length) || (data && data.pictures.length > 1)) && (
                <div className={styles.preview}>
                  {data?.video && (
                    <div
                      className={cx(
                        styles.preview_item,
                        {
                          [styles.active_item]: (viewer.index === -1),
                        },
                      )}
                      onClick={() => clickHandler('vid', -1)}
                    >
                      {VIDEO.youtube && (
                        <YouTube
                          videoId={VIDEO.url}
                          opts={{ playerVars: { autoplay: 0 } }}
                          className={styles.video}
                        />
                      )}
                      {!VIDEO.youtube && (
                        <video
                          className={styles.video}
                          src={VIDEO.url}
                        >
                          <track kind="captions" />
                        </video>
                      )}
                    </div>
                  )}
                  {data?.pictures.length > 0 && data?.pictures.map((el, index) => (
                    <div
                      key={index}
                      className={cx(
                        styles.preview_item,
                        {
                          [styles.active_item]: (viewer.index === index),
                        },
                      )}
                      onClick={() => clickHandler('pic', index)}
                    >
                      <ContentImage
                        className={styles.prevPhoto}
                        src={el.image}
                      />
                    </div>

                  ))}
                </div>
              )}
            </NoData>
          </div>
        </div>
      </Preloader>
    </MediaLayout>
  );
};

export default MediaGalleryDetails;
