import React, {
  FC,
  PropsWithChildren,
  useState,
  useMemo,
} from 'react';
import { Text, LabelValue, FilterGroup } from 'components';
import cx from 'classnames';
import { _t } from 'utils';
import { useWindowResize } from 'hooks';
import styles from './styles.module.scss';

type Props = {
  params: Array<{ label: string, value: string }>,
  sidebarTitle: string,
  fixedOnScroll?: boolean,
};

const DetailsSidebar: FC<PropsWithChildren<Props>> = ({
  children,
  params,
  sidebarTitle,
  fixedOnScroll = false,
}) => {
  const [pageScrolled, setPageScrolled] = useState<boolean>(false);
  const [bottomClip, setbottomClip] = useState<boolean>(false);

  document.addEventListener('scroll', () => {
    if (window.scrollY > 110 && (document.body.scrollHeight - window.scrollY) > 1150) {
      setPageScrolled(true);
    } else {
      setPageScrolled(false);

      if (document.body.scrollHeight - window.scrollY < 1150) {
        setbottomClip(true);
      } else {
        setbottomClip(false);
      }
    }
  }, false);

  const { layout } = useWindowResize({});

  const content = useMemo(() => (
    <>
      <div className={styles.list}>
        {params.map(({ label, value }) => (
          <LabelValue
            key={label}
            className={styles.labelValue}
            label={label}
            value={value}
          />
        ))}
      </div>
      {children}
    </>
  ), [children, params]);

  return (
    ['FULL', 'DESKTOP_BIG'].includes(layout) ? (
      <div className={cx({
        [styles.fixedOnScroll]: (pageScrolled && fixedOnScroll),
        [styles.bottomClip]: bottomClip,
      })}
      >
        <Text
          uppercase
          bold
          className={styles.title}
        >
          {_t(sidebarTitle)}
        </Text>
        {content}
      </div>
    ) : (
      <FilterGroup label="Описание мероприятия">
        {content}
      </FilterGroup>
    )
  );
};

export default DetailsSidebar;
