import React from 'react';
import { _t } from 'utils';
import {
  Text,
  // YouTube
} from 'components';
import aboutImage from 'assets/img/fillers/about.jpg';
// import { ClientLang } from 'appConstants';
import { MainLayout } from 'containers';
import styles from './styles.module.scss';

const LIST_ABOUT: Array<string> = [
  'Аналитические исследования рынков и ликвидности конкретных товаров',
  'Помощь в поиске бизнес-партнеров и подбор международных покупателей под запросы московских производителей',
  'Верификация контрагентов, гарантия их благонадежности',
  'Организация переговоров и помощь в их проведении',
  'Организация выездных, реверсных бизнес-миссий и технологических туров на Московские производства',
];

const FOOTER_ABOUT: Array<string> = [
  'более 700 московских производителей',
  'Индивидуальный подход к каждому запросу',
  'квалифицированные эксперты в области вэд',
];

// const Videos:{[index:string]:string} = {
//   ru: '-ncVRm14BjI',
//   en: '3fm30z0Qy54',
// };

const About = () => {
  return (
    <MainLayout title="О нас">
      <img
        src={aboutImage}
        alt=""
      />
      {/* <YouTube
        videoId={Videos[ClientLang]}
        opts={{ playerVars: { autoplay: 0 } }}
        className={styles.video}
      /> */}
      <div className={styles.blockText}>
        <div className={styles.text}>
          <Text className={styles.description}>
            {_t('Центр «Моспром» был создан Департаментом...')}
          </Text>
          <Text className={styles.description}>
            {_t('Рост московских компаний за рубежом является главным приоритетом...')}
          </Text>
        </div>
        <div className={styles.text}>
          <ul className={styles.list}>
            {LIST_ABOUT.map((item) => (
              <li key={item} className={styles.item}>{_t(item)}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.footer}>
        {FOOTER_ABOUT.map((item) => (
          <Text
            key={item}
            size="big"
            align="center"
            uppercase
            className={styles.itemFooter}
          >
            {_t(item)}
          </Text>
        ))}
      </div>
    </MainLayout>
  );
};

export default About;
