import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import cx from 'classnames';
import {
  MainLayout,
} from 'containers';
import { useRequest } from 'hooks';
import {
  ColorBlock,
  Text,
  Preloader,
  ExpandingBlock,
  RegionInfo,
} from 'components';
import {
  AnalyticsRegional,
  RegionalAnalyticStatic,
  RequestStatus,
} from 'types';
import {
  _t,
  _t_back,
  // _has_t_back,
} from 'utils';
import {
  URL,
  isProd,
} from 'appConstants';
import ItemWithLabel from './ItemWithLabel';
import styles from './styles.module.scss';

interface Item {
  label: string,
  firstValue: JSX.Element,
  secondValue?: JSX.Element,
}

type Props = {};

const staticDataRender = (static_data: RegionalAnalyticStatic) => {
  const {
    export_nne,
    period_export_nne,
    place_export_moscow,
    ratio_regions_export_moscow,
    ratio_total_export_moscow,
  } = static_data;

  const ITEMS: Item[] = [
    {
      label: 'Несырьевой неэнергетический экспорт города Москвы:',
      firstValue: (
        <Text bold>
          <Text tag="span" className={styles.valueSmall}>$</Text>
          <Text tag="span" className={styles.value}>{export_nne}</Text>
          <Text tag="span" className={styles.valueSmall}>{_t('млрд')}</Text>
        </Text>
      ),
      secondValue: <Text bold color="passive" className={styles.valueSecond}>(+{period_export_nne})</Text>,
    },
    {
      label: 'Место Москвы среди регионов РФ (доля в ННЭ):',
      firstValue: <Text bold className={styles.value}>{_t(`${place_export_moscow} место`)}</Text>,
      secondValue: <Text bold color="passive" className={styles.valueSecond}>({ratio_regions_export_moscow})</Text>,
    },
    {
      label: 'Доля ННЭ в совокупном экспорте Москвы:',
      firstValue: <Text bold className={styles.value}>{ratio_total_export_moscow}</Text>,
    },
  ];

  return (
    ITEMS.map(({ label, firstValue, secondValue }) => (
      <ItemWithLabel
        label={label}
        firstValue={firstValue}
        secondValue={secondValue}
        className={styles.itemWithLabel}
        key={label}
      />
    ))
  );
};

const AnalyticsRegionalExecute: FC<Props> = () => {
  const { request, status, data: regional = [] } = useRequest<AnalyticsRegional[]>();
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    request({
      method: 'GET',
      url: URL.ANALYTIC.REGIONAL,
    });
  }, []);

  const clickHandler = (pageNew: number) => {
    setPage(pageNew);
  };

  // const filteredRegional = regional?.filter(_has_t_back) || [];

  const dataN = regional ? regional[page] : null;
  const staticData = regional ? regional[0].static_data : null;

  return (
    <MainLayout
      title="Исполнение региональных проектов"
      className={styles.wrap}
      classNameTitle={styles.title}
    >
      <Preloader
        className={styles.wrapper}
        isLoading={status === RequestStatus.REQUEST && !regional}
      >
        <div className={styles.columnOne}>
          <ColorBlock color="gray">
            {staticData && (
              <Text
                tag="span"
                size="tiny"
                className={styles.legend}
              >
                {_t(`* За ${staticData?.period || 2020} `)}
              </Text>
            )}
            {staticData && staticDataRender(staticData)}
          </ColorBlock>
        </div>
        <div className={styles.columnTwo}>
          <div className={styles.buttonWrap}>
            {regional && regional?.map((item, index) => (
              <div
                key={index}
                className={cx((index === page && styles.active), styles.topButton)}
                data-id={index}
                onClick={() => clickHandler(index)}
              >
                {_t_back(item, 'data_name')}
              </div>
            ))}
          </div>
          {dataN && (
            <div className={styles.columnData}>
              <div className={styles.dataHeader}>
                {_t_back(dataN, 'data_name')} ${_t_back(dataN, 'data_cost')} {_t('млрд')}
              </div>
              <RegionInfo
                data={dataN}
              />
              {!isProd && (
                <div>
                  <div className={styles.separator} />
                  <div className={styles.dataHeader}>
                    {_t_back(dataN, 'data_name')} {_t('по отраслям')}
                  </div>
                  {dataN.regional_analytic_industry?.map((industry, index) => (
                    <ExpandingBlock
                      key={index}
                      label={`${_t_back(industry, 'data_name')}:
                        $${_t_back(industry, 'data_cost')} ${_t('млрд')}`}
                      classNameContent="{classNameContent}"
                    >
                      <RegionInfo
                        data={industry}
                      />
                    </ExpandingBlock>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </Preloader>
    </MainLayout>
  );
};

export default AnalyticsRegionalExecute;
