/* eslint-disable react/self-closing-comp */
import React, {
  FC,
  useEffect,
} from 'react';
import cx from 'classnames';
import {
  Section,
  Text,
  NavLink,
  ButtonCircle,
  ContentImage,
  ButtonArrow,
} from 'components';
import { useRequest } from 'hooks';
import {
  URL,
  routes,
} from 'appConstants';
import {
  dateToUiFormat,
  history,
  _has_t_back,
  _t_back,
  _t,
} from 'utils';
import type { News, GetResponse } from 'types';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const MainNews:FC<Props> = ({ className }) => {
  const {
    data,
    request,
  } = useRequest<GetResponse<News[]>>();
  useEffect(() => {
    request({
      method: 'GET',
      url: URL.NEWS.GET,
      params: {
        limit: 5,
      },
    });
  }, []);

  return (
    <Section className={cx(className)}>
      <div className={styles.content}>
        <Text use="title">{_t('Новости')}</Text>
        <ul className={styles.list}>
          {data?.results.map((news) => (_has_t_back(news) && (
            <li key={news.id} className={styles.card}>
              <NavLink to={`${routes.media.root}/${news.slug}`} className={styles.link}>
                <ContentImage
                  className={styles.photo}
                  src={news.image}
                />
                <div className={styles.info}>
                  <Text size="tiny" bold color="extra">{dateToUiFormat(news.pubdate)}</Text>
                  <Text size="small" bold className={styles.infoTitle}>{_t_back(news, 'title')}</Text>
                  <ButtonArrow className={styles.infoArrow} />
                </div>
              </NavLink>
            </li>
          )))}
          <li className={cx(styles.card, styles.more)}>
            <ButtonCircle
              onClick={() => history.push(routes.media.news.root)}
            >
              {_t('Все новости')}
            </ButtonCircle>
          </li>
        </ul>
      </div>
    </Section>
  );
};

export default MainNews;
