import React, {
  FC,
  PropsWithChildren,
} from 'react';
import { _t } from 'utils';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  tabTitle?: string,
  withoutWrap?: boolean,
};

const PersonalTabLayoutRight: FC<PropsWithChildren<Props>> = ({
  tabTitle,
  className,
  withoutWrap = false,
  children,
}) => {
  return (
    <div className={cx(className, styles.tabLayoutRight,
      { [styles.wrapLayout]: !withoutWrap })}
    >
      {tabTitle && (
        <div className={styles.title}>{_t(tabTitle)}</div>
      )}
      {children}
    </div>
  );
};

export default PersonalTabLayoutRight;
