import React, {
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import cx from 'classnames';
import ButtonToScroll from '../ButtonToScroll';
import styles from './styles.module.scss';

type Props = {
};

const ButtonToTop:FC<Props> = () => {
  const wrap = document.getElementsByTagName('main')[0];

  const [top, setTop] = useState(0);

  const handleNavigation = useCallback(() => {
    // const { currentTarget } = e;
    setTop(wrap.scrollTop);
    /* eslint-disable-next-line */
    console.log(wrap.scrollTop);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleNavigation);
    return () => { document.removeEventListener('scroll', handleNavigation); };
  }, [handleNavigation]);

  return (
    <ButtonToScroll
      title="Вернуться к результатам тестирования"
      targetId="adept0"
      className={cx(
        styles.button,
        { [styles.visible]: top >= 0 },
      )}
    />
  );
};

export default ButtonToTop;
