import React, { useEffect, useMemo, FC } from 'react';
import { useParams } from 'react-router-dom';
import { DetailsLayout, DetailsSidebar } from 'containers';
import { DownloadButton, Preloader } from 'components';
import { URL } from 'appConstants';
import { _t, _t_back } from 'utils';
import { useRequest } from 'hooks';
import type { Card } from 'types';
import { RequestStatus } from 'types';

type Props = {
  className?: string,
  tag?: 'div' | 'section',
  crumbs: Array<{ label: string, to: string }>,
};

const AnalyticsIndustriesDetails: FC<Props> = ({ className, tag = 'section', crumbs }) => {
  const { id } = useParams<{ id: string }>();
  const { data, status, request } = useRequest<Card>();
  const crumbsArr = useMemo(() => [
    ...crumbs,
    {
      label: data?.title.title || id,
      to: '',
    },
  ], [data, crumbs]);

  const params = useMemo(() => [
    {
      label: '',
      value: data?.year_of_info ? `${_t('Информация предоставлена за')} ${data?.year_of_info || ''} ${_t('год')}` : '',
    },
  ], [data]);

  useEffect(() => {
    request({
      method: 'get',
      url: `${URL.ANALYTIC.GET_CARDS}/${id}`,
    });
  }, []);

  return (
    <Preloader isLoading={status === RequestStatus.REQUEST}>
      <DetailsLayout
        title={_t_back(data, 'title')}
        routes={crumbsArr}
        content={_t_back(data, 'article')}
        photo=""
        className={className}
        tag={tag}
      >
        <DetailsSidebar
          params={params}
          fixedOnScroll
          sidebarTitle="Справка"
        >
          <DownloadButton
            title="Скачать файл"
            onClick={() => {
              window.open(data?.file || '', '_blank');
            }}
          />
        </DetailsSidebar>
      </DetailsLayout>
    </Preloader>
  );
};

export default AnalyticsIndustriesDetails;
