import html2canvas from 'html2canvas';
import { jsPDF as JsPDF } from 'jspdf';
import { State, TAdeptResults } from 'types';

const domToPdf = require('dom-to-pdf');

type Props = {
  kind?: 'qualification' | 'validation',
  title?: string,
  onEndSave?: () => void,
  testState?: State['test'],
};

interface level extends Element {
  dataset: {
    category: string,
    level: string,
    margin: string,
  }
}

interface IProps extends Props {
  rootElementId: string,
}

interface IByPages extends Props {
  docData?: TAdeptResults,
}

const createFileName = (
  title: string | undefined,
  kind: string = '',
) => (
  title || kind.concat('_', new Date().toISOString().substring(0, 10))
);

const dwnlPDFByCanvas = ({
  rootElementId,
  title,
  onEndSave,
}: IProps) => {
  const HtmlDoc = document.getElementById(rootElementId);
  if (HtmlDoc) {
    html2canvas(HtmlDoc).then((HtmlDocCanvas) => {
      const imgData = HtmlDocCanvas.toDataURL('image/png');
      const doc = {
        width: HtmlDocCanvas.width,
        height: HtmlDocCanvas.height,
      };
      const border = 15;
      const width = 210 - (border * 2);
      const pdf = new JsPDF('p', 'mm', [
        width + (border * 2),
        width * (doc.height / doc.width) + (border * 2),
      ]);
      pdf.addImage({
        imageData: imgData,
        x: border,
        y: border,
        width,
        height: width * (doc.height / doc.width),
        format: 'JPEG',
      });
      pdf.save(createFileName(title));
      if (onEndSave) onEndSave();
    });
  }
};

const dwnlPDFByDom = ({
  rootElementId,
  title,
  onEndSave,
}: IProps) => {
  const HtmlDoc = document.getElementById(rootElementId);
  if (HtmlDoc) {
    const options = {
      filename: createFileName(title),
      overrideWidth: 1300,
    };
    domToPdf(HtmlDoc, options, () => {
      if (onEndSave) onEndSave();
    });
  }
};

const dwnlPDFByPages = ({
  kind,
  docData,
  onEndSave,
  testState,
}: IByPages) => {
  let pageNum = 1;
  const totalPages = 8;

  const pdf = new JsPDF('p', 'mm', 'a4');
  const border = 10;
  const width = 210 - (border * 2);
  const height = 297;

  const Pages:{
    [index: string] : HTMLElement | null,
  } = {
    header: document.getElementById('adept0'),
    footer: document.getElementById('ForPDF_footer'),
    // PAGE 1
    page1: document.getElementById('ForPDF_page1'),
    // PAGE 2
    page2: document.getElementById('adept2'),
    page2_p2: document.getElementById('ForPDF_navs'),
    // PAGE 3
    page3_p1: document.getElementById('ForPDF_Page3_title'),
    page3_p2: document.getElementById('recomend_0'),
    page3_p3: document.getElementById('recomend_1'),
    // PAGE 4
    page4_p1: document.getElementById('recomend_2'),
    page4_p2: document.getElementById('recomend_3'),
    // PAGE 5
    page5_p1: document.getElementById('recomend_4'),
    page5_p2: document.getElementById('recomend_5'),
    // PAGE 6
    page6_p1: document.getElementById('recomend_6'),
    page6_p2: document.getElementById('ForPDF_conclusion'),
    // PAGE 7-8-9
    page7_8_9: document.getElementById('ForPDF_page7'),
  };

  const CanvasList:{
    [index: string] : HTMLCanvasElement
  } = {};
  // const HtmlCanvasHandler = (item: string) => {
  //   const data = Pages[item];
  //   if (data) {
  //     html2canvas(data).then((canvas) => { CanvasList['item'] = canvas; });
  //   }
  // };
  if (Pages.header) html2canvas(Pages.header).then((header) => { CanvasList['header'] = header; });
  if (Pages.footer) html2canvas(Pages.footer).then((footer) => { CanvasList['footer'] = footer; });

  const addDataHandler = ({
    item,
    data,
    fromTop = 0,
    onSamePage = false,
    crop,
  }: {
    item?: string,
    data: HTMLCanvasElement,
    fromTop?: number,
    onSamePage?: boolean,
    crop?: {
      top: number,
      height: number,
    },
  }) => {
    const {
      header,
      footer,
    } = CanvasList;
    if (item && !CanvasList[item]) CanvasList[item] = data;

    pdf.addImage({
      imageData: data.toDataURL('image/png', 'low'),
      x: border,
      y: (fromTop || 35),
      width,
      height: width * (data.height / data.width),
      format: 'JPEG',
    });

    if (crop) {
      pdf.setDrawColor(255, 255, 255)
        .setFillColor(255, 255, 255)
        .rect(0, crop.top, 210, crop.height, 'F');
    }

    if (!onSamePage) {
      pdf.setDrawColor(255, 255, 255)
        .setFillColor(255, 255, 255)
        .rect(0, 0, 210, 35, 'F')
        .rect(0, 277, 210, 25, 'F')
        .addImage({
          imageData: header.toDataURL('image/png'),
          x: border,
          y: border,
          width,
          height: width * (header.height / header.width),
          format: 'JPEG',
        })
        .addImage({
          imageData: footer.toDataURL('image/png'),
          x: border,
          y: height - 15.5,
          width,
          height: width * (footer.height / footer.width),
          format: 'JPEG',
        })
        .setFontSize(8)
        .setTextColor('#6A6A6A')
        .text(`${pageNum} / ${totalPages}`, 25, 286.5);
    }
  };

  const addNewPageHandler = () => {
    pageNum += 1;
    pdf.addPage();
  };

  const createButtonsMap = (elems: Element) => {
    const elements = Array(...elems.getElementsByClassName('level_result')) as level[];

    let marginScalar:number = 0;
    const addMargin = (level: string) => {
      if (+level > 0 &&
        elements.filter((elem) => +elem.dataset.level === (+level - 1)).length === 1) {
        marginScalar = +level;
      }

      return (6.3 * +level) + (4 * marginScalar);
    };

    const buttons = elements.map((item, id) => ({
      x: 105,
      y: (151.28 + ((4.2 + 2.22) * id) + addMargin(item.dataset.level)),
      w: 97,
      h: 4.2,
      // eslint-disable-next-line no-bitwise
      toPage: (3 + ~~(+item.dataset.category / 2)),
      rect: false,
      // eslint-disable-next-line no-bitwise
      text: `level ${+item.dataset.level} / scal ${marginScalar}`,
      // text: `Page ${(3 + ~~(+item.dataset.category / 2))} / item ${+item.dataset.category + 1}`,
    }));

    return {
      buttons,
    };
  };

  const navigateToHandler = (item:{
    x:number,
    y:number,
    w:number,
    h:number,
    toPage:number,
    rect?:boolean,
    text?:string,
  }) => {
    const {
      x, y, w, h, toPage, rect = false, text = '',
    } = item;

    if (rect) {
      pdf.setDrawColor(0, 0, 0)
        .setFillColor(0, 0, 0)
        .rect(x, y, w, h)
        .setFontSize(10)
        .setTextColor('#6A6A6A')
        .text(text, x + 60, y + 3);
    }
    // Документирование кликабельных ссылок
    // eslint-disable-next-line no-console
    // console.warn(toPage);
    pdf.link(x, y, w, h, { pageNumber: toPage });
  };

  /* eslint-disable */
  if (Pages.page1) {
    html2canvas(Pages.page1)
    // PAGE 1
      .then((page1) => {
        addDataHandler({
          item: 'page1',
          data: page1,
        });
        navigateToHandler({
          x: 85,
          y: kind === 'qualification' ? 194 : 225,
          w: 115,
          h: 16,
          toPage: 2,
        });
      })
      // PAGE 2
      .then(() => {
        if (Pages.page2) {
          const {
            buttons,
          } = createButtonsMap(Pages.page2);

          html2canvas(Pages.page2).then((page2) => {
            addNewPageHandler();
            addDataHandler({
              item: 'page2',
              data: page2,
            });

            buttons.forEach((button) => 
              navigateToHandler(button)
            );
          })
      .then(() => {
        if (Pages.page2_p2) {
          html2canvas(Pages.page2_p2).then((page2_p2) => {
            addDataHandler({
              item: 'page2_p2',
              data: page2_p2,
              fromTop: 240,
              onSamePage: true,
            });
            navigateToHandler({x: 9, y: 240, w: 94, h: 12, toPage: 7});
            navigateToHandler({x: 107, y: 240, w: 94, h: 12, toPage: 8});
          })
      // PAGE 3
      .then(() => {
        if (Pages.page3_p1) {
          html2canvas(Pages.page3_p1).then((page3_p1) => {
            addNewPageHandler();
            addDataHandler({
              item: 'page3_p1',
              data: page3_p1,
            });
          })
      .then(() => {
        if (Pages.page3_p2) {
          html2canvas(Pages.page3_p2).then((page3_p2) => {
            addDataHandler({
              item: 'page3_p2',
              data: page3_p2,
              fromTop: 60,
              onSamePage: true,
            });
          })
      .then(() => {
        if (Pages.page3_p3) {
          html2canvas(Pages.page3_p3).then((page3_p3) => {
            addDataHandler({
              item: 'page3_p3',
              data: page3_p3,
              fromTop: 170,
              onSamePage: true,
            });
          })
      // PAGE 4
      .then(() => {
        if (Pages.page4_p1) {
          html2canvas(Pages.page4_p1).then((page4_p1) => {
            addNewPageHandler();
            addDataHandler({
              item: 'page4_p1',
              data: page4_p1,
            });
          })
      .then(() => {
        if (Pages.page4_p2) {
          html2canvas(Pages.page4_p2).then((page4_p2) => {
            addDataHandler({
              item: 'page4_p2',
              data: page4_p2,
              fromTop: 150,
              onSamePage: true,
            });
          })
      // PAGE 5
      .then(() => {
        if (Pages.page5_p1) {
          html2canvas(Pages.page5_p1).then((page5_p1) => {
            addNewPageHandler();
            addDataHandler({
              item: 'page5_p1',
              data: page5_p1,
            });
          })
      .then(() => {
        if (Pages.page5_p2) {
          html2canvas(Pages.page5_p2).then((page5_p2) => {
            addDataHandler({
              item: 'page5_p2',
              data: page5_p2,
              fromTop: 150,
              onSamePage: true,
            });
          })
      // PAGE 6
      .then(() => {
        if (Pages.page6_p1) {
          html2canvas(Pages.page6_p1).then((page6_p1) => {
            addNewPageHandler();
            addDataHandler({
              item: 'page6_p1',
              data: page6_p1,
            });
          })
      .then(() => {
        if (Pages.page6_p2) {
          html2canvas(Pages.page6_p2).then((page6_p2) => {
            addDataHandler({
              item: 'page6_p2',
              data: page6_p2,
              fromTop: 160,
              onSamePage: true,
            });
          })
      // PAGE 7-8-9
      .then(() => {
        if (Pages.page7_8_9) {
          html2canvas(Pages.page7_8_9).then((page7_8_9) => {
            // PAGE 7
            addNewPageHandler();
            addDataHandler({
              item: 'page7_8_9',
              data: page7_8_9,
            });
            // PAGE 8
            addNewPageHandler();
            addDataHandler({
              fromTop: -212,
              data: page7_8_9,
            });
          })
      // download
      .then(() => {
        // Дата_Квалификация_Наименование компании
        // Дата_Валидация_Наименование компании_Страна_Товар
        const date = docData?.completed.split('T')[0];
        const typeOfKind = kind === 'qualification' ? 'Квалификация' : 'Валидация'
        const company = testState?.personalData.company;

        const apendix = kind === 'qualification' ? '' :
          `_${docData?.country.name}_${docData?.product.name}`;

        pdf.save(createFileName(`${date}_${typeOfKind}_${company?.name}${apendix}`));
        if (onEndSave) onEndSave();
      });
      }
      });
      }
      });
      }
      });
      }
      });
      }
      });
      }
      });
      }
      });
      }
      });
      }
      });
      }
      });
      }
      });
      }
    });
  }
};

export const dwnlPDF = {
  byCanvas: dwnlPDFByCanvas,
  byDom: dwnlPDFByDom,
  byPages: dwnlPDFByPages,
};
