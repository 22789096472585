import React, { FC, memo } from 'react';

import { InputRadio } from 'components';
import { Step } from 'types';
import styles from './styles.module.scss';

type Props = {
  answers: Step['answers'];
  selectedItem: number | null;
  onChange: (id: number) => void;
};

const Radio: FC<Props> = memo(({ answers, onChange, selectedItem }) => {
  return (
    <div>
      {answers.map((item) => (
        <InputRadio
          key={item.value}
          checked={selectedItem === item.value}
          name={item.value.toString()}
          label={item.text}
          className={styles.radio}
          forAdept
          onChange={(e) => onChange(parseInt(e.target.name, 10))}
        />
      ))}
    </div>
  );
});

export { Radio };
