import React, { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  isActive?: boolean,
  className?: string,
  lines?: number,
  theme?: 'light' | 'dark',
  onClick?: () => void,
};

const Burger: FC<Props> = ({
  isActive = false,
  className,
  lines = 3,
  theme = 'light',
  onClick = () => {},
}) => (
  <button
    type="button"
    className={cx(
      styles.btn,
      className,
      styles[theme],
      styles[`lines${lines}`],
      { [styles.btnActive]: isActive },
    )}
    onClick={onClick}
  >
    <span className={styles.icon} />
  </button>
);

export default Burger;
