import React, {
  FC,
  memo,
  useState,
  // PropsWithChildren,
} from 'react';
import { ButtonRounded, HTMLVideo, Modal } from 'components';
import styles from './styles.module.scss';

type Props = {
  buttonName: string,
  videoUrl?: string,
  notButton?: boolean,
  onEndWatch?: () => void,
};

const PersonalTestVideo: FC<Props> = memo(({
  buttonName,
  videoUrl,
  notButton,
  onEndWatch = () => {},
}) => {
  const [isVideoOpen, setVideoOpen] = useState(false);

  return (
    <>
      {notButton ? (
        <div
          className={styles.opener}
          onClick={() => setVideoOpen(true)}
        >
          {buttonName}
        </div>
      ) : (
        <ButtonRounded
          title={buttonName}
          isVideo
          onClick={() => setVideoOpen(true)}
        />
      )}

      <Modal
        onClose={() => {
          setVideoOpen(false);
          onEndWatch();
        }}
        isOpen={isVideoOpen}
        classNameOverlay={styles.modalOverlay}
        className={styles.modalContent}
        classNameClose={styles.modalBtnClose}
      >
        <HTMLVideo
          src={videoUrl}
          controls
          controlsList="nodownload noplaybackrate noremoteplayback nocaption"
          onEnded={onEndWatch}
          preload="auto"
        />
      </Modal>
    </>
  );
});

export { PersonalTestVideo };
