import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

interface Props {
  links: string[],
  active: number,
  onClick: (key: number) => void,
}

const PersonalDetailMenu: FC<PropsWithChildren<Props>> = ({
  links,
  active,
  children,
  onClick,
}) => {
  return (
    <div className={styles.profileHeader}>
      {links.map((item, index) => (
        <div
          key={`header_${item}`}
          className={cx(
            styles.link,
            { [styles.active]: (index === active) },
          )}
          onClick={() => onClick(index)}
        >
          {item}
        </div>
      ))}
      {children && (
        <div
          className={cx(
            styles.right,
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default PersonalDetailMenu;
