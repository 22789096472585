/* eslint-disable react/jsx-props-no-spreading */
import React, {
  FC, memo, useState, ChangeEvent,
} from 'react';

import {
  Select as SelectUIKit,
  Checkbox as CheckboxUIKit,
  Input as InputUIKit,
} from 'components';
import { _t } from 'utils';
import { Country, Product } from 'types';
import styles from './styles.module.scss';
import { ProductSelectProps } from '..';

type Props = {
  countries: Country[];
  product: Product[];
  onChangeCountry: ProductSelectProps['onChangeCountry'];
  onChangeProduct: ProductSelectProps['onChangeProduct'];
  onChangeProductManual: ProductSelectProps['onChangeProductManual'];
  setProductDesc: ProductSelectProps['setProductDesc'];
};

const ProductSelect: FC<Props> = memo(({
  countries,
  onChangeCountry,
  product,
  onChangeProduct,
  onChangeProductManual,
  setProductDesc,
}) => {
  const [isChecked, setChecked] = useState(false);
  return (
    <div className={styles.selectWrapper}>
      <SelectUIKit
        isSearchable
        name="country"
        options={
          countries
            .map(({ id, name }) => ({ label: name, value: id.toString() }))
        }
        classNameWrap={styles.selectItem}
        placeholder={_t('Выберите страну')}
        disabled={false}
        onChange={(country) => onChangeCountry(
          parseInt((country as { value: string }).value, 10),
        )}
      />
      <CheckboxUIKit
        checked={isChecked}
        name="manual_input"
        label={_t('Добавить наименование товара вручную ')}
        className={styles.checkbox}
        forAdept
        onChange={() => {
          setChecked(!isChecked);
          onChangeProductManual(null, 'productName');
          onChangeProductManual(null, 'productTNVED');
          onChangeProduct(null);
        }}
      />
      {isChecked ? (
        <>
          <InputUIKit
            type="text"
            placeholder="Наименование товара"
            className={styles.input}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeProductManual(e.target.value, 'productName')}
            name="productName"
          />
          <InputUIKit
            type="text"
            placeholder="Код ТН ВЭД"
            className={styles.input}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeProductManual(e.target.value, 'productTNVED')}
            name="productTNVED"
          />
        </>
      ) : (
        <SelectUIKit
          isSearchable
          isVirtualazed
          name="product"
          options={
            product
              .map(({ id, name, code }) => ({ label: `${code} ${name}`, value: id.toString() }))
          }
          classNameWrap={styles.selectItem}
          placeholder={_t('ТН ВЭД и наименование товара')}
          disabled={false}
          onChange={(country) => onChangeProduct(
            parseInt((country as { value: string }).value, 10),
          )}
        />
      )}
      <InputUIKit
        type="text"
        placeholder="Описание товара"
        className={styles.input}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setProductDesc(e.target.value)}
        name="productDescription"
      />
    </div>
  );
});

export { ProductSelect };
