import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import type {
  Gallery,
  YearItem,
} from 'types';
import { RequestStatus } from 'types';
import { useRequest } from 'hooks';
import { MediaLayout } from 'containers';
import cx from 'classnames';
import {
  Link,
  YouTube,
  Preloader,
  ContentImage,
  NoData,
} from 'components';
import {
  URL,
  routes,
} from 'appConstants';
import {
  _t,
  _t_back,
  _has_t_back,
} from 'utils';
import styles from './styles.module.scss';

const convertDate = (data: string) => {
  const [year, month, day] = data.split('-');

  const rusMonth = [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
  ];
  return (`${day} ${_t(rusMonth[+month - 1])} ${year}`);
};

const getVideoLink = (link: string) => {
  const res = {
    youtube: true,
    url: link,
  };

  if (link.indexOf('youtu.be') > 0) {
    res.url = link.substr(link.indexOf('.be/') + 4);
  } else if (link.indexOf('youtube') > 0) {
    res.url = link.substr(link.indexOf('?v=') + 3);
  } else if (link.indexOf('/') < 0) {
    return res;
  } else {
    res.youtube = false;
  }
  return res;
};

const MediaGallery = () => {
  const [yearID, setYearID] = useState<Number>(1);

  const changeYear = useCallback(({ target: { id } }) => {
    setYearID(+id);
  }, []);

  const {
    data: years,
    request: yearsReq,
  } = useRequest<YearItem[]>();

  useEffect(() => {
    yearsReq({
      method: 'get',
      url: URL.GALLERY.YEARS,
    });
  }, []);

  const {
    data: gallery,
    status: galleryStat,
    request: galleryReq,
  } = useRequest<Gallery[]>();

  useEffect(() => {
    galleryReq({
      method: 'get',
      url: URL.GALLERY.GET,
      params: {
        category_gallery_date: yearID,
      },
    });
  }, [yearID]);

  const filteredGallery = gallery?.filter(_has_t_back) || [];

  return (
    <MediaLayout>
      <div className={styles.years}>
        {years?.map((item, index) => (
          <span
            key={index}
            className={cx(
              styles.year,
              { [styles.active]: yearID === item.id },
            )}
            id={`${item.id}`}
            onClick={changeYear}
          >
            {item.year}
          </span>
        ))}
      </div>
      <Preloader isLoading={galleryStat === RequestStatus.REQUEST}>
        <NoData count={filteredGallery?.length || 0}>
          <div className={styles.wrap}>
            {gallery?.map((galEl, key) => (galEl.pictures?.[0] || galEl.video) && (
              <Link
                key={key}
                className={cx(
                  styles.elem,
                  { [styles.big]: key === 0 },
                )}
                to={`${routes.media.gallery.root}/${galEl.id}`}
              >
                {(galEl.pictures[0] || !galEl.video) && (
                  <ContentImage
                    className={styles.preview}
                    src={galEl.pictures[0].image}
                  />
                )}
                {(!galEl.pictures[0] && galEl.video) && (
                  <div className={styles.preview}>
                    {getVideoLink(galEl.video).youtube && (
                      <YouTube
                        videoId={getVideoLink(galEl.video).url}
                        opts={{ playerVars: { autoplay: 0 } }}
                        className={styles.video}
                      />
                    )}
                    {!getVideoLink(galEl.video).youtube && (
                      <video
                        className={styles.video}
                        src={getVideoLink(galEl.video).url}
                      >
                        <track kind="captions" />
                      </video>
                    )}
                  </div>
                )}
                <div className={styles.info}>
                  <span className={styles.date}>{convertDate(galEl.date)}</span>
                  <span className={styles.title}>{_t_back(galEl, 'title')}</span>
                </div>
              </Link>
            ))}
          </div>
        </NoData>
      </Preloader>
    </MediaLayout>
  );
};

export default MediaGallery;
