import React, {
  useState,
  useEffect,
  useMemo,
  FC,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DetailsLayout, DetailsSidebar } from 'containers';
import {
  ApplicationForm,
  Button,
  Preloader,
  HTMLContent,
  ExpandingBlock,
} from 'components';
import { routes, URL } from 'appConstants';
import { _t_back } from 'utils';
import { useRequest } from 'hooks';
import type { Resolution, State } from 'types';
import { RequestStatus, PersonalResolutions } from 'types';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  crumbs: Array<{ label: string, to: string }>,
};

const ResolutionsDetails: FC<Props> = ({
  className,
  crumbs,
}) => {
  const isAuth = useSelector(({ me }: State) => me.isAuth);
  const token = useSelector(({ me }: State) => me.token);

  const [favButtonText, setFavButtonText] = useState<string>('В избранное');
  const { data: favRes, request: getFavorite } = useRequest<Array<PersonalResolutions>>();
  const { data: favorite, request: setFavorite } = useRequest<{created: string}>();
  const { data, status, request } = useRequest<Resolution>();

  const { id } = useParams<{ id: string }>();
  const crumbsArr = useMemo(() => [
    ...crumbs,
    {
      label: _t_back(data, 'res_title_t'),
      to: `${routes.resolutions.root}/${id}`,
    },
  ], [data, crumbs]);

  const params = useMemo(() => [
    {
      label: 'Регион',
      value: data?.moscow_or_rf.title || '-',
    },
  ], [data]);

  useEffect(() => {
    request({
      method: 'get',
      url: `${URL.RESOLUTIONS.GET}/${id}`,
    });
  }, []);

  useEffect(() => {
    if (token) {
      getFavorite({
        method: 'GET',
        url: URL.PERSONAL.RESOLUTIONS.GET,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }, []);

  useEffect(() => {
    favRes?.map((fav) => {
      if (fav.resolution.id === data?.id ||
        favorite?.created) {
        setFavButtonText('В избранном');
      }
    });
  }, [favRes, data, favorite]);

  const renderAdditionalFields = useMemo(() => {
    return (
      <>
        {_t_back(data, 'document_list') && (
          <ExpandingBlock
            label="Документы"
            className={styles.regions}
          >
            <HTMLContent content={_t_back(data, 'document_list')} />
          </ExpandingBlock>
        )}

        {_t_back(data, 'comments') && (
          <ExpandingBlock
            label="Комментарии"
            className={styles.regions}
          >
            <HTMLContent content={_t_back(data, 'comments')} />
          </ExpandingBlock>
        )}

        {_t_back(data, 'how_to_get_support') && (
          <ExpandingBlock
            label="Как получить поддержку?"
            className={styles.regions}
          >
            <HTMLContent content={_t_back(data, 'how_to_get_support')} />
          </ExpandingBlock>
        )}
      </>
    );
  }, [data]);

  const switchFavorite = () => {
    setFavorite({
      method: 'post',
      url: `${URL.PERSONAL.RESOLUTIONS.SEND}`,
      data: {
        resolution: data?.id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  return (
    <Preloader isLoading={status === RequestStatus.REQUEST}>
      <DetailsLayout
        title={_t_back(data, 'res_title')}
        routes={crumbsArr}
        content={_t_back(data, 'short_description')}
        photo=""
        className={className}
        additionalFields={renderAdditionalFields}
      >
        <DetailsSidebar
          params={params}
          fixedOnScroll
          sidebarTitle="Меры поддержки"
        >
          <ApplicationForm />
          {isAuth && (
            <div className={styles.favorite}>
              <Button
                onClick={switchFavorite}
                color="secondary"
                className={styles.favoriteButton}
                title={favButtonText}
              />
              <div className={styles.info}>
                Данная мера поддержки будет добавлена в ваш личный кабинет
              </div>
            </div>
          )}
        </DetailsSidebar>
      </DetailsLayout>
    </Preloader>
  );
};

export default ResolutionsDetails;
