import React, { FC, MouseEvent } from 'react';
import { Logo } from 'components';
import cx from 'classnames';
import styles from './styles.module.scss';

interface Props {
  isOpen: boolean,
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void,
  className?: string;
}

// const Logo = () => (
//   <div
//     className={styles.logo}
//   />
// );

const SidebarButton: FC<Props> = ({
  isOpen,
  onClick,
  className,
}) => {
  return (
    <div
      className={cx(
        {
          [styles.wide]: isOpen,
          [styles.btnMenu]: !isOpen,
        },
      )}
    >
      {isOpen && <Logo className={styles.logo} />}
      <button
        type="button"
        onClick={onClick}
        className={cx(
          styles.btn,
          {
            [styles.open]: isOpen,
          },
          className,
        )}
      >
        {}
      </button>
    </div>
  );
};

export default SidebarButton;
