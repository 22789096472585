import React, {
  FC,
  useState,
  useEffect,
  // useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { useRequest } from 'hooks';
import { useFormik } from 'formik';
import { URL, routes } from 'appConstants';
import {
  LeadStatus,
  State,
  RequestStatus,
  ExchangeDataExp,
} from 'types';
import {
  _t,
  // validateEmptyParams,
} from 'utils';
import { PersonalTabLayout } from 'containers';
import {
  Preloader,
  NoData,
  Paginate,
  Input,
} from 'components';
// import cx from 'classnames';
import PersonalExchangeFilter from '../PersonalExchangeFilter';
import PersonalExchangeListCard from '../PersonalExchangeListCard';
import styles from './styles.module.scss';

type Props = {};

const PAGE_SIZE = 12;

interface Params {
  support_measures: Array<number>,
  financial_res: number,
  page: number,
  count: number,
  search: string,
}

const initialValues:Params = {
  support_measures: [],
  financial_res: 0,
  page: 0,
  count: 40,
  search: '',
};

const PersonalExchangeMainTab: FC<Props> = () => {
  const [search, setSearch] = useState<string>('');
  const { data: indFCompData, request: IndFCompRequest } = useRequest<LeadStatus[]>();
  const [indFCompParam, setIndFCompParam] = useState<number>(0);
  const { data: finIndData, request: FinIndRequest } = useRequest<LeadStatus[]>();
  const [finIndParam, setFinIndParam] = useState<number>(0);

  const { data: LeadData, status: LeadReqStatus, request: ExchangeRequest } =
    useRequest<ExchangeDataExp>();
  const token = useSelector(({ me }: State) => me.token);

  const {
    values,
    handleChange,
    handleSubmit,
  } = useFormik<Params>({
    initialValues,
    enableReinitialize: true,
    onSubmit: (params) => {
      const {
        page,
        // ...paramsToSend
      } = params;
      ExchangeRequest({
        url: URL.PERSONAL.EXCHANGE.GET,
        method: 'get',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          // ...validateEmptyParams({
          //   params: paramsToSend,
          //   emptyParamsToDelete: [
          //     'search',
          //     'support_measures',
          //     'new_country',
          //     'show_archive',
          //     'category_of_event',
          //     'form_of_holding',
          //     'start_filter_date',
          //     'end_filter_date',
          //   ],
          // }),
          limit: PAGE_SIZE,
          offset: page * PAGE_SIZE,
        },
        // paramsSerializer: (value) => qs.stringify(value, { indices: false }),
      });
    },
  });

  // const setIndFCompFilter = (val: number = 0) => {
  //   if (indFCompParam.includes(val)) {
  //     setIndFCompParam(indFCompParam.filter((i) => (i !== val)));
  //   } else {
  //     setIndFCompParam(indFCompParam.concat(val));
  //   }
  // };

  const setIndFCompFilter = (val: number = 0) => {
    setIndFCompParam(val);
  };

  const setFinIndFilter = (val: number = 0) => {
    setFinIndParam(val);
  };

  const handleSearch = () => {
    handleSubmit();
  };

  const clearFilters = () => {
    setSearch('');
    setIndFCompParam(0);
    setFinIndParam(0);
  };

  useEffect(() => {
    ExchangeRequest({
      method: 'GET',
      url: URL.PERSONAL.EXCHANGE.GET,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        offset: values.page,
        limit: PAGE_SIZE,
      },
    });
  }, [indFCompParam, finIndParam, search]);

  useEffect(() => {
    IndFCompRequest({
      method: 'GET',
      url: URL.PERSONAL.EXCHANGE.FILT_IFC,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }, []);

  useEffect(() => {
    FinIndRequest({
      method: 'GET',
      url: URL.PERSONAL.EXCHANGE.FILT_FI,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }, []);

  // useEffect(() => {
  // }, []);

  const renderSearch = (
    <Input
      type="search"
      name="search"
      value={values.search}
      placeholder="Поиск"
      onChange={handleChange}
      onSearch={handleSearch}
      className={styles.search}
    />
  );

  return (
    <PersonalTabLayout
      single
      className={styles.layout}
    >
      {/* <div className={styles.mainContent}> */}
      <div className={styles.filterNSearch}>
        {renderSearch}
        <PersonalExchangeFilter
          finIndParam={finIndParam}
          finIndData={finIndData}
          indFCompParam={indFCompParam}
          indFCompData={indFCompData}
          setFinIndFilter={setFinIndFilter}
          setIndFCompFilter={setIndFCompFilter}
          applyFilters={handleSubmit}
          clearFilters={clearFilters}
        />
      </div>

      <Preloader isLoading={LeadReqStatus === RequestStatus.REQUEST}>
        <NoData count={initialValues?.count || LeadData?.count || 0}>
          <div className={styles.totalCount}>
            {_t('Найдено предложений')}: {LeadData?.count}
          </div>
          <div className={styles.list}>
            {LeadData?.results.map((exchange) => (
              <PersonalExchangeListCard
                key={exchange.id}
                value={exchange}
                className={styles.card}
                targetDetailsPath={routes.personal.exchange.root}
              />
            ))}
          </div>
          <Paginate
            page={values.page}
            count={LeadData?.count || 0}
            pageSize={PAGE_SIZE}
            className={styles.paginate}
            onChange={(value) => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              handleChange({
                target: {
                  name: 'page',
                  value,
                },
              });
              handleSubmit();
            }}
          />
        </NoData>
      </Preloader>

      {/* {LeadData?.[detail] && (
        <PersonalExchangeDetail
          data={LeadData[detail]}
        />
      )} */}
    </PersonalTabLayout>
  );
};

export default PersonalExchangeMainTab;
