import React, {
  useState,
  useEffect,
  useMemo,
  FC,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DetailsLayout, DetailsSidebar } from 'containers';
import {
  ApplicationForm,
  Preloader,
  Button,
} from 'components';
import { routes, URL } from 'appConstants';
// import { ClientLang, routes, URL } from 'appConstants';
import { useRequest } from 'hooks';
import { dateToUiFormat, _t_back } from 'utils';
import type { Event, State, FavoriteEvent } from 'types';
import { RequestStatus } from 'types';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  tag?: 'div' | 'section',
  crumbs: Array<{ label: string, to: string }>,
};

const EventsDetails: FC<Props> = ({ className, tag = 'section', crumbs }) => {
  const isAuth = useSelector(({ me }: State) => me.isAuth);
  const token = useSelector(({ me }: State) => me.token);

  const [favButtonText, setFavButtonText] = useState<string>('В избранное');
  const { data: favorite, request: setFavorite } = useRequest<{created: string}>();
  const { data: favRes, request: CalendarRequest } = useRequest<FavoriteEvent[]>();

  const { id } = useParams<{ id: string }>();
  const { data, status, request } = useRequest<Event>();
  const crumbsArr = useMemo(() => [
    ...crumbs,
    {
      label: _t_back(data, 'title'),
      to: `${routes.events.root}/${id}`,
    },
  ], [data, crumbs]);

  const params = useMemo(() => [
    {
      label: 'Место проведения',
      value: _t_back(data, 'location') || '-',
    },
    {
      label: 'Дата мероприятия',
      value: `${dateToUiFormat(data?.date_of_event_start || '')} - ${dateToUiFormat(data?.date_of_event_end || '')}`,
    },
    {
      label: 'Отрасль',
      value: _t_back(data?.support_measures, 'title') || '-',
    },
    {
      label: 'Формат',
      value: _t_back(data?.form_of_holding, 'title') || '-',
    },
  ], [data]);

  useEffect(() => {
    request({
      method: 'get',
      url: `${URL.EVENTS.GET}/${id}`,
    });
  }, []);

  const switchFavorite = () => {
    setFavorite({
      method: 'post',
      url: URL.PERSONAL.CALENDAR.SEND,
      data: {
        event: data?.id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  useEffect(() => {
    if (isAuth) {
      CalendarRequest({
        method: 'GET',
        url: URL.PERSONAL.CALENDAR.GET,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (isAuth) {
      favRes?.map((fav) => {
        if (fav.event.id === data?.id ||
          favorite?.created) {
          setFavButtonText('В избранном');
        }
      });
    }
  }, [favRes, data, favorite]);

  return (
    <Preloader isLoading={status === RequestStatus.REQUEST}>
      <DetailsLayout
        title={_t_back(data, 'title')}
        routes={crumbsArr}
        content={_t_back(data, 'description')}
        photo={data?.image || ''}
        className={className}
        tag={tag}
      >
        <DetailsSidebar
          params={params}
          fixedOnScroll
          sidebarTitle="Мероприятия"
        >
          {(data && new Date(data.date_of_registration_to_event) > new Date()) && (
            <div>
              <ApplicationForm />
              {isAuth && (
                <div className={styles.favorite}>
                  <Button
                    onClick={switchFavorite}
                    color="secondary"
                    className={styles.favoriteButton}
                    title={favButtonText}
                  />
                  <div className={styles.info}>
                    Данная мера поддержки будет добавлена в ваш личный кабинет
                  </div>
                </div>
              )}
            </div>
          )}
        </DetailsSidebar>
      </DetailsLayout>
    </Preloader>
  );
};

export default EventsDetails;
