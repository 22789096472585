import { routes } from 'appConstants';

export const mediaCategoryRoutes: {[key: number]: {
  label: string,
  to: string,
}} = {
  3: {
    label: routes.media.news.label,
    to: routes.media.news.root,
  },
  // 21: {
  //   label: routes.media.history.label,
  //   to: routes.media.history.root,
  // },
  // 22: {
  //   label: routes.media.interview.label,
  //   to: routes.media.interview.root,
  // },
  4: {
    label: routes.media.articles.label,
    to: routes.media.articles.root,
  },
  47: {
    label: routes.media.specProjects.label,
    to: routes.media.specProjects.root,
  },
  46: {
    label: routes.media.techTours.label,
    to: routes.media.techTours.root,
  },
  500: {
    label: routes.media.videoAlbums.label,
    to: routes.media.videoAlbums.root,
  },
};

export const DefaultLang:string = 'ru';

const setDefaultLang: () => string = () => {
  const isDefaultLang:boolean = navigator.language.toLowerCase().indexOf(DefaultLang) >= 0;
  const ClientLang = isDefaultLang ? DefaultLang : 'en';
  localStorage.setItem('siteLocale', ClientLang);
  return ClientLang;
};

export const ClientLang:string = localStorage.getItem('siteLocale') || setDefaultLang();

export const isProd:boolean = window.location.href.includes('https://prom.moscow/');
export const isLocalTest:boolean = window.location.href.includes('http://localhost:300');

export const showLoginOnProd = true;

export const envSwitch: () => string = () => {
  const routeSwitch = localStorage.getItem('useProdEnv') === 'true' ? 'https://prom.moscow/' : 'http://prom.moscow:32100/';
  // return (!isProd ? routeSwitch : process.env.REACT_APP_API_URL) as string;
  return (isLocalTest ? routeSwitch : process.env.REACT_APP_API_URL) as string;
};

export const setPageMeta = (title?:string, descr?:string) => {
  const SiteName:string = ClientLang === DefaultLang ? 'МОСПРОМ' : 'MOSPROM';
  document.title = `${title || SiteName}`;
  // @ts-ignore: Unreachable code error
  document.getElementsByName('description')[0].content = descr || 'Центр поддержки и развития экспорта "Моспром"'.concat(title);
};

export const addPageMeta = (title:string) => {
  document.title = `${document.title} ${title ? ' - '.concat(title) : ''}`;
};
