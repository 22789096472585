import React, { FC } from 'react';
import {
  NoImage,
} from 'utils';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?:string,
  src?:string,
};

const ContentImage: FC<Props> = ({
  className,
  src = '',
}) => {
  const alt = `alt_text_${Math.random().toString().substr(2)}`;

  const handleNoImage = (e: React.BaseSyntheticEvent) => {
    e.target.onerror = null;
    e.target.parentNode.style.backgroundImage = `url('${NoImage}')`;
  };

  return (
    <div
      className={cx(className, styles.contentImage)}
      style={{
        backgroundImage: `url('${src || NoImage}')`,
      }}
    >
      <img
        className={styles.hiddenImage}
        alt={alt}
        src={src}
        onError={handleNoImage}
        width="1px"
        height="1px"
      />
    </div>
  );
};

export default ContentImage;
