import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { isProd } from 'appConstants';
import { DevPanel } from 'components';
import 'react-toastify/dist/ReactToastify.css';
import { ACCEPT_TEXT } from 'appConstants/texts';
import PopupAccept from 'components/PopupAccept';
import { setItem } from 'storage/index';

import Routes from '../Routes';

const App = () => {
  const memoizedIsAccept = useMemo(() => (localStorage.getItem('accept')), []);

  const [isOpen, setIsOpen] = useState<boolean>(!(memoizedIsAccept));

  const location = useLocation();

  const closeModal = () => {
    setIsOpen(false);
    setItem('accept', JSON.stringify('true'));
  };

  const closeModalWithAccept = () => {
    setIsOpen(false);
    setItem('accept', JSON.stringify('true'));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    history.scrollRestoration = 'manual';
  }, []);

  return (
    <>
      {!isProd && <DevPanel />}
      <Routes />
      <ToastContainer />
      <PopupAccept
        isOpen={isOpen}
        onClose={closeModal}
        onCloseModalWithAccept={closeModalWithAccept}
      >
        {ACCEPT_TEXT}
      </PopupAccept>
    </>
  );
};

export default App;
