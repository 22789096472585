import React, { FC } from 'react';
// import { Link } from 'react-router-dom';
// import { routes } from 'appConstants';
import {
  Section,
  Text,
  H3,
  // ButtonCircle,
} from 'components';
import { _t } from 'utils';
import libraryMainPic from 'assets/img/mainExportTest.png';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const MainExportTest:FC<Props> = ({
  className,
}) => (
  <Section className={cx(className)}>
    <div className={styles.row}>
      <div className={cx(styles.column, styles.titleWrap)}>
        <Text use="title">{_t('Тест экспортной готовности')}</Text>

        <H3 className={styles.title}>
          {_t('Протестируй первую в России модель оценки экспортного потенциала «ADEPT 7»')}
        </H3>

        {/* <Link to={routes.auth.exportTest.root}>
          <ButtonCircle>{_t('Оставить заявку')}</ButtonCircle>
        </Link> */}
      </div>
      <div className={styles.column}>
        <img
          className={styles.img}
          src={libraryMainPic}
          alt={libraryMainPic}
        />
      </div>
    </div>
  </Section>
);

export default MainExportTest;
