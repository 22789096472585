import React, { FC } from 'react';
import { MainLayout } from 'containers';
import { Card, Link } from 'components';
import { _t } from 'utils';
import { routes } from 'appConstants';
import styles from './styles.module.scss';

type Props = {};

const SECTIONS = [
  {
    title: 'Исполнение региональных проектов',
    to: routes.analytics.regionalExecute.root,
  },
  {
    title: 'Аналитика по странам',
    to: routes.analytics.countries.root,
  },
  {
    title: 'Аналитика по индустриям',
    to: routes.analytics.industry.root,
  },
];

const AnalyticsList: FC<Props> = () => (
  <MainLayout title="Аналитика" className={styles.section}>
    {SECTIONS.map(({ title, to }) => (
      <Link to={to} key={title} className={styles.link}>
        <Card key={to} tag="div" className={styles.card}>
          {_t(title)}
        </Card>
      </Link>
    ))}
  </MainLayout>
);

export default AnalyticsList;
