/* eslint-disable react/jsx-props-no-spreading */
import React, {
  createElement,
  ComponentType,
  FC,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';
import type { State } from 'types';
import { routes } from 'appConstants';

type Props = RouteProps & {
  component: ComponentType,
};

const ProtectedRoute: FC<Props> = ({
  component,
  ...rest
}) => {
  const isAuth = useSelector(({ me }: State) => me.isAuth);

  const routeComponent = (props: RouteComponentProps) => (
    isAuth ?
      createElement(component, props)
      : <Redirect to={{ pathname: routes.main.root }} />
  );

  return (
    <Route
      {...rest}
      render={routeComponent}
    />
  );
};

export default ProtectedRoute;
