import React, {
  FC, useEffect, useState, useCallback,
} from 'react';
import cx from 'classnames';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { meRestoreAction } from 'store/me/actions';
import {
  Button, Checkbox, Input, Section, Text,
} from 'components';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { ERRORS, routes } from 'appConstants';
import { history, _t } from 'utils';
import type {
  RestoreReq,
  State,
} from 'types';
import { RequestStatus } from 'types';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const initialValues: RestoreReq = {
  email: '',
};

const validationSchema = object().shape({
  email: string().required(ERRORS.required).email(ERRORS.email),
});

const RestorePage: FC<Props> = ({ className }) => {
  const dispatch = useDispatch();
  const restoreReqStatus = useSelector(({ ui }: State) => ui.ME_RESTORE);
  const isAuth = useSelector(({ me }: State) => me.isAuth);
  const [checkbox, setCheckbox] = useState<boolean>(true);
  const [canSend, setCanSend] = useState<boolean>(false);
  const handleChangeCheckbox = useCallback(() => {
    setCheckbox((state) => !state);
  }, []);

  const {
    values,
    errors,
    isValid,
    touched,
    setErrors,
    handleChange,
    handleSubmit,
  } = useFormik<RestoreReq>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: (data) => {
      if (canSend) {
        dispatch(meRestoreAction(data));
      }
    },
  });

  useEffect(() => {
    if (values.email !== '' && !checkbox) {
      setCanSend(true);
    } else {
      setCanSend(false);
    }
  }, [values, checkbox]);

  useEffect(() => {
    if (isAuth) history.push(routes.personal.root);
  }, []);

  useEffect(() => {
    if (restoreReqStatus === RequestStatus.ERROR) {
      setErrors({ email: '' });
    }
  }, [restoreReqStatus]);

  return (
    <Section>
      <form className={cx(styles.form, className)} onSubmit={handleSubmit}>
        <h2 className={styles.title}>{_t('Восстановление пароля')}</h2>
        <div className={styles.info}>{_t('Введите email, который вы использовали при регистрации')}</div>
        <Input
          name="email"
          placeholder="Email"
          className={styles.input}
          value={values.email}
          error={errors?.hasOwnProperty('email') && touched.email ? errors.email || true : ''}
          onChange={handleChange}
        />
        <div className={styles.wrapCheckbox}>
          <Checkbox
            name="checkbox"
            checked={checkbox}
            onChange={handleChangeCheckbox}
            label={<Text tag="span" size="tiny">{_t('Вы РОБОТ?')}</Text>}
            className={styles.checkbox}
          />
          <Link to={routes.auth.signUp.root} className={styles.link}>{_t('Регистрация')}</Link>
        </div>
        <Button
          type="submit"
          isLoading={restoreReqStatus === RequestStatus.REQUEST}
          className={styles.btn}
          disabled={(!isValid && !canSend)}
          upperCase
          color="secondary"
          title="Восстановить"
        />

        <Text tag="span" className={styles.littleText}>{_t('или')}</Text>

        <Link to={routes.auth.login.root} className={styles.linkBig}>{_t('Войти')}</Link>
      </form>
    </Section>
  );
};
export default RestorePage;
