import React, { FC } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

interface Props {
  color: 'black' | 'gray',
  disabled?: boolean,
  className?: string,
}

const ColorBlock: FC<Props> = ({
  color,
  disabled,
  className,
  children,
}) => (
  <div className={cx(
    styles.colorBlock,
    styles[color],
    disabled && styles.disabled,
    className,
  )}
  >
    {children}
  </div>
);

export default ColorBlock;
