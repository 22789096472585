import React, {
  FC,
  useCallback,
  ChangeEvent,
  useEffect,
  useState,
  useRef,
} from 'react';
import {
  PolicyPolitics,
  Button,
  // Input,
  Modal,
} from 'components';
import { _t } from 'utils';
import cx from 'classnames';
import styles from './styles.module.scss';

interface Props {
  className?: string,
  customModal?: string,
  customTitle?: string,
  customClose?: string,
  customModalOverlay?: string,
  modalContent?: string,
  onOpen?: () => void,
  onClose?: () => void,
  openerName?: string,
  titleName?: string,
  submitName?: string,
  onSubmit: () => void,
  disabledSubmit?: boolean,
  // eslint-disable-next-line
  values?: any,
  isLoading?: boolean,
  attachFile?: boolean,
  autoClose?: boolean,
  // eslint-disable-next-line
  attachOnChange?: (value: any) => void,
  policy?: boolean,
  policyText?: string,
}

const CustomApplicationForm: FC<Props> = ({
  className,
  customModal,
  customTitle,
  customClose,
  customModalOverlay,
  modalContent,
  onOpen = () => {},
  onClose = () => {},
  openerName = 'Отправить форму',
  titleName = openerName,
  submitName = openerName,
  onSubmit,
  disabledSubmit = false,
  values,
  isLoading = false,
  autoClose = false,
  attachFile = false,
  attachOnChange,
  policy = false,
  policyText,
  children,
}) => {
  const C_inputFile = useRef(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [fileName, setfileName] = useState<string>('');
  const handleToggle = useCallback(() => {
    if (!isOpen) onOpen();
    setOpen((state) => !state);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
    onClose();
  }, []);

  useEffect(() => {
    if (autoClose) {
      handleClose();
    }
  }, [autoClose]);

  const attachOnChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    // eslint-disable-next-line
    const Files:File = (C_inputFile as any).current.files[0];
    if (Files) {
      // eslint-disable-next-line
      attachOnChange && attachOnChange(Files);
      setfileName(Files.name);
    }
  };

  return (
    <>
      <Button
        onClick={handleToggle}
        className={className || styles.customOpener}
        title={openerName}
      />
      <Modal
        onClose={handleClose}
        title={titleName}
        isOpen={isOpen}
        className={cx(customModal, styles.customModal)}
        classNameTitle={cx(customTitle, styles.customTitle)}
        classNameClose={cx(customClose, styles.customClose)}
        classNameOverlay={cx(customModalOverlay, styles.customModalOverlay)}
        classNameContent={cx(modalContent, styles.modalContent)}
      >
        <form onSubmit={onSubmit} className={styles.form}>
          {children}

          {attachFile && (
            <div className={styles.uploadInputWrap}>
              {/* eslint-disable-next-line */}
              <label htmlFor="file-upload" className={styles.uploadInput}>
                {fileName || _t('Прикрепить файл')}
              </label>
              <input
                id="file-upload"
                className={styles.uploadInputHidden}
                onChange={attachOnChangeHandler}
                name={values && values.attachedFile?.name}
                ref={C_inputFile}
                type="file"
                accept="file/*"
                multiple={false}
              />
              {/* {fileName && <span className={styles.fileName}>{}</span>} */}
            </div>
          )}

          {submitName && (
            <Button
              type="submit"
              className={styles.buttonSubmit}
              isLoading={isLoading}
              disabled={disabledSubmit}
              title={submitName}
            />
          )}
          {policy && <PolicyPolitics policyText={policyText} />}
        </form>
      </Modal>
    </>
  );
};

export default CustomApplicationForm;
