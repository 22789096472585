import React from 'react';
import { useFormik } from 'formik';
import { boolean, object, string } from 'yup';
import {
  Button, H2, Input, Section, SocialLinks, Text, Textarea, PersonalData,
} from 'components';
import { useRequest } from 'hooks';
import { ERRORS, URL, CONTACTS_VALUES } from 'appConstants';
import { toast } from 'react-toastify';
import { _t, sendMetrics, phoneRefactor } from 'utils';
import { RequestStatus } from 'types';
import styles from './styles.module.scss';

type Values = {
  name: string,
  email: string,
  theme: string,
  message: string,
  consent: boolean,
};

type Req = {
  name: string,
  email: string,
  theme: string,
  message: string,
};

const initialValues: Values = {
  name: '',
  email: '',
  theme: '',
  message: '',
  consent: true,
};

const validationSchema = object().shape({
  email: string()
    .email(ERRORS.email)
    .required(ERRORS.required),
  message: string()
    .required(ERRORS.required),
  consent: boolean().oneOf([true], ERRORS.required),
});

const smiPhone = CONTACTS_VALUES.phones.main.smi.split(',');

const Contacts = () => {
  const {
    values,
    isValid,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik<Values>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: ({
      name,
      email,
      message,
      theme,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
    }) => request({
      url: URL.FEEDBACK,
      method: 'post',
      data: {
        name,
        email,
        message,
        theme,
      },
    }),
  });

  const { request, status } = useRequest<Req>({
    callback: () => {
      toast.success(_t('Данные отправлены!'));
      sendMetrics('', ['form_feedback', 'form']);
      resetForm();
    },
  });

  return (
    <Section className={styles.wrap}>
      <div
        className={styles.contacts}
        itemScope
        itemType="http://schema.org/Organization"
      >
        <H2 className={styles.title} uppercase>{_t('Контакты')}</H2>
        <span className={styles.hidden} itemProp="name">Моспром</span>
        <div>
          <a
            href={`mailto:${CONTACTS_VALUES.emails.main.value}`}
            onClick={() => sendMetrics(null, ['email', 'click'])}
          >
            <Text className={styles.text} bold>
              <span itemProp="email">{CONTACTS_VALUES.emails.main.value}</span>
            </Text>
          </a>
        </div>
        <div>
          <a
            href={`tel:${CONTACTS_VALUES.phones.main.value}`}
            onClick={() => sendMetrics(null, ['telefon', 'tel'])}
          >
            <Text bold>
              <span itemProp="telephone">{phoneRefactor(CONTACTS_VALUES.phones.main.value)}</span>
            </Text>
          </a>
          <Text className={styles.text} bold>
            <span>{`${_t('доб')}. (${smiPhone[1]})`}</span>
          </Text>
        </div>
        <div itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
          <Text className={styles.text} bold>
            <span itemProp="postalCode">{CONTACTS_VALUES.addrs.main.postal}</span>,
            <span itemProp="addressCountry"> {_t(CONTACTS_VALUES.addrs.main.contry)}</span>,
            <span itemProp="addressLocality"> {_t(CONTACTS_VALUES.addrs.main.city)}</span>
            <br />
            <span itemProp="streetAddress">{_t(CONTACTS_VALUES.addrs.main.street)}</span>
          </Text>
        </div>
        <SocialLinks theme="black" />
      </div>

      <form className={styles.form} onSubmit={handleSubmit}>
        <H2 className={styles.title} color="extra" uppercase>{_t('Обратная связь')}</H2>
        <div>
          <Input
            name="name"
            placeholder="Ваше имя"
            className={styles.input}
            value={values.name}
            onChange={handleChange}
          />
          <Input
            name="email"
            placeholder="Ваш e-mail"
            className={styles.input}
            value={values.email}
            error={errors.email && touched.email ? errors.email : ''}
            onChange={handleChange}
          />
          <Input
            name="theme"
            placeholder="Тема"
            className={styles.input}
            value={values.theme}
            onChange={handleChange}
          />
          <Textarea
            name="message"
            placeholder="Сообщение"
            className={styles.input}
            error={errors.message && touched.message ? errors.message : ''}
            value={values.message}
            onChange={handleChange}
          />
        </div>
        <PersonalData
          name="checkbox"
          checkbox={values.consent}
          onChange={() => setFieldValue('consent', !values.consent)}
          className={styles.checkbox}
        />
        <Button
          type="submit"
          isLoading={status === RequestStatus.REQUEST}
          className={styles.buttonSubmit}
          disabled={!isValid}
          title="Отправить заявку"
        />
      </form>
    </Section>
  );
};

export default Contacts;
