import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { routes } from 'appConstants';
import { PersonalPageMenu } from 'components';
import {
  PersonalBaseLayout,
  PersonalLeadsMainTab,
  PersonalLeadsArchiveTab,
} from 'containers';

const Links = [
  {
    title: 'Все заявки',
    to: routes.personal.leads.root,
  },
  {
    title: 'Архив',
    to: routes.personal.leads.archive.root,
  },
];

const PersonalLeads = () => {
  return (
    <PersonalBaseLayout pageTitle={routes.personal.leads.title}>
      <PersonalPageMenu
        links={Links}
      />
      <Switch>
        <Route
          path={routes.personal.leads.root}
          exact
          render={() => (<PersonalLeadsMainTab />)}
        />
        <Route
          path={`${routes.personal.leads.archive.root}`}
          exact
          render={() => (<PersonalLeadsArchiveTab />)}
        />
        <Redirect to={{ pathname: routes.personal.leads.root }} />
      </Switch>
    </PersonalBaseLayout>
  );
};

export default PersonalLeads;
