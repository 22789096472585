import React, { FC, useState } from 'react';
import { _t } from 'utils';
import {
  TestGoodsData,
} from 'types';
import { routes } from 'appConstants';
import cx from 'classnames';
// import { testResults } from '../testData';
import PersonalGoodsListCard from './PersonalGoodsListCard';
import PersonalGoodsListLine from './PersonalGoodsListLine';
import styles from './styles.module.scss';

type dataFormat = {
  support_measures: Array<number>,
  financial_res: number,
  page: number,
  count: number,
  results: TestGoodsData[],
};

type Props = {
  title?: string,
  data: dataFormat,
  gridClassName?: string,
  listClassName?: string,
  gridItemClassName?: string,
  listItemClassName?: string,
  viewSwitcher?: boolean,
  showCount?: boolean,
  minCard?: boolean,
};

const PersonalBDLayout: FC<Props> = ({
  title,
  data,
  gridClassName,
  listClassName,
  gridItemClassName,
  listItemClassName,
  viewSwitcher = false,
  showCount = false,
  minCard,
}) => {
  const [defaultGridView, setDefaultGridView] = useState<boolean>(true);

  return (
    <div>
      {title && <div className={styles.title}>{title}</div>}
      {showCount && (
        <div className={styles.totalCount}>
          <div>
            {_t('Найдено предложений')}: {data?.count}
          </div>
          {viewSwitcher && (
            <div
              className={cx(styles.viewSwitcher, {
                [styles.switched]: !defaultGridView,
              })}
              onClick={() => setDefaultGridView(!defaultGridView)}
            />
          )}
        </div>
      )}
      <div
        className={cx({
          [gridClassName as string || styles.gridWrapper]: defaultGridView,
          [listClassName as string || styles.listWrapper]: !defaultGridView,
        })}
      >
        {data?.results.map((exchange) => (
          defaultGridView ? (
            <PersonalGoodsListCard
              className={gridItemClassName}
              key={exchange.id}
              value={exchange}
              targetDetailsPath={routes.personal.goods.root}
              minCard={minCard}
            />
          ) : (
            <PersonalGoodsListLine
              className={listItemClassName}
              key={exchange.id}
              value={exchange}
              targetDetailsPath={routes.personal.goods.root}
            />
          )
        ))}
      </div>
    </div>
  );
};

export default PersonalBDLayout;
