import type {
  MeState,
  AuthRes,
  SignUpResCamel,
  IExportTestResCamel,
  IProfileCamel,
  ICompanyCamel,
} from 'types';
import actionTypes, { MeAction } from './actionTypes';

const initialState: MeState = {
  email: '',
  token: '',
  refresh: '',
  isAuth: false,
  id: null,
  nameOfCompany: null,
  inn: null,
  fullName: null,
  position: null,
  phone: null,
  role: null,
  created: null,
  status: null,
  isActive: null,
  ProfileUser: null,
  ProfileCompany: null,
};

export default (state: MeState = initialState, action: MeAction): MeState => {
  switch (action.type) {
    case actionTypes.ME_LOGIN_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        isAuth: true,
        token: (payload as AuthRes).access as string,
        refresh: (payload as AuthRes).refresh as string,
      };
    }
    case actionTypes.ME_LOGOUT_SUCCESS: {
      return {
        ...state,
        isAuth: false,
        token: '',
        refresh: '',
      };
    }
    case actionTypes.ME_SIGN_UP_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        ...payload as SignUpResCamel,
      };
    }
    case actionTypes.ME_EXPORT_TEST_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        ...payload as IExportTestResCamel,
      };
    }
    case actionTypes.ME_PROFILE_USER_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        ProfileUser: payload as IProfileCamel,
      };
    }
    case actionTypes.ME_PROFILE_COMPANY_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        ProfileCompany: payload as ICompanyCamel,
      };
    }
    default:
      return state;
  }
};
