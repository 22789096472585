import {
  FC,
  PropsWithChildren,
  createElement,
} from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  wide?: boolean,
  tag?: 'div' | 'section' | 'nav',
  customRef?: (element: HTMLElement | null) => void,
};

const Section: FC<PropsWithChildren<Props>> = ({
  children,
  wide = false,
  tag = 'section',
  className,
  customRef,
}) => createElement(tag, {
  className: cx(
    { [styles.wide]: wide },
    styles.section,
    className,
  ),
  ref: customRef,
}, children);

export default Section;
