import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { MediaLayout } from 'containers';
import {
  BreadCrumbs, H2, HTMLContent, Label, Preloader, Text,
} from 'components';
import {
  mediaCategoryRoutes,
  routes,
  URL,
} from 'appConstants';
import { useRequest } from 'hooks';
import type { News } from 'types';
import { RequestStatus } from 'types';
import {
  dateToUiFormat,
  _t,
  _t_back,
  handleNoImage,
} from 'utils';
import styles from './styles.module.scss';

const MediaNewsDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { data, status, request } = useRequest<News>();

  const crumbs = useMemo(() => [
    {
      label: 'Медиа',
      to: routes.media.root,
    },
    {
      ...mediaCategoryRoutes[data?.category?.id || 0],
    },
    {
      label: _t_back(data, 'title'),
      to: `${routes.media.root}/${id}`,
    },
  ], [data]);

  useEffect(() => {
    request({
      method: 'get',
      url: `${URL.NEWS.DETAILS.GET}/${id}`,
    });
  }, []);

  return (
    <MediaLayout>
      <Preloader isLoading={status === RequestStatus.REQUEST}>
        <div className={styles.wrap}>
          <div className={styles.content}>
            <BreadCrumbs crumbs={crumbs} className={styles.crumbs} />

            {data?.image && (
              <img
                src={data?.image}
                className={styles.photo}
                alt={_t_back(data, 'title')}
                onError={handleNoImage}
                width="1px"
                height="1px"
              />
            )}

            <div className={styles.header}>
              <Label className={styles.label}>
                {_t(data?.category?.title || mediaCategoryRoutes[data?.category?.id || 0]?.label)}
              </Label>
              <Text tag="span" bold size="tiny">{dateToUiFormat(data?.pubdate)}</Text>
            </div>

            <H2 className={styles.title}>{_t_back(data, 'title')}</H2>
            <HTMLContent content={_t_back(data, 'text_of_article')} />
          </div>
        </div>
      </Preloader>
    </MediaLayout>
  );
};

export default MediaNewsDetails;
