import React, {
  FC,
  memo,
  useState,
} from 'react';
import clsx from 'classnames';
import { addDays, format } from 'date-fns';

import { _t } from 'utils';
import { ButtonRounded, Modal } from 'components';
import Link from 'components/NavLink';
import { routes } from 'appConstants';
import styles from './styles.module.scss';
import modalStyles from '../../styles.module.scss';

type Props = {
  onNext: () => void;
  titleNext: NextBtnTitle;
  isDisabledNext?: boolean;
  onBack: () => void;
  onExit: () => void;
  showExit?: boolean;
  showFixData?: boolean;
  sessionCreatedDate: string | null;
};
type NextBtnTitle = 'Подтвердить' | 'Далее' | 'Завершить';

const CardControls: FC<Props> = memo(({
  onBack,
  onNext,
  isDisabledNext = true,
  titleNext,
  onExit,
  showExit = true,
  showFixData = false,
  sessionCreatedDate,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const createdDate = typeof sessionCreatedDate === 'string' ?
    sessionCreatedDate : new Date();
  const date = format(
    addDays(new Date(createdDate), 7),
    'dd.MM.yyyy, HH:mm',
  );

  return (
    <div className={styles.controlsWrapper}>
      <div className={styles.pagination}>
        <ButtonRounded
          className={clsx(styles.button, styles.buttonMargin)}
          color="secondary"
          blueMode
          onClick={onBack}
        >
          {_t('Назад')}
        </ButtonRounded>
        <ButtonRounded
          className={styles.button}
          blueMode
          onClick={onNext}
          disabled={isDisabledNext}
        >
          {_t(titleNext)}
        </ButtonRounded>
      </div>
      {showExit && (
        <ButtonRounded
          className={styles.exitBtn}
          onClick={() => {
            setModalOpen(true);
          }}
          title={_t('Сохранить и выйти')}
          whiteMode
        />
      )}
      {!showExit && showFixData && (
        <Link to={routes.personal.profile.root}>
          <ButtonRounded
            className={styles.exitBtn}
            title={_t('Изменить данные')}
            whiteMode
          />
        </Link>
      )}

      <Modal
        onClose={() => setModalOpen(false)}
        title={_t('Обратите внимание!')}
        isOpen={isModalOpen}
        className={modalStyles.modalContent}
        classNameTitle={modalStyles.modalTitle}
        classNameOverlay={modalStyles.modalOverlay}
        classNameClose={modalStyles.modalBtnClose}
      >
        <div>
          <span className={modalStyles.modalText}>
            {`${_t('сохранение черновика')}`} <b>{date}</b>.
          </span>
          <div className={modalStyles.modalControls}>
            <ButtonRounded
              className={modalStyles.modalButton}
              color="secondary"
              blueMode
              onClick={() => setModalOpen(false)}
            >
              {_t('Отмена').toUpperCase()}
            </ButtonRounded>
            <ButtonRounded
              className={modalStyles.modalButton}
              color="primary"
              blueMode
              onClick={() => {
                setModalOpen(false);
                onExit();
              }}
            >
              {_t('Подтвердить').toUpperCase()}
            </ButtonRounded>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export { CardControls };
export type { NextBtnTitle };
