// author Victor K.

export const phoneRefactor = (phone: string) => {
  const code = phone.substring(0, 2);
  const opCode = phone.substring(2, 5);
  const oth1 = phone.substring(5, 8);
  const oth2 = phone.substring(8, 10);
  const oth3 = phone.substring(10, 12);
  return `${code} (${opCode}) ${oth1}-${oth2}-${oth3}`;
};
