/* eslint-disable no-else-return */
import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  testVPopulateSessionAction,
  testVSelectCheckbox,
  testVSelectCountry,
  testVSelectProduct,
  testVSelectProductManual,
  testVSelectRadio,
  testVSelectRange,
  testVSendAnswer,
  testVSetProductDesc,
} from 'store/test/actions';
import { State, Step } from 'types';
import { _t } from 'utils';
import { Kind } from '../CardContent';
import { ValidationCard } from './Card';

const Validation: FC<{}> = () => {
  const {
    validationMeta,
    validationSteps,
    validationTopic,
  } = useSelector(({ test }: State) => test);
  const dispatch = useDispatch();
  const {
    total,
    current,
    isGoing,
    isFinished,
    isSelectedProduct,
  } = validationMeta;
  const getCurrentStep = (currentNum: number, steps: Step[]) => steps
    .find(({ step }) => step === currentNum);

  if (isGoing && !isSelectedProduct) {
    return (
      <ValidationCard
        kind="productSelect"
        title={_t('выберите страну экспорта...')}
        countries={validationTopic.countries}
        products={validationTopic.products}
        onChangeCountry={(id) => dispatch(testVSelectCountry(id))}
        onChangeProduct={(id) => dispatch(testVSelectProduct(id))}
        onChangeProductManual={(value, field) => dispatch(testVSelectProductManual(value, field))}
        setProductDesc={(value) => dispatch(testVSetProductDesc(value))}
        handleNext={() => {
          dispatch(testVPopulateSessionAction());
        }}
      />
    );
  } else if (isGoing && current && total && !isFinished && isSelectedProduct) {
    const step = getCurrentStep(current, validationSteps);

    let kind: Kind = 'text';
    if (step?.type === 'S') kind = 'radio';
    if (step?.type === 'M') kind = 'checkbox';
    if (step?.type === 'R') kind = 'range';

    switch (kind) {
      case 'radio':
        return (
          <ValidationCard
            kind="radio"
            title={step?.text!}
            answers={step?.answers!}
            radioSelected={validationMeta.answer as (number | null)}
            onChangeRadio={(value: number) => dispatch(testVSelectRadio(value))}
            handleNext={() => dispatch(testVSendAnswer({ shouldIncrement: true }))}
          />
        );
      case 'checkbox':
        return (
          <ValidationCard
            kind="checkbox"
            title={step?.text!}
            answers={step?.answers!}
            checkboxSelected={validationMeta.answer as (number[] | null)}
            onChangeCheckbox={(id: number, isChecked: boolean) => {
              dispatch(testVSelectCheckbox(id, isChecked));
            }}
            handleNext={() => dispatch(testVSendAnswer({ shouldIncrement: true }))}
          />
        );
      case 'range':
        return (
          <ValidationCard
            kind="range"
            title={step?.text!}
            answers={step?.answers!}
            rangeSelected={validationMeta.answer as (number[] | null)}
            onChangeRange={(position: number, value: number | null) => {
              dispatch(testVSelectRange(position, value));
            }}
            handleNext={() => dispatch(testVSendAnswer({ shouldIncrement: true }))}
          />
        );
      default:
        break;
    }
  }
  return <></>;
};

export { Validation };
