import { FC, PropsWithChildren, createElement } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  tag?: 'div' | 'section' | 'article' | 'li' | 'a',
  target?: '_blank' | '_self' | '_parent' | '_top',
  title?: string,
  href?: string,
  withHoverEffect?: boolean,
  className?: string,
};

const Card: FC<PropsWithChildren<Props>> = ({
  children,
  tag = 'article',
  title,
  href,
  target,
  withHoverEffect = true,
  className,
}) => createElement(tag, {
  className: cx(
    styles.card,
    className,
    {
      [styles.withHover]: withHoverEffect,
    },
  ),
  href,
  title: title || '',
  target: tag === 'a' ? target : null,
}, children);

export default Card;
