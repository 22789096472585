/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import {
  Section,
  H1,
  Text,
  Button,
  Link,
} from 'components';
import { useSelector } from 'react-redux';
import {
  routes,
  showLoginOnProd,
} from 'appConstants';
import { history, _t } from 'utils';
import type { State } from 'types';
import mp4 from 'assets/video/main-video.mp4';
import poster from 'assets/img/main-bg.png';
import styles from './styles.module.scss';

const MainPreview = () => {
  const isAuth = useSelector((state: State) => state.me.isAuth);

  return (
    <section className={styles.wrap}>
      <div className={styles.video}>
        <video
          poster={poster}
          autoPlay
          loop
          muted
        >
          <source src={mp4} />
        </video>
        <img src={poster} alt="" />
      </div>
      <Section tag="div" className={styles.section}>
        <H1 color="secondary" className={styles.title}>
          {_t('Центр поддержки экспортеров')}
        </H1>
        <Text color="secondary" bold className={styles.subTitle}>
          {_t('Главный инструмент московского экспорта')}
        </Text>
        <div className={styles.actions}>
          <Button
            color="secondary"
            className={styles.detailBtn}
            onClick={() => history.push(routes.about.root)}
            title="О нас"
          />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
          {showLoginOnProd && (
            <Link
              to={isAuth ? routes.personal.events.list.root : routes.auth.login.root}
              className={styles.forBuyer}
            >
              <Text tag="span" size="tiny" color="secondary" bold uppercase>{_t('В личный кабинет')}</Text>
            </Link>
          )}
        </div>
      </Section>
    </section>
  );
};

export default MainPreview;
