import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  PersonalFaqMainTab,
  PersonalBaseLayout,
} from 'containers';
import { PersonalPageMenu } from 'components';
import { routes } from 'appConstants';

const Links = [
  {
    title: 'Основные вопросы',
    to: routes.personal.faq.root,
  },
];

const PersonalFaq = () => {
  return (
    <PersonalBaseLayout pageTitle="Справка">
      <PersonalPageMenu
        links={Links}
      />
      <Switch>
        <Route
          path={routes.personal.faq.root}
          exact
          render={() => (<PersonalFaqMainTab />)}
        />
        <Redirect to={{ pathname: routes.personal.faq.root }} />
      </Switch>
    </PersonalBaseLayout>
  );
};

export default PersonalFaq;
