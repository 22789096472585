import React, {
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import {
  CustomApplicationForm,
  Input,
  Textarea,
} from 'components';
import { ERRORS, URL } from 'appConstants';
import { _t } from 'utils';
import { toast } from 'react-toastify';
import { RequestStatus, State } from 'types';
import styles from './styles.module.scss';

type Values = {
  news_title: string,
  link: string,
  text: string,
};

const initialValues: Values = {
  news_title: '',
  link: '',
  text: '',
};

const validationSchema = object().shape({
  news_title: string().required(ERRORS.required),
  link: string().required(ERRORS.required),
  text: string().required(ERRORS.required),
});

const CustomData = new FormData();

const PersonalFeedRequest = () => {
  const [status, setStatus] = useState<'INIT' | 'SUCCESS'>('INIT');
  const token = useSelector(({ me }: State) => me.token);

  const {
    values,
    isValid,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik<Values>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onSubmit: () => upload(),
  });

  const upload = () => {
    CustomData.append('news_title', values.news_title);
    CustomData.append('link', values.link);
    CustomData.append('text', values.text);

    fetch(URL.PERSONAL.FEED.OFFER_NEWS, {
      method: 'POST',
      mode: 'cors',
      body: CustomData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return false;
      })
      .then(() => {
        toast.success(_t('Данные обновлены'));
        setStatus('SUCCESS');
        resetForm();
      })
      .catch((err) => toast.error(_t(err.response.data.detail || 'Ошибка загрузки, повторите позже')));
  };

  const addFile = (value: File) => CustomData.append('file', value);

  return (
    <CustomApplicationForm
      onSubmit={handleSubmit}
      openerName="Предложить новость"
      titleName="Заполните заявку на публикацию новости и прикрепите необходимые файлы"
      disabledSubmit={isValid && !isValid}
      autoClose={status === RequestStatus.SUCCESS}
      attachFile
      attachOnChange={addFile}
    >
      <Input
        name="news_title"
        placeholder="Название новости"
        className={styles.input}
        error={errors.news_title && touched.news_title ? errors.news_title : ''}
        value={values.news_title}
        onChange={handleChange}
      />
      <Input
        name="link"
        placeholder="Ссылка на источник"
        className={styles.input}
        error={errors.link && touched.link ? errors.link : ''}
        value={values.link}
        onChange={handleChange}
      />
      <Textarea
        name="text"
        placeholder="Текст новости"
        className={styles.textarea}
        error={errors.text && touched.text ? errors.text : ''}
        value={values.text}
        onChange={handleChange}
      />
    </CustomApplicationForm>
  );
};

export default PersonalFeedRequest;
