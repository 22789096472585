import { useState } from 'react';
import { apiClient } from 'utils';
import { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { RequestStatus } from 'types';
import { meLogoutAction } from 'store/me/actions';
import { useDispatch } from 'react-redux';

type HookConfig = {
  callback?: () => void,
  errback?: () => void,
};

interface AxiosRequestConfigAdds extends AxiosRequestConfig {
  mode?: string,
}

export default<T = never> (
  hookConfig: HookConfig = {},
): {
  status: RequestStatus,
  data: T | null,
  request: (config: AxiosRequestConfigAdds) => void,
} => {
  const dispatch = useDispatch();

  const [status, setStatus] = useState<RequestStatus>(RequestStatus.INIT);
  const [data, setData] = useState<T | null>(null);

  const request = async (config: AxiosRequestConfigAdds) => {
    setStatus(RequestStatus.REQUEST);
    try {
      const res = await apiClient(config);
      setData(res.data);
      setStatus(RequestStatus.SUCCESS);
      if (hookConfig.callback) hookConfig.callback();
    } catch (err) {
      if (err?.response?.data?.code as string === 'token_not_valid') {
        dispatch(meLogoutAction());
      } else if (hookConfig.errback) {
        hookConfig.errback();
      } else {
        toast.error(
          `Непредвиденная ошибка сервера${err?.response?.data ? ` (${err?.response?.data?.detail})` : ''}, повторите позже`,
        );
      }
      setStatus(RequestStatus.ERROR);
    }
  };

  return {
    status,
    request,
    data,
  };
};
