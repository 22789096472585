import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  PersonalCatalogMainTab,
  PersonalBaseLayout,
} from 'containers';
import { PersonalPageMenu } from 'components';
import { routes } from 'appConstants';

const PersonalCatalog = () => {
  return (
    <PersonalBaseLayout pageTitle={routes.personal.catalog.title}>
      <PersonalPageMenu
        links={[]}
      />
      <Switch>
        <Route
          path={routes.personal.catalog.root}
          exact
          render={() => (<PersonalCatalogMainTab />)}
        />
        <Redirect to={{ pathname: routes.personal.catalog.root }} />
      </Switch>
    </PersonalBaseLayout>
  );
};

export default PersonalCatalog;
