import React, {
  useEffect,
} from 'react';
import { setPageMeta, routes } from 'appConstants';
import { About as Container } from 'containers';

const About = () => {
  useEffect(() => {
    setPageMeta(routes.about.title, routes.about.descr);
  });

  return <Container />;
};

export default About;
