import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { routes } from 'appConstants';
import {
  PublicAddRoute,
  // MediaInteresting,
  MediaNews,
  // MediaHistory,
  // MediaInterview,
  MediaDetails,
  MediaGallery,
  MediaArticles,
  MediaSpecialProjects,
  MediaTechTours,
  MediaGalleryDetails,
  MediaVideos,
} from 'containers';

const Media = () => (
  <Switch>
    {/* <PublicAddRoute
      path={routes.media.interesting.root}
      item={routes.media.interesting}
      exact
      component={MediaInteresting}
    /> */}

    <PublicAddRoute
      path={routes.media.news.root}
      item={routes.media.news}
      exact
      component={MediaNews}
    />

    {/* <PublicAddRoute
      path={routes.media.history.root}
      item={routes.media.history}
      exact
      component={MediaHistory}
    /> */}

    {/* <PublicAddRoute
      path={routes.media.interview.root}
      item={routes.media.interview}
      exact
      component={MediaInterview}
    /> */}

    <PublicAddRoute
      path={routes.media.gallery.root}
      item={routes.media.gallery}
      exact
      component={MediaGallery}
    />

    <PublicAddRoute
      path={routes.media.articles.root}
      item={routes.media.articles}
      exact
      component={MediaArticles}
    />

    <PublicAddRoute
      path={routes.media.specProjects.root}
      item={routes.media.specProjects}
      exact
      component={MediaSpecialProjects}
    />

    <PublicAddRoute
      path={routes.media.techTours.root}
      item={routes.media.techTours}
      exact
      component={MediaTechTours}
    />

    <PublicAddRoute
      path={`${routes.media.gallery.root}/:id`}
      exact
      component={MediaGalleryDetails}
    />

    <PublicAddRoute
      path={`${routes.media.root}/:id`}
      exact
      component={MediaDetails}
    />

    <PublicAddRoute
      path={routes.media.videoAlbums.root}
      item={routes.media.videoAlbums}
      exact
      component={MediaVideos}
    />

    <PublicAddRoute
      path={`${routes.media.videoAlbums.root}/:id`}
      exact
      component={MediaVideos}
    />
    {/* Video Pages */}
    <PublicAddRoute
      path={`${routes.media.videoFaces.root}/:id`}
      exact
      component={MediaVideos}
    />

    <PublicAddRoute
      path={`${routes.media.videoLifeHacks.root}/:id`}
      exact
      component={MediaVideos}
    />

    <PublicAddRoute
      path={`${routes.media.videoWhatBuy.root}/:id`}
      exact
      component={MediaVideos}
    />

    <PublicAddRoute
      path={`${routes.media.videoAboutExport.root}/:id`}
      exact
      component={MediaVideos}
    />

    <Redirect to={routes.media.news.root} />
    {/* <Redirect to={routes.media.interesting.root} /> */}
  </Switch>
);

export default Media;
