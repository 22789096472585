import { put, takeLatest, call } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { toast } from 'react-toastify';
import { URL } from 'appConstants';
import { TESTS_ERRORS } from 'utils';
import type {
  Response,
  PersonalDataRes,
  QSessionIDRes,
  SessionStepsRes,
  QSessionsRes,
  GetHtmlReportRes,
  // TAdeptResults,
} from 'types';
import { store } from 'store/configureStore';
import api from './api';
import actionTypes, { TestAction as Action } from '../actionTypes';
import {
  testQRemoveSession,
  testQReset,
  testQShowReport,
  testQShowFinalAction,
  testQVisibleToggle,
} from '../actions';
import { APIError, errorHandler } from './errorHandler';

function* getPersonalData({ type }: Action) {
  try {
    yield put(apiActions.request(type));
    const { data: personalData }: Response<PersonalDataRes['member']> = yield call(api, {
      method: 'GET',
      url: URL.PERSONAL.PROFILE.USER,
    });
    const { data: company }: Response<PersonalDataRes['company']> = yield call(api, {
      method: 'GET',
      url: `${URL.PERSONAL.PROFILE.COMPANY}/${personalData.client.company}/`,
    });
    yield put(apiActions.success(type, { member: personalData, company }));
  } catch (err) {
    yield errorHandler({ type, err });
  }
}

function* getSteps({ type }: Action) {
  try {
    const { test } = store.getState();
    const {
      qualificationSessionID: sessionId,
      personalData: {
        member: { role },
      },
    } = test;
    if (!sessionId) throw new Error('Not found active qualification');
    if (role !== 'E') throw new Error('Not authorized for the current user');
    yield put(apiActions.request(type));
    const { data }: Response<SessionStepsRes> = yield call(api, {
      method: 'GET',
      url: `${URL.TEST.QUALIFICATION.Q}/${sessionId}/steps`,
    });
    yield put(apiActions.success(type, data));
  } catch (err) {
    yield errorHandler({ type, err, showToast: false });
  }
}

function* getSessionId({ type }: Action) {
  try {
    const { test } = store.getState();
    const {
      // qualificationSessionID,
      // qualificationMeta: { isFinished },
      personalData: {
        member: { role },
      },
    } = test;
    if (role !== 'E') throw new Error('Not authorized for the current user');

    yield put(apiActions.request(type));
    const { data }: Response<QSessionIDRes> = yield call(api, {
      method: 'POST',
      url: URL.TEST.QUALIFICATION.GET_ID,
    });
    yield put(apiActions.success(type, data));
    yield call(getSteps, { type: actionTypes.TEST_Q_STEPS } as Action);
  } catch (err) {
    const error = TESTS_ERRORS[(err as Error).response.data.reason];
    if ((err as Error).response.data.code === 2) {
      toast.info(error || (err as Error).response.data.message);
    } else {
      toast.error(error || (err as Error).response.data.message);
    }
    yield put(apiActions.error(type, err));
  }
}

function* getSessions({ type }: Action) {
  try {
    const msTo = {
      days: 1000 * 60 * 60 * 24,
      mins: 1000 * 60,
    };
    const {
      personalData: {
        member: { role },
      },
    } = store.getState().test;

    if (role !== 'E') throw new Error('Not authorized for the current user');

    yield put(apiActions.request(type));
    const { data: SessionInfo }: Response<QSessionsRes> = yield call(api, {
      method: 'GET',
      url: URL.TEST.QUALIFICATION.SESSION_INFO,
    });

    // const { data: Sessions }: Response<{ id: string }[]> = yield call(api, {
    //   method: 'GET',
    //   url: URL.TEST.QUALIFICATION.GET_SESSIONS,
    // });

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const createdMS = ((new Date()) as any) - ((new Date(SessionInfo.created)) as any);
    if (
      createdMS / msTo.days >= 7 &&
      SessionInfo.status === 'A'
    ) {
      yield call(getSessionId, { type: actionTypes.TEST_Q_SESSION_ID } as Action);
      store.dispatch(testQRemoveSession());
      return;
    }
    yield put(apiActions.success(type, SessionInfo));

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const updatedMS = ((new Date()) as any) - ((new Date(SessionInfo.updated)) as any);
    // if (SessionInfo.status === 'A') {
    if (SessionInfo.status === 'A' || (updatedMS / msTo.mins) >= 5) {
      yield call(getSessionId, { type: actionTypes.TEST_Q_SESSION_ID } as Action);
    }
  } catch (err) {
    if ((err as APIError).response?.data.code === 14) {
      store.dispatch(testQReset());
      yield call(getSessionId, { type: actionTypes.TEST_Q_SESSION_ID } as Action);
    } else {
      yield errorHandler({ type, err });
    }
  }
}

function* init({ type }: Action) {
  try {
    yield call(getPersonalData, { type: actionTypes.TEST_PERSONAL_DATA } as Action);
    yield call(getSteps, { type: actionTypes.TEST_Q_STEPS } as Action);
  } catch (err) {
    yield errorHandler({ type, err, showToast: false });
  }
}

function* getSessionIdAndRunTest({ type }: Action) {
  try {
    yield put(apiActions.request(type));
    const { test } = store.getState();

    if (!store.getState().test.qualificationSessionID) {
      yield call(getSessions, { type: actionTypes.TEST_Q_SESSIONS } as Action);
    }

    if (
      store.getState().test.qualificationSessionID &&
      !test.qualificationSteps.length
    ) {
      yield call(getSteps, { type: actionTypes.TEST_Q_STEPS } as Action);
    }

    if (
      store.getState().test.qualificationSessionID &&
      store.getState().test.qualificationSteps.length
    ) {
      store.dispatch(testQVisibleToggle(true));
    }
    yield put(apiActions.success(type));
  } catch (err) {
    yield errorHandler({ type, err, showToast: false });
  }
}

function* finish({ type }: Action) {
  try {
    const {
      qualificationSessionID: sessionId,
      qualificationSteps,
      personalData: {
        member: { role },
      },
    } = store.getState().test;
    const isAllAnswers = qualificationSteps.every((step) => !!step.answer);

    if (!sessionId) throw new Error('Not found active qualification');
    if (!isAllAnswers) throw new Error('You have unfilled answers');
    if (role !== 'E') throw new Error('Not authorized for the current user');

    yield put(apiActions.request(type));
    yield call(api, {
      method: 'POST',
      url: `${URL.TEST.QUALIFICATION.Q}/${sessionId}/finish`,
    });
    yield put(apiActions.success(type));
  } catch (err) {
    yield errorHandler({ type, err });
  }
}

function* sendAnswer({ type, payload }: Action) {
  try {
    const {
      qualificationSessionID: sessionId,
      qualificationMeta: {
        current,
        answer,
        total,
      },
      personalData: {
        member: { role },
      },
      qualificationSteps,
    } = store.getState().test;
    const {
      shouldExit,
      shouldFinish,
      shouldIncrement,
    } = payload as {
      shouldExit: boolean;
      shouldFinish: boolean;
      shouldIncrement: boolean;
    };

    if (!sessionId) throw new Error('Not found active qualification');
    if (answer === null) throw new Error('You have to select an answer');
    if (role !== 'E') throw new Error('Not authorized for the current user');

    const currentStep = qualificationSteps.find((step) => step.step === current);
    if (JSON.stringify(answer) !== JSON.stringify(currentStep?.answer)) {
      yield put(apiActions.request(type));
      yield call(api, {
        method: 'POST',
        url: `${URL.TEST.QUALIFICATION.Q}/${sessionId}/step/${current}`,
        data: { answer },
      });
      yield put(apiActions.success(type));
    }

    if (shouldIncrement) {
      yield put({ type: actionTypes.TEST_Q_INCREMENT_STEP });
    }

    if (shouldExit) {
      if (current === total && shouldFinish) {
        yield call(finish, { type: actionTypes.TEST_Q_FINISH } as Action);
      }
      yield put({ type: actionTypes.TEST_Q_HIDE });
    }
  } catch (err) {
    yield errorHandler({ type, err });
  }
}

function* removeSessionId({ type }: Action) {
  try {
    const {
      qualificationSessionID: sessionId,
      personalData: {
        member: { role },
      },
    } = store.getState().test;
    if (!sessionId) throw new Error('Not found active qualification');
    if (role !== 'E') throw new Error('Not authorized for the current user');

    yield put(apiActions.request(type));
    yield call(api, {
      method: 'DELETE',
      url: `${URL.TEST.QUALIFICATION.Q}/${sessionId}`,
    });
    yield put(apiActions.success(type));
    yield call(getSessions, { type: actionTypes.TEST_Q_SESSIONS } as Action);
    yield call(getSteps, { type: actionTypes.TEST_Q_STEPS } as Action);
  } catch (err) {
    yield errorHandler({ type, err });
  }
}

function* getHtmlReport({ type }: Action) {
  try {
    const {
      qualificationSessionID: sessionId,
      qualificationMeta: { hiddenSessionID },
      personalData: {
        member: { role },
      },
    } = store.getState().test;
    if (!(sessionId || hiddenSessionID)) throw new Error('Not found active qualification');
    if (role !== 'E') throw new Error('Not authorized for the current user');

    yield put(apiActions.request(type));
    const { data }: Response<GetHtmlReportRes> = yield call(api, {
      method: 'GET',
      url: `${URL.TEST.QUALIFICATION.Q}/${sessionId || hiddenSessionID}/html`,
    });
    yield put(apiActions.success(type, { html: data.html }));
  } catch (err) {
    yield errorHandler({ type, err });
  }
}

function* getPdfReport({ type }: Action) {
  try {
    const {
      qualificationSessionID: sessionId,
      qualificationMeta: { hiddenSessionID },
      personalData: {
        member: { role },
      },
    } = store.getState().test;
    if (!(sessionId || hiddenSessionID)) throw new Error('Not found active qualification');
    if (role !== 'E') throw new Error('Not authorized for the current user');

    yield put(apiActions.request(type));
    const response: Response<BlobPart> = yield call(api, {
      method: 'GET',
      url: `${URL.TEST.QUALIFICATION.Q}/${sessionId || hiddenSessionID}/report.pdf`,
      responseType: 'blob',
    });
    const file = new Blob(
      [response.data],
      { type: 'application/pdf' },
    );
    const fileURL = window.URL.createObjectURL(file);
    window.open(fileURL);

    yield put(apiActions.success(type));
  } catch (err) {
    yield errorHandler({ type, err });
  }
}

function* finishAndShowReport({ type }: Action) {
  try {
    const {
      qualificationSessionID: hiddenSessionID,
    } = store.getState().test;
    yield call(finish, { type: actionTypes.TEST_Q_FINISH } as Action);
    yield call(getHtmlReport, { type: actionTypes.TEST_Q_GET_HTML_REPORT } as Action);
    store.dispatch(testQShowFinalAction());
    // store.dispatch(testQShowReport(hiddenSessionID as string));
    yield put(testQShowReport(hiddenSessionID as string));
    store.dispatch(testQVisibleToggle(false));
  } catch (err) {
    yield errorHandler({ type, err });
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.TEST_INIT, init);
  yield takeLatest(actionTypes.TEST_PERSONAL_DATA, getPersonalData);
  yield takeLatest(actionTypes.TEST_Q_SESSION_ID, getSessionId);
  yield takeLatest(actionTypes.TEST_Q_SESSION_ID_AND_RUN_TEST, getSessionIdAndRunTest);
  yield takeLatest(actionTypes.TEST_Q_SESSIONS, getSessions);
  yield takeLatest(actionTypes.TEST_Q_STEPS, getSteps);
  yield takeLatest(actionTypes.TEST_Q_SEND_ANSWER, sendAnswer);
  yield takeLatest(actionTypes.TEST_Q_FINISH, finish);
  yield takeLatest(actionTypes.TEST_Q_REMOVE_SESSION, removeSessionId);
  yield takeLatest(actionTypes.TEST_Q_FINISH_AND_SHOW_REPORT, finishAndShowReport);
  yield takeLatest(actionTypes.TEST_Q_GET_HTML_REPORT, getHtmlReport);
  yield takeLatest(actionTypes.TEST_Q_GET_PDF_REPORT, getPdfReport);
}

type Error = {
  response: {
    data: {
      detail: string;
      message: string;
      reason: string;
      code: number;
    };
  };
};
