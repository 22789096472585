import React, { FC } from 'react';
import { HTMLContent } from 'components';
import { Feed } from 'types';
import { dateToUiFormat } from 'utils';
// import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  FeedItem: Feed,
};

const FeedNewsBlock: FC<Props> = ({
  FeedItem: {
    title,
    text,
    link,
    pubdate,
  },
}) => (
  <div className={styles.FeedNewsBlock}>
    <h3 className={styles.title}>{title}</h3>
    <HTMLContent className={styles.text} content={text || ''} />
    <div className={styles.footer}>
      <div className={styles.date}>{dateToUiFormat(pubdate)} г.</div>
      <a
        className={styles.externalLink}
        target="_blank"
        href={link}
        rel="noopener noreferrer"
      >
        {link}
      </a>
    </div>
  </div>
);

export default FeedNewsBlock;
