import format from 'date-fns/format';
import { DATE_FORMATS } from 'appConstants';

export const dateToUiFormat = (value?: string | Date | null) => {
  if (value) return format(new Date(value), DATE_FORMATS.UI);
  return '';
};

export const dateToBackendFormat = (value?: string | Date) => {
  if (value) return format(new Date(value), DATE_FORMATS.BACKEND);
  return '';
};
