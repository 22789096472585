import React, { FC, useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  max?: string,
  cur?: string,
  level?: 'high' | 'mid' | 'low' | 'def';
  descr?: string,
};

const Levels = {
  def: '#f9b445',
  high: '#57627A',
  mid: '#DADADA',
  low: '#B71A3C',
};

const RadialChart:FC<Props> = ({
  className,
  max = '1',
  cur = '0',
  level = 'def',
  descr,
}) => useMemo(() => {
  return (
    <div className={styles.chart}>
      <div className={styles.donutWrapper}>
        <div className={styles.donut}>
          <Doughnut
            width={100}
            height={100}
            options={{
              animation: {
                duration: 0,
              },
            }}
            data={{
              datasets: [{
                data: [+cur, (+max - +cur)],
                backgroundColor: [Levels?.[level], '#f1f1f1'],
                borderWidth: 0,
              }],
            }}
          />
        </div>
      </div>
      <div className={cx(className, styles.donutLegend)}>
        <span>{cur}</span>
        {descr}
      </div>
    </div>
  );
}, [cur, max]);

export default RadialChart;
