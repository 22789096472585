import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  Login,
  HeaderLogin,
  SignUp,
  RestorePass,
  NewPass,
  ExportTest,
  Activation,
  Activated,
} from 'containers';
import { routes } from 'appConstants';
import styles from './styles.module.scss';

const Auth = () => {
  const [email, setEmail] = useState('');

  return(
    <div className={styles.wrapper}>
      <HeaderLogin />
      <main className={styles.main}>
        <Switch>
          <Route
            exact
            path={routes.auth.login.root}
            component={Login}
          />
          <Route
            exact
            path={routes.auth.restore.root}
            component={RestorePass}
          />
          <Route
            exact
            path={routes.auth.newPass.root}
            component={NewPass}
          />
          <Route
            exact
            path={routes.auth.signUp.root}
            render={() => <SignUp setEmail={setEmail} />}
          />
          <Route
            exact
            path={routes.auth.exportTest.root}
            component={ExportTest}
          />
          <Route
            exact
            path={routes.auth.activation.root}
            render={() => <Activation email={email} />}
          />
          <Route
            exact
            path={routes.auth.activated.root}
            component={Activated}
          />
          <Redirect to={{ pathname: routes.auth.login.root }} />
        </Switch>
      </main>
    </div>
  );
};

export default Auth;
