import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import { Section, H1 } from 'components';
import { _t } from 'utils';
import styles from './styles.module.scss';

type Props = {
  title: string,
  className?: string,
  classNameTitle?: string,
};

const MainLayout: FC<PropsWithChildren<Props>> = ({
  title,
  children,
  className,
  classNameTitle,
}) => (
  <>
    {title && (
      <Section className={styles.header}>
        <H1 className={cx(styles.title, classNameTitle)} uppercase>{_t(title)}</H1>
      </Section>
    )}
    <Section className={cx(className)}>
      {children}
    </Section>
  </>
);

export default MainLayout;
