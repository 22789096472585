/* eslint-disable react/button-has-type */
import React, { FC, MouseEvent, PropsWithChildren } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  type?: 'button' | 'submit',
  className?: string,
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void,
};

const ButtonCircle: FC<PropsWithChildren<Props>> = ({
  children,
  type = 'button',
  className,
  onClick = () => {},
}) => (
  <>
    <button
      type={type}
      className={cx(styles.btn, className)}
      onClick={onClick}
    >
      {children}
    </button>
  </>

);

export default ButtonCircle;
