import React from 'react';
import { hideAfterDate } from 'utils';
import vertBanner from 'assets/img/banners/census-vertical.jpg';
import horBanner from 'assets/img/banners/census-horizontal.jpg';
import cx from 'classnames';
import styles from './styles.module.scss';

interface Props {
  wiev: 'vertical' | 'horizontal';
  to?: string,
  // lastDate?: string,
  lastDate1?: string,
  lastDate2?: string,
}

const Banners:{[index:string]:string} = {
  vertical: vertBanner,
  horizontal: horBanner,
};

const BannerBlock = ({
  wiev = 'vertical',
  lastDate1 = '10.15.2021',
  lastDate2 = '11.14.2021',
}: Props) => {
  const show = !hideAfterDate(lastDate1) || !hideAfterDate(lastDate2);

  return show ? (
    <a
      href={!hideAfterDate(lastDate1) ? 'https://www.strana2020.ru/howto/' : 'http://census.gosuslugi.ru/'}
      className={cx(
        styles.bannerLink,
        styles[wiev],
      )}
      target="blank"
    >
      <img
        src={Banners[wiev]}
        className={cx(styles.banner)}
        alt={`VoteBanner_${wiev}`}
        width="10px"
        height="10px"
      />
    </a>
  ) : (<div />);
};

export default BannerBlock;
