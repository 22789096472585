/* eslint-disable */
import React, {
  useEffect,
} from 'react';
import { _t } from 'utils';
import styles from './styles.module.scss';

const PersonalCatalogMainTab = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'pp-widget';
    script.src = 'https://widget.test.pp24.dev/lib/widget.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      // @ts-ignore
      window.document.getElementById('pp-widget-root')?.remove();
    }
  });

  const clickHandler = () => {
    // @ts-ignore
    if (window.document.getElementById('pp-widget-app-root')) {
      // @ts-ignore
      const childNodes = window.document.getElementById('pp-widget-app-root').shadowRoot.childNodes;
      childNodes.forEach(node => {
        // @ts-ignore
        if (!node.hasAttributes()) {
          // @ts-ignore
          node.getElementsByTagName('img')[0].click();
        }
      });

    }
  }

  return (
    <div
      className={styles.openCat}
      onClick={() => clickHandler()}
    >
      {_t('Открыть каталог')}
    </div>
    // <div className={styles.mainContent}>
    //   {/* <div id="pp-widget-root" /> */}
    // </div>
  );
};

export default PersonalCatalogMainTab;
