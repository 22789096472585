/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import cx from 'classnames';
import {
  Card,
  Link,
  Text,
  LabelValue,
} from 'components';
import type { Resolution } from 'types';
import { _t, _t_back } from 'utils';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  value: Resolution,
  targetDetailsPath?: string,
};

const ResolutionsListCard: FC<Props> = ({ className, value, targetDetailsPath }) => (
  <Card
    key={value.id}
    tag="div"
    className={cx(styles.card, className)}
  >
    <Link
      to={`${targetDetailsPath}/${value.slug}`}
      className={styles.linkWrap}
    >
      <Text
        size="small"
        bold
        className={styles.title}
      >
        {_t_back(value, 'res_title')}
      </Text>

      <div className={styles.info}>
        {value.moscow_or_rf && (
          <LabelValue
            className={styles.labelValue}
            label="Регион"
            value={value.moscow_or_rf.short_description || value.moscow_or_rf.title}
          />
        )}

        {value.organizer_of_resolution.length ? (
          <LabelValue
            className={styles.labelValue}
            label="Ответственный орган"
            value={value.organizer_of_resolution[0].title}
          />
        ) : null}
      </div>

      <div className={cx(styles.btnWrap, 'btnWrap')}>
        <button
          type="button"
          className={styles.btn}
        >
          {_t('Подробнее')}
        </button>
      </div>
    </Link>
  </Card>
);

export default ResolutionsListCard;
