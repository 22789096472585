import React, {
  FC, useCallback, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, PersonalData, Input, Section, Text,
} from 'components';
import {
  RequestStatus, IExportTest, State,
} from 'types';
import { useFormik } from 'formik';
import { boolean, object, string } from 'yup';
import { ERRORS } from 'appConstants';
import { _t, sendMetrics } from 'utils';
import { meExportTestAction } from 'store/me/actions';
import styles from './styles.module.scss';

const validationSchema = object().shape({
  nameOfCompany: string().required(ERRORS.required),
  inn: string().min(10, ERRORS.min(10)).required(ERRORS.required),
  fullName: string().required(ERRORS.required),
  position: string().required(ERRORS.required),
  phone: string().min(18, ERRORS.phone).required(ERRORS.required),
  email: string().min(5, ERRORS.email).required(ERRORS.required),
  consent: boolean().oneOf([true], ERRORS.required),
});

const initialValues: IExportTest = {
  nameOfCompany: '',
  inn: '',
  fullName: '',
  position: '',
  phone: '',
  email: '',
  isActive: true,
  status: 'N',
  consent: true,
};

const ExportTest: FC = () => {
  const dispatch = useDispatch();
  const exportTestReqStatus = useSelector(({ ui }: State) => ui.ME_EXPORT_TEST);
  // const isAuth = useSelector(({ me }: State) => me.isAuth);

  const {
    values,
    isValid,
    errors,
    touched,
    setErrors,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik<IExportTest>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: (obj) => {
      const formatObj: IExportTest = {
        ...obj,
      };

      dispatch(meExportTestAction(formatObj));
      sendMetrics('', ['form_passing_test', 'form']);
    },
  });

  useEffect(() => {
    if (exportTestReqStatus === RequestStatus.ERROR) {
      setErrors({
        nameOfCompany: '',
        inn: '',
        fullName: '',
        position: '',
        phone: '',
        email: '',
      });
    }
  }, [exportTestReqStatus]);

  const handleChangeWithMask = useCallback(({ target: { name, value } }) => {
    const event = {
      target: {
        name,
        value: value.replace(/_+$/, ''),
      },
    };

    handleChange(event);
  }, []);

  return (
    <Section>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Text
          tag="span"
          align="center"
          className={styles.title}
          bold
          uppercase
        >
          {_t('Заявка на прохождение теста')}
        </Text>
        <Input
          name="nameOfCompany"
          label="Название компании"
          placeholder="Название компании"
          className={styles.input}
          value={values.nameOfCompany}
          error={errors?.hasOwnProperty('nameOfCompany') && touched.nameOfCompany ? errors.nameOfCompany || true : ''}
          onChange={handleChange}
        />
        <Input
          name="inn"
          label="ИНН"
          placeholder="ИНН"
          className={styles.input}
          value={values.inn}
          error={errors?.hasOwnProperty('inn') && touched.inn ? errors.inn || true : ''}
          onChange={handleChangeWithMask}
          mask="999999999999"
        />
        <Input
          name="fullName"
          label="ФИО"
          placeholder="Фамилия Имя Отчество"
          className={styles.input}
          value={values.fullName}
          error={errors?.hasOwnProperty('fullName') && touched.fullName ? errors.fullName || true : ''}
          onChange={handleChange}
        />
        <Input
          name="position"
          label="Должность"
          placeholder="Должность"
          className={styles.input}
          value={values.position}
          error={errors?.hasOwnProperty('position') && touched.position ? errors.position || true : ''}
          onChange={handleChange}
        />
        <Input
          name="phone"
          label="Контактный телефон"
          placeholder="Контактный телефон"
          className={styles.input}
          value={values.phone}
          error={errors?.hasOwnProperty('phone') && touched.phone ? errors.phone || true : ''}
          onChange={handleChangeWithMask}
          mask="+7 (999) 999-99-99"
        />
        <Input
          name="email"
          label="E-mail"
          placeholder="example@mail.com"
          className={styles.input}
          value={values.email}
          error={errors?.hasOwnProperty('phone') && touched.email ? errors.email || true : ''}
          onChange={handleChange}
        />
        <PersonalData
          name="checkbox"
          checkbox={values.consent}
          onChange={() => setFieldValue('consent', !values.consent)}
          className={styles.checkbox}
        />
        <Button
          type="submit"
          color="secondary"
          disabled={!isValid}
          isLoading={exportTestReqStatus === RequestStatus.REQUEST}
          className={styles.btn}
          title="Отправить заявку"
        />
      </form>
    </Section>
  );
};

export default ExportTest;
