import React from 'react';
// import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from 'appConstants';
// import { routes, setPageMeta } from 'appConstants';
import {
  Main,
  Resolutions,
  Services,
  Events,
  Media,
  Page404,
  Personal,
  Auth,
  Contacts,
  About,
  Welcome,
  Analytics,
} from 'pages';
import { ProtectedRoute, PublicRoute } from 'containers';

const Routes = () => {
  return (
    <Switch>
      <PublicRoute
        path={routes.main.root}
        item={routes.main}
        exact
        component={Main}
      />
      <PublicRoute
        path={routes.resolutions.root}
        item={routes.resolutions}
        component={Resolutions}
      />
      <PublicRoute
        path={routes.services.root}
        item={routes.services}
        exact
        component={Services}
      />
      <PublicRoute
        path={routes.events.root}
        item={routes.events}
        component={Events}
      />
      <PublicRoute
        path={routes.media.root}
        item={routes.media}
        component={Media}
      />
      <PublicRoute
        path={routes.contacts.root}
        item={routes.contacts}
        component={Contacts}
      />
      <PublicRoute
        path={routes.about.root}
        item={routes.about}
        component={About}
      />
      <PublicRoute
        path={routes.analytics.root}
        item={routes.analytics}
        component={Analytics}
      />
      <PublicRoute
        path={routes.personal.welcome.root}
        item={routes.personal.welcome}
        component={Welcome}
      />
      <ProtectedRoute path={routes.personal.root} component={Personal} />
      <Route path={routes.auth.root} component={Auth} />
      <PublicRoute
        path={routes.page404.root}
        item={routes.page404}
        component={Page404}
      />
      <Redirect to={{ pathname: routes.page404.root }} />
    </Switch>
  );
};

export default Routes;
