import {
  Action,
  AuthReq,
  RestoreReq,
  AuthRes,
  SendApplicationsReq,
  ConfirmPassReq,
  SignUpResCamel,
  IExportTest,
  IExportTestResCamel,
  IProfileSnake,
  IProfileCamel,
  ICompanySnake,
  ICompanyCamel,
  SignUpResSnake,
  Activation,
} from 'types';

const ME_LOGIN = 'ME_LOGIN';
const ME_LOGIN_SUCCESS = 'ME_LOGIN_SUCCESS';

const ME_RESTORE = 'ME_RESTORE';
const ME_RESTORE_SUCCESS = 'ME_RESTORE_SUCCESS';

const ME_NEWPASS = 'ME_NEWPASS';
const ME_NEWPASS_SUCCESS = 'ME_NEWPASS_SUCCESS';

const ME_LOGOUT = 'ME_LOGOUT';
const ME_LOGOUT_SUCCESS = 'ME_LOGOUT_SUCCESS';

const ME_SIGN_UP = 'ME_SIGN_UP';
const ME_SIGN_UP_SUCCESS = 'ME_SIGN_UP_SUCCESS';

const ME_ACTIVATE = 'ME_ACTIVATE';
const ME_ACTIVATE_SUCCESS = 'ME_ACTIVATE_SUCCESS';

const ME_EXPORT_TEST = 'ME_EXPORT_TEST';
const ME_EXPORT_TEST_SUCCESS = 'ME_EXPORT_TEST_SUCCESS';

const ME_SEND_APPLICATIONS = 'ME_SEND_APPLICATIONS';
const ME_SEND_APPLICATIONS_SUCCESS = 'ME_SEND_APPLICATIONS_SUCCESS';

const ME_PROFILE_USER = 'ME_PROFILE_USER';
const ME_PROFILE_USER_SUCCESS = 'ME_PROFILE_USER_SUCCESS';

const ME_PROFILE_COMPANY = 'ME_PROFILE_COMPANY';
const ME_PROFILE_COMPANY_SUCCESS = 'ME_PROFILE_COMPANY_SUCCESS';

export type LoginAction = Action<typeof ME_LOGIN, AuthReq>;
export type RestoreAction = Action<typeof ME_RESTORE, ConfirmPassReq>;
export type NewPassAction = Action<typeof ME_NEWPASS, RestoreReq>;
export type LogoutAction = Action<typeof ME_LOGOUT>;
export type SignUpAction = Action<typeof ME_SIGN_UP, SignUpResSnake>;
export type ActivateAction = Action<typeof ME_ACTIVATE, Activation>;
export type ExportTestAction = Action<typeof ME_EXPORT_TEST, IExportTest>;
export type SendApplicationAction = Action<typeof ME_SEND_APPLICATIONS, SendApplicationsReq>;
export type UpdateProfileUserAction =
  Action<typeof ME_PROFILE_USER, {token: string, data: IProfileSnake}>;
export type UpdateProfileCompanyAction =
  Action<typeof ME_PROFILE_COMPANY, {token: string, data: ICompanySnake}>;

export type MeAction =
  LoginAction | Action<typeof ME_LOGIN_SUCCESS, AuthRes>
  | RestoreAction | Action<typeof ME_RESTORE_SUCCESS, RestoreReq>
  | RestoreAction | Action<typeof ME_NEWPASS_SUCCESS, ConfirmPassReq>
  | LogoutAction | Action<typeof ME_LOGOUT_SUCCESS>
  | SignUpAction | Action<typeof ME_SIGN_UP_SUCCESS, SignUpResCamel>
  | ExportTestAction | Action<typeof ME_EXPORT_TEST_SUCCESS, IExportTestResCamel>
  | SendApplicationAction | Action<typeof ME_SIGN_UP_SUCCESS>
  | UpdateProfileUserAction | Action<typeof ME_PROFILE_USER_SUCCESS, IProfileCamel>
  | UpdateProfileCompanyAction | Action<typeof ME_PROFILE_COMPANY_SUCCESS, ICompanyCamel>
  | ActivateAction | Action<typeof ME_ACTIVATE_SUCCESS, Activation>;

export default {
  ME_LOGIN,
  ME_LOGIN_SUCCESS,

  ME_RESTORE,
  ME_RESTORE_SUCCESS,

  ME_NEWPASS,
  ME_NEWPASS_SUCCESS,

  ME_LOGOUT,
  ME_LOGOUT_SUCCESS,

  ME_SIGN_UP,
  ME_SIGN_UP_SUCCESS,

  ME_ACTIVATE,
  ME_ACTIVATE_SUCCESS,

  ME_EXPORT_TEST,
  ME_EXPORT_TEST_SUCCESS,

  ME_SEND_APPLICATIONS,
  ME_SEND_APPLICATIONS_SUCCESS,

  ME_PROFILE_USER,
  ME_PROFILE_USER_SUCCESS,

  ME_PROFILE_COMPANY,
  ME_PROFILE_COMPANY_SUCCESS,
};
