type TErrors = {
  [index: string]: string,
};

export const LOGIN_ERRORS:TErrors = {
  'No active account found with the given credentials': 'Данные введены не верно или пользователь не существует',
};

export const RESTORE_ERRORS:TErrors = {
  'No active account found with the given credentials': 'Данные введены не верно или пользователь не существует',
};

export const SIGNUP_ERRORS:TErrors = {
  'user with this Email already exists.': 'Пользователь уже существует',
  'Enter a valid email address.': 'Укажите корректный адрес электронной почты',
  // 'The password is too similar to the Email.': 'Пароль содержит часть адреса электронной почты',
  'This password is too short. It must contain at least 8 characters.': 'Пароль слишком короткий. Укажите пароль не менее 8 символов',
  'This password is too common.': 'Пароль слишком простой',
  'This password is entirely numeric.': 'Пароль содержит только цифры. Укажите дополнительно хотя бы одну букву',
};

export const SIGNUP_ERRORS_CHECK = (val:string) => {
  if (val.includes('similar to the')) {
    const mas = val.split(' ');
    return `Пароль содержит данные из поля ${mas[mas.length - 1]}`;
  }
  return SIGNUP_ERRORS[val] || val;
};

export const TESTS_ERRORS: TErrors = {
  /* API */
  'not authorized': 'Пользователь не авторизован',
  'quiz has already been completed': 'Квалификация либо валидация была пройдена менее 6 месяцев назад',
  'invalid session ID': 'Некорректный ID сессии',
  'invalid step': 'Некорректный номер вопроса',
  'invalid answer': 'Некорректный номер ответа',
  'unfilled answers': 'В текущей сессии имеются не отвеченные вопросы',
  'qualification not completed': 'Квалификация не пройдена',
  'invalid parameters': 'Некорректные параметры запроса',
  'invalid validation session ID': 'Некорректный идентификатор сессии валидации',
  'already moderated': 'Данная валидация уже отмодерирована',
  'server not ready': 'Потеряна связь с сервером',
  /* Custom UI */
  'Not found active qualification': 'Не найдена активная квалификация',
  'Not found active validation': 'Не найдена активная валидация',
  'Not authorized for the current user': 'Действие не авторизовано для данного пользователя',
  'You have unfilled answers': 'Не на все вопросы даны ответы',
  'You have to select an answer': 'Не выбран вариант ответа',
  'Country or/and product should be selected': 'Не выбрана страна или продукт',
  'You have unfilled fields': 'Не все необходимые поля заполнены',
};
