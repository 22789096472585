import React from 'react';
import { Text, ApplicationForm } from 'components';
import { _t } from 'utils';
import styles from './styles.module.scss';

const ServicesBuyer = () => (
  <>
    <Text className={styles.description}>
      {_t('программа центра направлена на подбор контрагентов и...')}
    </Text>

    <Text bold>
      {_t('Центр организует для московских производителей программу по направлениям:')}
    </Text>

    <ul className={styles.list}>
      <li className={styles.item}>
        {_t('Выездные и реверсные бизнес-миссии (online и offline)')}
      </li>
      <li className={styles.item}>
        {_t('Технологические туры')}
      </li>
      <li className={styles.item}>
        {_t('Предпродажные мероприятия (online и offline), включая MatchMaking')}
      </li>
      <li className={styles.item}>
        {_t('Индивидуальная поддержка по участию в международных выставках и...')}
      </li>
    </ul>

    <Text className={styles.description}>
      {_t('участие столичных компаний в программе на 100% финансируется за счет...')}
    </Text>

    <ApplicationForm />
  </>
);

export default ServicesBuyer;
