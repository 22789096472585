import React, { FC, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { meLoginAction } from 'store/me/actions';
import {
  Button, Checkbox, Input, Section, Text,
} from 'components';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { ERRORS, routes } from 'appConstants';
import { history, _t } from 'utils';
import type { AuthReq as Values, State } from 'types';
import { RequestStatus } from 'types';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

interface InitialValues extends Values {
  checkbox: boolean,
}

const initialValues: InitialValues = {
  email: '',
  password: '',
  checkbox: false,
};

const validationSchema = object().shape({
  email: string().required(ERRORS.required).email(ERRORS.email),
  password: string().min(6, ERRORS.min(6)).required(ERRORS.required),
});

const LoginPage: FC<Props> = ({ className }) => {
  const dispatch = useDispatch();
  const loginReqStatus = useSelector(({ ui }: State) => ui.ME_LOGIN);
  const isAuth = useSelector(({ me }: State) => me.isAuth);

  const {
    values,
    errors,
    isValid,
    touched,
    setErrors,
    handleChange,
    handleSubmit,
  } = useFormik<InitialValues>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (data) => dispatch(meLoginAction(data)),
  });

  useEffect(() => {
    if (isAuth) history.push(routes.personal.root);
  }, []);

  useEffect(() => {
    if (loginReqStatus === RequestStatus.ERROR) {
      setErrors({ email: '', password: '' });
    }
  }, [loginReqStatus]);

  return (
    <Section>
      <form className={cx(styles.form, className)} onSubmit={handleSubmit}>
        <Text
          tag="span"
          align="center"
          className={styles.title}
          bold
          uppercase
        >
          {_t('Вход в личный кабинет')}
        </Text>
        <Input
          name="email"
          placeholder="Логин"
          className={styles.input}
          value={values.email}
          error={errors?.hasOwnProperty('email') && touched.email ? errors.email || true : ''}
          onChange={handleChange}
        />
        <Input
          name="password"
          placeholder="Пароль"
          type="password"
          className={styles.input}
          value={values.password}
          error={errors?.hasOwnProperty('password') && touched.password ? errors.password || true : ''}
          onChange={handleChange}
        />
        <div className={styles.wrapCheckbox}>
          <Checkbox
            name="checkbox"
            checked={values.checkbox}
            onChange={handleChange}
            label={<Text tag="span" size="tiny">{_t('Сохранить пароль')}</Text>}
            className={styles.checkbox}
          />
          <Link to={routes.auth.restore.root} className={styles.link}>{_t('Забыли пароль?')}</Link>
        </div>
        <Button
          type="submit"
          isLoading={loginReqStatus === RequestStatus.REQUEST}
          className={styles.btn}
          disabled={!isValid}
          upperCase
          color="secondary"
          title="Войти"
        />

        <Text tag="span" className={styles.littleText}>{_t('или')}</Text>

        <Link to={routes.auth.signUp.root} className={styles.linkBig}>{_t('Создайте аккаунт')}</Link>
      </form>
    </Section>
  );
};
export default LoginPage;
