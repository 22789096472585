import React, { FC, useEffect, useState } from 'react';
import {
  Button, Preloader, Section, Text,
} from 'components';
import { routes } from 'appConstants';
import { _t, history } from 'utils';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { meActivateAction } from 'store/me/actions';
import { RequestStatus, State } from 'types';
import styles from './styles.module.scss';

type Props = {
  className?: string;
};

const ActivatedPage: FC<Props> = () => {
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const activationReqStatus = useSelector(({ ui }: State) => ui.ME_ACTIVATE);
  const params = new URLSearchParams(location.search);

  const uid = params.get('uid');
  const token = params.get('token');

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      if (uid && token) {
        const data = { uid, token };
        await dispatch(meActivateAction(data));
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    };

    fetchData();
    setIsLoading(false);
  }, [uid, token]);

  useEffect(() => {
    if (activationReqStatus === RequestStatus.ERROR) {
      setIsActive(false);
    }
  }, [activationReqStatus]);

  return (
    <Section>
      <Preloader isLoading={isLoading}>
        {isActive && (
        <>
          <Text
            tag="span"
            align="center"
            className={styles['activated__title']}
            bold
            uppercase
          >{_t('Аккаунт успешно активирован!')}
          </Text>
          <Text tag="p" align="center" className={styles['activated__active-info']}>
            {_t('Спасибо за активацию вашего аккаунта! Теперь вы можете войти в свой личный кабинет и начать пользоваться сервисом.')}
          </Text>
          <div className={styles['activated__btn-wrapper']}>
            <Button
              type="button"
              className={styles.btn}
              upperCase
              onClick={() => history.push(routes.auth.login.root)}
              color="secondary"
              title="Войти в личный кабинет"
            />
          </div>
        </>
        )}
        {!isActive && (
          <>
            <Text
              tag="span"
              align="center"
              className={styles['activated__title']}
              bold
              uppercase
            >{_t('Ошибка при активации аккаунта!')}
            </Text>
            <Text tag="p" align="center" className={styles['activated__active-info']}>
              {_t('К сожалению, мы не смогли активировать ваш аккаунт. Если у вас возникли проблемы, пожалуйста, обратитесь в нашу службу поддержки, и мы постараемся решить проблему в кратчайшие сроки.')}
            </Text>
          </>
        )}

      </Preloader>
    </Section>
  );
};
export default ActivatedPage;
