import React, {
  FC,
} from 'react';
import { Link } from 'types';
import { routes } from 'appConstants';
import {
  PersonalExit,
} from 'components';
import cx from 'classnames';
import SidebarLink from './SidebarLink';
import styles from './styles.module.scss';

interface Props {
  isOpen: boolean,
}

const LINKS: Link[] = [
  { /* com, good */
    to: routes.personal.feed.root,
    title: routes.personal.feed.title,
    icon: 'feed',
  },
  // { /* com, mid - нет роута, тестовые данные */
  //   to: routes.personal.notifications.root,
  //   title: routes.personal.notifications.title,
  //   icon: 'notifications',
  // },
  { /* com, good */
    to: routes.personal.profile.root,
    title: routes.personal.profile.title,
    icon: 'profile',
  },
  { /* com, good */
    to: routes.personal.calendar.root,
    title: routes.personal.calendar.title,
    icon: 'calendar',
  },
  { /* com, mid - роуты не отдают информацию */
    to: routes.personal.leads.root,
    title: routes.personal.leads.title,
    icon: 'leads',
  },
  { /* com, good */
    to: routes.personal.resolutions.root,
    title: routes.personal.resolutions.title,
    icon: 'resolutions',
  },
  { /* com, good */
    to: routes.personal.analytics.root,
    title: routes.personal.analytics.title,
    icon: 'analytics',
  },
  { /* mid, роуты есть, нужен фильтр */
    to: routes.personal.exchange.root,
    title: routes.personal.exchange.title,
    icon: 'exchange',
  },
  { /* com, good - внешний сервис */
    to: routes.personal.catalog.root,
    title: routes.personal.catalog.title,
    icon: 'catalog',
  },
  {
    to: routes.personal.goods.root,
    title: routes.personal.goods.title,
    icon: 'goods',
  },
  { /* com, good */
    to: routes.personal.faq.root,
    title: routes.personal.faq.title,
    icon: 'faq',
  },
];

const Sidebar: FC<Props> = ({
  children,
  isOpen,
}) => {
  return (
    <aside
      className={cx(
        styles.sidebar,
        {
          [styles.isOpen]: isOpen,
        },
      )}
    >
      {children}
      <nav className={styles.body}>
        <div>
          {
            LINKS.map(({
              to, title, icon, sublinks,
            }) => icon && (
              <SidebarLink
                isOpen={isOpen}
                to={to}
                title={title}
                className={to === '' ? styles.commonSideLink : ''}
                icon={icon!}
                sublinks={sublinks}
                key={to}
              />
            ))
          }
        </div>
        <div>
          <SidebarLink
            isOpen={isOpen}
            to=""
            title="Перейти на сайт"
            className={styles.commonSideLink}
            icon="main_site"
          />
          <PersonalExit
            className={styles.exit}
          >
            <SidebarLink
              isOpen={isOpen}
              to=""
              title="Выход"
              className={styles.commonSideLink}
              icon="exit"
            />
          </PersonalExit>
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;
