import React, { FC, useEffect } from 'react';
import cx from 'classnames';
import { useFormik } from 'formik';
import { Paginate, Preloader } from 'components';
import { MediaCard } from 'containers';
import { useRequest } from 'hooks';
import { _has_t_back } from 'utils';
import type { GetResponse, News } from 'types';
import { RequestStatus } from 'types';
import styles from './styles.module.scss';

type Props = {
  requestUrl: string,
  className?: string,
  action?: 'detail' | 'external' | 'opener',
  customData?: News[],
  noLimits?: boolean,
  onClickHandler?: (obj:News) => void,
};

const PAGE_SIZE = 9;

type Params = {
  page: number,
};

const initialValues: Params = { page: 0 };

const MediaBaseList: FC<Props> = ({
  requestUrl,
  className,
  action = 'detail',
  customData,
  noLimits = false,
  onClickHandler,
}) => {
  const {
    data,
    request,
    status,
  } = useRequest<GetResponse<News[]>>();

  const {
    values,
    handleChange,
    handleSubmit,
  } = useFormik<Params>({
    initialValues,
    onSubmit: (params) => {
      const { page } = params;
      request({
        method: 'get',
        url: requestUrl,
        params: {
          limit: PAGE_SIZE,
          offset: page * PAGE_SIZE,
        },
      });
    },
  });

  useEffect(() => {
    if (!customData?.length) {
      const reqObj = noLimits ?
        {
          url: requestUrl,
        } : {
          url: requestUrl,
          params: {
            limit: PAGE_SIZE,
          },
        };
      request(reqObj);
    }
  }, []);

  const listOfData:News[] = (customData?.length ? customData : data?.results) || [];

  return (
    <Preloader isLoading={status === RequestStatus.REQUEST}>
      <section className={cx(className)}>
        <ul className={styles.list}>
          {listOfData?.map((el) => (_has_t_back(el) && (
            <li key={el.id} className={styles.item}>
              <MediaCard
                action={action}
                value={el}
                onClickHandler={onClickHandler}
              />
            </li>
          )))}
        </ul>
        <Paginate
          pageSize={PAGE_SIZE}
          page={values.page}
          count={data?.count || 0}
          onChange={(selected) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            handleChange({
              target: {
                name: 'page',
                value: selected,
              },
            });
            handleSubmit();
          }}
        />
      </section>
    </Preloader>
  );
};

export default MediaBaseList;
