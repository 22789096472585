import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { routes } from 'appConstants';
import { PersonalPageMenu } from 'components';
import {
  PersonalBaseLayout,
  PersonalGoodsMainTab,
  PersonalGoodsOffers,
  PersonalGoodsMine,
  PersonalGoodsDetail,
  PersonalGoodsRequest,
} from 'containers';

const Links = [
  {
    title: 'Запросы',
    to: routes.personal.goods.root,
  },
  {
    title: routes.personal.goods.offers.title,
    to: routes.personal.goods.offers.root,
  },
  {
    title: routes.personal.goods.announcements.title,
    to: routes.personal.goods.announcements.root,
  },
];

const PersonalGoods = () => {
  return (
    <PersonalBaseLayout pageTitle="Запросы и предложения">
      <PersonalPageMenu
        links={Links}
      >
        <PersonalGoodsRequest />
      </PersonalPageMenu>
      <Switch>
        <Route
          path={routes.personal.goods.root}
          exact
          render={() => (<PersonalGoodsMainTab />)}
        />
        <Route
          path={routes.personal.goods.offers.root}
          exact
          render={() => (<PersonalGoodsOffers />)}
        />
        <Route
          path={routes.personal.goods.announcements.root}
          exact
          // render={() => (<PersonalGoodsMainTab />)}
          render={() => (<PersonalGoodsMine />)}
        />
        <Route
          path={`${routes.personal.goods.root}/:id`}
          exact
          render={() => (<PersonalGoodsDetail />)}
        />
        <Route
          path={`${routes.personal.goods.offers.root}/:id`}
          exact
          render={() => (<PersonalGoodsDetail />)}
        />
        <Route
          path={`${routes.personal.goods.announcements.root}/:id`}
          exact
          render={() => (<PersonalGoodsDetail />)}
        />
        <Redirect to={{ pathname: routes.personal.goods.root }} />
      </Switch>
    </PersonalBaseLayout>
  );
};

export default PersonalGoods;
