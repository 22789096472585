import React, { FC } from 'react';
import { routes } from 'appConstants';
import { ExternalLink, Link, Logo } from 'components';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  inverseTheme?: 'light' | 'dark',
  onMainClick?: () => void,
};

const HeaderLogos:FC<Props> = ({
  inverseTheme,
  onMainClick,
}) => {
  return (
    <div className={styles.logos}>
      <ExternalLink
        href="https://www.mos.ru/dipp/"
        className={cx(
          styles.logo_ext,
          styles.dipp_logo,
        )}
        newTab
      >
        <Logo
          preset="dipp"
          view={inverseTheme}
        />
      </ExternalLink>

      <Link
        to={routes.main.root}
        className={styles.logo}
        onClick={onMainClick}
      >
        <Logo
          className={styles.mainLogo}
          view={inverseTheme}
        />
      </Link>
    </div>
  );
};

export default HeaderLogos;
