/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'classnames';

import {
  Country,
  Product,
  State,
  Step,
} from 'types';
import { _t } from 'utils';
import { ButtonRounded, Modal } from 'components';
import {
  testVDecrementStep,
  testVFinishAndShowReport,
  testVSendAnswer,
  testVVisibleToggle,
} from 'store/test/actions';

import { CardHeader } from '../CardHeader';
import {
  CardContent,
  CheckboxProps,
  ProductSelectProps,
  RadioProps,
  RangeProps,
} from '../CardContent';
import type { AnswersProps } from '../CardContent';
import { CardControls } from '../CardControls';
import type { NextBtnTitle } from '../CardControls';

import styles from './styles.module.scss';
import modalStyles from '../../styles.module.scss';
import qualificationStyles from '../Qualification/styles.module.scss';
import { Loader } from '../../PersonalTestLoader';

type ValidationCardProps = AnswersProps;

const ValidationCard: FC<ValidationCardProps & { handleNext?: () => void }> = memo(({
  kind,
  title,
  company,
  inn,
  name,
  position,
  answers,
  radioSelected,
  onChangeRadio,
  checkboxSelected,
  onChangeCheckbox,
  rangeSelected,
  onChangeRange,
  handleNext = () => {},
  countries,
  products,
  onChangeCountry,
  onChangeProduct,
  onChangeProductManual,
  setProductDesc,
}) => {
  const dispatch = useDispatch();
  const testState = useSelector(({ test }: State) => test);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isInProgress, setInProgress] = useState(false);

  let cardContentProps: AnswersProps;
  let nextBtnTitle: NextBtnTitle;
  switch (kind) {
    case 'radio':
      cardContentProps = {
        kind,
        title,
        answers: answers as Step['answers'],
        radioSelected: radioSelected as RadioProps['radioSelected'],
        onChangeRadio: onChangeRadio as RadioProps['onChangeRadio'],
      };
      nextBtnTitle = 'Далее';
      break;
    case 'checkbox':
      cardContentProps = {
        kind,
        title,
        answers: answers as Step['answers'],
        checkboxSelected: checkboxSelected as CheckboxProps['checkboxSelected'],
        onChangeCheckbox: onChangeCheckbox as CheckboxProps['onChangeCheckbox'],
      };
      nextBtnTitle = 'Далее';
      break;
    case 'range':
      cardContentProps = {
        kind,
        title,
        answers: answers as Step['answers'],
        rangeSelected: rangeSelected as RangeProps['rangeSelected'],
        onChangeRange: onChangeRange as RangeProps['onChangeRange'],
      };
      nextBtnTitle = 'Далее';
      break;
    case 'text':
      cardContentProps = {
        kind,
        title,
        company: company as string,
        inn: inn as string,
        name: name as string,
        position: position as string,
      };
      nextBtnTitle = 'Подтвердить';
      break;
    case 'productSelect':
      cardContentProps = {
        kind,
        title,
        countries: countries as Country[],
        products: products as Product[],
        onChangeCountry: onChangeCountry as ProductSelectProps['onChangeCountry'],
        onChangeProduct: onChangeProduct as ProductSelectProps['onChangeProduct'],
        onChangeProductManual: onChangeProductManual as ProductSelectProps['onChangeProductManual'],
        setProductDesc: setProductDesc as ProductSelectProps['setProductDesc'],
      };
      nextBtnTitle = 'Подтвердить';
      break;
    default:
      cardContentProps = {
        kind: 'text',
        title: '',
        company: '',
        inn: '',
        name: '',
        position: '',
      };
      nextBtnTitle = 'Далее';
  }

  const isLastQuestion =
    testState.validationMeta.total &&
    testState.validationMeta.current === testState.validationMeta.total;
  if (nextBtnTitle === 'Далее' && isLastQuestion) {
    nextBtnTitle = 'Завершить';
  }

  const validationTNVED = (tnved: string | null): boolean => (
    typeof tnved === 'string' &&
    !Number.isNaN(tnved as unknown as number) && !Number.isNaN(parseInt(tnved, 10)) &&
    Array.isArray((tnved as string).match(/[0-9]/gi)) &&
    ((tnved as string).match(/[0-9]/gi) as { length: number }).length === 6
  );

  const isRadioSelected = kind === 'radio' &&
    radioSelected !== null;
  const isCheckboxSelected = kind === 'checkbox' &&
    Array.isArray(checkboxSelected) &&
    checkboxSelected.length;
  const isRangeSelected = kind === 'range' &&
    Array.isArray(rangeSelected) &&
    rangeSelected.every((a) => a !== null);
  const isProductSelected = kind === 'productSelect' &&
    testState.validationTopic.countryId &&
    (
      testState.validationTopic.productId ||
      (testState.validationTopic.productName &&
      validationTNVED(testState.validationTopic.productTNVED))
    );

  const createdDate = testState.validationSessions
    .find((session) => session.id === testState.validationSessionID)?.created || null;

  return (
    <div className={styles.card}>
      <CardHeader
        // kind="validation"
        currentQuestion={testState.validationMeta.current}
        passedQuestions={testState.validationMeta.passed}
        totalQuestions={testState.validationMeta.total}
        hideStep={kind === 'productSelect'}
      />
      <div className={styles.contentControl}>
        <CardContent {...cardContentProps} />
        <CardControls
          sessionCreatedDate={createdDate}
          onBack={() => {
            if (
              !testState.validationMeta.passed ||
              !testState.validationMeta.current ||
              testState.validationMeta.current === 1
            ) {
              dispatch(testVVisibleToggle(false));
            } else {
              dispatch(testVDecrementStep());
            }
          }}
          onNext={() => {
            if (isLastQuestion) {
              // dispatch(testVSendAnswer({ shouldFinish: true, shouldExit: true }));
              setModalOpen(true);
              dispatch(testVSendAnswer({ shouldFinish: false, shouldExit: false }));
            } else {
              handleNext();
            }
          }}
          titleNext={nextBtnTitle}
          onExit={() => {
            if (testState.validationMeta.answer === null) {
              dispatch(testVVisibleToggle(false));
            } else {
              dispatch(testVSendAnswer({ shouldExit: true }));
            }
          }}
          isDisabledNext={!(
            isRadioSelected ||
            isCheckboxSelected ||
            isRangeSelected ||
            isProductSelected
          )}
          showExit={kind !== 'productSelect'}
        />
      </div>

      <Modal
        onClose={() => setModalOpen(false)}
        title={isInProgress ?
          _t('Ответы обрабатываются') :
          _t('Вы уверены, что хотите завершить тестирование?')}
        isOpen={isModalOpen}
        className={clsx(modalStyles.modalContent, qualificationStyles.modal)}
        classNameTitle={modalStyles.modalTitle}
        classNameOverlay={modalStyles.modalOverlay}
        classNameClose={modalStyles.modalBtnClose}
      >
        {isInProgress ? (
          <div>
            <span>
              {_t('Пожалуйста, ожидайте. Обработка ответов может занять некоторое время.')}
            </span>
            <div className={styles.loaderWrap}>
              <Loader />
            </div>
          </div>
        ) : (
          <div>
            <span className={modalStyles.modalText}>
              {_t('После завершения тестирования изменить ответы на вопросы будет невозможно.')}
            </span>
            <div className={modalStyles.modalControls}>
              <ButtonRounded
                className={modalStyles.modalButton}
                color="secondary"
                blueMode
                onClick={() => setModalOpen(false)}
                title={_t('Отмена')}
              />
              <ButtonRounded
                className={modalStyles.modalButton}
                color="primary"
                blueMode
                onClick={() => {
                  setInProgress(true);
                  dispatch(testVFinishAndShowReport());
                }}
                title={_t('Завершить')}
              />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
});

export { ValidationCard };
