import React, {
  FC,
  useState,
  useEffect,
  // useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { useRequest } from 'hooks';
import {
  URL,
  // routes,
} from 'appConstants';
import {
  Lead,
  LeadStatus,
  State,
  RequestStatus,
} from 'types';
import { _t, dateToUiFormat } from 'utils';
import { PersonalTabLayout } from 'containers';
import { Preloader, NoData } from 'components';
import cx from 'classnames';
import PersonalLeadsDetail from '../PersonalLeadsDetail';
import styles from './styles.module.scss';

type Props = {};

const PersonalLeadsMainTab: FC<Props> = () => {
  const [search, setSearch] = useState<string>('');
  const [statusParam, setStatusParam] = useState<number>(0);
  const [categoryParam, setCategoryParam] = useState<number>(0);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [detail, setDetail] = useState<number>(-1);

  const { data: LeadData, status: LeadReqStatus, request: LeadRequest } = useRequest<Lead[]>();
  const { data: StatData, request: StatRequest } = useRequest<LeadStatus[]>();
  const { data: CatData, request: CatRequest } = useRequest<LeadStatus[]>();
  const token = useSelector(({ me }: State) => me.token);

  const setStatusFilter = (val: number = 0) => {
    setStatusParam(val);
  };

  const setCategoryFilter = (val: number = 0) => {
    setCategoryParam(val);
  };

  const managePop = (tag: string, id: number = -1) => {
    if (tag === 'filter') {
      setOpenFilter(true);
      setDetail(-1);
    } else if (tag === 'detail') {
      setOpenFilter(false);
      setDetail(id);
    }
  };

  const clearFilters = () => {
    setSearch('');
    setStatusParam(0);
    setCategoryParam(0);
  };

  useEffect(() => {
    const reqText = `${search && `&search=${search}`}${statusParam ? `&status=${statusParam}` : ''}${categoryParam ? `&category=${categoryParam}` : ''}`;
    LeadRequest({
      method: 'GET',
      url: `${URL.PERSONAL.LEADS.GET}?arhive=false${reqText}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      // params: {
      //   status: statusParam,
      //   category: categoryParam,
      // },
    });
  }, [statusParam, categoryParam, search]);

  useEffect(() => {
    StatRequest({
      method: 'GET',
      url: URL.PERSONAL.LEADS.STATUS,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }, []);

  useEffect(() => {
    CatRequest({
      method: 'GET',
      url: URL.PERSONAL.LEADS.CATEGORY,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }, []);

  const renderFilter = () => (
    <div className={styles.filterWrap}>
      <div
        className={styles.filter}
        onClick={() => managePop('filter')}
      >
        {_t('Фильтр')}
      </div>
      {openFilter && (
        <div className={styles.filterBlock}>
          <div
            className={styles.close}
            onClick={() => setOpenFilter(false)}
          >
            +
          </div>
          <h3>{_t('Фильтр')}</h3>
          <div className={styles.filterparam}>
            <h4>{_t('Категория')}</h4>
            <div>
              <span
                className={cx({
                  [styles.active]: categoryParam === 0,
                })}
                onClick={() => setCategoryFilter()}
              >
                {_t('Все категории')}
              </span>
              {CatData?.map((item) => (
                <span
                  className={cx({ [styles.active]: categoryParam === item.id })}
                  key={`catItem_${Math.random()}`}
                  onClick={() => setCategoryFilter(item.id)}
                >
                  {_t(item.title)}
                </span>
              ))}
            </div>
          </div>
          <div className={styles.filterparam}>
            <h4>{_t('Статус')}</h4>
            <div>
              <span
                className={cx({
                  [styles.active]: statusParam === 0,
                })}
                onClick={() => setStatusFilter()}
              >
                {_t('Все статусы')}
              </span>
              {StatData?.map((item) => (
                <span
                  className={cx({ [styles.active]: statusParam === item.id })}
                  key={`statusItem_${Math.random()}`}
                  onClick={() => setStatusFilter(item.id)}
                >
                  {_t(item.title)}
                </span>
              ))}
            </div>
          </div>
          <div className={styles.clearFilter}>
            <div
              className={styles.clearFilterBtn}
              onClick={clearFilters}
            >
              {_t('Очистить фильтр')}
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const renderSearch = () => (
    <input
      className={styles.search}
      type="text"
      placeholder={_t('Поиск')}
      value={search}
      onChange={(e) => setSearch(e.target.value)}
    />
  );

  const renderStatus = (val: LeadStatus) => (
    <div
      className={cx(styles.leadStatus, styles[`icon-${val.id}`])}
    >
      {_t(val.title || 'Не указан')}
    </div>
  );

  const renderDataLine = (data:Lead[]) => (
    data?.map(({
      id,
      title,
      created,
      category,
      status,
    }, index) => (
      <div
        key={`leadsItem_${Math.random()}`}
        className={styles.list_item}
        onClick={() => managePop('detail', index)}
      >
        <div>#{id}</div>
        <div>{dateToUiFormat(created)}</div>
        <div>{category.title}</div>
        <div>{title || _t('Не указан')}</div>
        <div>
          {StatData ? ' ' : ' '}
          {renderStatus(status || [])}
        </div>
      </div>
    ))
  );

  return (
    <PersonalTabLayout
      single
      className={styles.wrapper}
    >
      <div className={styles.filterNSearch}>
        {renderFilter()}
        {renderSearch()}
      </div>

      <Preloader isLoading={LeadReqStatus === RequestStatus.REQUEST}>
        <NoData count={LeadData?.length || 0}>
          <div className={styles.list}>
            <div className={styles.listHeaders}>
              <div>{_t('Номер')}</div>
              <div>{_t('Дата подачи')}</div>
              <div>{_t('Категория')}</div>
              <div>{_t('Наименование')}</div>
              <div>{_t('Статус заявки')}</div>
            </div>
            {renderDataLine(LeadData || [])}
          </div>
        </NoData>
      </Preloader>

      {LeadData?.[detail] && (
        <PersonalLeadsDetail
          data={LeadData[detail]}
        />
      )}
    </PersonalTabLayout>
  );
};

export default PersonalLeadsMainTab;
