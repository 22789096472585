import React, { useEffect } from 'react';
import { Modal, Button } from 'components';
import type { State } from 'types';
import { useSelector } from 'react-redux';
import { setPageMeta, routes } from 'appConstants';
import { history } from 'utils';
import styles from './styles.module.scss';

const Welcome = () => {
  useEffect(() => {
    setPageMeta(routes.about.title, routes.about.descr);
  });

  const isAuth = useSelector(({ me }: State) => me.isAuth);

  const toMain = () => {
    history.push(routes.main.root);
  };

  const toPersonal = () => {
    if (isAuth) {
      history.push(routes.personal.root);
    } else {
      history.push(routes.auth.login.root);
    }
  };

  return (
    <Modal
      onClose={() => {}}
      title="Вы успешно зарегистрировались. Перейти в личный кабинет?"
      isOpen
      className={styles.welcomeModal}
      classNameTitle={styles.title}
      classNameClose={styles.hidden}
      classNameOverlay={styles.modalOverlay}
    >
      <div className={styles.confirmBtns}>
        <Button
          className={styles.toMain}
          onClick={toMain}
          upperCase
          title="Назад"
          // title="Вернуться на сайт"
        />
        <Button
          className={styles.toPersonal}
          onClick={toPersonal}
          upperCase
          title="Перейти"
          // title="перейти в ЛК"
        />
      </div>
    </Modal>
  );
};

export default Welcome;
