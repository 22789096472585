import React, { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  title: string,
};

const BlockTitle:FC<Props> = ({
  className,
  title,
}) => {
  return (
    <p className={cx(styles.header, className)}>{title}</p>
  );
};

export default BlockTitle;
