import React, { FC } from 'react';
import cx from 'classnames';
import { ClientLang } from 'appConstants';
import {
  Input,
  Select,
  FilterGroup,
  InfinityScroll,
  Text,
  DownloadButton,
  Checkbox,
} from 'components';
import { _t, _t_back } from 'utils';
import {
  AnalyticsCountry,
  AnalyticsCountryInfo,
  OptionType,
} from 'types';
import useBusinessLogic from './useBusinessLogic';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  onSelectedCountry?: (value: string) => void,
};

const getTextLabel = (name: string, label: string, className?: string) => (
  <label htmlFor={name} className={cx(styles.label, className)}>{_t(label)}</label>
);

const COUNTRIES_HTML_ID = 'countries';

const LabelValue = ({ label, value }: { label: string, value: string }) => (
  <div className={styles.option}>
    <Text color="extra" className={styles.optionLabel}>{_t(label)}</Text>
    <Text>{value}</Text>
  </div>
);

const AnalyticsCountriesSidebar: FC<Props> = ({
  className,
  onSelectedCountry = () => {},
}) => {
  const {
    values,
    regions,
    countries,
    handleSearch,
    handleChange,
    handleNext,
    handleFile,
    handleSelectRegion,
  } = useBusinessLogic();

  const CheckboxHandle = () => {
    handleFile(!values.file_added);
  };

  const getFieldTranslate = (country:AnalyticsCountryInfo) => {
    const name = ClientLang === 'ru' ? 'name' : 'name_in_en';
    return country[name] || _t_back(country, 'name');
  };

  return (
    <aside className={cx(styles.sidebar, className)}>
      <Input
        name="search"
        type="search"
        customLabel={getTextLabel('search', 'Найти страну', styles.searchLabel)}
        className={styles.search}
        onChange={(e) => {
          handleChange(e);
          handleSearch();
        }}
      />
      <Select
        placeholder={_t('Регион')}
        isSearchable
        isClearable
        className={styles.select}
        options={regions.map((region) => ({
          label: _t_back(region, 'title'),
          value: (region.id || region.pk).toString(),
        }))}
        isMulti={false}
        customLabel={getTextLabel('region', 'Выбрать регион')}
        onChange={(option) => {
          if (option) {
            handleSelectRegion(+(option as OptionType).value);
          } else {
            handleSelectRegion(0);
          }
        }}
      />
      <Checkbox
        className={styles.checkbox}
        name="with_analytics"
        label={_t('Аналитический отчет')}
        checked={values.file_added}
        onChange={CheckboxHandle}
      />
      <div id={COUNTRIES_HTML_ID} className={styles.countries}>
        <InfinityScroll
          hasMore={countries.results.length < countries.count}
          dataLength={countries.results.length}
          scrollableTarget={COUNTRIES_HTML_ID}
          loader={null}
          next={handleNext}
        >
          {countries.results.map((country: AnalyticsCountry) => (
            <FilterGroup
              key={country.id}
              label={getFieldTranslate(country.country)}
              onToggle={(isOpen) => {
                if (isOpen) {
                  onSelectedCountry(country.country.alpha2);
                } else {
                  onSelectedCountry('');
                }
              }}
            >
              <LabelValue
                label="ННЭ Москвы"
                value={country.nne_moscow ? `$${country.nne_moscow} ${_t('млн')}` : '-'}
              />
              <LabelValue
                label="Промышленный экспорт"
                value={country.prom_export ? `$${country.prom_export} ${_t('млн')}` : '-'}
              />
              <LabelValue
                label="Экспорт АПК"
                value={country.export_apk ? `$${country.export_apk} ${_t('млн')}` : '-'}
              />
              {country.file && (
                <DownloadButton
                  title="Скачать аналитический обзор"
                  onClick={() => {
                    window.open(country.file || '', '_blank');
                  }}
                />
              )}

            </FilterGroup>
          ))}
        </InfinityScroll>
      </div>
    </aside>
  );
};

export default AnalyticsCountriesSidebar;
