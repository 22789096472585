import React, {
  FC,
  useEffect,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import { useRequest } from 'hooks';
import { URL } from 'appConstants';
import { Resolution } from 'types';
import { _t_back } from 'utils';
import {
  PersonalTabLayout,
  PersonalTabLayoutLeft,
  PersonalTabLayoutRight,
} from 'containers';
import { HTMLContent, ExpandingBlock } from 'components';
import PersonalResolutionsRequest from '../PersonalResolutionsRequest';
import styles from './styles.module.scss';

type Props = {};

const PersonalResolutionsDetail: FC<Props> = () => {
  const { data, request } = useRequest<Resolution>();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    request({
      method: 'GET',
      url: `${URL.RESOLUTIONS.GET}/${id}`,
    });
  }, []);

  const params = useMemo(() => [
    {
      label: 'Место проведения',
      value: data?.moscow_or_rf.title || '-',
    },
  ], [data]);

  const renderAdditionalFields = useMemo(() => {
    return (
      <>
        {_t_back(data, 'document_list') && (
          <ExpandingBlock
            label="Документы"
            className={styles.regions}
          >
            <HTMLContent content={_t_back(data, 'document_list')} />
          </ExpandingBlock>
        )}

        {_t_back(data, 'comments') && (
          <ExpandingBlock
            label="Комментарии"
            className={styles.regions}
          >
            <HTMLContent content={_t_back(data, 'comments')} />
          </ExpandingBlock>
        )}

        {_t_back(data, 'how_to_get_support') && (
          <ExpandingBlock
            label="Как получить поддержку?"
            className={styles.regions}
          >
            <HTMLContent content={_t_back(data, 'how_to_get_support')} />
          </ExpandingBlock>
        )}
      </>
    );
  }, [data]);

  return (
    <PersonalTabLayout>
      <PersonalTabLayoutLeft
        tabTitle="Описание"
        className={styles.ResolutionDetails}
      >
        {(data && params) && (
          params.map((param) => (
            <div
              key={`left-param-${param}`}
              className={styles.dataInfo}
            >
              <div className={styles.dataLabel}>{param.label}</div>
              <div className={styles.dataValue}>{param.value}</div>
            </div>
          ))
        )}
        <PersonalResolutionsRequest />
      </PersonalTabLayoutLeft>
      <PersonalTabLayoutRight>
        {(data) && (
          <div>
            <div className={styles.dataTitle}>
              {_t_back(data, 'res_title')}
            </div>
            <HTMLContent
              content={_t_back(data, 'short_description')}
            />
            {renderAdditionalFields}
          </div>
        )}
      </PersonalTabLayoutRight>
    </PersonalTabLayout>
  );
};

export default PersonalResolutionsDetail;
