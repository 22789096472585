import React, {
  FC,
  useState,
  // useEffect,
  // useMemo,
} from 'react';
// import { useSelector } from 'react-redux';
// import { useRequest } from 'hooks';
// import {
// //   URL,
//   routes,
// } from 'appConstants';
// import {
//   PersonalCompanyAnalytic,
//   State,
// } from 'types';
// import {
//   PersonalTabLayout,
//   PersonalTabLayoutLeft,
//   PersonalTabLayoutRight,
// } from 'containers';
// import { HTMLContent } from 'components';
import { _t } from 'utils';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {};

type Tstatus = '1' | '2';
type TtestData = {
  id: number,
  status: Tstatus,
  author: string,
  checked?: boolean,
  text: string,
  date: string,
};
type Ttest = {
  [index: string]: string,
};

const testData:TtestData[] = [
  {
    id: 1,
    status: '1',
    author: 'Менеджер МОСПРОМ',
    checked: false,
    text: 'Равным образом постоянный количественный рост и сфера нашей активности в Равным образом постоянный количественный рост и сфера нашей активности в Равным образом постоянный количественный рост и сфера нашей активности в',
    date: '1 июля',
  },
  {
    id: 2,
    status: '1',
    author: 'Менеджер МОСПРОМ',
    checked: false,
    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Et doloremque amet fugit repellat, quidem officiis harum atque, fuga quisquam pariatur ut qui facere placeat voluptatum repudiandae quia recusandae. Aliquam, est.',
    date: '1 июля',
  },
  {
    id: 3,
    status: '2',
    author: 'Менеджер МОСПРОМ',
    checked: true,
    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    date: '1 июля',
  },
  {
    id: 4,
    status: '2',
    author: 'Менеджер МОСПРОМ',
    checked: false,
    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    date: '1 июля',
  },
  {
    id: 5,
    status: '2',
    author: 'Менеджер МОСПРОМ',
    checked: true,
    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    date: '1 июля',
  },
];

const Statuses:Ttest = {
  1: 'active',
  2: 'archive',
};

// const TrimHeader = (str:string) => {
//   return `${str.substr(0, 72)}...`;
// };

const PersonalNotificationsMainTab: FC<Props> = () => {
  const [search, setSearch] = useState<string>('');
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  // const { data, request } = useRequest<PersonalCompanyAnalytic[]>();
  // const token = useSelector(({ me }: State) => me.token);

  // useEffect(() => {
  //   request({
  //     method: 'GET',
  //     url: URL.PERSONAL.LEADS.GET,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  // }, []);

  const renderFilter = () => {
    return (
      <div className={styles.filterWrap}>
        <div
          className={styles.filter}
          onClick={() => setOpenFilter(true)}
        >
          Фильтр
        </div>
        {openFilter && (
          <div className={styles.filterBlock}>
            <div
              className={styles.close}
              onClick={() => setOpenFilter(false)}
            >
              +
            </div>
            <h3>Фильтр</h3>
            <div className={styles.filterparam}>
              <h4>Категория</h4>
              <div>
                <span className={styles.active}>Все категории</span>
                <span>Бизнес-миссия</span>
                <span>Новости</span>
                <span>Аналитика</span>
                <span>Что-то еще</span>
              </div>
            </div>
            <div className={styles.filterparam}>
              <h4>Статус</h4>
              <div>
                <span className={styles.active}>Все категории</span>
                <span>Новости</span>
                <span>Аналитика</span>
                <span>Бизнес-миссия</span>
              </div>
            </div>
            <div className={styles.clearFilter}>
              <div className={styles.clearFilterBtn}>Очистить фильтр</div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderSearch = () => {
    return (
      <input
        className={styles.search}
        type="text"
        placeholder={_t('Поиск')}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    );
  };

  const renderDataLine = (data:TtestData[]) => {
    return (
      <div className={styles.list}>
        {/* <div className={styles.listHeaders}>
          <div>{' '}</div>
          <div>Дата подачи</div>
          <div>Категория</div>
          <div>Наименование</div>
          <div>Статус заявки</div>
        </div> */}
        {data.map(({
          status,
          // checked,
          date,
          text,
          author,
        }) => (
          <div
            key={`notifKey_${Math.random()}`}
            className={styles.list_item}
          >
            <div
              className={cx(styles.leadStatus, styles[`icon-${Statuses[Number(status)]}`])}
            />
            <div>{author}</div>
            <div
              className={cx(styles.checkBlock)}
              // className={cx(styles.checkBlock, styles[checked ? 'checked' : 'none'])}
            >
              {/* <div className={styles.chevron} />
              <span className={styles.descr}>{_t(checked ?
                'Снять отметку' : 'Пометить флажком')}</span> */}
            </div>
            <div className={styles.text}>{text}</div>
            <div className={styles.date}>{date}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.mainContent}>
      <div className={styles.filterNSearch}>
        {(<div />) || renderFilter()}
        {renderSearch()}
      </div>
      {renderDataLine(testData)}
    </div>
  );
};

export default PersonalNotificationsMainTab;
