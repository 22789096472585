/* eslint-disable react/jsx-props-no-spreading
*/
import React, { FC } from 'react';
import RYouTube, { YouTubeProps } from 'react-youtube';

type Props = YouTubeProps;

const YouTube: FC<Props> = ({
  ...rest
}) => (
  <RYouTube {...rest} />
);

export default YouTube;
