import type { UIState } from 'types/store/ui';
import { RequestStatus } from 'types/api';
import type { UIAction } from './actions';

const initialState: UIState = {
  ME_LOGIN: RequestStatus.INIT,
  ME_RESTORE: RequestStatus.INIT,
  ME_NEWPASS: RequestStatus.INIT,
  ME_SIGN_UP: RequestStatus.INIT,
  ME_EXPORT_TEST: RequestStatus.INIT,
  ME_SEND_APPLICATION: RequestStatus.INIT,
  ME_PROFILE_USER: RequestStatus.INIT,
  ME_PROFILE_COMPANY: RequestStatus.INIT,
  ME_ACTIVATE: RequestStatus.INIT,
};

export default (state: UIState = initialState, action: UIAction): UIState => {
  const { type } = action;

  if (type === 'RESET_UI') return initialState;

  const matches = /(.*)_(REQUEST|SUCCESS|ERROR|RESET)/.exec(type);
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  return {
    ...state,
    [requestName]: requestState === RequestStatus.RESET ? RequestStatus.INIT : requestState,
  };
};
