import React, {
  useEffect,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import {
  Input,
  CustomApplicationForm,
} from 'components';
import { useRequest } from 'hooks';
import { ERRORS, URL } from 'appConstants';
import { toast } from 'react-toastify';
import { RequestStatus, State } from 'types';
import { _t } from 'utils';
// import cx from 'classnames';
import styles from './styles.module.scss';

type Values = {
  inn: string,
  title: string,
};

type Req = {
  inn: string,
  title: string,
};

const initialValues: Values = {
  inn: '',
  title: '',
};

const validationSchema = object().shape({
  inn: string().min(10, ERRORS.min(10)).required(ERRORS.required),
  title: string().required(ERRORS.required),
});

const PersonalHoldingRequest = () => {
  const token = useSelector(({ me }: State) => me.token);

  const {
    values,
    isValid,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik<Values>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: ({
      inn,
      title,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    }) => sendData({
      url: URL.PERSONAL.PROFILE.HOLDING,
      method: 'post',
      data: {
        inn,
        title,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  });

  const { request: sendData, status } = useRequest<Req>({
    callback: () => {
      toast.success(_t('Данные отправлены!'));
      resetForm();
    },
  });

  useEffect(() => {
    if (status === RequestStatus.ERROR) {
      toast.error(_t('Ошибка отправки данных'));
    }
  }, [status]);

  const handleChangeWithMask = useCallback(({ target: { name, value } }) => {
    const event = {
      target: {
        name,
        value: value.replace(/_+$/, ''),
      },
    };

    handleChange(event);
  }, []);

  return (
    <CustomApplicationForm
      className={styles.addHolding}
      onSubmit={handleSubmit}
      openerName="Добавить холдинг"
      submitName="Отправить данные"
      titleName={_t('Если ваша организация входит в группу компаний, укажите название управляющей компании.')}
      disabledSubmit={isValid && !isValid}
      isLoading={status === RequestStatus.REQUEST}
      autoClose={status === RequestStatus.SUCCESS}
    >
      <Input
        name="inn"
        placeholder="ИНН компании"
        className={styles.input}
        error={errors.inn && touched.inn ? errors.inn : ''}
        value={values.inn}
        onChange={handleChangeWithMask}
        mask="999999999999"
      />
      <Input
        name="title"
        placeholder="Название компании"
        className={styles.input}
        error={errors.title && touched.title ? errors.title : ''}
        value={values.title}
        onChange={handleChange}
      />
    </CustomApplicationForm>
  );
};

export default PersonalHoldingRequest;
