import React, { FC } from 'react';
import { ReactComponent as Legend } from 'assets/img/adept-res/--img-legend.svg';
import { IAdeptItem, TAdeptScores } from 'types';
import { getAdeptLevel } from 'utils';
import cx from 'classnames';
import BlockTitle from '../BlockTitle';
import ButtonToScroll from '../ButtonToScroll';
import DiagramBlock from './DiagramBlock';
import styles from './styles.module.scss';

type Props = {
  dimNormalized?: TAdeptScores,
  legend: IAdeptItem[],
};

const Levels = [
  {
    title: 'Сильные стороны',
    class: 'high',
  }, {
    title: 'Нейтральная зона',
    class: 'mid',
  }, {
    title: 'Зона развития',
    class: 'low',
  },
];

const DetailScore:FC<Props> = ({
  dimNormalized,
  legend,
}) => {
  let actualLevel = -1;

  const DataTable = () => (
    <div
      className={styles.dataTable}
    >
      {Levels.map((level, levelId) => {
        const filtered:IAdeptItem[] = legend.filter((item) => dimNormalized && (
          level.class === getAdeptLevel(item, dimNormalized[item.toRes])
        ));
        if (filtered.length) {
          actualLevel += 1;

          return (
            <div
              key={`level_${levelId.toString()}`}
              className={styles.category}
            >
              <div>
                <h3 className={styles[level.class]}>{level.title}</h3>
              </div>
              <div className={styles.catDetails}>
                {filtered.map((legendItem, counter) => {
                  const { id: legId, title } = legendItem;
                  return (
                    <div
                      key={`list_${counter.toString()}`}
                      className={cx(styles.detailItem, 'level_result')}
                      data-category={legId}
                      data-level={actualLevel}
                    >
                      <ButtonToScroll
                        title={`${legId + 1}. ${title}`}
                        targetId={`recomend_${legId}`}
                        className={styles.linkButton}
                      />
                      {dimNormalized && (
                        <div>
                          {dimNormalized[legendItem.toRes].toFixed(2)}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }
        return <></>;
      })}
    </div>
  );

  return (
    <div className={styles.detailScore}>
      <BlockTitle title="Результаты тестирования" />

      <div className={styles.graph}>
        <Legend className={styles.legend} />
        <DiagramBlock
          dimNormalized={dimNormalized}
          poorResults={legend}
        />
      </div>

      <DataTable />
    </div>
  );
};

export default DetailScore;
