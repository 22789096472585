import React from 'react';
import {
  Logo,
  ButtonClose,
  Section,
  Link,
} from 'components';
import { routes } from 'appConstants';
import { history } from 'utils';
import styles from './styles.module.scss';

const HeaderLogin = () => (
  <header className={styles.header}>
    <Section tag="div" className={styles.section}>
      <Link to={routes.main.root}>
        <Logo className={styles.logo} view="dark" />
      </Link>

      <ButtonClose
        theme="transparent"
        onClick={() => history.push(routes.main.root)}
      />
    </Section>
  </header>
);

export default HeaderLogin;
