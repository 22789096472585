// author Victor K.
import React, { FC } from 'react';
import styles from './styles.module.scss';

type Props = {
  url: string,
};

const Refactor = (str: string) => {
  // return `https://vk.com/video_ext.php?oid=-${187438722}&id=456240955&hash=64f561308c010ddc&hd=2`;
  return str;
};

// "https://vk.com/video/playlist/-187438722_19?section=playlist_19&z=video-187438722_456240955%2Fclub187438722%2Fpl_-187438722_19"
// https://vk.com/video-187438722_456240955
// https://vk.com/video_ext.php?oid=-187438722&id=456240955&hash=64f561308c010ddc&hd=2

const VKVideo:FC<Props> = ({
  url,
}) => (
  <div className={styles.playerWrap}>
    <iframe
      className={styles.iframeItem}
      title={url}
      src={Refactor(url)}
      width="100%"
      height="100%"
      allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
      frameBorder="0"
      allowFullScreen
    />
  </div>
);

export default VKVideo;
