import React, { FC, memo } from 'react';
import clsx from 'classnames';

import { _t } from 'utils';
import { Kind } from '.';
import styles from './styles.module.scss';

type Props = {
  title: string;
  kind: Kind;
};

const CardContentTitle: FC<Props> = memo(({ title, kind }) => {
  const showSubTitle = kind === 'radio' || kind === 'checkbox' || kind === 'range';
  let subtitle: string;
  switch (kind) {
    case 'radio':
      subtitle = _t('Выберете один вариант ответа');
      break;
    case 'checkbox':
      subtitle = _t('Выберете один или несколько вариантов ответа');
      break;
    case 'range':
      subtitle = _t('Выберете цели, где 1-наиболее важная цель, 3-наименее важная');
      break;
    default:
      subtitle = '';
  }
  return (
    <>
      <div className={clsx(styles.title, { [styles.titleMargin]: !showSubTitle })}>{title}</div>
      {showSubTitle && (
        <div className={styles.subTitle}>
          {subtitle}
        </div>
      )}
    </>
  );
});

export { CardContentTitle };
