import React, { FC, memo } from 'react';

import { _t } from 'utils';

import { PersonalTestProgressBar } from '../../PersonalTestProgressBar';
import styles from './styles.module.scss';

type Props = {
  // kind: 'qualification' | 'validation';
  currentQuestion: number;
  passedQuestions: number;
  totalQuestions: number;
  hideStep?: boolean;
};

const CardHeader: FC<Props> = memo(({
  // kind,
  currentQuestion = 1,
  passedQuestions,
  totalQuestions,
  hideStep = false,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrap}>
        {!hideStep && (
          <div className={styles.questions}>
            {_t('Ход выполнения')}: {` ${currentQuestion} `}
            <span className={styles.totalQuestions}>
              {_t('из')} {totalQuestions}
            </span>
          </div>
        )}
      </div>
      <div className={styles.progressWrap}>
        <PersonalTestProgressBar
          passed={passedQuestions}
          total={totalQuestions}
        />
      </div>
    </div>
  );
});

export { CardHeader };
