import { connectRouter } from 'connected-react-router';
import { history } from 'utils';

import me from './me/reducer';
import ui from './ui/reducer';
import test from './test/reducer';

export default {
  router: connectRouter(history),
  me,
  ui,
  test,
};
