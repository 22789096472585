import actionTypes from './actionTypes';

export const testInitAction = () => ({
  type: actionTypes.TEST_INIT,
});

/* Qualification */
export const testQSessionIDAndRunTestAction = () => ({
  type: actionTypes.TEST_Q_SESSION_ID_AND_RUN_TEST,
});
export const testQSteps = () => ({
  type: actionTypes.TEST_Q_STEPS,
});
export const testQVisibleToggle = (isVisible: boolean) => ({
  type: isVisible ? actionTypes.TEST_Q_SHOW : actionTypes.TEST_Q_HIDE,
});
export const testQShowFinalAction = () => ({
  type: actionTypes.TEST_Q_SHOW_FINAL_ACTION,
});
export const testQHideFinalAction = () => ({
  type: actionTypes.TEST_Q_HIDE_FINAL_ACTION,
});
export const testQDecrementStep = () => ({
  type: actionTypes.TEST_Q_DECREMENT_STEP,
});
export const testQGetSessionsAction = () => ({
  type: actionTypes.TEST_Q_SESSIONS,
});
export const testQConfirmPersonalData = () => ({
  type: actionTypes.TEST_Q_CONFIRM_PERSONAL_DATA,
});
export const testQSelectRadio = (answer: number) => ({
  type: actionTypes.TEST_Q_SELECT_RADIO,
  payload: { answer },
});
export const testQSelectCheckbox = (answer: number, isChecked: boolean) => ({
  type: actionTypes.TEST_Q_SELECT_CHECKBOX,
  payload: { answer, isChecked },
});
export const testQSelectRange = (position: number, value: number | null) => ({
  type: actionTypes.TEST_Q_SELECT_RANGE,
  payload: { position, value },
});
export const testQSendAnswer = ({
  shouldExit,
  shouldFinish,
  shouldIncrement,
}: Partial<{
  shouldExit: boolean;
  shouldFinish: boolean;
  shouldIncrement: boolean;
}>) => ({
  type: actionTypes.TEST_Q_SEND_ANSWER,
  payload: { shouldExit, shouldFinish, shouldIncrement },
});
export const testQRemoveSession = () => ({
  type: actionTypes.TEST_Q_REMOVE_SESSION,
});
export const testQReset = () => ({
  type: actionTypes.TEST_Q_RESET,
});
export const testQFinishAndShowReport = () => ({
  type: actionTypes.TEST_Q_FINISH_AND_SHOW_REPORT,
});
export const testQShowReport = (sessionId: string) => ({
  type: actionTypes.TEST_Q_SHOW_REPORT,
  payload: { sessionId },
});
export const testQHideReport = () => ({
  type: actionTypes.TEST_Q_HIDE_REPORT,
});
export const testQGetHtmlReport = (sessionId: string) => ({
  type: actionTypes.TEST_Q_GET_HTML_REPORT,
  payload: { sessionId },
});
export const testQGetPdfReport = (sessionId: string) => ({
  type: actionTypes.TEST_Q_GET_PDF_REPORT,
  payload: { sessionId },
});
export const testQGetCustomPdfReport = (sessionId: string) => ({
  type: actionTypes.TEST_Q_GET_CUSTOM_PDF_REPORT,
  payload: { sessionId },
});
export const testQCloseCustomPdfReport = () => ({
  type: actionTypes.TEST_Q_CLOSE_CUSTOM_PDF_REPORT,
});

/* Validation */
export const testVGetProducts = () => ({
  type: actionTypes.TEST_V_GET_PRODUCTS,
});
export const testVVisibleToggle = (isVisible: boolean) => ({
  type: isVisible ? actionTypes.TEST_V_SHOW : actionTypes.TEST_V_HIDE,
});
export const testVShowFinalAction = () => ({
  type: actionTypes.TEST_V_SHOW_FINAL_ACTION,
});
export const testVHideFinalAction = () => ({
  type: actionTypes.TEST_V_HIDE_FINAL_ACTION,
});
export const testVSelectCountry = (countryId: number) => ({
  type: actionTypes.TEST_V_SELECT_COUNTRY,
  payload: { countryId },
});
export const testVSelectProduct = (productId: number | null) => ({
  type: actionTypes.TEST_V_SELECT_PRODUCT,
  payload: { productId },
});
export const testVSelectProductManual = (value: string | null, field: 'productName' | 'productTNVED') => ({
  type: actionTypes.TEST_V_SELECT_PRODUCT_MANUAL,
  payload: { value, field },
});
export const testVSetProductDesc = (value: string | null) => ({
  type: actionTypes.TEST_V_SET_PRODUCT_DESC,
  payload: { value },
});
export const testVGetSessionsAction = () => ({
  type: actionTypes.TEST_V_SESSIONS,
});
export const testVPopulateSessionAction = () => ({
  type: actionTypes.TEST_V_POPULATE_SESSION,
});
export const testVSendAnswer = ({
  shouldExit,
  shouldFinish,
  shouldIncrement,
}: Partial<{
  shouldExit: boolean;
  shouldFinish: boolean;
  shouldIncrement: boolean;
}>) => ({
  type: actionTypes.TEST_V_SEND_ANSWER,
  payload: { shouldExit, shouldFinish, shouldIncrement },
});
export const testVDecrementStep = () => ({
  type: actionTypes.TEST_V_DECREMENT_STEP,
});
export const testVSelectRadio = (answer: number) => ({
  type: actionTypes.TEST_V_SELECT_RADIO,
  payload: { answer },
});
export const testVSelectCheckbox = (answer: number, isChecked: boolean) => ({
  type: actionTypes.TEST_V_SELECT_CHECKBOX,
  payload: { answer, isChecked },
});
export const testVSelectRange = (position: number, value: number | null) => ({
  type: actionTypes.TEST_V_SELECT_RANGE,
  payload: { position, value },
});
export const testVRemoveSession = (id: string) => ({
  type: actionTypes.TEST_V_REMOVE_SESSION,
  payload: { id },
});
export const testVRunFromExisted = (
  { productId, countryId }:
  { productId: number; countryId: number },
) => ({
  type: actionTypes.TEST_V_RUN_FROM_EXISTED,
  payload: { productId, countryId },
});
export const testVFinishAndShowReport = () => ({
  type: actionTypes.TEST_V_FINISH_AND_SHOW_REPORT,
});
export const testVShowReport = (sessionId: string) => ({
  type: actionTypes.TEST_V_SHOW_REPORT,
  payload: { sessionId },
});
export const testVHideReport = () => ({
  type: actionTypes.TEST_V_HIDE_REPORT,
});
export const testVGetHtmlReport = (sessionId: string) => ({
  type: actionTypes.TEST_V_GET_HTML_REPORT,
  payload: { sessionId },
});
export const testVGetPdfReport = (sessionId: string) => ({
  type: actionTypes.TEST_V_GET_PDF_REPORT,
  payload: { sessionId },
});
export const testVGetCustomPdfReport = (sessionId: string) => ({
  type: actionTypes.TEST_V_GET_CUSTOM_PDF_REPORT,
  payload: { sessionId },
});
export const testVGetCustomPdfReportConfirm = () => ({
  type: actionTypes.TEST_V_GET_CUSTOM_PDF_REPORT_CONFIRM,
});
export const testVCloseCustomPdfReport = () => ({
  type: actionTypes.TEST_V_CLOSE_CUSTOM_PDF_REPORT,
});
