import { put, takeLatest, call } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { toast } from 'react-toastify';
import api from 'store/api';
import {
  URL,
  // routes,
} from 'appConstants';
import {
  _t,
  camelCaseToSnakeCase,
  snakeCaseToCamelCase,
} from 'utils';
import type {
  Response,
  ICompanySnake,
} from 'types';
import actionTypes, { UpdateProfileCompanyAction as Action } from '../actionTypes';

function* updateCompany({ type, payload }: Action) {
  try {
    yield put(apiActions.request(type));

    const { data }: Response<ICompanySnake> = yield call(api, {
      method: 'patch',
      url: `${URL.PERSONAL.PROFILE.COMPANY}/${payload?.data.id}/`,
      data: camelCaseToSnakeCase(payload!.data),
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });

    yield put(apiActions.success(type, snakeCaseToCamelCase(data)));
    toast.success(_t('Данные обновлены'));
  } catch (err) {
    toast.error(_t(err.response.data.detail || 'Ошибка загрузки, повторите позже'));
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.ME_PROFILE_COMPANY, updateCompany);
}
