import React, { FC, PropsWithChildren } from 'react';
import {
  Section,
  H2,
  Text,
  NavLink,
  Preloader,
  // BannerBlock,
} from 'components';
import { _t, phoneRefactor } from 'utils';
import { routes, CONTACTS_VALUES } from 'appConstants';
// import { mediaCategoryRoutes, routes } from 'appConstants';
import styles from './styles.module.scss';

type Props = {
  isLoading?: boolean,
};

const LINKS = [
  // {
  //   label: routes.media.interesting.label,
  //   to: routes.media.interesting.root,
  // },
  {
    label: routes.media.news.label,
    to: routes.media.news.root,
  },
  // {
  //   label: routes.media.history.label,
  //   to: routes.media.history.root,
  // },
  {
    label: routes.media.videoFaces.label,
    to: `${routes.media.videoFaces.root}/2`,
  },
  {
    label: routes.media.gallery.label,
    to: routes.media.gallery.root,
  },
  {
    label: routes.media.articles.label,
    to: routes.media.articles.root,
  },
  {
    label: routes.media.specProjects.label,
    to: routes.media.specProjects.root,
  },
  {
    label: routes.media.techTours.label,
    to: routes.media.techTours.root,
  },
  // {
  //   label: routes.media.videoAlbums.label,
  //   to: routes.media.videoAlbums.root,
  // },
  // videos links
  {
    label: routes.media.videoWhatBuy.label,
    to: `${routes.media.videoWhatBuy.root}/4`,
  },
  {
    label: routes.media.videoAboutExport.label,
    to: `${routes.media.videoAboutExport.root}/3`,
  },
  {
    label: routes.media.videoLifeHacks.label,
    to: `${routes.media.videoLifeHacks.root}/5`,
  },
];

const MediaLayout: FC<PropsWithChildren<Props>> = ({
  children,
  isLoading = false,
}) => {
  const smiPhone = CONTACTS_VALUES.phones.main.smi.split(',');

  return (
    <Section className={styles.section}>
      <aside className={styles.aside}>
        <H2 uppercase className={styles.title}>{_t('Медиа')}</H2>
        <Text bold className={styles.description}>
          {_t('описание главного медиа раздела...')}
        </Text>

        <nav className={styles.nav}>
          {LINKS.map(({ label, to }) => (
            <NavLink
              key={label}
              to={to}
              className={styles.link}
              activeClassName={styles.active}
            >
              <Text color="extra" bold uppercase className={styles.label}>{_t(label)}</Text>
            </NavLink>
          ))}
        </nav>

        <Text bold className={styles.description}>
          {/* баннер на странице медия в левой менюшке */}
          {/* <BannerBlock wiev="vertical" /> */}
        </Text>

        <div className={styles.media_contacts}>
          <p className={styles.media_contacts_subheader}>
            {_t('Контактная информация для СМИ:')}
          </p>
          <a
            href={`tel:${CONTACTS_VALUES.phones.main.smi}`}
            className={styles.media_contacts_link}
          >
            {phoneRefactor(smiPhone[0])}
            {smiPhone[1].length > 0 && (
              <><br /> ({_t('доб')}. {smiPhone[1]})</>
            )}
          </a>
          <a
            href={`mailto:${CONTACTS_VALUES.emails.smi.value}`}
            className={styles.media_contacts_link}
          >
            {CONTACTS_VALUES.emails.smi.text}
          </a>
        </div>
      </aside>

      <div className={styles.content}>
        <Preloader isLoading={isLoading}>
          {children}
        </Preloader>
      </div>
    </Section>
  );
};

export default MediaLayout;
