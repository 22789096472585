/* eslint-disable */
// eslint-disable-next-line max-len
/* eslint-disable react/jsx-props-no-spreading,@typescript-eslint/no-explicit-any,react/destructuring-assignment */
import React, { FC, useCallback } from 'react';
import RSelect, {
  components as component,
  ControlProps,
  OptionProps,
  PlaceholderProps,
  MenuProps,
  IndicatorContainerProps,
} from 'react-select';
import RSelectV from 'react-select-virtualized';
import cx from 'classnames';
import { Text, Icon } from 'components';
import { _t } from 'utils';
import { SelectProps, OptionType } from 'types';
import styles from './styles.module.scss';
import './styles.scss';

const ROOT = document.querySelector('body');

interface Props extends SelectProps {
  color?: 'dark' | 'light',
  isVirtualazed?: boolean,
}

const Select: FC<Props> = ({
  name,
  options,
  value,
  placeholder = 'Select value',
  onChange = () => {},
  label = '',
  customLabel = undefined,
  error = '',
  withErrorText = true,
  disabled,
  closeMenuOnSelect,
  hideSelectedOptions,
  controlShouldRenderValue,
  isClearable = false,
  isSearchable = false,
  isMulti = false,
  customStyles = {},
  classNameWrap = '',
  classNameControl = '',
  classNameOption = '',
  classNamePlaceholder = '',
  classNameMenu = '',
  classNameIndicatorsContainer = '',
  classNameDropdownIndicator = '',
  classNameValueContainer = '',
  className,
  onMenuOpen,
  onMenuClose,
  withPortal = false,
  menuPortalTarget,
  components,
  color = 'light',
  isVirtualazed = false,
}) => {
  const Control = useCallback((props: ControlProps<OptionType, boolean>) => (
    <component.Control
      {...props}
      className={cx(
        styles.control,
        styles[color],
        {
          [styles.controlFocused]: props.isFocused,
          [styles.controlOpen]: props.menuIsOpen,
        },
        classNameControl,
      )}
    />
  ), [classNameControl]);

  const Option = useCallback((props: OptionProps<OptionType, boolean>) => (
    <component.Option
      {...props}
      className={cx(
        styles.option,
        {
          [styles.optionSelected]: props.isSelected,
          [styles.optionFocused]: props.isFocused,
        },
        classNameOption,
      )}
    >
      <Text {...props} tag="span" size="small" />
    </component.Option>
  ), [classNameOption]);

  const Placeholder = useCallback((props: PlaceholderProps<OptionType, boolean>) => (
    <Text
      {...props}
      className={cx(styles.placeholder, classNamePlaceholder)}
      tag="span"
      size="small"
    />
  ), [classNamePlaceholder]);

  const Menu = useCallback((props: MenuProps<OptionType, boolean>) => (
    <component.Menu {...props} className={cx(styles.menu, classNameMenu)} />
  ), [classNameMenu]);

  const ValueContainer = useCallback((props: any) => (
    <component.ValueContainer
      {...props}
      className={cx(styles.valueContainer, classNameValueContainer)}
    />
  ), [classNameValueContainer]);

  const IndicatorsContainer = useCallback((props: IndicatorContainerProps<OptionType, boolean>) => (
    <component.IndicatorsContainer
      {...props}
      className={cx(styles.indicatorsContainer, classNameIndicatorsContainer)}
    />
  ), [classNameIndicatorsContainer]);

  const DropdownIndicator = useCallback((props: any) => (
    <Icon
      {...props}
      icon="dropdown"
      className={cx(
        styles.dropdownIndicator,
        {
          [styles.dropdownIndicatorOpen]: props.selectProps.menuIsOpen,
        },
        classNameDropdownIndicator,
      )}
    />
  ), [classNameDropdownIndicator]);

  const NoOptionsMessage = useCallback(() => (
    <Text className={styles.noOptionsMessage} align="center">Ничего не найдено</Text>
  ), []);

  const menuPortalTargetInfo = withPortal
    ? menuPortalTarget || ROOT
    : null;

  const Comp = isVirtualazed ? RSelectV : RSelect;
  return (
    <div className={classNameWrap}>
      {customLabel}
      {(label && !customLabel) && <Text className={styles.label} tag="span">{_t(label)}</Text>}

      <Comp
        components={{
          Option: components?.Option || Option,
          Control: components?.Control || Control,
          IndicatorSeparator: components?.IndicatorSeparator || null,
          IndicatorsContainer: components?.IndicatorsContainer || IndicatorsContainer,
          Placeholder: components?.Placeholder || Placeholder,
          Menu: components?.Menu || Menu,
          ValueContainer: components?.ValueContainer || ValueContainer,
          DropdownIndicator: components?.DropdownIndicator || DropdownIndicator,
          NoOptionsMessage,
        }}
        isDisabled={disabled}
        options={options}
        // menuIsOpen
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
        controlShouldRenderValue={controlShouldRenderValue}
        value={value}
        name={name}
        placeholder={placeholder}
        className={cx(
          styles.select,
          {
            [styles.selectError]: error,
            [styles.selectDisabled]: disabled,
          },
          className,
        )}
        onChange={onChange}
        styles={{ ...customStyles }}
        menuPortalTarget={menuPortalTargetInfo}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        isMulti={isMulti}
        isClearable={isClearable}
        isSearchable={isSearchable}
        {
          ...(isVirtualazed ? {
            optionHeight: 36,
          } : {})
        }
      />

      {error && withErrorText && (
        <Text
          size="tiny"
          color="error"
          align="right"
          className={styles.error}
        >
          {_t(error)}
        </Text>
      )}
    </div>
  );
};

export default Select;
