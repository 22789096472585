import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import type { ButtonProps as Props } from 'types';
import { _t } from 'utils';
import styles from './styles.module.scss';

const Button: FC<PropsWithChildren<Props>> = ({
  children,
  type = 'button',
  color = 'primary',
  title = '',
  size = 'normal',
  linkTo = '',
  upperCase = false,
  disabled = false,
  isLoading = false,
  id = '',
  className,
  onClick = () => {},
  blueMode = false,
}) => {
  let tTitle = title ? _t(title) : '';
  if (upperCase) tTitle = tTitle.toUpperCase();
  return (
    <>
      {/* eslint-disable-next-line react/button-has-type */}
      <button
        type={type}
        id={id}
        className={cx(
          styles.btn,
          styles[size],
          styles[blueMode ? `${color}Blue` : color],
          className,
          {
            [styles.disabled]: disabled || isLoading,
            [styles.disabledGrey]: disabled && blueMode,
          },
        )}
        disabled={disabled}
        onClick={onClick}
      >
        {isLoading ? `${_t('Загрузка')}...` : tTitle || children}
      </button>
      {!!linkTo && (
        <a className={styles.hiddenLink} href={linkTo}>
          {tTitle}
        </a>
      )}
    </>
  );
};

export default Button;
