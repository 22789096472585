import React, {
  useEffect,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import {
  CustomApplicationForm,
  Input,
  Textarea,
} from 'components';
import { useRequest } from 'hooks';
import {
  ERRORS,
  // URL
} from 'appConstants';
import { _t } from 'utils';
import { toast } from 'react-toastify';
import { RequestStatus, State } from 'types';
import styles from './styles.module.scss';

type Values = {
  name: string,
  link: string,
  phone: string,
  email: string,
  text: string,
};

type Req = {
  name: string,
  link: string,
  phone: string,
  email: string,
  text: string,
};

const initialValues: Values = {
  name: '',
  link: '',
  phone: '',
  email: '',
  text: '',
};

const validationSchema = object().shape({
  name: string().required(ERRORS.required),
  link: string().required(ERRORS.required),
  phone: string().required(ERRORS.required),
  email: string().required(ERRORS.required),
  text: string().required(ERRORS.required),
});

const PersonalGoodsCopmRequest = () => {
  const token = useSelector(({ me }: State) => me.token);

  const {
    values,
    isValid,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik<Values>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: ({
      name,
      link,
      phone,
      email,
      text,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    }) => request({
      url: 'URL.PERSONAL',
      method: 'post',
      data: {
        name,
        link,
        phone,
        email,
        text,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  });

  const { request, status } = useRequest<Req>({
    callback: () => {
      toast.success(_t('Данные отправлены!'));
      resetForm();
    },
  });

  useEffect(() => {
    if (status === RequestStatus.ERROR) {
      toast.error(_t('Ошибка отправки данных'));
    }
  }, [status]);

  const handleChangeWithMask = useCallback(({ target: { name, value } }) => {
    const event = {
      target: {
        name,
        value: value.replace(/_+$/, ''),
      },
    };

    handleChange(event);
  }, []);

  return (
    <CustomApplicationForm
      onSubmit={handleSubmit}
      className={styles.def}
      openerName="Отправить заявку"
      titleName="Запрос на связь с компанией"
      disabledSubmit={isValid && !isValid}
      isLoading={status === RequestStatus.REQUEST}
      autoClose={status === RequestStatus.SUCCESS}
      policy
    >
      <div className={styles.contactHead}>{_t('Контактные данные')}</div>
      <Input
        name="name"
        placeholder="ФИО"
        className={styles.input}
        error={errors.name && touched.name ? errors.name : ''}
        value={values.name}
        onChange={handleChange}
      />
      <Input
        name="link"
        placeholder="Название компании"
        className={styles.input}
        error={errors.link && touched.link ? errors.link : ''}
        value={values.link}
        onChange={handleChange}
      />
      <Input
        name="email"
        placeholder="Контактная почта"
        className={styles.input}
        error={errors.email && touched.email ? errors.email : ''}
        value={values.email}
        onChange={handleChange}
      />
      <Input
        name="phone"
        placeholder="Контактный телефон"
        className={styles.input}
        error={errors.phone && touched.phone ? errors.phone : ''}
        value={values.phone}
        onChange={handleChangeWithMask}
        mask="+7 (999) 999-99-99"
      />
      <Textarea
        name="text"
        placeholder="Ваш запрос"
        className={styles.textarea}
        error={errors.text && touched.text ? errors.text : ''}
        value={values.text}
        onChange={handleChange}
      />
    </CustomApplicationForm>
  );
};

export default PersonalGoodsCopmRequest;
