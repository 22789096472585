import React, {
  FC,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { PersonalDetailMenu } from 'components';
import {
  PersonalBaseLayout,
} from 'containers';
import {
  Lead,
  // LeadStatus,
} from 'types';
import { _t, dateToUiFormat } from 'utils';
// import cx from 'classnames';
import styles from './styles.module.scss';

// type Tstatus = '1' | '2';
// type TtestData = {
//   [index: string]: string | Tstatus,
// };

interface Props {
  data?: Lead,
}

const Links = [
  _t('Информация о заявке'),
  // 'Файлы',
];

const PersonalLeadsDetail: FC<Props> = ({
  data = null,
}) => {
  const [windowId, setWindowId] = useState<Number>(0);
  const [showDetail, setShowDetail] = useState<boolean>(false);

  const params = useMemo(() => [
    {
      label: _t('Номер заявки'),
      value: `#${data?.id}` || '',
    },
    {
      label: _t('Дата подачи'),
      value: dateToUiFormat(data?.created) || '',
    },
    {
      label: _t('Ответственный'),
      // value: data?.created || '',
      value: 'Не указан',
    },
    {
      label: _t('Компания'),
      value: 'Не указан',
    },
  ], [data]);

  useEffect(() => {
    if (data) {
      setShowDetail(true);
    } else {
      setShowDetail(false);
    }
  }, [data]);

  const closeHandler = () => {
    setShowDetail(false);
  };

  const handleToggle = (tabKey: number) => {
    setWindowId(tabKey);
  };

  // const renderStatus = (val: string) => (
  //   <div
  //     className={cx(styles.leadStatus, styles[`icon-${val}`])}
  //   >
  //     {val === '1' ? 'Требуется отчет' : 'Заявка закрыта'}
  //   </div>
  // );

  return (showDetail && data) ? (
    <div
      className={styles.PersonalLeadsDetail}
    >
      <div
        className={styles.close}
        onClick={closeHandler}
      />
      {/* {renderStatus(data.status || [])} */}
      <PersonalBaseLayout
        pageTitle={data.title}
        className={styles.detailContent}
        nonLang
        nonTopM
      >
        <PersonalDetailMenu
          links={Links}
          active={Number(windowId)}
          onClick={handleToggle}
        />

        <div className={styles.data}>
          {Links[+windowId] === Links[0] && (
            params.map(({ label, value }) => (
              <div
                className={styles.dataLine}
                key={`leadsMain_${Math.random()}`}
              >
                <div className={styles.label}>{label}:</div>
                <div className={styles.value}>{value}</div>
              </div>
            ))
          )}

          {Links[+windowId] === Links[1] && (
            <div>
              {Links[+windowId]}
            </div>
          )}
        </div>
      </PersonalBaseLayout>
    </div>
  ) : (
    <div />
  );
};

export default PersonalLeadsDetail;
