import React, { FC } from 'react';
import {
  IDocument,
} from 'types';
import { FileUploader } from 'components';
import { URL } from 'appConstants';
import { _t } from 'utils';
import cx from 'classnames';
import styles from './styles.module.scss';

interface Props {
  data: IDocument[],
  token: string,
  onFilesUpdate?: () => void,
}

const FILE_STATUS = {
  A: {
    text: _t('Документ отсутствует'),
    slug: 'NEED_TO_ADD',
    btnName: 'Добавить документ',
    code: 'error',
  },
  P: {
    text: _t('На проверке'),
    slug: 'IN_PROCESS',
    btnName: 'Изменить',
    code: 'inprogress',
  },
  U: {
    text: _t('Обновите документ'),
    slug: 'UPDATE_DOC',
    btnName: 'Обновить',
    code: 'refresh',
  },
  D: {
    text: _t('Документ действителен'),
    slug: 'DONE',
    btnName: 'Изменить',
    code: 'ok',
  },
};

const refactorData = (val:string) => {
  return new Date(val).toLocaleDateString();
};

const ProfileMainDocuments: FC<Props> = ({
  data,
  token,
  onFilesUpdate,
}) => {
  return (
    <div className={styles.itemData}>
      {data.map((item) => (
        <div
          key={`line_${item.id}`}
          className={styles.docItem}
        >
          <div
            className={cx(
              styles.status,
              styles[FILE_STATUS[item?.status].code],
            )}
          >
            {FILE_STATUS[item?.status].text}
            {(FILE_STATUS[item?.status].code === 'ok' && item.doc_valid_date) && ` до ${refactorData(item.doc_valid_date)} г.`}
          </div>
          {/* <div className={styles.dataValue}>
            {item.due_date}
          </div> */}
          <div className={styles.dataName}>
            {item.title}
          </div>
          <FileUploader
            title={FILE_STATUS[item?.status].btnName}
            className={styles.btnName}
            token={token}
            onFilesUpdate={onFilesUpdate}
            uploadUrl={`${URL.PERSONAL.PROFILE.DOCUMENTS.GET}/${item.id}/`}
          />
        </div>
      ))}
    </div>
  );
};

export default ProfileMainDocuments;
