import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  isLoading: boolean,
  withoutAnim?: boolean,
  className?: string,
};

const Preloader: FC<PropsWithChildren<Props>> = ({
  isLoading,
  children,
  withoutAnim = false,
  className,
}) => (
  isLoading ? (
    <div
      className={cx(
        styles.preloader,
        className,
      )}
    >
      <span />
      <span />
      <span />
    </div>
  ) : (
    <div
      className={cx(
        { [styles.visibleContent]: !withoutAnim },
        className,
      )}
    >
      {children}
    </div>
  )
);

export default Preloader;
