import React, { FC, useEffect } from 'react';
import { MainLayout } from 'containers';
import {
  ButtonArrow, Card, Preloader, Text, NoData,
} from 'components';
import {
  addZeroInStartForLittleNum,
  _has_t_back,
  _t_back,
} from 'utils';
import { Card as CardType, RequestStatus } from 'types';
import { useRequest } from 'hooks';
import { URL, routes } from 'appConstants';
import styles from './styles.module.scss';

type Props = {
  targetDetailsPath: string,
};

const AnalyticsIndustry: FC<Props> = ({
  targetDetailsPath = routes.analytics.industry.root,
}) => {
  const { request, status, data: cards = [] } = useRequest<CardType[]>();
  useEffect(() => {
    request({
      method: 'GET',
      url: URL.ANALYTIC.GET_CARDS,
    });
  }, []);

  const filtered = cards?.filter(_has_t_back) || [];

  return (
    <MainLayout
      title="Аналитические исследования по индустриям"
      classNameTitle={styles.title}
    >
      <Preloader isLoading={status === RequestStatus.REQUEST}>
        <NoData count={filtered?.length || 0}>
          <ul className={styles.list}>
            {
              filtered?.map(({
                file,
                file_name,
                article,
                id,
              }, index) => ((
                <li key={id} className={styles.item}>
                  <Card
                    tag="a"
                    target={article !== '' ? '_self' : '_blank'}
                    href={article !== '' ? `${targetDetailsPath}/${id}` : file}
                    className={styles.card}
                  >
                    <Text
                      className={styles.num}
                      bold
                    >
                      {addZeroInStartForLittleNum(index + 1)}
                    </Text>
                    <div className={styles.footer}>
                      <Text
                        className={styles.text}
                        bold
                      >
                        {_t_back(filtered[index], 'title')}{file_name?.length > 0 && `: ${_t_back(filtered[index], 'file_name')}`}
                      </Text>
                      <ButtonArrow className={styles.arrow} />
                    </div>
                  </Card>
                </li>
              )))
            }
          </ul>
        </NoData>
      </Preloader>
    </MainLayout>
  );
};

export default AnalyticsIndustry;
