import { TestState } from 'types';

export const initialState: TestState = {
  personalData: {
    member: {
      id: null,
      email: null,
      firstName: null,
      lastName: null,
      role: null,
      client: {
        id: null,
        role: null,
        inn: null,
        ogrn: null,
        phone: null,
        position: null,
        email: null,
        company: null,
      },
    },
    company: {
      id: null,
      actualAddress: null,
      dateOfRegistration: null,
      descriptionTypesOfActivity: null,
      email: null,
      fullName: null,
      fullNameEng: null,
      inn: null,
      kpp: null,
      legalAddress: null,
      name: null,
      okved: null,
      ogrn: null,
      region: null,
      status: null,
      sector: null,
      tnved: null,
      typeOfActivity: null,
      webSite: null,
      workPhone: null,
    },
  },
  qualificationSessionID: null,
  qualificationSteps: [],
  qualificationSessionShowID: null,
  qualificationSessions: [],
  qualificationMeta: {
    isGoing: false,
    isConfermedPersonalData: false,
    checkFinalAction: false,
    isFinished: false,
    showReport: false,
    getCustomPdf: false,
    reportHtml: null,
    hiddenSessionID: null,
    answer: null,
    current: 0,
    passed: 0,
    total: 0,
    status: null,
    created: null,
    updated: null,
    completed: null,
  },
  validationTopic: {
    countries: [],
    products: [],
    countryId: null,
    productId: null,
    productName: null,
    productTNVED: null,
    productDescription: null,
  },
  validationSessionShowID: null,
  validationSessionID: null,
  validationSessions: [],
  validationSteps: [],
  validationMeta: {
    isGoing: false,
    isFinished: false,
    checkFinalAction: false,
    isSelectedProduct: false,
    reportHtml: null,
    showReport: false,
    getCustomPdf: false,
    current: 0,
    answer: null,
    passed: 0,
    total: 0,
  },
};
