import React, { FC } from 'react';
import { FormikHandlers, FormikValues } from 'formik';
import { ClientLang } from 'appConstants';
import { _t_back } from 'utils';
import { FilterGroup, Checkbox } from 'components';
import { BaseFilter as Filter } from 'types';
import styles from './styles.module.scss';

type Props = {
  values: FormikValues,
  fieldByName?: boolean,
  classNameContent?: string,
  name: string,
  label: string,
  list: Array<Filter>,
  handleChange: FormikHandlers['handleChange']
  onScroll?: () => void
};
// containers/Filters/FiltersCheckboxes/index.tsx

const FiltersCheckboxes: FC<Props> = ({
  values,
  fieldByName,
  name,
  label,
  list,
  handleChange,
  classNameContent,
  onScroll,
}) => {
  const getFieldTranslate = (value: Filter) => {
    if (fieldByName) {
      if (ClientLang === 'ru') {
        return _t_back(value, 'name');
      }
      return (value.name_in_en || _t_back(value, 'name'));
    }
    return _t_back(value, 'title');
  };

  return (
    <FilterGroup
      label={label}
      selectedCount={values[name].length}
      onReset={() => handleChange({
        target: {
          name,
          value: [],
        },
      })}
      classNameContent={classNameContent}
      onScroll={onScroll}
    >
      {list.map((listItem) => (
        <Checkbox
          key={`${listItem.id}-${name}`}
          checked={values[name].includes(listItem.id)}
          name={`${listItem.id}-${name}`}
          label={getFieldTranslate(listItem)}
          className={styles.filter}
          onChange={(e, checked) => {
            if (e) {
              window.scrollTo({
                // eslint-disable-next-line
                top: Number.parseInt(sessionStorage.getItem('eventsScrollTop') || '', 10),
              });
            }
            handleChange({
              target: {
                name,
                value: checked ? [...values[name] as number[], listItem.id]
                  : (values[name] as number[]).filter((el: number) => el !== listItem.id),
              },
            });
          }}
        />
      ))}
    </FilterGroup>
  );
};

export default FiltersCheckboxes;
