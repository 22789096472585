import React, { FC } from 'react';
import {
  Select,
} from 'components';
import { _t } from 'utils';
import styles from './styles.module.scss';

type Options = {
  id: number,
  title: string,
};

type Props = {
  title: string,
  placeholder: string,
  options: Options[],
};

const getTextLabel = (title: string) => (
  <div className={styles.title}>{title}</div>
);

const FilterParamSelect: FC<Props> = ({
  title,
  placeholder,
  options,
}) => {
  return (
    <Select
      placeholder={_t(placeholder)}
      isSearchable
      isClearable
      className={styles.filterparam}
      options={options.map((region) => ({
        label: region.title,
        value: region.id.toString(),
      }))}
      isMulti={false}
      customLabel={getTextLabel(_t(title))}
      onChange={() => {
      //   onChange={(option) => {
      //     if (option) {
      //       handleSelectRegion(+(option as OptionType).value);
      //     } else {
      //       handleSelectRegion(0);
      //     }
      }}
    />
  );
};

export default FilterParamSelect;
