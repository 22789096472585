import React, {
  FC,
  PropsWithChildren,
  useState,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { useRequest } from 'hooks';
import { routes, URL } from 'appConstants';
import {
  Link,
  PersonalExit,
} from 'components';
import {
  IUserMe,
  IProfileSnake,
  State,
} from 'types';
import { NoImage } from 'utils';
import cx from 'classnames';
import styles from './styles.module.scss';

interface Props {}

const PersonalTopControl: FC<PropsWithChildren<Props>> = () => {
  const token = useSelector(({ me }: State) => me.token);
  const [topControlOpen, setTopControlOpen] = useState<boolean>(false);

  const { data: UserData, request: UserReq } = useRequest<IUserMe>();
  const { data: ProfData, request: ProfReq } = useRequest<IProfileSnake>();

  useEffect(() => {
    UserReq({
      method: 'GET',
      url: URL.PERSONAL.PROFILE.USER,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }, []);

  useEffect(() => {
    if (UserData?.client?.id) {
      ProfReq({
        method: 'GET',
        url: `${URL.PERSONAL.PROFILE.MAIN}/${UserData.client.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }, [UserData]);

  const handleClick = () => {
    setTopControlOpen(!topControlOpen);
  };

  return (
    <div
      className={cx(
        styles.personalTopControl,
        { [styles.opened]: topControlOpen },
      )}
    >
      <div onClick={handleClick}>
        <img
          className={styles.userAvatar}
          src={ProfData?.client_avatar || NoImage}
          alt="ProfilePicture"
        />
      </div>

      <div
        className={styles.topContolMenu}
      >
        <Link
          className={styles.link}
          to={routes.personal.profile.root}
          title={routes.personal.profile.title}
        >
          <div>{ProfData?.user}</div>
          <div className={styles.smallEmail}>{ProfData?.email}</div>
        </Link>
        <Link
          className={cx(styles.link, styles.separator)}
          to={routes.personal.faq.root}
          title={routes.personal.faq.title}
        >
          {routes.personal.faq.title}
        </Link>
        <PersonalExit
          className={cx(styles.link, styles.separator, styles.exit)}
        />
      </div>

    </div>
  );
};

export default PersonalTopControl;
