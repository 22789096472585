import type {
  Company,
  Member,
  PersonalDataRes,
  GetHtmlReportRes,
  QSessionIDErr,
  QSessionIDRes,
  QSessionsRes,
  SessionStepsRes,
  TestState,
  ValidationSession,
  VGetProductsRes,
  VSessionIDRes,
  VSessionsRes,
} from 'types';
import { snakeCaseToCamelCase } from 'utils';
import actionTypes, { TestAction } from './actionTypes';
import { initialState } from './InitialState';

export default (state: TestState = initialState, action: TestAction): TestState => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.TEST_PERSONAL_DATA_SUCCESS: {
      const { company, member } = payload as PersonalDataRes;
      return {
        ...state,
        personalData: {
          member: snakeCaseToCamelCase(member) as Member,
          company: snakeCaseToCamelCase(company) as Company,
        },
      };
    }
    case actionTypes.TEST_Q_SESSION_ID_SUCCESS: {
      return {
        ...state,
        qualificationSessionID: (payload as QSessionIDRes).id,
      };
    }
    case actionTypes.TEST_Q_SESSION_ID_ERROR: {
      return {
        ...state,
        qualificationMeta: {
          ...state.qualificationMeta,
          isFinished: (payload as QSessionIDErr)?.response?.data?.code === 2 ?? false,
        },
      };
    }
    case actionTypes.TEST_Q_SESSIONS_SUCCESS: {
      const {
        status, created, updated, completed, id,
      } = payload as QSessionsRes;
      return {
        ...state,
        qualificationMeta: {
          ...state.qualificationMeta,
          isFinished: status === 'C' || status === 'P' || false,
          status,
          created,
          updated,
          completed,
          hiddenSessionID: id,
        },
      };
    }
    case actionTypes.TEST_Q_STEPS_SUCCESS: {
      const { steps } = (payload as SessionStepsRes);
      const passed = steps.reduce((acc, item) => (item.answer !== undefined ? acc + 1 : acc), 0);
      return {
        ...state,
        qualificationSteps: steps,
        qualificationMeta: {
          ...state.qualificationMeta,
          isGoing: false,
          isConfermedPersonalData: passed > 0,
          isFinished: false,
          current: passed === steps.length ? passed : passed + 1,
          answer: null,
          passed,
          total: steps.length,
        },
      };
    }
    case actionTypes.TEST_Q_INCREMENT_STEP: {
      const nextStep = state.qualificationMeta.current + 1;
      const stepData = state.qualificationSteps.find(({ step }) => step === nextStep);
      const answer = stepData?.answer ? stepData.answer : null;
      return {
        ...state,
        qualificationMeta: {
          ...state.qualificationMeta,
          current: nextStep,
          answer: Array.isArray(answer) ? [...answer] : answer,
        },
      };
    }
    case actionTypes.TEST_Q_DECREMENT_STEP: {
      const prevStep = state.qualificationMeta.current - 1;
      const stepData = state.qualificationSteps.find(({ step }) => step === prevStep);
      const answer = stepData?.answer && prevStep !== 0 ? stepData.answer : null;
      return {
        ...state,
        qualificationMeta: {
          ...state.qualificationMeta,
          current: prevStep,
          answer: Array.isArray(answer) ? [...answer] : answer,
        },
      };
    }
    case actionTypes.TEST_Q_CONFIRM_PERSONAL_DATA: {
      return {
        ...state,
        qualificationMeta: {
          ...state.qualificationMeta,
          isConfermedPersonalData: true,
          answer: null,
        },
      };
    }
    case actionTypes.TEST_Q_SELECT_RADIO: {
      return {
        ...state,
        qualificationMeta: {
          ...state.qualificationMeta,
          answer: (payload as { answer: number }).answer,
        },
      };
    }
    case actionTypes.TEST_Q_SELECT_CHECKBOX: {
      const { answer, isChecked } = payload as { answer: number; isChecked: boolean };
      let updatedAnswer = (state.qualificationMeta.answer as number[]) || [];
      if (isChecked) {
        updatedAnswer = [...updatedAnswer, answer];
      } else {
        updatedAnswer = updatedAnswer.filter((item) => item !== answer);
      }
      return {
        ...state,
        qualificationMeta: {
          ...state.qualificationMeta,
          answer: updatedAnswer,
        },
      };
    }
    case actionTypes.TEST_Q_SELECT_RANGE: {
      const { position, value } = payload as { position: number; value: number | null };
      const updatedAnswer = JSON.parse(
        JSON.stringify(state.qualificationMeta.answer as (number | null)[]),
      ) || [];
      updatedAnswer[position] = value;
      return {
        ...state,
        qualificationMeta: {
          ...state.qualificationMeta,
          answer: updatedAnswer,
        },
      };
    }
    case actionTypes.TEST_Q_SEND_ANSWER_SUCCESS: {
      const stepsUpdated = state.qualificationSteps.map((step) => {
        if (step.step === state.qualificationMeta.current) {
          return {
            ...step,
            answer: state.qualificationMeta.answer === null ?
              undefined : state.qualificationMeta.answer as number | number[],
          };
        }
        return step;
      });
      return {
        ...state,
        qualificationSteps: stepsUpdated,
        qualificationMeta: {
          ...state.qualificationMeta,
          passed: state.qualificationMeta.passed + 1,
          answer: null,
        },
      };
    }
    case actionTypes.TEST_Q_FINISH_SUCCESS: {
      return {
        ...state,
        qualificationMeta: {
          ...state.qualificationMeta,
          isFinished: true,
        },
      };
    }
    case actionTypes.TEST_Q_SHOW: {
      const passed = state.qualificationSteps
        .reduce((acc, item) => (item.answer !== undefined ? acc + 1 : acc), 0);
      const currentStep = passed === state.qualificationMeta.total ? passed : passed + 1;
      const stepData = state.qualificationSteps.find(({ step }) => step === currentStep);
      return {
        ...state,
        qualificationMeta: {
          ...state.qualificationMeta,
          isGoing: true,
          passed,
          current: currentStep,
          answer: stepData?.answer ? stepData.answer : null,
        },
      };
    }
    case actionTypes.TEST_Q_HIDE: {
      return {
        ...state,
        qualificationMeta: {
          ...state.qualificationMeta,
          isGoing: false,
          isConfermedPersonalData: !!state.qualificationMeta.passed,
          answer: initialState.qualificationMeta.answer,
          current: initialState.qualificationMeta.current,
        },
      };
    }
    case actionTypes.TEST_Q_REMOVE_SESSION_SUCCESS: {
      return {
        ...state,
        qualificationSessionID: initialState.qualificationSessionID,
        qualificationSteps: initialState.qualificationSteps,
        qualificationMeta: {
          ...initialState.qualificationMeta,
        },
      };
    }
    case actionTypes.TEST_Q_RESET: {
      return {
        ...state,
        qualificationSessionID: initialState.qualificationSessionID,
        qualificationSteps: initialState.qualificationSteps,
        qualificationMeta: initialState.qualificationMeta,
      };
    }
    case actionTypes.TEST_Q_SHOW_REPORT: {
      const { sessionId } = payload as { sessionId: string | null};
      return {
        ...state,
        qualificationMeta: {
          ...state.qualificationMeta,
          showReport: true,
        },
        qualificationSessionShowID: sessionId,
      };
    }
    case actionTypes.TEST_Q_HIDE_REPORT: {
      return {
        ...state,
        qualificationMeta: {
          ...state.qualificationMeta,
          showReport: false,
          reportHtml: null,
        },
        qualificationSessionShowID: null,
      };
    }
    case actionTypes.TEST_Q_SHOW_FINAL_ACTION: {
      return {
        ...state,
        qualificationMeta: {
          ...state.qualificationMeta,
          checkFinalAction: true,
        },
      };
    }
    case actionTypes.TEST_Q_HIDE_FINAL_ACTION: {
      return {
        ...state,
        qualificationMeta: {
          ...state.qualificationMeta,
          checkFinalAction: false,
        },
      };
    }
    case actionTypes.TEST_Q_GET_CUSTOM_PDF_REPORT: {
      const { sessionId } = payload as { sessionId: string | null};
      return {
        ...state,
        qualificationMeta: {
          ...state.qualificationMeta,
          showReport: false,
          getCustomPdf: true,
        },
        qualificationSessionShowID: sessionId,
      };
    }
    case actionTypes.TEST_Q_CLOSE_CUSTOM_PDF_REPORT: {
      return {
        ...state,
        qualificationMeta: {
          ...state.qualificationMeta,
          getCustomPdf: false,
        },
        qualificationSessionShowID: null,
      };
    }
    case actionTypes.TEST_Q_GET_HTML_REPORT_SUCCESS: {
      const { html } = payload as GetHtmlReportRes;
      return {
        ...state,
        qualificationMeta: {
          ...state.qualificationMeta,
          reportHtml: html,
        },
      };
    }
    case actionTypes.TEST_V_GET_PRODUCTS_SUCCESS: {
      const { countries, products } = payload as VGetProductsRes;
      return {
        ...state,
        validationTopic: {
          countryId: initialState.validationTopic.countryId,
          productId: initialState.validationTopic.productId,
          productName: initialState.validationTopic.productName,
          productTNVED: initialState.validationTopic.productTNVED,
          productDescription: initialState.validationTopic.productDescription,
          countries,
          products,
        },
      };
    }
    case actionTypes.TEST_V_SHOW: {
      const passed = state.validationSteps
        .reduce((acc, item) => (item.answer !== undefined ? acc + 1 : acc), 0);
      const currentStep = passed === state.validationMeta.total ? passed : passed + 1;
      const stepData = state.validationSteps.find(({ step }) => step === currentStep);
      return {
        ...state,
        validationMeta: {
          ...state.validationMeta,
          isGoing: true,
          passed,
          current: currentStep,
          answer: stepData?.answer ? stepData.answer : null,
        },
      };
    }
    case actionTypes.TEST_V_HIDE: {
      return {
        ...state,
        validationTopic: initialState.validationTopic,
        validationSessionID: initialState.validationSessionID,
        validationSessions: initialState.validationSessions,
        validationSteps: initialState.validationSteps,
        validationMeta: {
          ...initialState.validationMeta,
          reportHtml: state.validationMeta.reportHtml,
        },
      };
    }
    case actionTypes.TEST_V_SELECT_COUNTRY: {
      return {
        ...state,
        validationTopic: {
          ...state.validationTopic,
          countryId: (payload as { countryId: number }).countryId,
        },
      };
    }
    case actionTypes.TEST_V_SELECT_PRODUCT: {
      return {
        ...state,
        validationTopic: {
          ...state.validationTopic,
          productId: (payload as { productId: number | null }).productId,
          productName: initialState.validationTopic.productName,
          productTNVED: initialState.validationTopic.productTNVED,
        },
      };
    }
    case actionTypes.TEST_V_SELECT_PRODUCT_MANUAL: {
      const {
        value,
        field,
      } = payload as { value: string | null, field: 'productName' | 'productTNVED' };
      return {
        ...state,
        validationTopic: {
          ...state.validationTopic,
          productId: initialState.validationTopic.productId,
          [field]: value,
        },
      };
    }
    case actionTypes.TEST_V_SET_PRODUCT_DESC: {
      const {
        value,
      } = payload as { value: string | null };
      return {
        ...state,
        validationTopic: {
          ...state.validationTopic,
          productDescription: value,
        },
      };
    }
    case actionTypes.TEST_V_SESSION_ID_SUCCESS: {
      return {
        ...state,
        validationSessionID: (payload as VSessionIDRes).id,
      };
    }
    case actionTypes.TEST_V_SESSIONS_SUCCESS: {
      return {
        ...state,
        validationSessions: (payload as VSessionsRes).map((session) => ({
          ...session,
          company: snakeCaseToCamelCase(session.company) as ValidationSession['company'],
          user: snakeCaseToCamelCase(session.user) as ValidationSession['user'],
        })),
      };
    }
    case actionTypes.TEST_V_GET_STEPS_SUCCESS: {
      const { steps } = (payload as SessionStepsRes);
      const passed = steps.reduce((acc, item) => (item.answer !== undefined ? acc + 1 : acc), 0);
      const isLastStep = passed === steps.length;
      const currentStep = isLastStep ? passed : passed + 1;
      const stepData = steps.find(({ step }) => step === currentStep);
      return {
        ...state,
        validationSteps: steps,
        validationMeta: {
          ...state.validationMeta,
          isSelectedProduct: true,
          current: currentStep,
          answer: isLastStep && stepData?.answer ? stepData.answer : null,
          passed,
          total: steps.length,
        },
      };
    }
    case actionTypes.TEST_V_SEND_ANSWER_SUCCESS: {
      const stepsUpdated = state.validationSteps.map((step) => {
        if (step.step === state.validationMeta.current) {
          return {
            ...step,
            answer: state.validationMeta.answer === null ?
              undefined : state.validationMeta.answer as number | number[],
          };
        }
        return step;
      });
      return {
        ...state,
        validationSteps: stepsUpdated,
        validationMeta: {
          ...state.validationMeta,
          passed: state.validationMeta.passed + 1,
        },
      };
    }
    case actionTypes.TEST_V_INCREMENT_STEP: {
      const nextStep = state.validationMeta.current + 1;
      const stepData = state.validationSteps.find(({ step }) => step === nextStep);
      const answer = stepData?.answer ? stepData.answer : null;
      return {
        ...state,
        validationMeta: {
          ...state.validationMeta,
          current: nextStep,
          answer: Array.isArray(answer) ? [...answer] : answer,
        },
      };
    }
    case actionTypes.TEST_V_DECREMENT_STEP: {
      const prevStep = state.validationMeta.current - 1;
      const stepData = state.validationSteps.find(({ step }) => step === prevStep);
      const answer = stepData?.answer && prevStep !== 0 ? stepData.answer : null;
      return {
        ...state,
        validationMeta: {
          ...state.validationMeta,
          current: prevStep,
          answer: Array.isArray(answer) ? [...answer] : answer,
        },
      };
    }
    case actionTypes.TEST_V_SELECT_RADIO: {
      return {
        ...state,
        validationMeta: {
          ...state.validationMeta,
          answer: (payload as { answer: number }).answer,
        },
      };
    }
    case actionTypes.TEST_V_SELECT_CHECKBOX: {
      const { answer, isChecked } = payload as { answer: number; isChecked: boolean };
      let updatedAnswer = (state.validationMeta.answer as number[]) || [];
      if (isChecked) {
        updatedAnswer = [...updatedAnswer, answer];
      } else {
        updatedAnswer = updatedAnswer.filter((item) => item !== answer);
      }
      return {
        ...state,
        validationMeta: {
          ...state.validationMeta,
          answer: updatedAnswer,
        },
      };
    }
    case actionTypes.TEST_V_SELECT_RANGE: {
      const { position, value } = payload as { position: number; value: number | null };
      const updatedAnswer = JSON.parse(
        JSON.stringify(state.validationMeta.answer as (number | null)[]),
      ) || [];
      updatedAnswer[position] = value;
      return {
        ...state,
        validationMeta: {
          ...state.validationMeta,
          answer: updatedAnswer,
        },
      };
    }
    case actionTypes.TEST_V_REMOVE_SESSION_SUCCESS: {
      const updatedSession = state.validationSessions
        .filter((session) => session.id !== ((payload as unknown) as string));
      return {
        ...state,
        validationSessions: updatedSession,
      };
    }
    case actionTypes.TEST_V_SHOW_REPORT: {
      const { sessionId } = payload as { sessionId: string | null};
      return {
        ...state,
        validationMeta: {
          ...state.validationMeta,
          showReport: true,
        },
        validationSessionShowID: sessionId,
      };
    }
    case actionTypes.TEST_V_HIDE_REPORT: {
      return {
        ...state,
        validationMeta: {
          ...state.validationMeta,
          showReport: false,
          reportHtml: null,
        },
        validationSessionShowID: null,
      };
    }
    case actionTypes.TEST_V_SHOW_FINAL_ACTION: {
      return {
        ...state,
        validationMeta: {
          ...state.validationMeta,
          checkFinalAction: true,
        },
      };
    }
    case actionTypes.TEST_V_HIDE_FINAL_ACTION: {
      return {
        ...state,
        validationMeta: {
          ...state.validationMeta,
          checkFinalAction: false,
        },
      };
    }
    case actionTypes.TEST_V_GET_CUSTOM_PDF_REPORT: {
      const { sessionId } = payload as { sessionId: string | null};
      return {
        ...state,
        validationMeta: {
          ...state.validationMeta,
          getCustomPdf: true,
        },
        validationSessionShowID: sessionId,
      };
    }
    case actionTypes.TEST_V_GET_CUSTOM_PDF_REPORT_CONFIRM: {
      return {
        ...state,
        validationMeta: {
          ...state.validationMeta,
          checkFinalAction: false,
          showReport: false,
          getCustomPdf: true,
        },
      };
    }
    case actionTypes.TEST_V_CLOSE_CUSTOM_PDF_REPORT: {
      return {
        ...state,
        validationMeta: {
          ...state.validationMeta,
          getCustomPdf: false,
        },
        validationSessionShowID: null,
      };
    }
    case actionTypes.TEST_V_GET_HTML_REPORT_SUCCESS: {
      const { html } = payload as GetHtmlReportRes;
      return {
        ...state,
        validationMeta: {
          ...state.validationMeta,
          reportHtml: html,
        },
      };
    }
    default:
      return state;
  }
};
