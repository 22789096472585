import { useEffect, useState } from 'react';

type Params = {
  callback?: () => void,
};

type Size = {
  width: number,
  height: number,
};

type Layout = 'DESKTOP_BIG' | 'DESKTOP_SM' | 'TABLET' | 'PHONE' | 'FULL';

type ReturnValue = Size & {
  layout: Layout,
};

const RESOLUTIONS: { [key in Layout]: number } = {
  FULL: Infinity,
  DESKTOP_BIG: 1280,
  DESKTOP_SM: 980,
  TABLET: 720,
  PHONE: 480,
};

function getLayout(width: number): Layout {
  let layout: Layout = 'DESKTOP_BIG';

  switch(true) {
    case width <= RESOLUTIONS.PHONE:
      layout = 'PHONE';
      break;

    case width <= RESOLUTIONS.TABLET:
      layout = 'TABLET';
      break;

    case width <= RESOLUTIONS.DESKTOP_SM:
      layout = 'DESKTOP_SM';
      break;

    case width <= RESOLUTIONS.DESKTOP_BIG:
      layout = 'DESKTOP_BIG';
      break;

    default:
      return 'FULL';
  }

  return layout;
}

export default function ({ callback = () => {} }: Params): ReturnValue {
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });
  const [layout, setLayout] = useState<Layout>(getLayout(window.innerWidth));

  useEffect(() => {
    function handleResize() {
      const { innerWidth: width, innerHeight: height } = window;
      setSize({
        width,
        height,
      });

      setLayout(getLayout(window.innerWidth));
      callback();
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    width: size.width,
    height: size.height,
    layout,
  };
}
