import React, { FC } from 'react';
import styles from './styles.module.scss';

type Props = {
  saveHandler: () => void,
  dataObj?: TInitialData,
};

type TInitialData = {
  title: string,
  description: string,
  currency: number | '',
  price: number | '',
  category: number | '',
  subcategory: number | '',
  geography: number | '',
  photos?: string,
};

const CURRENCY:{[index:number]: string} = {
  0: 'Р',
  1: '$',
};

const Step4: FC<Props> = ({
  saveHandler = () => {},
  dataObj,
}) => {
  return (
    <div
      className={styles.wrapper}
    >
      <h2 className={styles.itemTitle}>{dataObj?.title}</h2>
      <div className={styles.text}>
        <div className={styles.subtitle}>Описание предложения:</div>
        {dataObj?.description}
      </div>
      <div className={styles.text}>
        <div className={styles.subtitle}>Стоимость:</div>
        {/* eslint-disable-next-line */}
        {dataObj?.price} {CURRENCY[dataObj?.currency || 0]}
      </div>
      <div className={styles.text}>
        <div className={styles.subtitle}>Категория и подкатегория:</div>
        {dataObj?.category} / {dataObj?.subcategory}
      </div>
      <div className={styles.text}>
        <div className={styles.subtitle}>Геогравия поставки товара:</div>
        {dataObj?.geography}
      </div>
      <div className={styles.controls}>
        <div
          onClick={saveHandler}
          className={styles.next}
        >
          Опубликовать
        </div>
        <div
          onClick={() => {}}
          className={styles.draft}
        >
          Сохранить в черновик
        </div>
      </div>
    </div>
  );
};

export default Step4;
