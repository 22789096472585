/* eslint-disable react/jsx-props-no-spreading */
import React, {
  FC,
  PropsWithChildren,
  useEffect,
} from 'react';
import {
  Section,
  LangSwitcher,
  PersonalTopControl,
} from 'components';
import { routes } from 'appConstants';
import { _t } from 'utils';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  pageTitle: string,
  className?: string,
  nonLang?: boolean,
  nonTopM?: boolean,
};

const PersonalBaseLayout: FC<PropsWithChildren<Props>> = ({
  pageTitle,
  className,
  nonLang = false,
  nonTopM = false,
  children,
}) => {
  useEffect(() => {
    if (window.location.pathname !== routes.personal.catalog.root) {
      window.document.getElementById('pp-widget-root')?.remove();
    }
  });

  return (
    <Section
      className={cx(className, styles.personalBase)}
    >
      <div className={styles.pageHeader}>
        <h1 className={styles.pageTitle}>{_t(pageTitle)}</h1>
        <div className={styles.rightContent}>
          {!nonLang && <LangSwitcher theme="light" />}
          {!nonTopM && <PersonalTopControl />}
        </div>
      </div>
      {children}
    </Section>
  );
};

export default PersonalBaseLayout;
