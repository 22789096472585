import React, { FC, useState } from 'react';
import { LeadStatus } from 'types';
import { _t } from 'utils';
import styles from './styles.module.scss';
import FilterParamSelect from './FilterParamSelect';
import FilterParamPrice from './FilterParamPrice';

type Props = {
  categoryParam: number,
  statusParam: number,
  CatData?: LeadStatus[] | null,
  StatData?: LeadStatus[] | null,
  setCategoryFilter: (id?:number) => void,
  setStatusFilter: (id?:number) => void,
  clearFilters: () => void,
};

const PersonalGoodsReqsFilter: FC<Props> = ({
  // categoryParam = 0,
  // statusParam = 0,
  // CatData,
  // StatData,
  // setCategoryFilter,
  // setStatusFilter,
  clearFilters,
}) => {
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  return (
    <div className={styles.filterWrap}>
      <div
        className={styles.filter}
        onClick={() => setOpenFilter(!openFilter)}
      >
        {_t('Фильтр')}
      </div>
      {openFilter && (
        <div className={styles.filterBlock}>
          <div
            className={styles.close}
            onClick={() => setOpenFilter(false)}
          >
            +
          </div>
          <h3>{_t('Фильтр')}</h3>
          <FilterParamSelect
            title="Выберите отрасль компании"
            placeholder="Выберите отрасль"
            options={[
              { id: 0, title: '0' },
              { id: 1, title: '1' },
              { id: 2, title: '2' },
              { id: 3, title: '3' },
            ]}
          />
          <FilterParamSelect
            title="География поставок"
            placeholder="Выберите регион"
            options={[
              { id: 0, title: '0' },
              { id: 1, title: '1' },
              { id: 2, title: '2' },
              { id: 3, title: '3' },
            ]}
          />
          <FilterParamSelect
            title="Валюта"
            placeholder="Выберите валюту"
            options={[
              { id: 0, title: '0' },
              { id: 1, title: '1' },
              { id: 2, title: '2' },
              { id: 3, title: '3' },
            ]}
          />
          <FilterParamPrice
            title="Цена"
          />
          <div className={styles.filterControl}>
            <div className={styles.applyFilter}>
              Применить фильтр
            </div>
            <div
              className={styles.clearFilterBtn}
              onClick={clearFilters}
            >
              {_t('Очистить фильтр')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalGoodsReqsFilter;
