/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import cx from 'classnames';
import {
  Link,
  Label,
  ContentImage,
  Text,
} from 'components';
import { dateToUiFormat, _t, _t_back } from 'utils';
import type { News } from 'types';
import {
  mediaCategoryRoutes,
  routes,
} from 'appConstants';
import styles from './styles.module.scss';

type Props = {
  value: News,
  className?: string,
  action?: 'detail' | 'external' | 'opener',
  to?: string,
  onClickHandler?: (obj:News) => void,
};

const MediaCard: FC<Props> = ({
  value,
  className,
  action = 'detail',
  to,
  onClickHandler = () => {},
}) => {
  const data = (
    <div>
      <ContentImage
        className={styles.photo}
        src={value.image}
      />
      <div className={styles.header}>
        <Label className={styles.label}>
          {_t(value.category?.title || mediaCategoryRoutes[value.category?.id || 0]?.label)}
        </Label>
        <Text tag="span" bold size="tiny">{dateToUiFormat(value.pubdate)}</Text>
      </div>
      <Text size="small" bold className={styles.description}>{_t_back(value, 'title')}</Text>
    </div>
  );

  if (action === 'external') {
    return (
      <a
        className={cx(className)}
        href={value.url}
        target="blank"
      >
        {data}
      </a>
    );
  }
  if (action === 'opener') {
    return (
      <div
        className={cx(className, styles.pointer)}
        onClick={() => onClickHandler(value)}
      >
        {data}
      </div>
    );
  }
  return (
    <Link
      className={cx(className)}
      to={to ? `${to}` : `${routes.media.root}/${value.slug}`}
    >
      {data}
    </Link>
  );
};

export default MediaCard;
