import React, {
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import {
  Textarea,
  CustomApplicationForm,
} from 'components';
import { _t } from 'utils';
import { ERRORS, URL } from 'appConstants';
import { toast } from 'react-toastify';
import { RequestStatus, State } from 'types';
import styles from './styles.module.scss';

type Values = {
  description: string,
};

const initialValues: Values = {
  description: '',
};

const validationSchema = object().shape({
  description: string().required(ERRORS.required),
});

const CustomData = new FormData();

const PersonalBarrierRequest = () => {
  const [status, setStatus] = useState<'INIT' | 'SUCCESS'>('INIT');
  const token = useSelector(({ me }: State) => me.token);

  const {
    values,
    isValid,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik<Values>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onSubmit: () => upload(),
  });

  const upload = () => {
    CustomData.append('description', values.description);

    fetch(URL.PERSONAL.RESOLUTIONS.BARRIER, {
      method: 'POST',
      mode: 'cors',
      body: CustomData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return false;
      })
      .then(() => {
        toast.success(_t('Данные обновлены'));
        setStatus('SUCCESS');
        resetForm();
      })
      .catch((err) => toast.error(_t(err.response.data.detail || 'Ошибка загрузки, повторите позже')));
  };

  const addFile = (value: File) => CustomData.append('file', value);

  return (
    <CustomApplicationForm
      className={styles.addBarier}
      onSubmit={handleSubmit}
      openerName="Сообщить о барьере"
      submitName="Отправить заявку"
      titleName="Если у Вас возникли трудности с выходом на новый рынок, наши специалисты помогут найти решение."
      disabledSubmit={isValid && !isValid}
      autoClose={status === RequestStatus.SUCCESS}
      policy
      attachFile
      attachOnChange={addFile}
    >
      <Textarea
        name="description"
        placeholder="Опишите вашу проблему"
        className={styles.input}
        error={errors.description && touched.description ? errors.description : ''}
        value={values.description}
        onChange={handleChange}
      />
    </CustomApplicationForm>
  );
};

export default PersonalBarrierRequest;
