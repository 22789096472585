import React, {
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import {
  // Textarea,
  CustomApplicationForm,
} from 'components';
import { _t } from 'utils';
import { useRequest } from 'hooks';
import { ERRORS, URL } from 'appConstants';
import { toast } from 'react-toastify';
import { RequestStatus, State } from 'types';
import cx from 'classnames';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import styles from './styles.module.scss';

type Values = {
  description: string,
  // file: string,
};

type TInitialData = {
  // [index:string]: string,
  title: string,
  description: string,
  currency: number | '',
  price: number | '',
  category: number | '',
  subcategory: number | '',
  geography: number | '',
  photos?: string,
};

const InitialData:TInitialData = {
  title: '',
  description: '',
  currency: '',
  price: '',
  category: '',
  subcategory: '',
  geography: '',
  photos: '',
};

type Req = {
  description: string,
  // file: string,
};

const initialValues: Values = {
  description: '',
  // file: '',
};

const validationSchema = object().shape({
  description: string().required(ERRORS.required),
});

const stepVariantTitle:{[index:number]:string} = {
  0: 'Что вы хотите разместить?',
  1: 'Выберите категорию',
  2: 'Новое предложение',
  3: 'Новое предложение (предпросмотр)',
};

const PersonalGoodsRequest = () => {
  const [goodsStep, setGoodsStep] = useState<0 | 1 | 2 | 3>(0);
  const [categoryId, setCategoryId] = useState<number>();
  const [dataObj, setDataObj] = useState<TInitialData>(InitialData);
  const [stepVariant, setStepVariant] = useState<'req' | 'offer' | ''>('');

  const token = useSelector(({ me }: State) => me.token);

  const {
    // values,
    isValid,
    // errors,
    // touched,
    // handleChange,
    handleSubmit,
    resetForm,
  } = useFormik<Values>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: ({
      description,
      // file,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    }) => sendData({
      url: URL.PERSONAL.RESOLUTIONS.BARRIER,
      method: 'post',
      data: {
        description,
        // file,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  });

  const { request: sendData, status } = useRequest<Req>({
    callback: () => {
      toast.success(_t('Данные отправлены!'));
      resetForm();
    },
  });

  const saveHandler = () => {

  };

  useEffect(() => {
    if (status === RequestStatus.ERROR) {
      toast.error(_t('Ошибка отправки данных'));
    }
  }, [status, categoryId]);

  return (
    <CustomApplicationForm
      className={styles.addBarier}
      customModal={styles.customModal}
      customTitle={styles.customTitle}
      customModalOverlay={styles.customModalOverlay}
      modalContent={styles.modalContent}
      onSubmit={handleSubmit}
      onClose={() => setGoodsStep(0)}
      openerName="Создать объявление"
      submitName=""
      titleName={stepVariantTitle[goodsStep]}
      disabledSubmit={isValid && !isValid}
      isLoading={status === RequestStatus.REQUEST}
      autoClose={status === RequestStatus.SUCCESS}
    >
      {(goodsStep === 0 || !stepVariant) && (
        <div className={styles.step1Btns}>
          <div
            className={cx(styles.step1Btn, styles.startOffer)}
            onClick={() => {
              setStepVariant('offer');
              setGoodsStep(1);
            }}
          >
            Разместить запрос
          </div>
          <div
            className={cx(styles.step1Btn, styles.startReq)}
            onClick={() => {
              setStepVariant('req');
              setGoodsStep(1);
            }}
          >
            Разместить объявление
          </div>
        </div>
      )}

      {(goodsStep === 1 && stepVariant) && (
        <Step2
          handleSelectRegion={(id:number) => {
            setCategoryId(id);
            setGoodsStep(2);
          }}
        />
      )}

      {(goodsStep === 2 && stepVariant) && (
        <Step3
          nextStep={(data:TInitialData) => {
            setDataObj(data);
            setGoodsStep(3);
          }}
        />
      )}

      {(goodsStep === 3 && stepVariant) && (
        <Step4
          dataObj={dataObj}
          saveHandler={() => saveHandler()}
        />
      )}
    </CustomApplicationForm>
  );
};

export default PersonalGoodsRequest;
