import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { routes } from 'appConstants';
import { PersonalPageMenu } from 'components';
import {
  PersonalBaseLayout,
  PersonalCalendarGrid,
} from 'containers';

const PersonalCalendar = () => {
  return (
    <PersonalBaseLayout pageTitle={routes.personal.calendar.title}>
      <PersonalPageMenu
        links={[]}
      />
      <Switch>
        <Route
          path={routes.personal.calendar.root}
          exact
          render={() => (<PersonalCalendarGrid />)}
        />
        <Redirect to={{ pathname: routes.personal.calendar.root }} />
      </Switch>
    </PersonalBaseLayout>
  );
};

export default PersonalCalendar;
