import React, { FC } from 'react';
import cx from 'classnames';
import { _t } from 'utils';
import { Text } from 'components';
import styles from './styles.module.scss';

type Props = {
  label: string,
  value: string,
  className?: string,
};

const LabelValue: FC<Props> = ({ label, value, className }) => (
  <div className={cx(className)}>
    <Text className={styles.label} color="extra" size="tiny" bold>{_t(label)}</Text>
    <Text className={styles.value} size="small" bold>{_t(value)}</Text>
  </div>
);

export default LabelValue;
