import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  PersonalNotificationsMainTab,
  PersonalNotificationsDetail,
  PersonalBaseLayout,
} from 'containers';
import { PersonalPageMenu } from 'components';
import { routes } from 'appConstants';

const PersonalNotifications = () => {
  return (
    <PersonalBaseLayout pageTitle={routes.personal.notifications.title}>
      <PersonalPageMenu
        links={[]}
      />
      <Switch>
        <Route
          path={routes.personal.notifications.root}
          exact
          render={() => (<PersonalNotificationsMainTab />)}
        />
        <Route
          path={`${routes.personal.notifications.root}/:id`}
          exact
          render={() => (<PersonalNotificationsDetail />)}
        />
        <Redirect to={{ pathname: routes.personal.notifications.root }} />
      </Switch>
    </PersonalBaseLayout>
  );
};

export default PersonalNotifications;
