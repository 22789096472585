import React, {
  useEffect, FC, useState, useMemo,
} from 'react';
import { useFormik } from 'formik';
import qs from 'qs';
import {
  FiltersCheckboxes,
  FilterSidebar,
  FiltersRadioButtons,
} from 'containers';
import {
  Datepicker,
  FilterGroup,
  NoData,
  Paginate,
  Checkbox,
  Preloader,
} from 'components';
import { useRequest } from 'hooks';
import { URL, routes } from 'appConstants';
import {
  dateToBackendFormat,
  validateEmptyParams,
  _t,
  _has_t_back,
} from 'utils';
import type { BaseFilter as Filter, Event, GetResponse } from 'types';
import { RequestStatus } from 'types';
import EventsListCard from './EventsListCard';
import styles from './styles.module.scss';

type Params = {
  support_measures: Array<number>,
  new_country: Array<number>,
  category_of_event: Array<number>,
  form_of_holding: number,
  page: number,
  archive: boolean,
  search: string,
  start_filter_date: string,
  end_filter_date: string,
};

const PAGE_SIZE = 9;

const initialValues: Params = {
  support_measures: [],
  new_country: [],
  category_of_event: [],
  form_of_holding: 0,
  page: 0,
  archive: false,
  search: '',
  start_filter_date: '',
  end_filter_date: '',
};

type Props = {
  targetDetailsPath?: string,
};

const PART = 15;

const Events: FC<Props> = ({
  targetDetailsPath = routes.events.root,
}) => {
  const {
    data: events,
    request: getEvents,
    status,
  } = useRequest<GetResponse<Event[]>>();

  const {
    values,
    handleChange,
    handleSubmit,
    dirty,
    resetForm,
  } = useFormik<Params>({
    initialValues,
    enableReinitialize: true,
    onSubmit: (params) => {
      const {
        page,
        ...paramsToSend
      } = params;
      getEvents({
        method: 'get',
        params: {
          ...validateEmptyParams({
            params: paramsToSend,
            emptyParamsToDelete: [
              'search',
              'support_measures',
              'new_country',
              'show_archive',
              'category_of_event',
              'form_of_holding',
              'start_filter_date',
              'end_filter_date',
            ],
          }),
          limit: PAGE_SIZE,
          offset: page * PAGE_SIZE,
          start_filter_date: values.start_filter_date,
          end_filter_date: values.end_filter_date || values.start_filter_date,
        },
        paramsSerializer: (value) => qs.stringify(value, { indices: false }),
        url: URL.EVENTS.GET,
      });
    },
  });

  const {
    data: supportMeasures,
    request: getSupportMeasures,
  } = useRequest<Filter[]>();

  const {
    data: countries,
    request: getCountries,
  } = useRequest<GetResponse<[]>>();

  const [allCountries, setAllCountries] = useState([]);
  useEffect(() => {
    const newCountries = countries?.results || [];
    setAllCountries((oldCountries) => [...oldCountries, ...newCountries]);
  }, [countries]);

  const {
    data: categories,
    request: getCategories,
  } = useRequest<Filter[]>();

  const [countriesCount, setCountriesCount] = useState(PART);
  const handleScroll = useMemo(() => {
    // @ts-ignore
    let timer;

    return () => {
      // @ts-ignore
      clearTimeout(timer);
      timer = setTimeout(() => {
        setCountriesCount((count) => count + PART);
      }, 1000);
    };
  },
  [setCountriesCount]);

  useEffect(() => {
    getCountries({
      url: URL.EVENTS.FILTERS.NEW_COUNTRIES,
      params: {
        limit: PART,
        offset: countriesCount - PART,
      },
    });
  }, [countriesCount]);

  const {
    data: forms,
    request: getForms,
  } = useRequest<Filter[]>();

  useEffect(() => {
    getSupportMeasures({ url: URL.EVENTS.FILTERS.SUPPORT_MEASURES });
    getCategories({ url: URL.EVENTS.FILTERS.CATEGORIES });
    getForms({ url: URL.EVENTS.FILTERS.FORMS });

    getEvents({
      method: 'get',
      url: URL.EVENTS.GET,
      params: {
        archive: initialValues.archive,
        limit: PAGE_SIZE,
      },
    });
  }, []);

  const resetDateFilter = () => {
    handleChange({
      target: {
        name: 'start_filter_date',
        value: null,
      },
    });
    handleChange({
      target: {
        name: 'end_filter_date',
        value: null,
      },
    });
  };

  const filtered = events?.results.filter(_has_t_back) || [];

  return (
    <div className={styles.layout}>
      <FilterSidebar
        search={values.search}
        isCanReset={dirty}
        className={styles.sidebar}
        onReset={resetForm}
        onSubmit={() => {
          // reset pagination
          handleChange({
            target: {
              name: 'page',
              value: 0,
            },
          });
          handleSubmit();
        }}
        onChangeSearch={handleChange}
        onSubmitSearch={handleSubmit}
      >
        <FiltersCheckboxes
          values={values}
          name="category_of_event"
          label="Формат мероприятия"
          list={categories || []}
          handleChange={handleChange}
        />

        <FiltersCheckboxes
          values={values}
          fieldByName
          name="new_country"
          label="Целевой рынок"
          list={allCountries}
          handleChange={handleChange}
          classNameContent={styles.countries}
          onScroll={handleScroll}
        />

        <FiltersRadioButtons
          values={values}
          name="form_of_holding"
          label="Форма проведения"
          list={forms || []}
          handleChange={handleChange}
        />

        <FiltersCheckboxes
          values={values}
          name="support_measures"
          label="Область продукции для экспорта"
          list={supportMeasures || []}
          handleChange={handleChange}
        />

        <FilterGroup
          label="Выбрать дату"
          selectedCount={values.start_filter_date ? 1 : 0}
          onReset={resetDateFilter}
        >
          <Datepicker
            start={values.start_filter_date}
            end={values.end_filter_date}
            onChange={(dates) => {
              handleChange({
                target: {
                  name: 'start_filter_date',
                  value: dateToBackendFormat(dates[0]),
                },
              });
              handleChange({
                target: {
                  name: 'end_filter_date',
                  value: dateToBackendFormat(dates[1]),
                },
              });
            }}
          />
        </FilterGroup>
        <Checkbox
          className={styles.archive_checkbox}
          name="with_analytics"
          label={_t('Архивные мероприятия')}
          checked={values.archive}
          onChange={() => {
            handleChange({
              target: {
                name: 'archive',
                value: !values.archive,
              },
            });
          }}
        />
      </FilterSidebar>

      <div className={styles.content}>
        <Preloader isLoading={status === RequestStatus.REQUEST}>
          <NoData count={filtered?.length || 0}>
            {filtered?.map((event) => (
              <EventsListCard
                targetDetailsPath={targetDetailsPath}
                key={event.id}
                className={styles.card}
                value={event}
              />
            ))}
            <Paginate
              page={values.page}
              count={events?.count || 0}
              pageSize={PAGE_SIZE}
              className={styles.paginate}
              onChange={(value) => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                handleChange({
                  target: {
                    name: 'page',
                    value,
                  },
                });
                handleSubmit();
              }}
            />
          </NoData>
          {/* <NoData count={events?.count || 0}>
            {events?.results.map((event) => ((
            // {events?.results.map((event) => (_has_t_back(event) && (
              <EventsListCard
                targetPath={targetDetailsPath}
                key={event.id}
                className={styles.card}
                value={event}
              />
            )))}
          </NoData> */}
        </Preloader>
      </div>
    </div>
  );
};

export default Events;
