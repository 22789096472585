import { put, takeLatest, call } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { toast } from 'react-toastify';
import api from 'store/api';
import { URL, routes } from 'appConstants';
import { _t, history } from 'utils';
import type { Response, RestoreReq } from 'types';
import actionTypes, { RestoreAction as Action } from '../actionTypes';

function* newPass({ type, payload }: Action) {
  try {
    if (payload) {
      yield put(apiActions.request(type));
      const { data }: Response<RestoreReq> = yield call(api, {
        method: 'post',
        url: URL.AUTH.RESTORE_CONF,
        data: payload,
      });
      yield put(apiActions.success(type, data));

      toast.success(_t('Пароль успешно изменен'));

      setTimeout(() => {
        history.push(routes.auth.login.root);
      }, 2000);
    }
  } catch (err) {
    if (err.response.data.token) {
      toast.error(_t(err.response.data.token[0]));
      // setTimeout(() => {
      //   history.push(routes.main.root);
      // }, 2000);
    }
    toast.error(_t(err.response.data.new_password[0]));
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.ME_NEWPASS, newPass);
}
