import React, {
  useEffect,
} from 'react';
import { setPageMeta, routes } from 'appConstants';
import { Error404 as Container } from 'components';

const Page404 = () => {
  useEffect(() => {
    setPageMeta(routes.page404.title, routes.page404.descr);
  });

  return <Container />;
};
export default Page404;
