import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { routes } from 'appConstants';
import { PersonalPageMenu } from 'components';
import {
  PersonalBaseLayout,
  PersonalExchangeMainTab,
  // PersonalExchangeBuyers,
  PersonalExchangeDetail,
} from 'containers';

const Links = [
  {
    title: 'Поиск экспортера',
    to: routes.personal.exchange.root,
  },
  {
    title: routes.personal.exchange.buyer.title,
    to: routes.personal.exchange.buyer.root,
  },
];

const PersonalExchange = () => {
  return (
    <PersonalBaseLayout pageTitle={routes.personal.exchange.title}>
      <PersonalPageMenu
        links={Links}
      >
        {/* <PersonalBarrierRequest /> */}
      </PersonalPageMenu>
      <Switch>
        <Route
          path={routes.personal.exchange.root}
          exact
          render={() => (<PersonalExchangeMainTab />)}
        />
        <Route
          path={routes.personal.exchange.buyer.root}
          exact
          render={() => (<PersonalExchangeMainTab />)}
          // render={() => (<PersonalExchangeBuyers />)}
        />
        <Route
          path={`${routes.personal.exchange.root}/:id`}
          exact
          render={() => (<PersonalExchangeDetail />)}
        />
        <Route
          path={`${routes.personal.exchange.buyer.root}/:id`}
          exact
          render={() => (<PersonalExchangeDetail />)}
        />
        <Redirect to={{ pathname: routes.personal.exchange.root }} />
      </Switch>
    </PersonalBaseLayout>
  );
};

export default PersonalExchange;
