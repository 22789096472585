import React, { FC } from 'react';
import {
  PersonalTabLayout,
  PersonalTabLayoutLeft,
  PersonalTabLayoutRight,
} from 'containers';
import pageImg from 'assets/img/PersAnalytics.svg';
import tabImg from 'assets/img/personal/analytics.svg';
import PersonalAnalyticsRequest from '../PersonalAnalyticsRequest';
import styles from './styles.module.scss';

type Props = {};

const PersonalAnalyticsMain: FC<Props> = () => {
  return (
    <PersonalTabLayout>
      <PersonalTabLayoutLeft
        tabTitle="Получите индивидуальные исследования прямо сейчас"
        image={tabImg}
      >
        <PersonalAnalyticsRequest />
      </PersonalTabLayoutLeft>
      <PersonalTabLayoutRight
        tabTitle="Общая информация"
      >
        <div className={styles.subTitle}>
          Индивидуальные аналитические исследования состоят из двух частей (этапов):
        </div>
        <img
          src={pageImg}
          className={styles.pageImg}
          alt="pageImg"
        />
        <div className={styles.subTitle}>
          Этап 1. Первичные индивидуальные аналитические исследования.
        </div>
        <div>
          Итогом Этапа 1 является: Определение потенциала целевого рынка
          (наполненность в зависимости от запроса компании).
        </div>
        <div className={styles.subTitle}>
          Этап 2. Расширенные индивидуальные аналитические исследования.
        </div>
        <div>
          Второй этап возможен при положительном результате Первого Этапа:
          проводится по дополнительному запросу компании и одобрении данного
          запроса Экспертной комиссией АНО «Моспром». Итогом Этапа 2 является:
          Стратегия выхода на целевой рынок (наполненность в зависимости
          от запроса компании).
        </div>
        <div className={styles.separator} />
        <div className={styles.subTitle}>
          Требования к организации для подачи заявки на индивидуальные
          аналитические исследования:
        </div>
        <ul className={styles.list}>
          <li>
            <div>
              Срок деятельности Претендента с момента его государственной
              регистрации в качестве юридического лица на дату подачи заявки
              — не менее 6 месяцев, за исключением случаев:
              <div className={styles.grayText}>
                — в уставном капитале юридического лица — Претендента преобладающая доля
                (более 50%) принадлежит юридическому лицу, зарекомендовавшему себя с
                положительной стороны и имеющему опыт работы на соответствующем рынке
                товаров, работ, услуг не менее 6 месяцев;
              </div>
              <div className={styles.grayText}>
                — юридическое лицо — Претендент образовано в результате реорганизации в виде
                разделения или выделения из юридического лица, зарекомендовавшего себя с
                положительной стороны и имеющего опыт работы на соответствующем рынке
                товаров, работ, услуг не менее 6 месяцев.
              </div>
            </div>
          </li>
          <li>
            При помещении товаров под таможенную процедуру экспорта, декларантом
            является юридическое лицо, зарегистрированное в городе Москва.
          </li>
          <li>
            Отсутствие проведения в отношении Претендента процедуры ликвидации или
            банкротства, реорганизации, приостановления деятельности в порядке,
            предусмотренном законодательством Российской Федерации, на дату подачи заявки.
          </li>
          <li>
            Отсутствие просроченной задолженности по уплате налогов, сборов и иных
            обязательных платежей в бюджеты бюджетной системы Российской Федерации
            на сумму более 100 000 (сто тысяч) рублей.
          </li>
          <li>
            Не является российским юридическим лицом, в уставном (складочном) капитале
            которого доля участия иностранного юридического лица, местом регистрации
            которого является государство или территория, включенные в утверждаемый
            Министерством финансов Российской Федерации перечень государств и территорий,
            предоставляющих льготный налоговый режим налогообложения и (или) не
            предусматривающих раскрытия и предоставления информации при проведении финансовых
            операций (офшорные зоны) в отношении такого юридического лица, в совокупности
            превышает 50 процентов
          </li>
        </ul>
      </PersonalTabLayoutRight>
    </PersonalTabLayout>
  );
};

export default PersonalAnalyticsMain;
