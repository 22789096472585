import React, { FC, ChangeEvent } from 'react';
import cx from 'classnames';
import { Text } from 'components';
import { _t } from 'utils';
import styles from './styles.module.scss';

type Props = {
  label?: string,
  name: string,
  value?: string,
  placeholder?: string,
  className?: string,
  error?: boolean | string,
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void,
};

const Textarea: FC<Props> = ({
  label = '',
  name,
  value,
  placeholder = 'Введите значение',
  className,
  error,
  onChange = () => {},
}) => (
  <div className={cx(styles.wrap, className)}>
    {label && (
      <label htmlFor={name} className={styles.label}>{_t(label)}</label>
    )}
    <div className={styles.textareaWrap}>
      <textarea
        id={name}
        name={name}
        value={value}
        placeholder={_t(placeholder)}
        className={cx(
          styles.textarea,
          {
            [styles.error]: error,
          },
        )}
        onChange={onChange}
      />

      { error && (
        <Text
          size="small"
          color="error"
          className={styles.errorImgText}
        >
          {error}
        </Text>
      )}
    </div>
  </div>
);

export default Textarea;
