import React, {
  FC,
  // useState,
  // useEffect,
  // useMemo,
} from 'react';
// import { useSelector } from 'react-redux';
// import { useRequest } from 'hooks';
// import {
//   // URL,
//   routes,
// } from 'appConstants';
// import {
//   PersonalCompanyAnalytic,
//   State,
// } from 'types';
import {
  PersonalTabLayout,
  PersonalTabLayoutLeft,
  PersonalTabLayoutRight,
} from 'containers';
import {
  ContentImage,
  // ValueLine
} from 'components';
import {
  _t,
  history,
} from 'utils';
import PersonalGoodsCopmRequest from '../PersonalGoodsCopmRequest';
import PersonalGoodsCompDetail from '../PersonalGoodsCompDetail';
import { testResults } from '../testData';
// import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {};

type Tstatus = '1' | '2';
type TtestData = {
  id: number,
  status: Tstatus,
  author: string,
  checked: boolean,
  title: string,
  text: string,
  date: string,
};
type Ttest = {
  [index: string]: string,
};

const testData = testResults[0];

const PersonalGoodsDetail: FC<Props> = () => {
  const renderBackButton = () => (
    <div
      className={styles.backButton}
      onClick={() => history.goBack()}
    >
      {_t('Назад')}
    </div>
  );

  return (
    <div className={styles.mainContent}>
      <PersonalTabLayout>
        {renderBackButton()}
      </PersonalTabLayout>
      <PersonalTabLayout
        className={styles.tabWrapper}
      >
        <PersonalTabLayoutLeft
          className={styles.leftTab}
        >
          <ContentImage
            className={styles.photo}
            src=""
            // src={data?.pictures[viewer.index < 0 ? 0 : viewer.index].image}
          />
        </PersonalTabLayoutLeft>
        <PersonalTabLayoutRight
          className={styles.rightTab}
        >
          <div className={styles.BL1}>
            <div className={styles.dataTitle}>{testData.title}</div>
            <div className={styles.descInfo}>{testData.descr}</div>
          </div>

          <div className={styles.BL2}>
            <div className={styles.goodInfo}>
              <div className={styles.colTitle}>Стоимость</div>
              <div>{testData.cost}</div>
            </div>
            <div className={styles.goodInfo}>
              <div className={styles.colTitle}>География товара</div>
              <div>Казахстан, Грузия</div>
            </div>
            <div className={styles.goodInfo}>
              <div>{testData.company}</div>
              <PersonalGoodsCompDetail />
            </div>
            <PersonalGoodsCopmRequest />
          </div>

          <div className={styles.dataTitle}>{_t('Описание Предложения:')}</div>
          <div className={styles.copmDescr}>
            С другой стороны постоянный количественный рост и сфера нашей активности
            способствует подготовки и реализации системы обучения кадров, соответствует насущным
            потребностям.Задача организации, в особенности же консультация с широким активом
            позволяет оценить значение модели развития. Равным образом постоянный количественный
            рост и сфера нашей активности позволяет выполнять важные задания по разработке
            соответствующий условий активизации.
          </div>
          {/* <Preloader isLoading={status === RequestStatus.REQUEST}>
            {(!noData && !!resolutions?.length) && (
            )}
          </Preloader> */}
        </PersonalTabLayoutRight>
      </PersonalTabLayout>

      {/* <div className={styles.separator} />
      <PersonalTabLayout>
        {renderBackButton()}
      </PersonalTabLayout> */}
    </div>
  );
};

export default PersonalGoodsDetail;
