import React, { FC, MouseEvent } from 'react';
import { Icon } from 'components';
import { _t } from 'utils';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  title: string,
  className?: string,
  onClick?: (e: MouseEvent) => void,
};

const DownloadButton: FC<Props> = ({
  title,
  className,
  onClick = () => {},
}) => (
  <button
    type="button"
    className={cx(styles.btn, className)}
    onClick={onClick}
  >
    <Icon icon="download" className={styles.icon} />
    {_t(title)}
  </button>
);

export default DownloadButton;
