import React, { FC } from 'react';
import { IAdeptItem } from 'types';
import people from 'assets/img/adept-res/1-people.svg';
import funds from 'assets/img/adept-res/2-funds.svg';
import infrastructure from 'assets/img/adept-res/3-infrastructure.svg';
import motivation from 'assets/img/adept-res/4-motivation.svg';
import environment from 'assets/img/adept-res/5-environment.svg';
import product from 'assets/img/adept-res/6-product.svg';
import implementation from 'assets/img/adept-res/7-implementation.svg';
// import diagram from 'assets/img/adept-res/_diagram.svg';
import cx from 'classnames';
import BlockTitle from '../BlockTitle';
import styles from './styles.module.scss';
import { LinearGraph } from '../Graphs';

const images:{[index:number]:string} = {
  0: people,
  1: funds,
  2: infrastructure,
  3: motivation,
  4: environment,
  5: product,
  6: implementation,
};

const Levels = [
  {
    class: 'high',
    title: 'Высокий уровень (сильная сторона)',
  }, {
    class: 'mid',
    title: 'Средний уровень (нейтральная зона)',
  }, {
    class: 'low',
    title: 'Недостаточный уровень (зона развития)',
  },
];

type Props = {
  legend: IAdeptItem[],
};

const ScoreMethods:FC<Props> = ({
  legend,
}) => {
  const Methods = () => (
    <div className={styles.scoreMethods}>
      <div id="adept4">
        <BlockTitle title="Методика оценки" />
        <h3>Семь измерений теста оценки экспортного потенциала ADEPT7</h3>
      </div>

      {legend.map((item, id) => (
        <div
          className={styles.method}
          key={id.toString()}
          id={`method_${id.toString()}`}
        >
          <div>
            <h4>{id + 1}. {item.title}</h4>
            <p>{item.descr}</p>
          </div>
          <img
            src={images?.[item.id]}
            alt={item.title}
            width="230px"
            height="230px"
          />
        </div>
      ))}
    </div>
  );

  const ScoreCut = () => (
    <div className={styles.scoreCut} id="adept5">
      <h3>Шкала оценок в разрезе измерений</h3>

      <div className={styles.diagram}>
        <LinearGraph
          data={legend}
        />

        <div className={styles.legend}>
          <div className={styles.items}>
            {legend.map((item, id) => (
              <p key={id.toString()}>{id + 1}. {item.title}</p>
            ))}
          </div>
          <div className={styles.levels}>
            {Levels.map((level, id) => (
              <p
                key={id.toString()}
                className={cx(
                  styles.colorDot,
                  styles[level.class],
                )}
              >
                {level.title}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div id="ForPDF_page7">
      <Methods />

      <ScoreCut />
    </div>
  );
};

export default ScoreMethods;
