import React, { FC } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import {
  Section,
  Text,
  H3, ButtonCircle,
} from 'components';
import { _t } from 'utils';
import libraryMainPic from 'assets/img/libraryMainPic.jpg';
import { routes } from 'appConstants';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const MainLibrary:FC<Props> = ({
  className,
}) => (
  <Section className={cx(className)}>
    <div className={styles.row}>
      <div className={cx(styles.column, styles.titleWrap)}>
        <Text use="title">{_t('Библиотека экспортера')}</Text>

        <H3 className={styles.title}>
          {_t('Полный перечень существующих мер поддержки...')}
        </H3>

        <Link to={routes.resolutions.root}>
          <ButtonCircle>{_t('Подробнее')}</ButtonCircle>
        </Link>
      </div>
      <div className={styles.column}>
        <img
          className={styles.img}
          src={libraryMainPic}
          alt={libraryMainPic}
        />
      </div>
    </div>
  </Section>
);

export default MainLibrary;
