/* eslint-disable no-console, @typescript-eslint/quotes, comma-dangle, space-in-parens */
import React, { FC, useEffect, useState } from 'react';
import RCTable from 'rc-table';
import clsx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
  format,
} from 'date-fns';

import { State, TAdeptResults } from 'types';
import {
  Input,
} from 'components';
import {
  testQShowReport,
  testQGetCustomPdfReport,
} from 'store/test/actions';

import { useRequest } from 'hooks';
import { URL } from 'appConstants';
import styles from './styles.module.scss';

type Row = {
  date: string;
  status: string;
  code: string;
  result: string;
  id: string;
};
type TableProps = {
  data: Row[];
  onRowClick?: () => void,
};

const Table: FC<TableProps> = ({ data, onRowClick }) => {
  // const [idToRemove, setIdToRemove] = useState<string | null>(null);
  const dispatch = useDispatch();

  const columns = [{
    title: 'Дата квалификации',
    key: 'date',
    dataIndex: 'date',
    width: '18%',
    className: styles.column,
  }, {
    title: 'Статус',
    key: 'status',
    dataIndex: 'status',
    width: '25%',
    className: styles.column,
    render: (text: string) => {
      let status = '';
      if (text === 'C') {
        status = 'Квалификация не пройдена';
      } else if (text === 'P') {
        status = 'Квалификация пройдена';
      }
      return (
        <div className={styles.statusWrap}>
          <div className={clsx(
            styles.statusCircle,
            {
              // [styles.statusCircleA]: text === 'A',
              [styles.statusCircleC]: text === 'C',
              [styles.statusCircleP]: text === 'P',
            },
          )}
          />
          {status}
        </div>
      );
    },
  }, {
    title: 'Оценка',
    key: 'result',
    dataIndex: 'result',
    width: '12%',
    className: styles.column,
  }, {
    title: 'Отчет',
    key: 'code',
    dataIndex: 'code',
    width: '45%',
    className: styles.column,
  }, {
    key: 'action',
    dataIndex: 'action',
    width: '2%',
    className: styles.column,
    render: (_: string, record: Row) => (
      <div
        className={clsx(styles.commonIcon, {
          [styles.deleteIcon]: record.status === 'A',
          [styles.downloadIcon]: record.status !== 'A',
        })}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (record.status !== 'A') {
            dispatch(testQGetCustomPdfReport(record.id));
          }
        }}
      />
    ),
  }];

  return (
    <>
      <RCTable
        className={styles.table}
        rowClassName={styles.row}
        columns={columns}
        scroll={{ y: 300 }}
        data={data}
        onRow={(record) => ({
          onClick: (e: React.MouseEvent<HTMLElement>) => {
            // @ts-ignore
            if (e.target.closest('a')) {
              return;
            }
            if (record.status !== 'A') {
              if (onRowClick) onRowClick();
              dispatch(testQShowReport(record.id));
            }
          },
        })}
      />
    </>
  );
};

const QualificationSessionsTable: FC = () => {
  const [inputValue, setInputValue] = useState('');
  const testState = useSelector(({ test }: State) => test);
  const token = useSelector(({ me }: State) => me.token);
  const [tableData, setTableData] = useState<Row[]>([]);

  const {
    data: qualList,
    request: getQualList,
  } = useRequest<TAdeptResults[]>();

  useEffect(() => {
    if (token) {
      getQualList({
        method: 'GET',
        url: URL.PERSONAL.ADEPT7.GET,
        headers: { Authorization: `Bearer ${token}` },
      });
    }
  }, [token]);

  useEffect(() => {
    if (qualList) {
      const data = qualList
        .filter((item) => item.quiz.type === 'Q' && item.status !== 'A')
        .reduce((acc, item) => {
          const newItem = {
            date: format(new Date(item.updated || item.completed), 'dd.MM.yyyy'),
            status: item.status,
            code: `${item.completed.split('T')[0]}_Квалификация_${testState.personalData.company.name}`,
            result: item.results.model_res.toFixed(2),
            id: item.id,
            key: item.id,
          };
          return [...acc, newItem];
        }, new Array<Row>())
        .filter((item) => {
          if (!inputValue) return true;
          return (
            item.date.includes(inputValue) ||
            item.status.includes(inputValue) ||
            item.code.includes(inputValue) ||
            item.result.includes(inputValue)
          );
        });
      setTableData(data);
    }
  }, [qualList, inputValue]);

  return (
    <div className={styles.tableBlockWrap}>
      <div className={styles.controlsWrap}>
        <Input
          name="validationSearch"
          className={styles.inputSearch}
          placeholder="Поиск"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </div>
      <div className={styles.tableWrap}>
        <Table
          data={tableData}
        />
      </div>
    </div>
  );
};

export { QualificationSessionsTable };
