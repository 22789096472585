/* eslint-disable no-irregular-whitespace */
import React, { FC, useEffect } from 'react';
import cx from 'classnames';
import {
  Section,
  Text,
  ButtonCircle,
} from 'components';
import { useRequest } from 'hooks';
import { URL, routes } from 'appConstants';
import {
  handleNoImage,
  history,
  _has_t_back,
  _t,
} from 'utils';
import type { Event, GetResponse } from 'types';
import EventsListCard from 'containers/Events/EventsList/EventsListCard';
import photo from 'assets/img/main-events-preview.jpg';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const MainEvents: FC<Props> = ({
  className,
}) => {
  const {
    data,
    request,
  } = useRequest<GetResponse<Event[]>>();

  useEffect(() => {
    request({
      method: 'GET',
      url: URL.EVENTS.GET,
      params: {
        limit: 7,
        archive: false,
      },
    });
  }, []);

  return (
    <Section className={cx(className)}>
      <div className={styles.preview}>
        <div className={styles.previewText}>
          <Text use="title" className={styles.title}>{_t('Мероприятия')}</Text>
          <Text size="small" bold className={styles.description}>
            {_t('Международные бизнес-миссии и выставки, обучающая программа...')}
          </Text>
        </div>
        <div className={styles.photo}>
          <img
            className={styles.eventsImg}
            src={photo}
            alt="Mosprom_Events"
            onError={handleNoImage}
            width="100px"
            height="100px"
          />
        </div>
      </div>

      <ul className={styles.list}>
        <li className={styles.card} />
        {data?.results.map((event) => (_has_t_back(event) && (
          <EventsListCard
            key={event.id}
            className={styles.card}
            value={event}
            tag="li"
          />
        )))}
        <li className={cx(styles.card, styles.btnCircle)}>
          <ButtonCircle
            onClick={() => history.push(routes.events.root)}
          >
            {_t('Все мероприятия')}
          </ButtonCircle>
        </li>
      </ul>
    </Section>
  );
};

export default MainEvents;
