import React, { FC } from 'react';
import cx from 'classnames';
import RDatepicker, { registerLocale } from 'react-datepicker';
import { ru, enUS } from 'date-fns/locale';
import { DATE_FORMATS, ClientLang } from 'appConstants';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';

registerLocale('en', enUS);
registerLocale('ru', ru);

type Props = {
  start?: string,
  end?: string,
  className?: string,
  onChange?: (value: Array<Date>) => void,
};

const Datepicker: FC<Props> = ({
  start,
  end,
  className,
  onChange = () => {},
}) => (
  <RDatepicker
    startDate={start ? new Date(start) : null}
    selectsRange
    className={cx(className)}
    endDate={end ? new Date(end) : null}
    dateFormat={DATE_FORMATS.BACKEND}
    inline
    locale={ClientLang}
    onChange={(values) => {
      onChange(Array.isArray(values) ? values : []);
    }}
  />
);

export default Datepicker;
