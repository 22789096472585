import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button, PersonalData, Input, Section, Select, Text,
} from 'components';
import {
  RequestStatus,
  RoleUser,
  RoleUserSelect,
  SignUpForm,
  SignUpReq,
  State,
  TDaData,
  TPersona,
} from 'types';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import {
  useRequest,
//   // useDebounceCallback,
} from 'hooks';
import { history, _t, personaRefactor } from 'utils';
import {
  ERRORS,
  routes,
  URL,
} from 'appConstants';
import { toast } from 'react-toastify';
import { meSignUpAction } from 'store/me/actions';
import styles from './styles.module.scss';

const validationSchema = object().shape({
  companyName: string().required(ERRORS.required),
  inn: string().min(10, ERRORS.min(10)).required(ERRORS.required),
  ogrn: string().min(13, ERRORS.min(13)).required(ERRORS.required),
  lastName: string().required(ERRORS.required),
  firstName: string().required(ERRORS.required),
  position: string().required(ERRORS.required),
  phone: string().min(18, ERRORS.phone).required(ERRORS.required),
  email: string().min(5, ERRORS.email).required(ERRORS.required),
  password: string().min(8, ERRORS.min(8)).required(ERRORS.required),
  rePassword: string().min(8, ERRORS.password).required(ERRORS.required),
});

const OPTIONS_TYPE: RoleUserSelect[] = [
  {
    label: _t('Экспортер'),
    value: RoleUser.export,
  },
  {
    label: _t('Байер'),
    value: RoleUser.buyer,
  },
];

type RegistrationProps = {
  setEmail: (email: string) => void;
};

const initialValues: SignUpForm = {
  companyName: '',
  inn: '',
  ogrn: '',
  firstName: '',
  lastName: '',
  sirName: '',
  position: '',
  phone: '',
  role: OPTIONS_TYPE[0],
  email: '',
  password: '',
  rePassword: '',
};

const SignUp: FC<RegistrationProps> = ({ setEmail }: RegistrationProps) => {
  const signUpReqStatus = useSelector(({ ui }: State) => ui.ME_SIGN_UP);
  const isAuth = useSelector(({ me }: State) => me.isAuth);
  const dispatch = useDispatch();

  // const [uniqueINN, setUniqeINN] = useState<boolean>(true);
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const [canSend, setCanSend] = useState<boolean>(false);
  const handleChangeCheckbox = useCallback(() => {
    setCheckbox((state) => !state);
  }, []);

  const {
    values,
    errors,
    touched,
    isValid,
    setErrors,
    handleChange,
    handleSubmit,
  } = useFormik<SignUpForm>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: (obj) => {
      if (canSend) {
        const formatObj: SignUpReq = {
          ...obj,
          role: obj.role.value,
        };
        setEmail(values.email);
        dispatch(meSignUpAction(formatObj));
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
  });

  useEffect(() => {
    if (values.password !== '' &&
      values.password === values.rePassword &&
      checkbox
    ) {
      setCanSend(true);
    } else {
      setCanSend(false);
    }
  }, [values, checkbox]);

  const { data: DadataData, request: DadataRequest } = useRequest<TDaData>();
  useEffect(() => {
    if (values.inn.length > 9) {
      DadataRequest({
        url: URL.DADATA.URL,
        method: 'POST',
        mode: 'cors',
        headers: {
          ContentType: 'application/json',
          Accept: 'application/json',
          Authorization: 'Token '.concat(URL.DADATA.TOKEN),
        },
        data: {
          count: 1,
          query: values.inn, // 7707083893
          branch_type: 'MAIN',
        },
      });
    }
  }, [values.inn]);

  useEffect(() => {
    if (DadataData?.suggestions[0]) {
      const { value, data } = DadataData?.suggestions[0];

      const Persona:TPersona = (() => {
        if (data.management) {
          const { name, post } = data.management;
          return personaRefactor(...name.split(' '), post);
        }
        if (data.fio) {
          const { surname, name, patronymic } = data.fio;
          const { full } = data.opf;
          return personaRefactor(surname, name, patronymic, full);
        }
        return personaRefactor();
      })();

      handleChange({ target: { name: 'companyName', value } });
      handleChange({ target: { name: 'ogrn', value: data.ogrn } });

      handleChange({ target: { name: 'lastName', value: Persona.lastName } });
      handleChange({ target: { name: 'firstName', value: Persona.firstName } });
      handleChange({ target: { name: 'sirName', value: Persona.sirName } });
      handleChange({ target: { name: 'position', value: Persona.position } });

      toast.info('Доступные данные взяты из публичного источника по ИНН');
    }
  }, [DadataData]);

  useEffect(() => {
    if (isAuth) history.push(routes.personal.root);
  }, []);

  useEffect(() => {
    if (signUpReqStatus === RequestStatus.ERROR) {
      setErrors({
        companyName: '',
        inn: '',
        ogrn: '',
        firstName: '',
        lastName: '',
        sirName: '',
        position: '',
        phone: '',
        role: OPTIONS_TYPE[0],
        email: '',
        password: '',
        rePassword: '',
      });
    }
  }, [signUpReqStatus]);

  const handleChangeSelect = useCallback((value) => {
    const e = {
      target: {
        name: 'role',
        value,
      },
    };

    handleChange(e);
  }, []);

  const handleChangeWithMask = useCallback(({ target: { name, value } }) => {
    const event = {
      target: {
        name,
        value: value.replace(/_+$/, ''),
      },
    };

    handleChange(event);
  }, []);

  return (
    <Section>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Text
          className={styles.title}
          tag="span"
          align="center"
          bold
          text=""
        >
          {_t('Регистрация')}
        </Text>
        <h4 className={styles.gropup_header}>
          {_t('Информация о компании')}
        </h4>
        <Input
          name="companyName"
          placeholder="Название компании"
          className={styles.input}
          value={values.companyName}
          error={errors?.hasOwnProperty('companyName') && touched.companyName ? errors.companyName || true : ''}
          onChange={handleChange}
        />
        <Input
          name="inn"
          placeholder="ИНН"
          className={styles.input}
          value={values.inn}
          // error={!uniqueINN || (errors?.hasOwnProperty('inn') && touched.inn)
          // ? errors.inn || 'ИНН зарегистрирован' : ''}
          error={errors?.hasOwnProperty('inn') && touched.inn ? errors.inn || true : ''}
          onChange={handleChangeWithMask}
          mask="999999999999"
        />
        <Input
          name="ogrn"
          placeholder="ОГРН"
          className={styles.input}
          value={values.ogrn}
          error={errors?.hasOwnProperty('ogrn') && touched.ogrn ? errors.ogrn || true : ''}
          onChange={handleChangeWithMask}
          mask="999999999999999"
        />
        <Select
          name="role"
          options={OPTIONS_TYPE}
          value={values.role}
          onChange={handleChangeSelect}
          placeholder="В качестве кого вы регистрируетесь?"
          errors={errors?.hasOwnProperty('role') && touched.role ? errors.role || true : ''}
          classNameWrap={styles.select}
          color="dark"
        />
        <h4 className={styles.gropup_header}>
          {_t('Личные данные')}
        </h4>
        <Input
          name="lastName"
          placeholder="Фамилия"
          className={styles.input}
          value={values.lastName}
          error={errors?.hasOwnProperty('lastName') && touched.lastName ? errors.lastName || true : ''}
          onChange={handleChange}
        />
        <Input
          name="firstName"
          placeholder="Имя"
          className={styles.input}
          value={values.firstName}
          error={errors?.hasOwnProperty('firstName') && touched.firstName ? errors.firstName || true : ''}
          onChange={handleChange}
        />
        <Input
          name="sirName"
          placeholder="Отчество"
          className={styles.input}
          value={values.sirName}
          onChange={handleChange}
        />
        <Input
          name="position"
          placeholder="Должность"
          className={styles.input}
          value={values.position}
          error={errors?.hasOwnProperty('position') && touched.position ? errors.position || true : ''}
          onChange={handleChange}
        />
        <Input
          name="email"
          placeholder="E-mail"
          className={styles.input}
          value={values.email}
          error={errors?.hasOwnProperty('email') && touched.email ? errors.email || true : ''}
          onChange={handleChange}
        />
        <Input
          name="phone"
          placeholder="Контактный телефон"
          className={styles.input}
          value={values.phone}
          error={errors?.hasOwnProperty('phone') && touched.phone ? errors.phone || true : ''}
          onChange={handleChangeWithMask}
          mask="+7 (999) 999-99-99"
        />
        <Input
          name="password"
          type="password"
          placeholder="Пароль"
          className={styles.input}
          value={values.password}
          error={errors?.hasOwnProperty('password') && touched.password ? errors.password || true : ''}
          onChange={handleChange}
        />
        <Input
          name="rePassword"
          type="password"
          placeholder="Подтверждение пароля"
          className={styles.input}
          value={values.rePassword}
          error={(values.password !== '' && values.password !== values.rePassword) ? _t('Подтвердите пароль') : ''}
          onChange={handleChange}
        />
        <PersonalData
          name="checkbox"
          checkbox={checkbox}
          onChange={handleChangeCheckbox}
          className={styles.checkbox}
        />
        <div className={styles.form_footer}>
          <Button
            type="submit"
            color="secondary"
            isLoading={signUpReqStatus === RequestStatus.REQUEST}
            className={styles.btn}
            disabled={!isValid && !canSend}
            upperCase
            title="Зарегистрироваться"
          />
          <Text tag="span" className={styles.littleText}>{_t('или')}</Text>
          <Link
            to={routes.auth.login.root}
            className={cx(styles.link, styles.linkBig)}
          >
            {_t('Войти')}
          </Link>
        </div>
      </form>
    </Section>
  );
};

export default SignUp;
