import React, { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  title: string,
  targetId: string,
  className?: string,
};

const ButtonToScroll:FC<Props> = ({
  title,
  targetId,
  className,
}) => {
  const clickHandler = (target: string) => {
    const targetY = document.getElementById(target)?.getBoundingClientRect().y;
    if (targetY) {
      (document.getElementById('modalSrollable')?.parentElement ||
        document.getElementsByTagName('main')[0]).scrollBy({
        top: Math.round(targetY) - 50,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div
      onClick={() => clickHandler(targetId)}
      className={cx(
        styles.button,
        className,
      )}
    >
      <span>
        {title}
      </span>
    </div>
  );
};

export default ButtonToScroll;
