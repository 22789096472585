import React, { FC, PropsWithChildren } from 'react';
import Modal from 'react-modal';
import { ButtonClose } from 'components';
import styles from './styles.module.scss';

type Props = {
  isOpen: boolean,
  onClose?: () => void,
  onCloseModalWithAccept?: () => void,
};

const PopupAccept: FC<PropsWithChildren<Props>> = ({
  isOpen,
  children,
  onClose = () => {},
  onCloseModalWithAccept = () => {},
}) => (
  <Modal
    isOpen={isOpen}
    ariaHideApp={false}
    className={styles.popup}
    closeTimeoutMS={300}
    parentSelector={() => document.querySelector('#root') as HTMLElement}
    onRequestClose={onClose}
    overlayClassName={styles.overlay}
    shouldCloseOnOverlayClick={false}

  >
    <ButtonClose className={styles.close} onClick={onClose} />
    <div className={styles.content}>
      {children}
    </div>
    <button type="button" className={styles.buttonAccept} onClick={onCloseModalWithAccept}>Принять</button>

  </Modal>
);

export default PopupAccept;
