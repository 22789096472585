/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { NavLink as ReactRouterDomLink, NavLinkProps } from 'react-router-dom';

const Link: FC<NavLinkProps> = ({
  to,
  children,
  className,
  title,
  onClick,
  ...rest
}) => (
  <ReactRouterDomLink
    {...rest}
    to={to}
    title={title}
    className={className}
    onClick={onClick}
  >
    {children}
  </ReactRouterDomLink>
);

export default Link;
