import { put, takeLatest } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { toast } from 'react-toastify';
import { routes } from 'appConstants';
import { _t, history } from 'utils';
import actionTypes, { LogoutAction as Action } from '../actionTypes';

function* logout({ type }: Action) {
  try {
    yield put(apiActions.request(type));

    yield put(apiActions.success(type));
    history.push(routes.main.root);
  } catch (err) {
    toast.error(_t(err.response.data.detail));
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.ME_LOGOUT, logout);
}
