import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from 'appConstants';
import { _t } from 'utils';
import {
  ResolutionsList,
  ResolutionsDetails,
  MainLayout,
} from 'containers';
import styles from './styles.module.scss';

const crumbs = [
  {
    label: _t('Библиотека экспортера'),
    to: routes.resolutions.root,
  },
];

const Resolutions = () => (
  <Switch>
    <Route
      path={routes.resolutions.root}
      exact
      render={() => (
        <MainLayout title="Библиотека экспортера">
          <ResolutionsList />
        </MainLayout>
      )}
    />
    <Route
      path={`${routes.resolutions.root}/:id`}
      exact
      render={() => (
        <ResolutionsDetails crumbs={crumbs} className={styles.details} />
      )}
    />
  </Switch>
);

export default Resolutions;
