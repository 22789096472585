import {
  _t,
  _t1,
} from '../utils';

export default {
  email: () => _t('Недопустимый формат электронной почты'),
  phone: () => _t('Введите номер телефона полностью'),
  min: (count: number) => _t1`Введите не менее ${count} символов`,
  max: (count: number) => _t1`Данное поле не может быть длиннее ${count} символов`,
  password: () => _t('Заполните поле пароля'),
  required: () => _t('Поле обязательное для заполнения'),
  file: () => _t('Прикрепите файл'),
};
