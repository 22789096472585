import React, { FC, useState } from 'react';
import clsx from 'classnames';
import { ButtonRounded } from 'components';

import { _t } from 'utils';
import { TVideoProps } from 'types';

import { PersonalTestVideo as VideoInstruction } from '../PersonalTestVideo';
import styles from './styles.module.scss';

type Props = {
  // isShort?: boolean;
  startTest: () => void;
  videoProps?: TVideoProps;
};

const CommonDescription: FC<Props> = ({
  startTest,
  videoProps,
}) => {
  const [isActiveBtn, setActiveBtn] = useState(videoProps?.isVideoSeen || false);

  const endedHandler = () => {
    setActiveBtn(true);
    videoProps?.setSeenVideo();
  };

  return (
    <div className={clsx(styles.data)}>
      <div className={styles.title}>{_t('заголовок adept7')}</div>
      <div className={styles.description}>{_t('краткое описание adept7')}</div>

      <div className={styles.buttonsWrap}>
        <VideoInstruction
          buttonName="об adept 7"
          videoUrl={videoProps?.videoUrl}
          onEndWatch={endedHandler}
        />
        <ButtonRounded
          onClick={startTest}
          color="secondary"
          title="Начать тестирование"
          disabled={!isActiveBtn}
        />
      </div>
    </div>
  );
};

export { CommonDescription };
