import React from 'react';
import { MediaLayout, MediaBaseList } from 'containers';
import { URL } from 'appConstants';

const MediaSpecialProjects = () => {
  return (
    <MediaLayout>
      <MediaBaseList
        requestUrl={URL.NEWS.SPECPROJECTS.GET}
        action="external"
      />
    </MediaLayout>
  );
};

export default MediaSpecialProjects;
