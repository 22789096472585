import type { AxiosResponse } from 'axios';

export type GetResponse<T> = {
  count: number,
  results: T,
};

export type BaseFilter = {
  created?: string,
  id: number,
  is_active?: boolean,
  slug?: string,
  title?: string,
  name?: string,
  short_description?: string,
  name_in_en?: string,
};

export enum RequestStatus {
  INIT = 'INIT',
  REQUEST = 'REQUEST',
  RESET = 'RESET',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type Response<T> = AxiosResponse<T>;
