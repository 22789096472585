import React, { FC } from 'react';
import adept7 from 'assets/img/adept-res/adept7-logo.svg';
import styles from './styles.module.scss';

type Props = {
  client: {
    kval: string,
    name: string,
    tin: string,
  },
};

const AdeptHeader:FC<Props> = ({
  client,
}) => {
  const { kval, name, tin } = client;

  return (
    <div
      className={styles.headerInfo}
    >
      <img
        src={adept7}
        alt=""
        width="337px"
        height="83px"
      />
      <div className={styles.client}>
        <p>{kval}</p>
        <p>{name}</p>
        <p>{tin}</p>
      </div>
    </div>
  );
};

export default AdeptHeader;
