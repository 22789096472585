import React from 'react';
import cx from 'classnames';
import {
  // AntiCorruptionDocs,
  Section,
  Logo,
  Text,
  NavLink,
  Link,
  SocialLinks,
  ExternalLink,
} from 'components';
import { _t, sendMetrics, phoneRefactor } from 'utils';
import {
  routes,
  CONTACTS_VALUES,
  ClientLang,
  DefaultLang,
} from 'appConstants';
import investMoscow from 'assets/img/investMoscowWhite.svg';
import npr_ru from 'assets/img/logo/npr/npr_ru.svg';
import { FOOTER_TEXT } from 'appConstants/texts';
import styles from './styles.module.scss';

const LINKS = [
  {
    to: routes.services.root,
    title: 'Услуги',
  },
  {
    to: routes.resolutions.root,
    title: 'Экспортерам',
  },
  {
    to: routes.events.root,
    title: 'Мероприятия',
  },
  {
    to: routes.analytics.root,
    title: 'Аналитика',
  },
  {
    to: routes.about.root,
    title: 'О нас',
  },
  {
    to: routes.media.root,
    title: 'Медиа',
  },
];

const EXTERNAL_LINKS = [
  {
    href: 'https://www.mos.ru/dipp/',
    preset: 'dipp',
    src: '',
  },
  {
    href: ClientLang === DefaultLang ? 'https://investmoscow.ru/' : 'https://en.investmoscow.ru/',
    preset: '',
    src: investMoscow,
  },
  {
    href: 'https://национальныепроекты.рф/',
    preset: '',
    src: npr_ru,
  },
];

const smiPhone = CONTACTS_VALUES.phones.main.smi.split(',');

const CONTACTS = [
  {
    label: 'Телефон',
    value: (
      <>
        <a
          href={`tel:${CONTACTS_VALUES.phones.main.value}`}
          onClick={() => sendMetrics(null, ['telefon', 'tel'])}
        >
          <Text color="secondary" tag="span">
            <span itemProp="telephone">{phoneRefactor(CONTACTS_VALUES.phones.main.value)}</span>
          </Text>
        </a>
        <Text color="secondary" tag="span">
          <br />
          <span>{`${_t('доб')}. (${smiPhone[1]})`}</span>
        </Text>
      </>
    ),
  },
  {
    label: 'Email',
    value: (
      <a
        href={`mailto:${CONTACTS_VALUES.emails.main.value}`}
        onClick={() => sendMetrics(null, ['email', 'click'])}
      >
        <Text color="secondary" tag="span">
          <span itemProp="email">{CONTACTS_VALUES.emails.main.value}</span>
        </Text>
      </a>
    ),
  },
  {
    label: 'Адрес',
    value: (
      <Text color="secondary" tag="span">
        <div itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
          <span itemProp="postalCode">{CONTACTS_VALUES.addrs.main.postal}</span>,
          <span itemProp="addressCountry"> {_t(CONTACTS_VALUES.addrs.main.contry)}</span>,
          <span itemProp="addressLocality"> {_t(CONTACTS_VALUES.addrs.main.city)}</span>
          <br />
          <span itemProp="streetAddress">{_t(CONTACTS_VALUES.addrs.main.street)}</span>
        </div>
      </Text>
    ),
  },
];

const Footer = () => (
  <footer className={styles.footer}>
    <Section tag="div" className={styles.content}>
      <div className={styles.info}>
        <Link
          to={routes.main.root}
          className={styles.logo}
        >
          <Logo
            view="light"
          />
        </Link>
        <div>
          <Text size="small" color="secondary">
            {_t('ано центр поддержки и развития промышленного экспорта...')}
          </Text>
          {/* <AntiCorruptionDocs /> */}
        </div>
      </div>

      <nav className={styles.nav}>
        {LINKS.map(({ title, to }) => ((
          <NavLink key={title} to={to} className={styles.link}>
            <Text
              tag="span"
              uppercase
              color="secondary"
            >
              {_t(title)}
            </Text>
          </NavLink>
        )))}
      </nav>

      <ul
        className={styles.contacts}
        itemScope
        itemType="http://schema.org/Organization"
      >
        <span className={styles.hidden} itemProp="name">Моспром</span>
        {CONTACTS.map(({ label, value }) => ((
          <li key={label} className={styles.contact}>
            <Text
              size="tiny"
              tag="span"
              uppercase
              bold
              className={styles.contactLabel}
              color="extra"
            >
              {_t(label)}:
            </Text>
            {value}
          </li>
        )))}
      </ul>

      <div className={styles.info}>
        {EXTERNAL_LINKS.map(({ href, preset, src }, id) => (
          <ExternalLink
            key={`ExternalLink_${id + 1}`}
            href={href}
            newTab
          >
            <Logo
              preset={preset}
              className={cx(styles.logo, styles.width)}
              view="light"
              src={src}
            />
          </ExternalLink>
        ))}
      </div>

      <div className={styles.copyright}>
        <Text tag="span" color="extra" className={styles.copyrightText}>© MOSPROM 2021 - {new Date().getFullYear()}</Text>
        <SocialLinks />
      </div>
      <Text color="extra" className={styles.adeptText} size="small">{FOOTER_TEXT}</Text>
    </Section>
  </footer>
);

export default Footer;
