import React, {
  FC,
  ChangeEvent,
} from 'react';
import {
  Input,
  // Select,
} from 'components';
import {
  RoleUserSelect,
  // RoleUser,
} from 'types';
import { _t } from 'utils';
import cx from 'classnames';
import styles from './styles.module.scss';

interface Props {
  label: string,
  name: string,
  value: string,
  options?: RoleUserSelect[],
  type?: 'select' | 'input',
  isEdit: boolean,
  siteLink?: boolean,
  locked?: boolean,
  error: string | boolean,
  updValue?: (e: ChangeEvent<HTMLInputElement>) => void,
  mask?: string;
}

const ValueLine: FC<Props> = ({
  label,
  name,
  // options,
  value,
  type = 'input',
  isEdit,
  siteLink = false,
  locked = false,
  error,
  updValue = () => {},
  mask,
}) => {
  return (
    <div className={cx(
      styles.lineText,
      { [styles.isEdit]: isEdit },
      { [styles.editable]: !locked },
    )}
    >
      <div className={styles.dataName}>{_t(label)}</div>
      <div className={styles.dataValue}>
        {isEdit && type === 'input' && !locked && (
          <Input
            className={styles.dataInput}
            name={name}
            label=""
            placeholder=""
            value={value}
            error={error}
            onChange={updValue}
            mask={mask}
          />
        )}
        {(!isEdit || locked) && (
          siteLink ? (
            <a
              target="blank"
              href={`http://${value}`}
            >
              {value}
            </a>
          ) : value
        )}
      </div>
    </div>
  );
};

export default ValueLine;
