import React, { FC, memo } from 'react';

import { _t } from 'utils';
import styles from './styles.module.scss';

type Props = {
  company: string;
  name: string;
  position: string;
  inn: string;
};

const Text: FC<Props> = memo(({
  company,
  name,
  position,
  inn,
}) => {
  return (
    <div>
      <div className={styles.string}>
        <span className={styles.title}>{_t('Наименование компании')}:</span>
        <span className={styles.text}>{company}</span>
      </div>
      <div className={styles.string}>
        <span className={styles.title}>{_t('ИНН компании')}:</span>
        <span className={styles.text}>{inn}</span>
      </div>
      <div className={styles.string}>
        <span className={styles.title}>{_t('ФИО')}:</span>
        <span className={styles.text}>{name}</span>
      </div>
      <div className={styles.string}>
        <span className={styles.title}>{_t('Должность')}:</span>
        <span className={styles.text}>{position}</span>
      </div>
    </div>
  );
});

export { Text };
