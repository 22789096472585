import React, { useState, useCallback } from 'react';
import cx from 'classnames';
import { MainLayout } from 'containers';
import { useLocation } from 'react-router-dom';
import {
  Popup,
  H2,
  ContentImage,
} from 'components';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useWindowResize } from 'hooks';
import { _t, sendMetrics } from 'utils';
import service1 from 'assets/img/service-1.png';
import service2 from 'assets/img/service-2.png';
import service3 from 'assets/img/service-3.png';
import {
  ServicesSupport,
  ServicesBuyer,
  ServicesAnalytics,
} from './ServicesInfo';
import styles from './styles.module.scss';

type Service = { id: number, title: string, photo: string, content: JSX.Element };

const SERVICES: Array<Service> = [
  {
    id: 1,
    title: _t('Индивидуальная поддержка'),
    photo: service1,
    content: <ServicesSupport />,
  },
  {
    id: 2,
    title: _t('Байерская программа'),
    photo: service2,
    content: <ServicesBuyer />,
  },
  {
    id: 3,
    title: _t('Аналитические исследования'),
    photo: service3,
    content: <ServicesAnalytics />,
  },
];

const Services = () => {
  const query = new URLSearchParams(useLocation().search).get('serviceId');
  const startId = query !== null ? Number(query) : query;
  const [current, setCurrent] = useState<Service>(SERVICES[startId || 0]);
  const [isOpen, setOpen] = useState<boolean>(startId !== null || false);
  const { layout } = useWindowResize({ });

  const findService = useCallback((id: number) => {
    const value = SERVICES.find((service) => service.id === id);
    setCurrent(value || SERVICES[startId || 0]);
  }, [current]);

  return (
    <MainLayout title="Услуги" className={styles.wrap}>
      <ul className={styles.list}>
        {SERVICES.map(({ title, id }) => (
          <li key={title} className={styles.service}>
            <button
              type="button"
              className={cx(styles.button, {
                [styles.current]: id === current.id,
              })}
              onMouseEnter={() => (layout === 'FULL' ? findService(id) : () => {})}
              onClick={() => {
                if (layout !== 'FULL') findService(id);
                sendMetrics('', ['services', 'click']);
                setOpen(true);
              }}
            >
              <span className={styles.title}>{title}</span>
              <span className={styles.arrow} />
            </button>
          </li>
        ))}
      </ul>

      <Popup isOpen={isOpen} onClose={() => setOpen(false)}>
        <H2 uppercase className={styles.serviceTitle}>{current.title}</H2>
        <ContentImage
          className={styles.servicePhoto}
          src={current.photo}
        />

        {current.content}
      </Popup>

      {layout === 'FULL' && (
      <TransitionGroup>
        <CSSTransition
          key={current.id}
          classNames={{
            enter: styles.enter,
            enterActive: styles.enterActive,
            exit: styles.exit,
            exitActive: styles.exitActive,
          }}
          timeout={200}
        >
          <ContentImage
            className={styles.photo}
            src={current?.photo}
          />
        </CSSTransition>
      </TransitionGroup>
      )}
    </MainLayout>
  );
};

export default Services;
