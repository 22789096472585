import React from 'react';
import { handleNoImage } from 'utils';
import styles from './styles.module.scss';

interface Props {
  src?: string,
}

const AvatarBlock = ({
  src,
}: Props) => (
  <img
    src={src || ''}
    className={styles.img}
    onError={handleNoImage}
    alt="AvatarBlock"
  />
);

export default AvatarBlock;
