import axios from 'axios';
import { envSwitch } from 'appConstants';

type Params = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: { [key: string]: any },
  emptyParamsToDelete?: Array<string>,
};

export const validateEmptyParams = ({
  params,
  emptyParamsToDelete = [],
}: Params) => {
  if (emptyParamsToDelete.length) {
    emptyParamsToDelete.forEach((paramKey) => {
      // eslint-disable-next-line no-param-reassign
      if (!params[paramKey]) delete params[paramKey];
    });
  }

  return params;
};

export const apiClient = axios.create({
  baseURL: envSwitch(),
});

export const hideAfterDate = (date: string) => {
  return new Date() > new Date(date);
};

export const sendMetrics = (YaTarget?: string|null, GTarget?:[
  string, // action
  'click' | 'form' | 'tel', // category
]|null) => {
  if (!YaTarget && !GTarget) return;
  if (YaTarget !== null) {
    // @ts-ignore: Unreachable code error
    ym(85387708, 'reachGoal', YaTarget || GTarget[0]);
  }
  if (GTarget !== null) {
    // https://developers.google.com/analytics/devguides/collection/gtagjs/events
    // @ts-ignore: Unreachable code error
    gtag('event', GTarget?.[0] || YaTarget, {
      event_category: GTarget?.[1] || 'click',
    });
  }
};

// export const uploadFile = (file, onSuccess, onFailure) => {
//   const data = new FormData();
//   data.append('file', file);

//   fetch('https://file.io/?expires=1w', {
//     method: 'POST',
//     mode: 'cors',
//     body: data,
//   })
//     .then(response => {
//       if (response.ok) {
//         return response.json();
//       // } else {
//       //   throw new Error(response.status);
//       }
//     })
//     .then(data => onSuccess(data))
//     .catch(error => {
//       onFailure();
//     });
// };
