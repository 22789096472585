import React, {
  FC,
  useRef,
  ChangeEvent,
} from 'react';
import { toast } from 'react-toastify';
import { AvatarBlock } from 'components';
import { _t } from 'utils';
import styles from './styles.module.scss';

type Props = {
  file?: string,
  fileFieldName?: string,
  token: string,
  uploadUrl: string,
  onUpdate?: () => void,
};

const ProfileAvatar: FC<Props> = ({
  file,
  fileFieldName = 'client_avatar',
  token,
  uploadUrl,
  onUpdate,
}) => {
  const inputEl = useRef(null);

  // const _onSuccess = data => {
  //   onUpload(data.link);
  // };

  const upload = (
    /* eslint-disable-next-line */
    fileIssue: any
  ) => {
    const data = new FormData();
    data.append(fileFieldName, fileIssue);
    fetch(uploadUrl, {
      method: 'PATCH',
      mode: 'cors',
      body: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(response.status === 400 ? 'Неверный формат изображения' : 'Ошибка загрузки, повторите позже');
      })
      .then(() => {
        toast.success(_t('Данные обновлены'));
        if (onUpdate) onUpdate();
      })
      .catch((err) => {
        toast.error(_t(err.message || 'Ошибка загрузки, повторите позже'));
      });
  };

  const handleSubmit = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    // upload(inputEl.current.files[0], _onSuccess, _onFailure);
    // eslint-disable-next-line
    const AttachedFile = (inputEl as any).current.files[0];
    if (AttachedFile) {
      upload(AttachedFile);
    }
  };

  return (
    <div className={styles.userPic}>
      <AvatarBlock
        src={file}
      />
      <input
        className={styles.uploader}
        onChange={handleSubmit}
        ref={inputEl}
        type="file"
        accept="image/*"
      />
    </div>
  );
};

export default ProfileAvatar;
