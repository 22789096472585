import React, {
  FC,
} from 'react';
import { Qualification } from './Qualification';
import { Validation } from './Validation';

type Props = {
  kindOfTest: 'qualification' | 'validation';
};

const Tests: FC<Props> = ({ kindOfTest }) => {
  switch (kindOfTest) {
    case 'qualification':
      return <Qualification />;
    case 'validation':
      return <Validation />;
    default:
      return <></>;
  }
};

export { Tests };
