import React, {
  FC,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
// import { useFormik } from 'formik';
// import { object, string } from 'yup';
// import {
//   Select,
// } from 'components';
// import { _t, _t_back } from 'utils';
import { _t_back } from 'utils';
import {
  State,
  LeadStatus,
} from 'types';
import { useRequest } from 'hooks';
import { URL } from 'appConstants';
// import { toast } from 'react-toastify';
// import { RequestStatus, State } from 'types';
// import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  handleSelectRegion: (id:number) => void,
};

const Step2: FC<Props> = ({
  handleSelectRegion = () => {},
}) => {
  const { data: indFCompData, request: IndFCompRequest } = useRequest<LeadStatus[]>();
  // const [indFCompParam, setIndFCompParam] = useState<number>(0);
  const token = useSelector(({ me }: State) => me.token);

  useEffect(() => {
    IndFCompRequest({
      method: 'GET',
      url: URL.PERSONAL.EXCHANGE.FILT_IFC,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      {indFCompData?.map((industry) => (
        <div
          key={industry.id}
          className={styles.catItem}
          onClick={() => handleSelectRegion(industry.id)}
        >
          {_t_back(industry, 'title')}
        </div>
      ))}
    </div>
  );
};

export default Step2;
