import React, { FC } from 'react';
import { Text } from 'components';
import { _t } from 'utils';
import styles from './style.module.scss';

interface Props {
  label: string,
  firstValue: JSX.Element,
  secondValue?: JSX.Element,
  className?: string,
}

const ItemWithLabel: FC<Props> = ({
  label,
  firstValue,
  secondValue,
  className,
}) => (
  <div className={className}>
    <Text
      tag="span"
      size="small"
      bold
      className={styles.label}
    >
      {_t(label)}
    </Text>
    <div className={styles.value}>
      {firstValue}
      {secondValue}
    </div>
  </div>
);

export default ItemWithLabel;
