import type {
  AuthReq,
  SignUpReq,
  IExportTest,
  IProfileSnake,
  ICompanySnake,
  SendApplicationsReq,
  RestoreReq,
  ConfirmPassReq,
  Activation,
} from 'types';
import actionTypes from './actionTypes';

export const meLoginAction = (payload: AuthReq) => ({
  type: actionTypes.ME_LOGIN,
  payload,
});

export const meRestoreAction = (payload: RestoreReq) => ({
  type: actionTypes.ME_RESTORE,
  payload,
});

export const newPasswordAction = (payload: ConfirmPassReq) => ({
  type: actionTypes.ME_NEWPASS,
  payload,
});

export const meLogoutAction = () => ({
  type: actionTypes.ME_LOGOUT,
});

export const meSignUpAction = (payload: SignUpReq) => ({
  type: actionTypes.ME_SIGN_UP,
  payload,
});

export const meActivateAction = (payload: Activation) => ({
  type: actionTypes.ME_ACTIVATE,
  payload,
});

export const meExportTestAction = (payload: IExportTest) => ({
  type: actionTypes.ME_EXPORT_TEST,
  payload,
});

export const meSendApplicationsAction = (payload: SendApplicationsReq) => ({
  type: actionTypes.ME_SEND_APPLICATIONS,
  payload,
});

export const meProfileUserAction = (payload:{ data: IProfileSnake, token: string }) => ({
  type: actionTypes.ME_PROFILE_USER,
  payload,
});

export const meProfileCompanyAction = (payload:{ data: ICompanySnake, token: string }) => ({
  type: actionTypes.ME_PROFILE_COMPANY,
  payload,
});
