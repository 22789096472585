import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import {
  Button, PersonalData, Input, Modal, Textarea,
} from 'components';
import { useFormik } from 'formik';
import { SendApplicationsReq, RequestStatus, State } from 'types';
import { object, string } from 'yup';
import { ERRORS } from 'appConstants';
import { sendMetrics } from 'utils';
import { meSendApplicationsAction } from 'store/me/actions';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';

interface Props {
  className?: string,
  onOpen?: () => void,
}

const initialValues: SendApplicationsReq = {
  nameOfCompany: '',
  fullName: '',
  position: '',
  phone: '',
  email: '',
  text: '',
};

const validationSchema = object().shape({
  nameOfCompany: string().required(ERRORS.required),
  fullName: string().required(ERRORS.required),
  position: string().required(ERRORS.required),
  phone: string().min(18, ERRORS.phone).required(ERRORS.required),
  email: string().email(ERRORS.email).required(ERRORS.required),
  text: string().required(ERRORS.required),
});

const ApplicationForm: FC<Props> = ({
  className,
  onOpen = () => {},
}) => {
  const dispatch = useDispatch();

  const [isOpen, setOpen] = useState<boolean>(false);
  const handleToggle = useCallback(() => {
    if (!isOpen) onOpen();
    setOpen((state) => !state);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const [checkbox, setCheckbox] = useState<boolean>(false);
  const handleChangeCheckbox = useCallback(() => {
    setCheckbox((state) => !state);
  }, []);

  const sendApplicationReqStatus = useSelector(({ ui }: State) => ui.ME_SEND_APPLICATION);

  const {
    values,
    errors,
    touched,
    setErrors,
    handleChange,
    handleSubmit,
  } = useFormik<SendApplicationsReq>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: (obj) => {
      dispatch(meSendApplicationsAction(obj));
      sendMetrics('', ['form_make_application', 'form']);
    },
  });

  useEffect(() => {
    if (sendApplicationReqStatus === RequestStatus.SUCCESS) {
      setOpen(false);
    }
  }, [sendApplicationReqStatus]);

  useEffect(() => {
    if (sendApplicationReqStatus === RequestStatus.ERROR) {
      setErrors({
        nameOfCompany: '',
        fullName: '',
        position: '',
        email: '',
        text: '',
        phone: '',
      });
    }
  }, [sendApplicationReqStatus]);

  const handleChangeWithMask = useCallback(({ target: { name, value } }) => {
    const event = {
      target: {
        name,
        value: value.replace(/_+$/, ''),
      },
    };

    handleChange(event);
  }, []);

  return (
    <>
      <Button
        onClick={handleToggle}
        className={className}
        title="Оформить заявку"
      />
      <Modal
        onClose={handleClose}
        title="Оставить заявку"
        isOpen={isOpen}
        className={styles.modal}
        classNameTitle={styles.title}
        classNameOverlay={styles.modalOverlay}
      >
        <form onSubmit={handleSubmit} className={styles.form}>
          <Input
            name="nameOfCompany"
            label="Название компании"
            placeholder="Введите текст"
            className={styles.input}
            value={values.nameOfCompany}
            error={errors?.hasOwnProperty('nameOfCompany') && touched.nameOfCompany ? errors.nameOfCompany || true : ''}
            onChange={handleChange}
          />
          <Input
            name="fullName"
            label="ФИО"
            placeholder="Фамилия Имя Отчество"
            className={styles.input}
            value={values.fullName}
            error={errors?.hasOwnProperty('fullName') && touched.fullName ? errors.fullName || true : ''}
            onChange={handleChange}
          />
          <Input
            name="position"
            label="Должность"
            placeholder="Введите текст"
            className={styles.input}
            value={values.position}
            error={errors?.hasOwnProperty('position') && touched.position ? errors.position || true : ''}
            onChange={handleChange}
          />
          <Input
            name="phone"
            label="Номер телефона"
            placeholder="+7 (___) ___-__-__"
            className={styles.input}
            value={values.phone}
            error={errors?.hasOwnProperty('phone') && touched.phone ? errors.phone || true : ''}
            onChange={handleChangeWithMask}
            mask="+7 (999) 999-99-99"
          />
          <Input
            name="email"
            label="E-mail"
            placeholder="example@gmail.com"
            className={styles.input}
            value={values.email}
            error={errors?.hasOwnProperty('email') && touched.email ? errors.email || true : ''}
            onChange={handleChange}
          />
          <Textarea
            name="text"
            label="Ваш вопрос"
            placeholder="Введите текст"
            className={styles.textarea}
            value={values.text}
            error={errors?.hasOwnProperty('text') && touched.text ? errors.text || true : ''}
            onChange={handleChange}
          />
          <PersonalData
            name="checkbox"
            checkbox={checkbox}
            onChange={handleChangeCheckbox}
            className={styles.checkbox}
          />
          <Button
            type="submit"
            disabled={!checkbox}
            isLoading={sendApplicationReqStatus === RequestStatus.REQUEST}
            title="Оформить заявку"
          />
        </form>
      </Modal>
    </>
  );
};

export default ApplicationForm;
