import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import { useRequest } from 'hooks';
import { URL } from 'appConstants';
import { Feed } from 'types';
import { FeedNewsBlock, NoData, Paginate } from 'components';
import {
  PersonalTabLayout,
  PersonalTabLayoutLeft,
  PersonalTabLayoutRight,
} from 'containers';
import tabImg from 'assets/img/personal/feed.svg';
import PersonalFeedRequest from '../PersonalFeedRequest';
// import { testFeedData } from './testData';
import styles from './styles.module.scss';

type Props = {};

const PAGE_SIZE = 15;

const PersonalFeedMainTab: FC<Props> = () => {
  const [page, setPage] = useState<number>(0);
  const { data: FeedData, request: FeedReq } = useRequest<Feed[]>();

  useEffect(() => {
    FeedReq({
      url: URL.PERSONAL.FEED.GET,
      params: {
        limit: PAGE_SIZE,
        offset: PAGE_SIZE * page,
      },
    });
  }, [page]);

  return (
    <PersonalTabLayout>
      <PersonalTabLayoutLeft
        className={styles.forTitle}
        tabTitle="публикация новости..."
        image={tabImg}
      >
        <PersonalFeedRequest />
      </PersonalTabLayoutLeft>
      <PersonalTabLayoutRight withoutWrap>
        <NoData
          className={styles.noData}
          // count={testFeedData?.length || FeedData?.length || 0}
          count={FeedData?.length || 0}
          warningText="Пока данных нет. Предложите новость, если хотите."
        >
          {FeedData?.map((FeedItem, index) => (
            <FeedNewsBlock
              key={index}
              FeedItem={FeedItem}
            />
          ))}
          <Paginate
            page={page}
            count={FeedData?.length || 0}
            pageSize={PAGE_SIZE}
            className={styles.paginate}
            onChange={(value) => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              setPage(value);
            }}
          />
        </NoData>
      </PersonalTabLayoutRight>
    </PersonalTabLayout>
  );
};

export default PersonalFeedMainTab;
