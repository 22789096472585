// author Victor K.
import React, { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = React.VideoHTMLAttributes<HTMLVideoElement>;

const HTMLVideo:FC<React.DetailedHTMLProps<Props, HTMLVideoElement>> = ({
  className,
  ...rest
}) => (
  <video
    className={cx(
      styles.videoInstruction,
      className,
    )}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    <track kind="captions" />
  </video>
);

export default HTMLVideo;
